/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { api, syncapi } from "helpers/common/api";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import '@inovua/reactdatagrid-community/theme/default-dark.css'
import { config } from "helpers/common/config";
import axios from 'axios'


import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useMaterialUIController } from "context";

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function ContentList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [contentData, setContentData] = useState([]);
  const [sortInfo, setSortInfo] = useState([]);
  const [changeCounter, setChangeCounter] = useState(0);

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole('Admin');

    console.log("filters", filters);

    let url =
      `${config.api.url}content/search/advance?totalResults=true` +
      '&offset=' +
      skip +
      '&limit=' +
      limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;


    return axios.get(url).then(res => {
      setContentData(res.data.items)
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }

  const dataSource = useCallback(loadData, [changeCounter]);
  console.log("dataSource", dataSource)

  const handleSwitchChange = (event, source, contentId) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;

    if (contentId > 0) {
      let data1 = {
        url: "content",
        query: "/" + contentId,
        body: {
          [source]: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log(res);
          common.notify("S", "Saved Successfully");
          setChangeCounter(changeCounter + 1);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => { }
      );
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Contents
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin && (
                  <MDButton variant="gradient" color="secondary" component={Link} to="/content">
                    New
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows={false}
                  showHoverRows={false}
                  showCellBorders="none"
                  theme={darkMode ? "default-dark" : "default-light"}
                  columns={[
                    {
                      header: "Action",
                      name: "id",
                      defaultWidth: 75,
                      headerAlign: "center",
                      textAlign: "center",
                      render: ({ value }) => (
                        <MDTypography
                          component={Link}
                          to={"/content/" + value}
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Edit
                        </MDTypography>
                      ),
                    },
                    {
                      header: "Title",
                      name: "titleEn",
                      defaultFlex: 1.5,
                      align: "left",
                      sortable: false,
                      render: ({ data, value }) => (
                        <MDBox display="flex" alignItems="center" lineHeight={2}>
                          <MDAvatar src={data.thumbnail} name={value} size="md" variant="square" />
                          <MDBox ml={2} lineHeight={2}>
                            <MDTypography display="block" variant="button" fontWeight="medium">
                              {value}
                            </MDTypography>

                            <MDBadge
                              badgeContent={data.contentKey}
                              variant="contained"
                              color="primary"
                            />
                            <MDBadge
                              badgeContent={moment.utc(data.runningTimeSecs * 1000).format("HH:mm")}
                              variant="contained"
                              color="success"
                            />
                          </MDBox>
                        </MDBox>
                      ),
                    },
                    {
                      header: "Type",
                      name: "$contentTypes.NAME$",
                      defaultFlex: 0.5,
                      align: "left",
                      render: ({ data, value }) => (
                        <MDTypography display="block" variant="button" fontWeight="medium">
                          {data.contentTypes.name}
                        </MDTypography>
                      ),
                    },
                    {
                      header: "Release Date",
                      name: "releaseDate",
                      defaultFlex: 1,
                      align: "left",
                      render: ({ data, value }) => {
                        return (
                          <MDBox display="flex" alignItems="center" lineHeight={1}>
                            <MDBox ml={2} lineHeight={1}>
                              <MDTypography display="block" variant="button" fontWeight="medium">
                                {moment(value).format("DD/MM/YYYY")}
                              </MDTypography>

                              <MDBox
                                size="sm"
                                color="white"
                                bgColor="secondary"
                                opacity={1}
                                variant="gradient"
                                p={0.3}
                              >
                                {data.yearReleased}
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        );
                      },
                    },
                    {
                      header: "Active",
                      name: "active",
                      defaultFlex: 0.7,
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              checked={data.active === "Y" ? true : false}
                              onChange={(e) => handleSwitchChange(e, "active", data.id)}
                              inputProps={{
                                "aria-label": "controlled",
                                align: "center",
                              }}
                            />
                          }
                          label=""
                        />
                      ),
                    },
                    {
                      header: "Featured",
                      name: "featured",
                      defaultFlex: 0.7,
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              name="featured"
                              checked={data.featured === "Y" ? true : false}
                              onChange={(e) => handleSwitchChange(e, "featured", data.id)}
                              inputProps={{
                                "aria-label": "controlled",
                                align: "center",
                              }}
                            />
                          }
                          label=""
                        />
                      ),
                    },
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={50}
                  rowHeight={60}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    { name: "title", operator: "startsWith", type: "string", value: "" },
                    { name: "titleEn", operator: "startsWith", type: "string", value: "" },
                    {
                      name: "$contentTypes.NAME$",
                      operator: "startsWith",
                      type: "string",
                      value: "",
                    },
                    { name: "active", operator: "eq", type: "string", value: "" },
                    { name: "featured", operator: "startsWith", type: "string", value: "" },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox sx={{ flexGrow: 1 }} mb={2}>
        <Grid
          container
          spacing={{ xs: 2, md: 2, lg: 2 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
        >
          {contentData.map((item) => (
            <Grid item xs={2} sm={4} md={3} lg={3}>
              <Card sx={{ maxWidth: 350 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.thumbnail}
                    alt={item.titleEn}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {item.titleEn}
                    </Typography>
                    <MDBadge badgeContent={item.contentKey} variant="contained" color="primary" />
                    <MDBadge
                      badgeContent={moment.utc(item.runningTimeSecs * 1000).format("HH:mm")}
                      variant="contained"
                      color="success"
                    />
                    <MDBadge
                      badgeContent={item.contentTypes.name}
                      variant="contained"
                      color="secondary"
                    />
                    <Typography gutterBottom variant="caption" component="div" noWrap mt={1}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Edit
                  </Button>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                  <Button size="small" color="primary">
                    Favorite
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ContentList;
