/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function BlogCommentsList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    emailId: "",
    body: "",
    postId: "",
    userId: "Y",
    parentId: null,    
    active: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    blogPost:[],
    users:[]
  });

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole('Admin');

    console.log("filters",filters," sorts:",sorts)
    let url =
      `${config.api.url}blogComments/search/advance?totalResults=true` +
      '&offset=' +
      skip +
      '&limit=' +
      limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;

    console.log("url",url)
    return axios.get(url).then(res => {
      console.log("blogComments api response",res.data.items)
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }

  const [changeCounter, setChangeCounter]= useState(0);

  const dataSource = useCallback(loadData, [changeCounter]);

  const handleSwitchChange = (event,id) => {    
    const { name, checked } = event.target;
    console.log("name: ", name," checked: ",checked);
    if (id> 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        [name]: checked ? "Y" : null
      }
      wsData = {
        url: "blogComments",
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          setChangeCounter(changeCounter+1);
          //loadData();        
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => { }
      );
    }

  };
  
  // const dataSource = useCallback(loadData, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Blog Comment
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin && (
                  <MDButton variant="gradient" color="secondary" component={Link} to="/blogcomment">
                    New
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    {
                      header: "Action",
                      name: "id",
                      defaultWidth: 100,
                      headerAlign: "center",
                      textAlign: "center",
                      render: ({ value }) => (
                        <MDTypography
                          component={Link}
                          to={"/blogcomment/" + value}
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Edit
                        </MDTypography>
                      ),
                    },
                    { header: "Name", name: "name", defaultFlex: 1 },
                    { header: "Email", name: "emailId", defaultFlex: 1, textAlign: "end" },
                    {
                      header: "Post",
                      name: "postId",
                      defaultFlex: 1,
                      textAlign: "end",
                      render: ({ value }) => (
                        <MDTypography
                          component={Link}
                          to={"/blogpost/" + value}
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          BlogPost-{value}
                        </MDTypography>
                      ),
                    },
                    { header: "body", name: "body", defaultFlex: 1, textAlign: "end" },
                    {
                      header: "Active",
                      name: "active",
                      defaultFlex: 1,
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              checked={data.active === "Y" ? true : false}
                              onChange={(e) => handleSwitchChange(e, data.id)}
                              inputProps={{
                                "aria-label": "controlled",
                                align: "center",
                              }}
                            />
                          }
                          label=""
                        />
                      ),
                    },
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    // { name: 'id', operator: 'eq', type: 'number', value: '' },
                    { name: "name", operator: "startsWith", type: "string", value: "" },
                    { name: "emailId", operator: "startsWith", type: "string", value: "" },
                    { name: "post", operator: "startsWith", type: "string", value: "" },
                    { name: "active", operator: "startsWith", type: "string", value: "" },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlogCommentsList;
