import { Link, useParams,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const _ = require('lodash'); 

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import 'react-image-crop/dist/ReactCrop.css';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 15,
    bottom: 15,
    border: 'none',
  },
}));




function FaqGroups() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));

  const [selected, setSelected] = useState({});
  const [selectedDtlId, setSelectedDtlId] = useState(0);

  const [isDtlLoading, setIsDtlLoading] = useState(false);
  const [dtlTableData, setDtlTableData] = useState([]);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    "id": 0,    
    "groupName": "",
    "description": "",
    "ordering": "",
    "active": "",   
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (id){
      loadData(id);
      setIsDisabled(false);
    }
  }, []);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleFaqSwitchChange = (event,id) => {
    console.log("handleFaqSwitchChange-id",id)
    const { name, checked } = event.target;
    setFormFaqData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
    if (id > 0) {
      let bodyData = {};
      bodyData = {
        // ordering: data[index]["ordering"],
        [name]: checked ? "Y" : null,
      };
      console.log("FAQ edit bodyData", bodyData);
      let wsData = {
        url: "faqs",
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadFaqs(res.data.item.groupId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }


  const [faqPopupOpen, setFaqPopupOpen] = useState(false);

  const [formFaqData, setFormFaqData] = useState({
    "id": 0,
    "groupId": "",
    "title": "",
    "faqText": "",
    "description": "",
    "ordering": "",
    "active": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null,
   
  });

  const handleFaqPopupClickOpen = () => {
    console.log("handleFaqPopupClickOpen")
    setFormFaqData({
      "id": 0,
      "groupId": null,
      "title": null,
      "faqText": null,
      "description": null,
      "ordering": null,
      "active": null,
      "created_by": null,
      "creation_date": null,
      "last_updated_by": null,
      "last_update_date": null,
      "last_update_login": null,
      "version_number": null
    }
    )
    setFaqPopupOpen(true);
  };

  const handleFaqPopupClose = () => {
    setFaqPopupOpen(false);
  };


  const handleAddFaq = (e) => {
    console.log("handleAddFaq")
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "faqs",
      method: "POST",
      body: {
        "groupId": formData.id,
        "title": formFaqData.title,
        "faqText": formFaqData.faqText,
        "description": formFaqData.description,
        "ordering": formFaqData.ordering,
        "active": formFaqData.active,       
      },
    };

    console.log("Add Faq with", data)
    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setFaqPopupOpen(false);
        setIsLoading(false);
        loadData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { }
    );

  }

  const onFaqEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...dtlTableData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log("rowId", rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);

      console.table(data);
      data[index][columnId] = value;
      setDtlTableData(data);

      // if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          // ordering: data[index]["ordering"],
          [columnId] : data[index][columnId]
        }
        console.log("FAQ edit bodyData",bodyData)
        wsData = {
          url: "faqs",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadFaqs(res.data.item.groupId)
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      // }
    },
    [dtlTableData]
  );

  const handleFaqFormChange = (val) => {
    const { name, value } = val.target;
    console.log(name, value)
    setFormFaqData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const onDeleteFaq = () => {
    console.log("onDeleteFaq -selected",selected)
    if (selected > 0) {
      const data1 = {
        url: "faqs",
        query: "/" + selected, //TODO..Need to remove contentId from collections
        method: "DELETE",
      };
  
      api.call(
        data1,
        (res) => {
          console.log("deleted Faq ", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => { }
      );
  
    }
  }

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);  

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "faqGroup",
        query: "/" + formData.id,
        body: {
          "groupName": formData.groupName,
          "description": formData.description,
          "ordering": formData.ordering,
          "active": formData.active,         
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "faqGroup",
        method: "POST",
        body: {
          "groupName": formData.groupName,
          "description": formData.description,
          "ordering": formData.ordering,
          "active": formData.active,         
        },
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0)
          loadData(currentData.id)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
        navigate('/faqgroupslist');
       }
    );
  }

 const handleFaqChange = (val) => {
    const { name, value } = val.target;
    setFormFaqData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const faqColumns = [    
    { name: "title", header: "Title", defaultFlex: 0.4 },
    { name: "faqText", header: "FAQ Text", defaultFlex: 0.8 },
    { name: "description", header: "Description", defaultFlex: 1.0 },
    { name: "ordering", header: "Ordering", defaultFlex: 0.5},
    { name: "active", header: "Active",  defaultFlex:0.6, textAlign: "center",
    render: ({ data, value }) => (
      <FormControlLabel
        control={
          <Switch
            name="active"
            checked={(data.active === "Y") ? true : false}
            onChange={(e)=> handleFaqSwitchChange(e,data.id)}
            inputProps={{
              "aria-label": "controlled",
              align: "center",
            }}
          />
        }
        label=""
      />
    ), } , 
   
  ];

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "faqGroup",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        setIsLoading(false);
        loadFaqs(pId)
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const loadFaqs = (pId) => {
    console.log("pId:",pId)
    setIsDtlLoading(true);
    const data1 = {
      url: "faqsAdvanceSearch",
      method: "GET",
      query: `?q={"groupId":{"$eq": ${pId}}}&orderBy=[["ordering", "ASC"]]`,

    };
    api.call(
      data1,
      (res) => {
        setDtlTableData(res.data.items);
        setFormFaqData(res.data.items);
        setIsDtlLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsDtlLoading(false);
      },
      (final) => { }
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Faq Group
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Group Name"
                            name="groupName"
                            value={formData.groupName}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={8}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={2}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active ? "Y" : null}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="number"
                            label="Ordering"
                            name="ordering"
                            value={formData.ordering}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/faqgroupslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                    <Dialog
                      open={faqPopupOpen}
                      onClose={handleFaqPopupClose}
                      maxWidth="md"
                      fullWidth={true}
                    >
                      <DialogTitle>FAQ</DialogTitle>
                      <DialogContent>
                        <DialogContentText>Add or edit a new FAQ</DialogContentText>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              required
                              label="Title"
                              name="title"
                              onChange={handleFaqChange}
                              value={formFaqData.title}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              required
                              label="FAQ Text"
                              name="faqText"
                              onChange={handleFaqChange}
                              value={formFaqData.faqText}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              label="Description"
                              multiline
                              rows={4}
                              name="description"
                              value={formFaqData.description}
                              onChange={handleFaqChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={3}>
                            <MDInput
                              type="number"
                              label="Ordering"
                              name="ordering"
                              onChange={handleFaqChange}
                              value={formFaqData.ordering}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Switch
                                  name="active"
                                  checked={formFaqData.active ? "Y" : null}
                                  onChange={(e) => handleFaqSwitchChange(e, 0)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Active"
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <MDButton onClick={handleFaqPopupClose}>Cancel</MDButton>
                        <MDButton onClick={handleAddFaq}>Ok</MDButton>
                      </DialogActions>
                    </Dialog>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-start", p: 0, mb: 1 }}>
                      {formData.id > 0 && (
                        <MDButton
                          variant="gradient"
                          color="info"
                          sx={{ mr: 1 }}
                          onClick={handleFaqPopupClickOpen}
                        >
                          Add
                        </MDButton>
                      )}
                      {selected && selected > 0 && (
                        <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteFaq}>
                          Delete
                        </MDButton>
                      )}
                    </MDBox>
                    <MDBox>
                      <ReactDataGrid
                        idProperty="id"
                        selected={selected}
                        style={gridStyle}
                        checkboxColumn
                        editable
                        onEditComplete={onFaqEditComplete}
                        multiSelect={false}
                        onSelectionChange={onSelectionChange}
                        columns={faqColumns}
                        loading={isDtlLoading}
                        dataSource={dtlTableData}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default FaqGroups;
