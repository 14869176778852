import { Link, useParams, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
import Select from "react-select";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import IconButton from "@mui/material/IconButton";
import { config } from "helpers/common/config";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
// import PdfViewerComponent from "components/PdfViewerComponent";
import PDFWebViewer from "components/UI/pdfWebViewer";

const gridStyle = { minHeight: 250, minWidth: 50, marginRight: 20, marginLeft: 20 };

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    bottom: 3,
    border: "none",
  },
}));

const Image = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100,
  }),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 1 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Contract() {
  const { id } = useParams();
  console.log(common.userInRole("Admin"));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");
  const [sortInfo, setSortInfo] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole("Admin"));

  const [contractItemSelected, setContractItemSelected] = useState({});
  const [contractItemRegionSelected, setContractItemRegionSelected] = useState({});

  const [fileInfo, setFileInfo] = useState({ file: "", type: "" });
  const [instance, setInstance] = useState();
  const [docLoaded, setDocLoaded] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOnReady = (instance) => {
    setDocLoaded(false);
    const { Core, UI } = instance;
    // adding an event listener for when a document is loaded
    Core.documentViewer.addEventListener("documentLoaded", () => {
      console.log("document loaded");
      setDocLoaded(true);
    });
    /*
  if (instance)
  {
  setDocLoaded(true);
  instance.contentDocument.addEventListener("mouseup", handleMouseUp);
  }
  */
    console.log(instance);
    setInstance(instance);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const onContractItemSelectionChange = useCallback(({ selected }) => {
    setContractItemSelected(selected);
    console.log("contractItemSelected", selected);
  }, []);

  const onContractItemRegionSelectionChange = useCallback(({ selected }) => {
    setContractItemRegionSelected(selected);
    console.log("ContractItemRegionSelected", selected);
  }, []);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: 0,
    contractNo: "",
    contractDate: "",
    contractType: "",
    status: "",
    contractValueType: "",
    firstPartyId: "",
    thirdPartyId: "",
    effStartDate: "",
    effEndDate: "",
    contractValue: "",
    funcCurrencyCode: "",
    transCurrencyCode: "",
    description: "",
    active: "",
    contractDocPath: "",
    enquiryId: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    contractItems: [],
    firstParties: {},
    thirdParties: {},
    funcCurrency: {},
    transCurrency: {},
  });

  const [formContractItemData, setFormContractItemData] = useState({
    id: 0,
    contractHdrId: "",
    displayOrder: "",
    contentId: "",
    contentRightId: "",
    // "exclusiveRight": "",
    // "durationType": "",
    // "durationUom": "",
    // "durationValue": "",
    remarks: "",
    status: "",
    effStartDate: "",
    effEndDate: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    contractItemRegions: [],
  });

  const [formContractItemRegionData, setFormContractItemRegionData] = useState({
    id: 0,
    contractItemId: "",
    regionId: "",
    incExlType: "",
    effStartDate: "",
    effEndDate: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    contractItemRegions: [],
  });

  //Status & ValueType
  const [statusListData, setStatusListData] = useState([]);
  const [ValueTypeListData, setValueTypeListData] = useState([]);

  const loadStatusList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_CONTRACT_STATUS"},"enabledFlag":{"$eq":"Y"}}&orderBy=[["ordering", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadStatusList", res.data.items);
        setStatusListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleStatusChange = (val) => {
    const name = "status";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  const loadValueTypeList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_CONTRACT_VALUE_TYPE"}}&orderBy=[["id", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadStatusList", res.data.items);
        setValueTypeListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleValueTypeChange = (val) => {
    const name = "contractValueType";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  useEffect(() => {
    loadStatusList();
    loadValueTypeList();
    if (id) loadData(id);
  }, []);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleGridSwitchChange = (event, id, url) => {
    const { name, checked } = event.target;
    console.log("name: ", name, " checked: ", checked);
    if (id > 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        [name]: checked ? "Y" : null,
      };

      wsData = {
        url: url,
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  //For First & Third Parties
  const [partyQuery, setPartyQuery] = useState("");

  const loadPartyOptions = async (inputValue) => {
    console.log("loadPartyOptions", inputValue);
    const res = await axios.get(
      `${config.api.url}parties/search/advance?q={"partyName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    return res.data.items;
  };
  const onPartyChange = (e, fieldName, objName) => {
    console.log("onPartyChange", e, "   ", e.id, " fieldName ", fieldName);
    if (e) {
      let localData = formData;
      localData[fieldName] = e.id;
      localData[objName]["partyName"] = e.partyName;
      setFormData(localData);
      console.log("onPartyChange-setFormData", formData);
    }
  };

  //For Currencies
  const [currencyQuery, setCurrencyQuery] = useState("");

  const loadCurrencyOptions = async (inputValue) => {
    console.log("loadCurrencyOptions", inputValue);
    const res = await axios.get(
      `${config.api.url}currencies/search/advance?q={"code":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    return res.data.items;
  };
  const onCurrencyChange = (e, fieldName, objName) => {
    console.log("onCurrencyChange", e, "   ", e.id, " fieldName ", fieldName);
    if (e) {
      let localData = formData;
      localData[fieldName] = e.code;
      localData[objName]["code"] = e.code;
      setFormData(localData);
      console.log("onCurrencyChange-setFormData", formData);
    }
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddContractItem = (e) => {
    console.log("handleAddContractItem");
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contractItems",
      method: "POST",
      body: {
        contractHdrId: formContentData.contractHdrId,
        displayOrder: formContentData.displayOrder,
        contentId: formContentData.contentId,
        contentRightId: formContentData.contentRightId,
        // exclusiveRight: formContentData.exclusiveRight,
        // durationType: formContentData.durationType,
        // durationUom: formContentData.durationUom,
        // durationValue: formContentData.durationValue,
        remarks: formContentData.remarks,
        status: formContentData.status,
        effStartDate: formContentData.effStartDate,
        effEndDate: formContentData.effEndDate,
        active: formContentData.active,
      },
    };

    console.log("Add ContractItem with", data);
    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setContentPopupOpen(false);
        setIsLoading(false);
        loadData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onContractItemEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...formData.contractItems];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log("data[index]", data[index]);
      console.log('data[index]["id"]', data[index]["contractItems"]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setFormData((prevState) => {
        return {
          ...prevState,
          contractItems: data,
        };
      });

      if (data[index]["contractItems"]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: data[index]["active"],
        };
        wsData = {
          url: "contractItems",
          query: "/" + data[index]["contractItems"]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadData(id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [formData.contractItems]
  );

  const onContractItemRegionEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...formData.contractItems[0].contractItemRegions]; //TODO
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log("data[index]", data[index]);
      console.log('data[index]["id"]', data[index]["contractItemRegions"]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setFormData((prevState) => {
        return {
          ...prevState,
          contractItemRegions: data,
        };
      });

      if (data[index]["contractItemRegions"]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: data[index]["active"],
        };
        wsData = {
          url: "contractItemRegions",
          query: "/" + data[index]["contractItemRegions"]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadData(id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [formData.contractItems]
  );

  const handleContractItemFormChange = (val) => {
    const { name, value } = val.target;
    console.log(name, value);
    setFormContentData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const onDeleteContractItem = () => {
    if (contractItemSelected > 0) {
      const data1 = {
        url: "contractItems",
        query: "/" + contractItemSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted contractItem", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const onDeleteContractItemRegion = () => {
    if (contractItemSelected > 0) {
      const data1 = {
        url: "contractItemRegions",
        query: "/" + contractItemRegionSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted contractItem", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const [contractItemPopupOpen, setContractItemPopupOpen] = useState(false);
  const handleContractItemPopupClickOpen = () => {
    setFormContractItemData({
      id: 0,
      contractHdrId: "",
      displayOrder: "",
      contentId: "",
      contentRightId: "",
      // "exclusiveRight": "",
      // "durationType": "",
      // "durationUom": "",
      // "durationValue": "",
      remarks: "",
      status: "",
      effStartDate: "",
      effEndDate: "",
      active: "",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
      contractItemRegions: [],
    });
    setContractItemPopupOpen(true);
  };

  const handleContractItemPopupClose = () => {
    setContractItemPopupOpen(false);
  };

  const handleSubmit = (e, sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    let body = {
      contractNo: formData.contractNo,
      contractDate: formData.contractDate,
      contractType: formData.contractType,
      status: formData.status,
      contractValueType: formData.contractValueType,
      firstPartyId: formData.firstPartyId,
      thirdPartyId: formData.thirdPartyId,
      effStartDate: formData.effStartDate,
      effEndDate: formData.effEndDate,
      contractValue: formData.contractValue,
      funcCurrencyCode: formData.funcCurrencyCode,
      transCurrencyCode: formData.transCurrencyCode,
      description: formData.description,
      active: formData.active,
      enquiryId: formData.enquiryId,
    };
    console.log("formData.id", formData.id);
    if (formData.id > 0)
      data1 = {
        url: "contractHeader",
        query: "/" + formData.id,
        body: body,
        method: "PATCH",
      };
    else
      data1 = {
        url: "contractHeader",
        method: "POST",
        body: body,
      };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) {
          console.log("ContractHeader", currentData.id);
          uploadContractDoc(currentData.id);
          loadData(currentData.id);
        }
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if (sourceLabel === "Save") navigate("/contractlist");
      }
    );
  };

  const uploadContractDoc = (contractId) => {
    let contractDocPath = formData.contractDocPath;
    console.log("uploadContractDoc-contractDocPath", contractDocPath);

    if (!(contractDocPath instanceof Object)) {
      return;
    }
    // setIsLoading(true);
    console.log("payload.uploadContractDoc", contractDocPath);

    let payLoad = new FormData();
    payLoad.append("file", contractDocPath, "contractDoc.pdf");
    console.log("payLoad", payLoad.get("file"));

    let data1 = {
      url: "contractHeader",
      query: "/uploadContractDoc/" + contractId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  const handleContractDoc = (event) => {
    console.log("handleContractDoc :" + event.target.files[0].name);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      let handleContractDoc = event.target.files[0];
      console.log("handleContractDoc", handleContractDoc);
      formData.contractDocPath = handleContractDoc;
      console.log("formData.contractDocPath", formData.contractDocPath);
      //TODO: Upload to cloud and get relative Path
    }
  };

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "contractHeader",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        currentData.contractDate = moment(currentData.contractDate).format("YYYY-MM-DD");
        currentData.effStartDate = moment(currentData.effStartDate).format("YYYY-MM-DD");
        currentData.effEndDate = currentData.effEndDate
          ? moment(currentData.effEndDate).format("YYYY-MM-DD")
          : null;
        setFormData(currentData);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Contract
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="ContractNo "
                            name="contractNo"
                            value={formData.contractNo}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="ContractType "
                            name="contractType"
                            value={formData.contractType}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        {/* <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Status "
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid> */}

                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="ContractValue"
                            name="contractValue"
                            value={formData.contractValue}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="contractDate"
                              label="ContractDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.contractDate}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="effStartDate"
                              label="Effective StartDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.effStartDate}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="effEndDate"
                              label="Effective EndDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.effEndDate}
                              onChange={handleChange}
                            />
                          </Grid>
                        </LocalizationProvider>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <div className="custom-select">
                            <span>Status</span>
                            <Select
                              id="status"
                              label="Status"
                              name="status"
                              onChange={handleStatusChange}
                              value={statusListData.find(
                                (i) => i.lookupCode == formData.status //compare string with number value
                              )}
                              options={statusListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select status"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="custom-select">
                            <span>Contract Value Type</span>
                            <Select
                              id="contractValueType"
                              label="ValueType"
                              name="contractValueType"
                              onChange={handleValueTypeChange}
                              value={ValueTypeListData.find(
                                (i) => i.lookupCode == formData.contractValueType //compare string with number value
                              )}
                              options={ValueTypeListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select Contract ValueType"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="selectWrapper">
                            <label>FuncCurrency *</label>
                            <AsyncSelect
                              label="Func Currency"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search Func Currency"
                              cacheOptions
                              fullWidth
                              value={{
                                code: formData.funcCurrency.code,
                                id: formData.funcCurrencyCode,
                              }}
                              getOptionLabel={(e) => e.code}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadCurrencyOptions}
                              onInputChange={(value) => setCurrencyQuery(value)}
                              onChange={(e) =>
                                onCurrencyChange(e, "funcCurrencyCode", "funcCurrency")
                              }
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="selectWrapper">
                            <label>TransCurrency *</label>
                            <AsyncSelect
                              label="TransCurrency"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search TransCurrency"
                              cacheOptions
                              fullWidth
                              value={{
                                code: formData.transCurrency.code,
                                id: formData.transCurrencyCode,
                              }}
                              getOptionLabel={(e) => e.code}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadCurrencyOptions}
                              onInputChange={(value) => setCurrencyQuery(value)}
                              onChange={(e) =>
                                onCurrencyChange(e, "transCurrencyCode", "transCurrency")
                              }
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>FirstParty *</label>
                            <AsyncSelect
                              label="FirstParty"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search first party"
                              cacheOptions
                              fullWidth
                              value={{
                                partyName: formData.firstParties.partyName,
                                id: formData.firstPartyId,
                              }}
                              getOptionLabel={(e) => e.partyName}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadPartyOptions}
                              onInputChange={(value) => setPartyQuery(value)}
                              onChange={(e) => onPartyChange(e, "firstPartyId", "firstParties")}
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>ThirdParty *</label>
                            <AsyncSelect
                              label="ThirdParty"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search third party"
                              cacheOptions
                              fullWidth
                              value={{
                                partyName: formData.thirdParties?.partyName,
                                id: formData.thirdPartyId,
                              }}
                              getOptionLabel={(e) => e.partyName}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadPartyOptions}
                              onInputChange={(value) => setPartyQuery(value)}
                              onChange={(e) => onPartyChange(e, "thirdPartyId", "thirdParties")}
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={4}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <p>Upload contract Doc:</p>
                          <StyledBadge
                            overlap="rectangular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload contract doc"
                                component="label"
                                size="xl"
                                onChange={handleContractDoc}
                              >
                                <input hidden accept="*/*" type="file" />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Image
                              src={formData.contractDocPath}
                              variant="square"
                              size="xxl"
                              shadow="sm"
                            ></Image>
                          </StyledBadge>
                          {formData.contractDocPath && (
                            <Button variant="text" onClick={handleOpen}>
                              Click to open contract doc
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        sx={{ mr: 1 }}
                        component={Link}
                        to="/contractlist"
                      >
                        Cancel
                      </MDButton>
                      {/* <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        name = "action"
                        disabled={formError}
                      >
                        {!isLoading ? "Action" : "Processing..."}
                      </MDButton> */}
                    </MDBox>
                    <MDBox pt={2}>
                      <MDBox pt={2} pb={3} px={0} py={0}>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                          indicatorColor="secondary"
                          textColor="inherit"
                          // variant="fullWidth"
                        >
                          <Tab label="Items" {...a11yProps(0)} />
                          <Tab label="Details" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                          <MDBox pt={1}>
                            <>
                              <MDBox
                                pt={1}
                                sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}
                              >
                                {/* {isAdmin && (
                            <MDButton
                              color="info"
                              sx={{ mr: 1 }}
                              onClick={handleContractItemPopupClickOpen}
                            >
                              Add ContractItem
                            </MDButton>
                          )} */}
                                {isAdmin && contractItemSelected > 0 && (
                                  <MDButton
                                    color="error"
                                    sx={{ mr: 1 }}
                                    onClick={onDeleteContractItem}
                                  >
                                    Delete
                                  </MDButton>
                                )}
                              </MDBox>
                              <MDBox pt={1}>
                                <ReactDataGrid
                                  idProperty="id"
                                  style={gridStyle}
                                  selected={contractItemSelected}
                                  checkboxColumn
                                  multiSelect={false}
                                  showZebraRows
                                  showHoverRows
                                  editable
                                  onEditComplete={onContractItemEditComplete}
                                  onSelectionChange={onContractItemSelectionChange}
                                  showCellBorders="vertical"
                                  columns={[
                                    {
                                      // defaultLocked: true,
                                      header: "DisplayOrder ",
                                      name: "displayOrder",
                                      // defaultFlex: 0.5,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.displayOrder}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Content Name",
                                      name: "contentId",
                                      // defaultFlex: 0.5,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.content.titleEn}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Content Right",
                                      name: "contentRightId",
                                      // defaultFlex: 0.5,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.contentRights?.rightType?.name}
                                        </MDTypography>
                                      ),
                                    },
                                    // {
                                    //   header: "ExclusiveRight",
                                    //   name: "exclusiveRight",
                                    //   // defaultFlex: 0.5,
                                    //   headerAlign: "center",
                                    //   textAlign: "center",
                                    //   render: ({ data, value }) => (
                                    //     <FormControlLabel
                                    //       control={
                                    //         <Switch
                                    //           name="exclusiveRight"
                                    //           checked={data.exclusiveRight === "Y" ? true : false}
                                    //           onChange={(e) =>
                                    //             handleGridSwitchChange(e, data.id, "contractItems")
                                    //           }
                                    //           inputProps={{
                                    //             "aria-label": "controlled",
                                    //             align: "center",
                                    //           }}
                                    //         />
                                    //       }
                                    //       label=""
                                    //     />
                                    //   ),
                                    // },
                                    {
                                      header: "Active",
                                      name: "active",
                                      // defaultFlex: 0.5,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              name="active"
                                              checked={data.active === "Y" ? true : false}
                                              onChange={(e) =>
                                                handleGridSwitchChange(e, data.id, "contractItems")
                                              }
                                              inputProps={{
                                                "aria-label": "controlled",
                                                align: "center",
                                              }}
                                            />
                                          }
                                          label=""
                                        />
                                      ),
                                    },

                                    {
                                      header: "Eff StartDate",
                                      name: "effStartDate",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {/* {data.effStartDate} */}
                                          {data.effStartDate
                                            ? moment(data.effStartDate).format("DD-MM-YYYY")
                                            : ""}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Eff EndDate",
                                      name: "effEndDate",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {/* {data.effEndDate} */}
                                          {data.effEndDate
                                            ? moment(data.effEndDate).format("DD-MM-YYYY")
                                            : ""}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Status",
                                      name: "status",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.status}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Remarks",
                                      name: "remarks",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.remarks}
                                        </MDTypography>
                                      ),
                                    },
                                  ]}
                                  pagination
                                  dataSource={formData.contractItems}
                                  defaultLimit={5}
                                  onSortInfoChange={setSortInfo}
                                  enableColumnAutosize
                                />
                              </MDBox>

                              <MDBox pt={1}>
                                {contractItemSelected > 0 &&
                                  formData.contractItems[0].contractItemRegions && (
                                    <MDBox>
                                      {isAdmin && contractItemRegionSelected > 0 && (
                                        <MDButton
                                          color="error"
                                          sx={{ mr: 1 }}
                                          onClick={onDeleteContractItemRegion}
                                        >
                                          Delete
                                        </MDButton>
                                      )}

                                      <MDBox pt={1}>
                                        <ReactDataGrid
                                          idProperty="id"
                                          style={gridStyle}
                                          scrollProps={false}
                                          selected={contractItemRegionSelected}
                                          checkboxColumn
                                          multiSelect={false}
                                          showZebraRows
                                          showHoverRows
                                          editable
                                          onEditComplete={onContractItemRegionEditComplete}
                                          onSelectionChange={onContractItemRegionSelectionChange}
                                          showCellBorders="vertical"
                                          columns={[
                                            {
                                              header: "Region",
                                              name: "regionId",
                                              render: ({ data, value }) => (
                                                <MDTypography
                                                  variant="caption"
                                                  color="text"
                                                  fontWeight="medium"
                                                >
                                                  {data.region.name}
                                                </MDTypography>
                                              ),
                                            },
                                            {
                                              header: "IncExlType",
                                              name: "incExlType",
                                              defaultFlex: 0.5,
                                              render: ({ data, value }) => (
                                                <MDTypography
                                                  variant="caption"
                                                  color="text"
                                                  fontWeight="medium"
                                                >
                                                  {data.incExlType}
                                                </MDTypography>
                                              ),
                                            },
                                            {
                                              header: "EffStartDate",
                                              name: "effStartDate",
                                              defaultFlex: 0.5,
                                              render: ({ data, value }) => (
                                                <MDTypography
                                                  variant="caption"
                                                  color="text"
                                                  fontWeight="medium"
                                                >
                                                  {/* {data.effStartDate} */}
                                                  {data.effStartDate
                                                    ? moment(data.effStartDate).format("DD-MM-YYYY")
                                                    : ""}
                                                </MDTypography>
                                              ),
                                            },
                                            {
                                              header: "EffEndDate",
                                              name: "effEndDate",
                                              defaultFlex: 0.7,
                                              headerAlign: "center",
                                              textAlign: "center",
                                              render: ({ data, value }) => (
                                                <MDTypography
                                                  variant="caption"
                                                  color="text"
                                                  fontWeight="medium"
                                                >
                                                  {/* {data.effEndDate} */}
                                                  {data.effEndDate
                                                    ? moment(data.effEndDate).format("DD-MM-YYYY")
                                                    : ""}
                                                </MDTypography>
                                              ),
                                            },
                                            {
                                              header: "Active",
                                              name: "active",
                                              defaultFlex: 0.5,
                                              headerAlign: "center",
                                              textAlign: "center",
                                              render: ({ data, value }) => (
                                                <FormControlLabel
                                                  control={
                                                    <Switch
                                                      name="active"
                                                      checked={data.active === "Y" ? true : false}
                                                      onChange={(e) =>
                                                        handleGridSwitchChange(
                                                          e,
                                                          data.id,
                                                          "contractItemRegions"
                                                        )
                                                      }
                                                      inputProps={{
                                                        "aria-label": "controlled",
                                                        align: "center",
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />
                                              ),
                                            },
                                          ]}
                                          pagination
                                          dataSource={formData.contractItems[0].contractItemRegions}
                                          defaultLimit={10}
                                          onSortInfoChange={setSortInfo}
                                          enableColumnAutosize
                                        />
                                      </MDBox>
                                    </MDBox>
                                  )}
                              </MDBox>
                            </>
                          </MDBox>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                          <MDBox pt={1}>
                            <>
                              <MDBox
                                pt={1}
                                sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}
                              >
                                {isAdmin && contractItemSelected > 0 && (
                                  <MDButton
                                    color="error"
                                    sx={{ mr: 1 }}
                                    onClick={onDeleteContractItem}
                                  >
                                    Delete
                                  </MDButton>
                                )}
                              </MDBox>
                              <MDBox pt={1}>
                                <ReactDataGrid
                                  idProperty="id"
                                  style={gridStyle}
                                  selected={contractItemSelected}
                                  checkboxColumn
                                  multiSelect={false}
                                  showZebraRows
                                  showHoverRows
                                  editable
                                  onEditComplete={onContractItemEditComplete}
                                  onSelectionChange={onContractItemSelectionChange}
                                  showCellBorders="vertical"
                                  columns={[
                                    {
                                      // defaultLocked: true,
                                      header: "DisplayOrder ",
                                      name: "displayOrder",
                                      // defaultFlex: 1.2,
                                      // defaultWidth: 70,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.displayOrder}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Clause",
                                      name: "clauseId",
                                      // defaultFlex: 1,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.clauseId}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "ClauseNo",
                                      name: "clauseNo",
                                      // defaultFlex: 1.2,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.clauseNo}
                                        </MDTypography>
                                      ),
                                    },

                                    {
                                      header: "Clause Header",
                                      name: "clauseHeader",
                                      // defaultFlex: 1.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.clauseHeader}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Clause Text",
                                      name: "clauseText",
                                      // defaultFlex: 1.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.clauseText}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Parent",
                                      name: "parentId",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.parentId}
                                        </MDTypography>
                                      ),
                                    },

                                    {
                                      header: "Active",
                                      name: "active",
                                      // defaultFlex: 0.5,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              name="active"
                                              checked={data.active === "Y" ? true : false}
                                              onChange={(e) =>
                                                handleGridSwitchChange(e, data.id, "contractItems")
                                              }
                                              inputProps={{
                                                "aria-label": "controlled",
                                                align: "center",
                                              }}
                                            />
                                          }
                                          label=""
                                        />
                                      ),
                                    },
                                    {
                                      header: "Eff StartDate",
                                      name: "effStartDate",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {/* {data.effStartDate} */}
                                          {data.effStartDate
                                            ? moment(data.effStartDate).format("DD-MM-YYYY")
                                            : ""}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Eff EndDate",
                                      name: "effEndDate",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {/* {data.effEndDate} */}
                                          {data.effEndDate
                                            ? moment(data.effEndDate).format("DD-MM-YYYY")
                                            : ""}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Status",
                                      name: "status",
                                      // defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.status}
                                        </MDTypography>
                                      ),
                                    },
                                  ]}
                                  pagination
                                  dataSource={formData.contractDetails}
                                  defaultLimit={5}
                                  onSortInfoChange={setSortInfo}
                                  enableColumnAutosize
                                />
                              </MDBox>
                            </>
                          </MDBox>
                        </TabPanel>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
                  <Box display="flex" alignItems="center">
                    <Box textAlign="center" flexGrow={1}>
                      Contract Document
                    </Box>
                    <Box>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <DialogContent>
                    {/* <PdfViewerComponent
                      document={formData.agreementFileUrl}
                      // onReady={handleOnReady}
                    /> */}
                    <PDFWebViewer document={formData.contractDocPath} />
                  </DialogContent>
                </Dialog>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Contract;
