import { useEffect, useState, useRef,useCallback } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async'
// import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const _ = require('lodash');

import { styled } from '@mui/material/styles';
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded';
import Badge from '@mui/material/Badge';
import Slider from '@mui/material/Slider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 1 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
} 

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 15,
    bottom: 15,
    border: 'none',
  },
}));


const Image = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `2px solid ${theme.palette.background.paper}`,
}));

import CropModal from "../../../../components/modal/cropModal";
import ImageCropper from "../../../../components/UI/imageCropper";
import axios from 'axios';
import { config } from "helpers/common/config";


const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}


function ContentPreview() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [isDisabled, setIsDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [isDtlLoading, setIsDtlLoading] = useState(false);
  const gridStyle = { minHeight: 500 };
  const [isEdit, setIsEdit] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    contentId: "",
    previewKey: "",
    description: "",
    previewType: "",
    securityType: "",
    password:"",
    status: "",
    active: "Y",
    ordering: "",
    noOfViews: "",
    avgRating: "",
    noOfRatings: "",
    thumbnailUrl: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,   
  });

  // Image upload -- start
  const [uploadFile, setUploadFile] = useState("");
  const [toggleCropModal, setToggleCropModal] = useState(false);
  const [imageRatio, setImageRatio] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  const profileImageInput = useRef(null);

  const [settingData, setSettingData] = useState({
    "id": 0,
    "settingsContext": "",
    "settingsKey": "",
    "value": "",
    "settingsType": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });

  const loadSettings = (settingsContext, settingsKey) => {
    // setIsLoading(true);
    const data1 = {
      url: "settings",
      method: "GET",
      query: `/search/advance?q={"settingsContext":{"$eq": "${settingsContext}"},"settingsKey":{"$eq": "${settingsKey}"}}&offset=0&limit=50`,
    };

    console.log("loadSettings:API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("loadSettings:res.data", res.data.items);
        let currentData = res.data.items[0];
        setSettingData(currentData);
        console.log("settingData",settingData)
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const imageUploadHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadFile(URL.createObjectURL(file));
      setToggleCropModal(true);      
      //Get image aspect Ration from setting api
      console.log("settingData.value",settingData.value)
      let splitter = new RegExp('x','i');
      const [width, height] = settingData?.value?.split(splitter)
      console.log("width, height",width, height)
      setImageRatio(width  / height);
    }
  };
  
  const getCroppedFile = (croppedImage) => {
    console.log("getCroppedFile -croppedImage",croppedImage)
    setCroppedImage(croppedImage);
    setToggleCropModal(false);
    formData.thumbnailUrl = URL.createObjectURL(croppedImage);
    setCroppedImagePreview(URL.createObjectURL(croppedImage));
    console.log("formData.image",formData.thumbnailUrl);
  };
  
  const uploadImgDelete = () => {
    profileImageInput.current.value = null;
  };

  // Image upload -- end 

  useEffect(() => {
    console.log("id",id)
    loadSettings("Content","thumbnail");
    
    if (id){
      loadData(id);
      setIsDisabled(false);
    }
    console.log("IsDisabled",isDisabled)     
  }, []);
  
  const [contentQuery, setContentQuery] = useState("");
  const [selectedContent,setSelectedContent] = useState();

  const loadContentsOptions = async (inputValue) => {
    console.log("loadContentsOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}content/search/advance?q={"titleEn":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    console.log("loadContentsOptions.res.data.items",res.data.items)
    return res.data.items;
  }

  const onContentChange = (value) => {
    console.log("onContentChange-value", value);   
    if (value) {
      formData.contentId = value.id;
      setSelectedContent(value)
      console.log(" formData.postId ", formData.contentId)
    }
    console.log("onUsersChange-selectedContent",selectedContent);    
  }
 
  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    let bodyData ={
      name: formData.name,
      contentId: formData.contentId,
      // previewKey: formData.previewKey,
      description: formData.description,
      previewType: formData.previewType,
      securityType: formData.securityType, 
      status: formData.status,
      ordering: formData.ordering,
      noOfViews: formData.noOfViews,
      avgRating: formData.avgRating,
      noOfRatings: formData.noOfRatings,
      thumbnailUrl: formData.thumbnailUrl,      
      active: formData.active,
    };
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "contentPreviews",
        query: "/" + formData.id,
        body: bodyData,
        method: "PATCH",
      };
    else
      data1 = {
        url: "contentPreviews",
        method: "POST",
        body: bodyData,
      };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;      
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0){          
          console.log("currentData.id",currentData.id)
          // loadData(currentData.id);
          uploadThumbnail();
        }
      },
      (error) => {
        if (error && error.status && error.status===500)
          common.notify("E","Api Error " +  error.data.msg);
        else
          common.notify("E", "Api Error " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
   
    setIsLoading(false);
    if(sourceLabel === "Save")
      navigate('/contentpreviewslist'); 
  
  }

  const uploadThumbnail = () => {
    console.log("uploadThumbnail")
    if(!croppedImage) return;
    setIsLoading(true);
    console.log("payload.croppedImage", croppedImage)

    let payLoad = new FormData()
    payLoad.append("image", croppedImage, "imageUpload.png")
    console.log("payLoad", payLoad.get("image"))
   
    let data1 = {
      url: "contentPreviews",
      query: `/content/${formData.contentId}/preview/${formData.id}`,
      method: "POST",
      body: payLoad,
      cType: 6
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;  
        if (currentData.id > 0)
          loadData(currentData.id)   
        if(currentData?.thumbnailUrl)
          setCroppedImagePreview(currentData?.thumbnailUrl);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { }
    );
    // common.notify("S", "Saved Successfully");    
    setIsLoading(false);
  };

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "contentPreviews",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("loadData -res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        loadContentData(currentData?.contentId);
        if(currentData?.thumbnailUrl)
          setCroppedImagePreview(currentData?.thumbnailUrl);
        console.log("croppedImagePreview",croppedImagePreview)
        setIsLoading(false);
        loadPreviewContent(pId);
        loadPreviewReviews(pId);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  /**
   * Get content details for given contentID
   * @param {*} pId 
   */
  const loadContentData =(pId) =>{
    const data1 = {
      url: "content",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
         setSelectedContent(currentData);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );

  }

  const [selected, setSelected] = useState({});
  const [selectedDtlId, setSelectedDtlId] = useState(0);

  const onSelectionChange = useCallback(({ selected }) => {
    console.log("selected ", selected);
    setSelected(selected);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    // resetDtlData();
    setSelectedDtlId(0);
  };

    
  //PreviewContent  
  const [previewContentTableData, setPreviewContentTableData] = useState([]);

  const [formPreviewContentData, setFormPreviewContentData] = useState({
    id: 0,
    previewId: formData?.id,
    contentUrl: "",
    description: "",
    status: "",
    contentType: "",
    contentSource: "",
    active: "Y",
    ordering: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const handlePreviewContentClickOpen = () => {
    let dtlInfo = {
      id: 0,
      previewId: formData.id,
      contentUrl: "",
      description: "",
      status: "",
      contentType: "",
      contentSource: "",
      active: "Y",
      ordering: "",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    };
    setFormPreviewContentData(dtlInfo);
    setOpen(true);
  };

  const handlePreviewContentFormSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked);
    setFormPreviewContentData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
    console.log(
      "handlePreviewContentFormSwitchChange-formPreviewContentData ",
      formPreviewContentData
    );
  };

  const handlePreviewContentSwitchChange = (event, previewContentId) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;

    if (previewContentId > 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        active: checked ? "Y" : null,
      };
      wsData = {
        url: "previewContent",
        query: "/" + previewContentId,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadData(id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };
  
  const handlePreviewContentChange = (val) => {
    const { name, value } = val.target;
    setFormPreviewContentData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleAddPreviewContent = (e) => {
    console.log(
      "handleAddPreviewContent-formPreviewContentData",
      formPreviewContentData,
      " selectedDtlId:",
      selectedDtlId
    );
    console.log("handleAddPreviewContent-formData", formData);

    e.preventDefault();
    setIsDtlLoading(true);
    let bodyObj = {
      previewId: formData.id,
      contentUrl: formPreviewContentData.contentUrl,
      description: formPreviewContentData.description,
      status: formPreviewContentData.status,
      contentType: formPreviewContentData.contentType,
      contentSource: formPreviewContentData.contentSource,
      active: formPreviewContentData.active,
      ordering: formPreviewContentData.ordering,
    };
    let data = {};
    if (selectedDtlId > 0) {
      data = {
        url: "previewContent",
        query: "/" + selectedDtlId,
        body: bodyObj,
        method: "PATCH",
      };
    } else {
      data = {
        url: "previewContent",
        method: "POST",
        body: bodyObj,
      };
    }
    api.call(
      data,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setOpen(false);
        setIsDtlLoading(false);
        loadData(formData.id);
        loadPreviewContent(formData.id);
        setSelectedDtlId(0);
        setIsEdit(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsDtlLoading(false);
      },
      (final) => {}
    );
  };

  const onDeletePreviewContent = async () => {
    if (selected > 0) {
      const data1 = {
        url: "previewContent",
        query: "/" + selected,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const loadPreviewContent = (pId) => {
    console.log("pId:", pId);
    setIsDtlLoading(true);
    const data1 = {
      url: "previewContentAdvanceSearch",
      method: "GET",
      query: `?q={"previewId":{"$eq": ${pId}}}&orderBy=[["id", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {       
        console.log("res.data.items", res.data.items);
        setPreviewContentTableData(res.data.items);
        setFormPreviewContentData(res.data.items);
        setIsDtlLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsDtlLoading(false);
      },
      (final) => {}
    );
  };

  const onPreviewContentEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...previewContentTableData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log("rowId", rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);

      console.table(data);
      data[index][columnId] = value;
      setPreviewContentTableData(data);

      let wsData = {};
      let bodyData = {};
      bodyData = {
        [columnId]: data[index][columnId],
      };
      console.log("PreviewContent edit bodyData", bodyData);
      wsData = {
        url: "previewContent",
        query: "/" + data[index]["id"],
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadPreviewContent (res?.data?.item?.previewId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
      // }
    },
    [previewContentTableData]
  );
  
  const previewContentColumns = [
    {
      name: "contentUrl",
      header: "ContentUrl",
      defaultFlex: 2,
      headerAlign: "center",
      textAlign: "center",
    },
    // {
    //   name: "description",
    //   header: "Description",
    //   defaultFlex: 0.4,
    //   headerAlign: "center",
    //   textAlign: "left",
    // },
    {
      name: "status",
      header: "Status",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },
    {
      name: "contentType",
      header: "ContentType",
      defaultFlex: 0.75,
      headerAlign: "center",
      textAlign: "center",
    },
    {
      name: "contentSource",
      header: "ContentSource",
      defaultFlex: 0.75,
      headerAlign: "center",
      textAlign: "center",
    },
    {
      name: "ordering",
      header: "Ordering",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },

    {
      header: "Active",
      name: "active",
      defaultFlex: 0.5,
      headerAlign: "center",
      textAlign: "center",
      render: ({ data, value }) => (
        <FormControlLabel
          control={
            <Switch
              name="active"
              checked={data.active === "Y" ? true : false}
              onChange={(e) => handlePreviewContentSwitchChange(e, data.id)}
              inputProps={{
                "aria-label": "controlled",
                align: "center",
              }}
            />
          }
          label=""
        />
      ),
    },
  ];

  //PreviewReviews
    const [previewReviewsTableData, setPreviewReviewsTableData] = useState([]);

    const [formPreviewReviewsData, setFormPreviewReviewsData] = useState({
      id: 0,
      previewId: formData?.id,
      userId: "",
      reviewComments: "",
      rating: "",
      parentId: "",
      noOfLikes: "",
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });

    const handlePreviewReviewsClickOpen = () => {
      let dtlInfo = {
        id: 0,
        previewId: formData.id,
        userId: "",
        reviewComments: "",
        rating: "",
        parentId: "",
        noOfLikes: "",
        active: "Y",
        createdBy: null,
        creationDate: null,
        lastUpdatedBy: null,
        lastUpdateDate: null,
        lastUpdateLogin: null,
        versionNumber: null,
      };
      setFormPreviewReviewsData(dtlInfo);
      setOpen(true);
    };

    const handlePreviewReviewsFormSwitchChange = (event) => {
      console.log("checked ", event.target);
      const { name, checked } = event.target;
      console.log("name ", name, " checked", checked);
      setFormPreviewReviewsData((prevState) => {
        return {
          ...prevState,
          [name]: checked ? "Y" : null,
        };
      });
      console.log(
        "handlePreviewReviewsFormSwitchChange-formPreviewReviewsData ",
        formPreviewReviewsData
      );
    };

    const handlePreviewReviewsSwitchChange = (event, previewReviewsId) => {
      console.log("checked ", event.target);
      const { name, checked } = event.target;

      if (previewReviewsId > 0) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: checked ? "Y" : null,
        };
        wsData = {
          url: "previewReviews",
          query: "/" + previewReviewsId,
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadData(id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    };

    const handlePreviewReviewsChange = (val) => {
      const { name, value } = val.target;
      setFormPreviewReviewsData((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    };

    const handleAddPreviewReviews = (e) => {
      console.log(
        "handleAddPreviewReviews-formPreviewReviewsData",
        formPreviewReviewsData,
        " selectedDtlId:",
        selectedDtlId
      );
      console.log("handleAddPreviewReviews-formData", formData);

      e.preventDefault();
      setIsDtlLoading(true);
      let bodyObj = {
        previewId: formData.id,
        userId: formPreviewReviewsData.userId,
        reviewComments: formPreviewReviewsData.reviewComments,
        parentId: formPreviewReviewsData.parentId,
        rating: formPreviewReviewsData.rating,
        noOfLikes: formPreviewReviewsData.noOfLikes,
        active: formPreviewReviewsData.active,
      };
      let data = {};
      if (selectedDtlId > 0) {
        data = {
          url: "previewReviews",
          query: "/" + selectedDtlId,
          body: bodyObj,
          method: "PATCH",
        };
      } else {
        data = {
          url: "previewReviews",
          method: "POST",
          body: bodyObj,
        };
      }
      api.call(
        data,
        (res) => {
          let currentData = res.data.item;
          common.notify("S", "Saved Successfully");
          setOpen(false);
          setIsDtlLoading(false);
          loadData(formData.id);
          loadPreviewReviews(formData.id);
          setSelectedDtlId(0);
          setIsEdit(false);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsDtlLoading(false);
        },
        (final) => {}
      );
    };

    const onDeletePreviewReviews = async () => {
      if (selected > 0) {
        const data1 = {
          url: "previewReviews",
          query: "/" + selected,
          method: "DELETE",
        };
        api.call(
          data1,
          (res) => {
            common.notify("S", "Deleted Successfully");
            setIsLoading(false);
            loadData(formData.id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
            setIsLoading(false);
          },
          (final) => {}
        );
      }
    };

    const loadPreviewReviews = (pId) => {
      console.log("loadPreviewReviews-pId:", pId);
      setIsDtlLoading(true);
      const data1 = {
        url: "previewReviewsAdvanceSearch",
        method: "GET",
        query: `?q={"previewId":{"$eq": ${pId}}}&orderBy=[["id", "ASC"]]`,
      };
      api.call(
        data1,
        (res) => {
          let currentData = res.data.items;
          console.log("loadPreviewReviews-currentData", currentData);
          setPreviewReviewsTableData(res.data.items);
          setFormPreviewReviewsData(currentData);
          setIsDtlLoading(false);
        },
        (error) => {
          common.notify("E", "Api Failed " + error.statusText);
          setIsDtlLoading(false);
        },
        (final) => {}
      );
    };

    const onPreviewReviewsEditComplete = useCallback(
      ({ value, columnId, rowId }) => {
        const data = [...previewReviewsTableData];
        const index = data.findIndex((p) => p.id === rowId);
        console.log("rowId", rowId);
        console.log("columnId", columnId);
        console.log('data[index]["id"]', data[index]["id"]);

        console.table(data);
        data[index][columnId] = value;
        setPreviewReviewsTableData(data);

        let wsData = {};
        let bodyData = {};
        bodyData = {
          [columnId]: data[index][columnId],
        };
        console.log("PreviewReviews edit bodyData", bodyData);
        wsData = {
          url: "previewReviews",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadPreviewReviews(res?.data?.item?.previewId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
        // }
      },
      [previewReviewsTableData]
    );

  const [userQuery, setUserQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState();

    const loadUsersOptions = async (inputValue) => {
      console.log("loadUsersOptions.inputValue", inputValue);
      const res = await axios.get(
        `${config.api.url}users/search/advance?q={"userName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
      );
      console.log("loadUsersOptions.res.data.items", res.data.items);
      return res.data.items;
    };

    const onUserChange = (value) => {
      console.log("onUsersChange-value", value);
      if (value) {
        formPreviewReviewsData.userId = value.id;
        setSelectedUser(value);
        console.log(" formPreviewReviewsData.userId ", formPreviewReviewsData.userId);
      }
      console.log("onUsersChange-selectedUser", selectedUser);
    };


    const previewReviewsColumns = [
      {
        name: "user.userName",
        header: "User",
        defaultFlex: 0.4,
        headerAlign: "center",
        textAlign: "center",
        render: ({ data, value }) => (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {data.user?.userName}
          </MDTypography>
        ),
      },
      {
        name: "reviewComments",
        header: "ReviewComments",
        defaultFlex: 0.4,
        headerAlign: "center",
        textAlign: "left",
      },
      {
        name: "parentId",
        header: "ParentId",
        defaultFlex: 0.4,
        headerAlign: "center",
        textAlign: "center",
      },
      {
        name: "rating",
        header: "Rating",
        defaultFlex: 0.4,
        headerAlign: "center",
        textAlign: "center",
      },
      {
        name: "noOfLikes",
        header: "NoOfLikes",
        defaultFlex: 0.4,
        headerAlign: "center",
        textAlign: "center",
      },
      {
        header: "Active",
        name: "active",
        defaultFlex: 0.5,
        headerAlign: "center",
        textAlign: "center",
        render: ({ data, value }) => (
          <FormControlLabel
            control={
              <Switch
                name="active"
                checked={data.active === "Y" ? true : false}
                onChange={(e) => handlePreviewReviewsSwitchChange(e, data.id)}
                inputProps={{
                  "aria-label": "controlled",
                  align: "center",
                }}
              />
            }
            label=""
          />
        ),
      },
    ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Content Preview
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6} xl={6}>
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} xl={6}>
                          <MDInput
                            type="text"
                            disabled
                            label="PreviewKey"
                            name="previewKey"
                            value={formData.previewKey}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6} xl={6}>
                          <div className="selectWrapper">
                            <label>Content</label>
                            <AsyncSelect
                              label="Content"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search Contents"
                              cacheOptions
                              fullWidth
                              value={selectedContent}
                              getOptionLabel={(e) => e.titleEn}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadContentsOptions}
                              onInputChange={(value) => setContentQuery(value)}
                              onChange={onContentChange}
                              sx={{ width: "18%", mr: 1, mt: 2 }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12} xl={12}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={4}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2} mb={5}>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="text"
                            label="PreviewType"
                            name="previewType"
                            value={formData.previewType}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="text"
                            label="Status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="text"
                            label="SecurityType"
                            name="securityType"
                            value={formData.securityType}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      {!formData.id > 0 && (
                        <Grid container spacing={1} mt={2} mb={5}>
                          <Grid item xs={4} xl={4}>
                            <MDInput
                              type="password"
                              label="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={1} mt={2} mb={5}>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="number"
                            label="NoOfViews"
                            name="noOfViews"
                            value={formData.noOfViews}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="number"
                            label="AvgRating"
                            name="avgRating"
                            value={formData.avgRating}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="number"
                            label="NoOfRatings"
                            name="noOfRatings"
                            value={formData.noOfRatings}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2} mb={5}>
                        <Grid item xs={3} xl={4}>
                          <MDInput
                            type="number"
                            label="Ordering"
                            name="ordering"
                            value={formData.ordering}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <p>PreviewThumbnail</p>
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                size="xl"
                                onChange={imageUploadHandle}
                              >
                                <input
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  id="profileImage"
                                  ref={profileImageInput}
                                />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Image
                              src={croppedImagePreview} //{formData.image}
                              variant="square"
                              size="xxl"
                              shadow="sm"
                            ></Image>
                          </StyledBadge>

                          {toggleCropModal && (
                            <CropModal>
                              <ImageCropper
                                uploadFile={uploadFile}
                                getCroppedFile={getCroppedFile}
                                imageRatio={imageRatio}
                                cropShape="rect"
                                uploadFileType="image"
                                closeCropPopup={() => {
                                  setToggleCropModal(false);
                                  if (croppedImage)
                                    formData.thumbnailUrl = URL.createObjectURL(croppedImage);
                                }}
                                uploadImgDelete={uploadImgDelete}
                              />
                            </CropModal>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/contentpreviewslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  {formData.id > 0 && (
                    <MDBox pt={2}>
                      <MDBox pt={2} pb={3} px={0} py={0}>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                          indicatorColor="secondary"
                          textColor="inherit"
                          // variant="fullWidth"
                        >
                          <Tab label="Content" {...a11yProps(0)} />
                          <Tab label="Reviews" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                          <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}>
                            {formData.id > 0 && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                sx={{ mr: 1 }}
                                onClick={handlePreviewContentClickOpen}
                              >
                                Add
                              </MDButton>
                            )}
                            {selected && selected > 0 && (
                              <MDButton
                                color="error"
                                sx={{ mr: 1 }}
                                onClick={onDeletePreviewContent}
                              >
                                Delete
                              </MDButton>
                            )}
                          </MDBox>
                          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
                            <DialogTitle>PreviewContent</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Add or edit a new PreviewContent
                              </DialogContentText>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={12}>
                                  <MDInput
                                    type="text"
                                    label="ContentUrl"
                                    name="contentUrl"
                                    onChange={handlePreviewContentChange}
                                    value={formPreviewContentData.contentUrl}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={12}>
                                  <MDInput
                                    type="text"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    name="description"
                                    value={formPreviewContentData.description}
                                    onChange={handlePreviewContentChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={4}>
                                  <MDInput
                                    type="text"
                                    label="Status"
                                    name="status"
                                    onChange={handlePreviewContentChange}
                                    value={formPreviewContentData.status}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <MDInput
                                    type="text"
                                    label="ContentType"
                                    name="contentType"
                                    onChange={handlePreviewContentChange}
                                    value={formPreviewContentData.contentType}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <MDInput
                                    type="text"
                                    label="ContentSource"
                                    name="contentSource"
                                    onChange={handlePreviewContentChange}
                                    value={formPreviewContentData.contentSource}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={4}>
                                  <MDInput
                                    type="text"
                                    label="Ordering"
                                    name="ordering"
                                    onChange={handlePreviewContentChange}
                                    value={formPreviewContentData.ordering}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="active"
                                        checked={
                                          formPreviewContentData.active === "Y" ? true : false
                                        }
                                        onChange={handlePreviewContentFormSwitchChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                    }
                                    label="Active"
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleClose}>Cancel</MDButton>
                              <MDButton onClick={handleAddPreviewContent}>Ok</MDButton>
                            </DialogActions>
                          </Dialog>
                          <MDBox>
                            <ReactDataGrid
                              idProperty="PreviewContent.id"
                              selected={selected}
                              style={gridStyle}
                              checkboxColumn
                              editable
                              onEditComplete={onPreviewContentEditComplete}
                              multiSelect={false}
                              onSelectionChange={onSelectionChange}
                              columns={previewContentColumns}
                              loading={isDtlLoading}
                              dataSource={previewContentTableData}
                            />
                          </MDBox>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                          <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}>
                            {formData.id > 0 && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                sx={{ mr: 1 }}
                                onClick={handlePreviewReviewsClickOpen}
                              >
                                Add
                              </MDButton>
                            )}
                            {selected && selected > 0 && (
                              <MDButton
                                color="error"
                                sx={{ mr: 1 }}
                                onClick={onDeletePreviewReviews}
                              >
                                Delete
                              </MDButton>
                            )}
                          </MDBox>
                          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
                            <DialogTitle>PreviewReviews</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Add or edit a new PreviewReviews
                              </DialogContentText>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={5}>
                                  <div className="selectWrapper">
                                    <label>Users</label>
                                    <AsyncSelect
                                      label="Users"
                                      styles={customSelectStyles}
                                      classNamePrefix="cus-select"
                                      placeholder="Type atleast 2 chars to search Users"
                                      cacheOptions
                                      fullWidth
                                      value={selectedUser}
                                      getOptionLabel={(e) => e.userName}
                                      getOptionValue={(e) => e.id}
                                      loadOptions={loadUsersOptions}
                                      onInputChange={(value) => setUserQuery(value)}
                                      onChange={onUserChange}
                                      sx={{ width: "18%", mr: 1, mt: 2 }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={12}>
                                  <MDInput
                                    type="text"
                                    label="ReviewComments"
                                    multiline
                                    rows={4}
                                    name="reviewComments"
                                    value={formPreviewReviewsData.reviewComments}
                                    onChange={handlePreviewReviewsChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={3}>
                                  <MDInput
                                    type="text"
                                    label="ParentId"
                                    name="parentId"
                                    onChange={handlePreviewReviewsChange}
                                    value={formPreviewReviewsData.parentId}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MDInput
                                    type="text"
                                    label="Rating"
                                    name="rating"
                                    onChange={handlePreviewReviewsChange}
                                    value={formPreviewReviewsData.rating}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MDInput
                                    type="text"
                                    label="NoOfLikes"
                                    name="noOfLikes"
                                    onChange={handlePreviewReviewsChange}
                                    value={formPreviewReviewsData.noOfLikes}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="active"
                                        checked={
                                          formPreviewReviewsData.active === "Y" ? true : false
                                        }
                                        onChange={handlePreviewReviewsFormSwitchChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                    }
                                    label="Active"
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleClose}>Cancel</MDButton>
                              <MDButton onClick={handleAddPreviewReviews}>Ok</MDButton>
                            </DialogActions>
                          </Dialog>
                          <MDBox>
                            <ReactDataGrid
                              idProperty="PreviewReviews.id"
                              selected={selected}
                              style={gridStyle}
                              checkboxColumn
                              editable
                              onEditComplete={onPreviewReviewsEditComplete}
                              multiSelect={false}
                              onSelectionChange={onSelectionChange}
                              columns={previewReviewsColumns}
                              loading={isDtlLoading}
                              dataSource={previewReviewsTableData}
                            />
                          </MDBox>
                        </TabPanel>
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ContentPreview;
