import { Link, useParams,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { config } from "helpers/common/config";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 1 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}

function User() {

  const { id } = useParams();
  const gridStyle = { minHeight: 500 };
  //State Management
  const [open, setOpen] = useState(false);

  const [formError, setFormError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDtlLoading, setIsDtlLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const [changeCounter, setChangeCounter]= useState(0);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: 0,
    userName: null,
    password: null,
    active: null,
    effStartDate: moment().format("YYYY-MM-DD"),
    effEndDate: null,
    mobileNo: null,
    emailAddress: null,
    firstLogin: null,
    noOfLoginAttempts: null,
    defaultTimezone: null,
    defaultLanguage: null,
    otpKey: null,
    otep: null,
    notes: null,
    userFileType: null,
    userPicPath: null,
    userFileName: null,
    fcmTokenId: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    roles: [],
    parties: [],
    userProfiles: null,
  });

  const [formPartyData, setFormPartyData] = useState({
    id: 0,
    userId: null,
    partyId: null,
    active: "Y",
    effStartDate: moment().format("YYYY-MM-DD"),
    effEndDate: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    userParties: {},
  });

  const [formRoleData, setFormRoleData] = useState({
    id: 0,
    userId: null,
    roleId: null,    
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    userRoles: {}
    
  });

  const [selected, setSelected] = useState({});
  const [selectedDtlId, setSelectedDtlId] = useState(0);

  const onSelectionChange = useCallback(({ selected }) => {
    console.log("selected ",selected)
    setSelected(selected);
  }, []);



  //Parties
  const [selectedParty, setSelectedParty] = useState("");
  const [partyQuery, setPartyQuery] = useState("");

  const loadPartyOptions = async (inputValue) => {
    console.log("loadPartyOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}parties/search/advance?q={"partyName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    console.log("loadPartyOptions.res.data.items",res.data.items)
    return res.data.items;
  }
  const onPartyChange = (value) => {
    console.log("onPartyChange", value);
    if (value) {
      formPartyData.partyId = value.id;
      setSelectedParty(value)
      console.log(" formPartyData.partyId ", formPartyData.partyId)
      
    }
  }

  const handlePartyClickOpen = () => {
    let dtlInfo = {
      id: 0,
      userId: formData.id,
      partyId: null,   
      active: "Y",
      effStartDate: moment().format('YYYY-MM-DD'),
      effEndDate: null,   
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    }
    setFormPartyData(dtlInfo);
    setOpen(true);
  };

  const handleAddParty = (e) => {
    console.log('handleAddParty-formPartyData',formPartyData," selectedDtlId:",selectedDtlId)
    console.log('handleAddParty-formData',formData)

    e.preventDefault();
    setIsDtlLoading(true);
    let data = {};
    if (selectedDtlId > 0) {
      data = {
        url: "usersParties",
        query: "/" + selectedDtlId,
        body: {
          userId: formData.id,
          partyId: formPartyData.partyId,
          active: formPartyData.active,
          effStartDate: formPartyData.effStartDate,
          effEndDate: formPartyData.effEndDate,          
        },
        method: "PATCH",
      };
    } else {
      data = {
        url: "usersParties",
        method: "POST",
        body: {
          userId: formData.id,
          partyId: formPartyData.partyId,
          active: formPartyData.active,
          effStartDate: formPartyData.effStartDate,
          effEndDate: formPartyData.effEndDate,       
        },
      };
    }
    api.call(
      data,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setOpen(false);
        setIsDtlLoading(false);
        loadData(formData.id);
        // loadDtls(formData.id);
        // resetDtlData();
        setSelectedDtlId(0);
        setIsEdit(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsDtlLoading(false);
      },
      (final) => { }
    );
  };

  const onDeleteParty = async() => {   
    if (selected > 0) {
      const data1 = {
        url: "usersParties",
        query: "/" + selected,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => { }
      );
    }
  };

  const onPartiesEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log("rowId",rowId);
      console.log(columnId);
     
      if ((columnId === "active") && rowId > 0) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: value
        }
        wsData = {
          url: "userParties",
          query: "/" + rowId,
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            setChangeCounter(changeCounter+1);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      }
    },
    [formData]
  );
  const partiesColumns = [
    {
      name: "partyName",
      header: "Party",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },

    {
      header: "Active",
      name: "active",
      defaultFlex: 0.5,
      headerAlign: "center",
      textAlign: "center",
      render: ({ data, value }) => (
        <FormControlLabel
          control={
            <Switch
              name="active"
              checked={data.userParties.active === "Y" ? true : false}
              onChange={(e) => handlePartySwitchChange(e, data.userParties.id)}
              inputProps={{
                "aria-label": "controlled",
                align: "center",
              }}
            />
          }
          label=""
        />
      ),
    },
    {
      header: "StartDateActive",
      name: "effStartDate",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.userParties.effStartDate
            ? moment(data.userParties.effStartDate).format("DD-MM-YYYY")
            : ""}
        </MDTypography>
      ),
    },
    {
      header: "EndDateActive",
      name: "effEndDate",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.userParties.effEndDate
            ? moment(data.userParties.effEndDate).format("DD-MM-YYYY")
            : ""}
        </MDTypography>
      ),
    },
  ];

  //Roles
  const [selectedRole, setSelectedRole] = useState("");
  const [roleQuery, setRoleQuery] = useState("");

  const loadRoleOptions = async (inputValue) => {
    console.log("loadRoleOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}roles/search/advance?q={"roleName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    console.log("loadRoleOptions.res.data.items",res.data.items)
    return res.data.items;
  }
  const onRoleChange = (value) => {
    console.log("onRoleChange", value);
    if (value) {
      formRoleData.roleId = value.id;
      setSelectedRole(value)
      console.log(" formData.roleId ", formRoleData.roleId)
      
    }
  }

  const handleRoleClickOpen = () => {
    let dtlInfo = {
      id: 0,
      userId: formData.id,
      roleId: null,     
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    }
    setFormRoleData(dtlInfo);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    // resetDtlData();
    setSelectedDtlId(0);
  };

 

  const rolesColumns = [ 
    { name: "roleName", header: "Role", defaultFlex: 0.4 },   
  ];

  const handleAddRole = (e) => {
    console.log('handleAddRole-formRoleData',formRoleData," selectedDtlId:",selectedDtlId)
    console.log('handleAddRole-formData',formData)

    e.preventDefault();
    setIsDtlLoading(true);
    let data = {};
    if (selectedDtlId > 0) {
      data = {
        url: "usersRoles",
        query: "/" + selectedDtlId,
        body: {
          userId: formData.id,
          roleId: formRoleData.roleId,               
        },
        method: "PATCH",
      };
    } else {
      data = {
        url: "usersRoles",
        method: "POST",
        body: {
          userId: formData.id,
          roleId: formRoleData.roleId,           
        },
      };
    }
    api.call(
      data,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setOpen(false);
        setIsDtlLoading(false);
        loadData(formData.id);
        // loadDtls(formData.id);
        // resetDtlData();
        setSelectedDtlId(0);
        setIsEdit(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsDtlLoading(false);
      },
      (final) => { }
    );
  };



  const onDeleteRole = async () => {    
    if (selected > 0) {
      const data1 = {
        url: "usersRoles",
        query: "/" + selected,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id)
          // loadDtls(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  useEffect(() => {
    if (id) loadData(id);
    // loadPayload();
  }, []);

  const handleSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked)
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handlePartyFormSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked)
    setFormPartyData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
    console.log("handlePartySwitchChange-formPartyData ",formPartyData)
  
    
  };

  const handlePartySwitchChange = (event,userPartiesId) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;

    if (userPartiesId>0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        active: (checked) ? "Y" : null,
      }
      wsData = {
        url: "usersParties",
        query: "/" + userPartiesId,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadData(id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => { }
      );
    }
    
  };
  
  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePartyChange = (val) => {
    const { name, value } = val.target;
    setFormPartyData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

 


  // const resetDtlData = () => {
  //   let dtlInfo = {
  //     id: 0,
  //     userId: null,
  //     partyId: null,
  //     partyName: null,
  //     active: null,
  //     effStartDate: null,
  //     effEndDate: null,
  //     createdBy: null,
  //     creationDate: null,
  //     lastUpdatedBy: null,
  //     lastUpdateDate: null,
  //     lastUpdateLogin: null,
  //     versionNumber: null,
  //   }
  //   setFormPartyData(dtlInfo);
  // }

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    let requestData = {        
      userName: formData.userName,
      password: formData.password,
      active: formData.active,
      effStartDate: formData.effStartDate,
      effEndDate: formData.effEndDate,
      mobileNo: formData.mobileNo,
      emailAddress: formData.emailAddress,
      // firstLogin: formData.firstLogin,
      // noOfLoginAttempts:formData.noOfLoginAttempts,
      // defaultTimezone: formData.defaultTimezone,
      // defaultLanguage: formData.defaultLanguage,
      // otpKey: formData.otpKey,
      // otep: formData.otep,
      notes: formData.notes,
      // userFileType: formData.userFileType,
      // userPicPath: formData.userPicPath,
      // userFileName: formData.userFileName,
      // fcmTokenId: formData.fcmTokenId,
    };
    console.log("formData.id",formData.id)
    if (formData.id > 0) {
      data1 = {
        url: "users",
        query: "/" + formData.id,
        body: requestData,
        method: "PATCH",
      };
    } else
      data1 = {
        url: "users",
        method: "POST",
        body: requestData
      };

    api.call(
      data1,
      (res) => {
        let currentData = res.data.item;
        console.log("user currentData", currentData);
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) loadData(currentData.id);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error?.statusText + " : " + error?.data?.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
          navigate('/userslist');
       }
    );
  };

  
  const loadData = (pId) => {
    console.log("loadData",pId);
    setIsLoading(true);
    const data1 = {
      url: "users",
      method: "GET",
      query: "/" + pId ,//+ "?onlyData=true",
    };

    api.call(
      data1,
      (res) => {
        let currentData = res.data.item;
        console.log("Get parties currentData",currentData)
        currentData.effStartDate = currentData.effStartDate?moment(res.data.item.effStartDate).format('YYYY-MM-DD'):"";
        currentData.effEndDate = currentData.effEndDate?moment(res.data.item.effEndDate).format('YYYY-MM-DD '):null;
        setFormData(currentData);
        setIsLoading(false);
        // loadDtls(pId);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          required
                          label="User"
                          name="userName"
                          onChange={handleChange}
                          value={formData.userName}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="password"
                          required
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          value={formData.password}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          required
                          label="Mobile"
                          name="mobileNo"
                          onChange={handleChange}
                          value={formData.mobileNo}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          required
                          label="Email"
                          name="emailAddress"
                          onChange={handleChange}
                          value={formData.emailAddress}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} mt={1}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                        <Grid item xs={3}>
                          <MDInput
                            type="Date"
                            name="effStartDate"
                            label="Effective Start Date"
                            InputLabelProps={{ shrink: true }}
                            value={formData.effStartDate}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="Date"
                            name="effEndDate"
                            label="Effective End Date"
                            InputLabelProps={{ shrink: true }}
                            value={formData.effEndDate}
                            onChange={handleChange}
                          />
                        </Grid>
                      </LocalizationProvider>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              checked={formData.active ? "Y" : null}
                              onChange={handleSwitchChange}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Active"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="Notes"
                          name="notes"
                          onChange={handleChange}
                          value={formData.notes}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={1} mt={1}>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="First Login"
                          name="firstLogin"
                          onChange={handleChange}
                          value={formData.firstLogin}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="noOf LoginAttempts"
                          name="noOfLoginAttempts"
                          onChange={handleChange}
                          value={formData.noOfLoginAttempts}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="Default Timezone"
                          name="defaultTimezone"
                          onChange={handleChange}
                          value={formData.defaultTimezone}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="Default Language"
                          name="defaultLanguage"
                          onChange={handleChange}
                          value={formData.defaultLanguage}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="User FileType"
                          name="userFileType"
                          onChange={handleChange}
                          value={formData.userFileType}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="usercPicPath"
                          name="userPicPath"
                          onChange={handleChange}
                          value={formData.userPicPath}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="Notes"
                          name="notes"
                          onChange={handleChange}
                          value={formData.notes}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="FCM TokenId"
                          name="fcmTokenId"
                          onChange={handleChange}
                          value={formData.fcmTokenId}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    </Grid> */}
                    {/* <Grid container spacing={1} mt={1}>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="User FileType"
                          name="userFileType"
                          onChange={handleChange}
                          value={formData.userFileType}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="User PicPath"
                          name="userPicPath"
                          onChange={handleChange}
                          value={formData.userPicPath}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MDInput
                          type="text"
                          label="User FileName"
                          name="userFileName"
                          onChange={handleChange}
                          value={formData.userFileName}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    </Grid> */}

                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="warning" component={Link} to="/userslist">
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  {formData.id > 0 && (
                    <MDBox pt={2}>
                      <MDBox pt={2} pb={3} px={0} py={0}>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                          indicatorColor="secondary"
                          textColor="inherit"
                          // variant="fullWidth"
                        >
                          <Tab label="Parties" {...a11yProps(0)} />
                          <Tab label="Roles" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                          <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}>
                            {formData.id > 0 && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                sx={{ mr: 1 }}
                                onClick={handlePartyClickOpen}
                              >
                                Add
                              </MDButton>
                            )}
                            {selected && selected > 0 && (
                              <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteParty}>
                                Delete
                              </MDButton>
                            )}
                          </MDBox>
                          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
                            <DialogTitle>Parties</DialogTitle>
                            <DialogContent>
                              <DialogContentText>Add or edit a new Parties</DialogContentText>
                              <Grid container spacing={1} mt={2}>
                                <Grid item xs={6}>
                                  {/* <div className="custom-select"> */}
                                  <AsyncSelect
                                    label="Party"
                                    styles={customSelectStyles}
                                    placeholder="Type atleast 2 chars to search party"
                                    cacheOptions
                                    fullWidth
                                    value={selectedParty}
                                    getOptionLabel={(e) => e.partyName}
                                    getOptionValue={(e) => e.id}
                                    loadOptions={loadPartyOptions}
                                    onInputChange={(value) => setPartyQuery(value)}
                                    onChange={onPartyChange}
                                    // sx={{ width: "18%", mr: 1, mt: 2 }}
                                  />{" "}
                                  {/* </div> */}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="active"
                                        checked={formPartyData.active === "Y" ? true : false}
                                        onChange={handlePartyFormSwitchChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                    }
                                    label="Active"
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={1}>
                                <Grid item xs={4}>
                                  <MDInput
                                    type="Date"
                                    label="Start Date"
                                    name="effStartDate"
                                    onChange={handlePartyChange}
                                    value={formPartyData.effStartDate}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <MDInput
                                    type="Date"
                                    label="End Date"
                                    name="effEndDate"
                                    onChange={handlePartyChange}
                                    value={formPartyData.effEndDate}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleClose}>Cancel</MDButton>
                              <MDButton onClick={handleAddParty}>Ok</MDButton>
                            </DialogActions>
                          </Dialog>
                          <MDBox>
                            <ReactDataGrid
                              idProperty="userParties.id"
                              selected={selected}
                              style={gridStyle}
                              checkboxColumn
                              editable
                              onEditComplete={onPartiesEditComplete}
                              multiSelect={false}
                              onSelectionChange={onSelectionChange}
                              columns={partiesColumns}
                              loading={isDtlLoading}
                              dataSource={formData.parties}
                            />
                          </MDBox>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                          <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}>
                            {formData.id > 0 && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                sx={{ mr: 1 }}
                                onClick={handleRoleClickOpen}
                              >
                                Add
                              </MDButton>
                            )}
                            {selected && selected > 0 && (
                              <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteRole}>
                                Delete
                              </MDButton>
                            )}
                          </MDBox>
                          <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Roles</DialogTitle>
                            <DialogContent>
                              <DialogContentText>Add or edit a new Roles</DialogContentText>
                              <Grid container spacing={1} mt={2}>
                                <Grid item xs={12}>
                                  <AsyncSelect
                                    label="Roles"
                                    styles={customSelectStyles}
                                    placeholder="Type atleast 2 chars to search roles"
                                    cacheOptions
                                    fullWidth
                                    value={selectedRole}
                                    getOptionLabel={(e) => e.roleName}
                                    getOptionValue={(e) => e.id}
                                    loadOptions={loadRoleOptions}
                                    onInputChange={(value) => setRoleQuery(value)}
                                    onChange={onRoleChange}
                                  />{" "}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={2}>
                                <Grid item xs={12}>
                                  <MDInput
                                    type="number"
                                    label="Ordering"
                                    // name="roleId"
                                    value={"1"}
                                    disabled={false}
                                    onChange={onPartyChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleClose}>Cancel</MDButton>
                              <MDButton onClick={handleAddRole}>Ok</MDButton>
                            </DialogActions>
                          </Dialog>
                          <MDBox>
                            <ReactDataGrid
                              idProperty="userRoles.id"
                              selected={selected}
                              style={gridStyle}
                              checkboxColumn
                              editable
                              onEditComplete={onPartiesEditComplete}
                              multiSelect={false}
                              onSelectionChange={onSelectionChange}
                              columns={rolesColumns}
                              loading={isDtlLoading}
                              dataSource={formData.roles}
                            />
                          </MDBox>
                        </TabPanel>
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default User;
