import { useEffect, useState, useRef,useCallback } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async'
// import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";

import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/Adapter/Dayjs';
// import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";
import axios from 'axios';
import { config } from "helpers/common/config";
const _ = require('lodash');

// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Slider from '@mui/material/Slider';

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import { canvasPreview } from "helpers/common/canvasPreview"
import { useDebounceEffect } from "helpers/common/useDebounceEffect"
import "react-image-crop/dist/ReactCrop.css"

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}


function ContentReport() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,    
    reason: "",  
    contentId: null,
    userId: null,   
    status: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,

  });

  useEffect(() => {
    console.log("id",id)
    loadContentOptions();
    loadUsersOptions();
    loadStatusList();
    if (id){
      loadData(id);
      setIsDisabled(false);
    }
    console.log("IsDisabled",isDisabled)     
  }, []);

  const [contentQuery, setContentQuery] = useState("");
  const [selectedContentValue, setSelectedContentValue] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const [selectedUser,setSelectedUser] = useState();
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [statusListData, setStatusListData] = useState([]);

  const loadUsersOptions = async (inputValue) => {
    console.log("loadUsersOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}users/search/advance?q={"userName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    console.log("loadUsersOptions.res.data.items",res.data.items)
    return res.data.items;
  }

  const onUserChange = (value) => {
    console.log("onUsersChange-value", value);   
    if (value) {
      formData.userId = value.id;
      setSelectedUser(value)
      console.log(" formData.postId ", formData.userId)
    }
    console.log("onUsersChange-selectedUser",selectedUser);    
  }

  const loadContentOptions = async (inputValue) => {
    console.log("loadContentOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}content/search/advance?q={"titleEn":{"$like": "*${inputValue}*"}}&totalResults=true&offset=0&limit=50`);
    console.log("res:",res.data.items)
    return res.data.items;  
  }

  const onContentChange = (value) => {
    console.log("onContentChange -value", value);
    if (value) {
      formData.contentId = value.id;
      setSelectedContentValue(value)
      console.log(" formData.postId ", formData.contentId)      
    }
  }

     const loadStatusList = () => {
       setIsLoading(true);
       const data1 = {
         url: "lookupValuesAdvanceSearch",
         method: "GET",
         query: `?q={"lookupType":{"$eq": "ZR_ENQUIRY_REPORT_STATUS"}}&orderBy=[["id", "DESC"]]`,
       };
       api.call(
         data1,
         (res) => {
           console.log("loadStatusList", res.data.items);
           setStatusListData(res.data.items);
           setIsLoading(false);
         },
         (error) => {
           common.notify("E", "Api Failed " + error.statusText);
           setIsLoading(false);
         },
         (final) => {}
       );
     };

     const handleStatusChange = (val) => {
       const name = "status";

       setFormData((prevState) => {
         return {
           ...prevState,
           [name]: val.lookupCode,
         };
       });
     };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e,sourceLabel) => {

    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    let bodyData = {
      contentId: formData.contentId,
      userId: formData.userId,      
      reason: formData.reason,      
      status: formData.status,
    };
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "contentReports",
        query: "/" + formData.id,
        body: bodyData,
        method: "PATCH",
      };
    else
      data1 = {
        url: "contentReports",
        method: "POST",
        body: bodyData,
      };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;      
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0){          
          console.log("currentData.id",currentData.id)
          loadData(currentData.id);
        }
      },
      (error) => {
        if (error && error.status && error.status===500)
          common.notify("E","Api Error " +  error.data.msg);
        else
          common.notify("E", "Api Error " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
   
    setIsLoading(false);
    if(sourceLabel === "Save")
      navigate('/contentreportslist'); 
  
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "contentReports",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
       console.log("loaddata-users",currentData.users);        
        setFormData(currentData);
        // setSelectedUser(currentData.users);
        // setSelectedContentValue(currentData.content);

        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Content Report
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2} mb={5}>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>Users</label>
                            <AsyncSelect
                              label="Users"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search Users"
                              cacheOptions
                              fullWidth
                              value={selectedUser}
                              getOptionLabel={(e) => e.userName}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadUsersOptions}
                              onInputChange={(value) => setUserQuery(value)}
                              onChange={onUserChange}
                              sx={{ width: "18%", mr: 1, mt: 2 }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>Content</label>
                            <AsyncSelect
                              label="Content"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search Content"
                              cacheOptions
                              fullWidth
                              value={selectedContentValue}
                              getOptionLabel={(e) => e.titleEn}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadContentOptions}
                              onInputChange={(value) => setContentQuery(value)}
                              onChange={onContentChange}
                              sx={{ width: "18%", mr: 1, mt: 2 }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>Status</label>
                            <Select
                              id="status"
                              label="Status"
                              name="status"
                              onChange={handleStatusChange}
                              value={statusListData.find(
                                (i) => i.lookupCode == formData.status //compare string with number value
                              )}
                              options={statusListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select Status"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Reason"
                            multiline
                            rows={4}
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/contentreportslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ContentReport;
