import { Link, useParams,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";


function Languages() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    "id": 0,
    "code": "",
    "name": "",
    "description": "",
    "active":"",
    "isoLanguage":"",
    "ordering": "",
    "bgColor": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });


  useEffect(() => {
    if (id)
      loadData(id);
  }, []);


  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "languages",
        query: "/" + formData.id,
        body: {
          "code": formData.code,
          "name": formData.name,
          "description": formData.description,
          "active":formData.active,
          "isoLanguage":formData.isoLanguage,
          "ordering": formData.ordering,
          "bgColor": formData.bgColor,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "languages",
        method: "POST",
        body: {
          "code": formData.code,
          "name": formData.name,
          "description": formData.description,
          "active":formData.active,
          "isoLanguage":formData.isoLanguage,
          "ordering": formData.ordering,
          "bgColor": formData.bgColor,
        },
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0)
          loadData(currentData.id)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
          navigate('/languageslist');
       }
    );
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "languages",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                Language
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: '100%' }} >
                    <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Code" name="code" value={formData.code} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                     
                        <Grid item xs={3}>
                          <MDInput type="text" label="Name" name="name" value={formData.name} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Description" name="description" value={formData.description} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Active" name="active" value={formData.active} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                     
                        <Grid item xs={3}>
                          <MDInput type="text" label="ISO Language" name="isoLanguage" value={formData.isoLanguage} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Ordering" name="ordering" value={formData.ordering} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput type="color" label="BG Color" name="bgColor" value={formData.bgColor} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, m: 1 }}>
                    <MDButton variant="gradient" color="success" sx={{ mr: 1 }} type="submit" disabled={formError} onClick={(e) => handleSubmit(e, "Apply")}>
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="info" sx={{ mr: 1 }} type="submit" disabled={formError} onClick={(e) => handleSubmit(e, "Save")}>
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="warning" component={Link} to="/languageslist">
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Languages;
