import { useEffect, useState, useRef,useCallback } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async'
// import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";

import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/Adapter/Dayjs';
// import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";
import axios from 'axios';
import { config } from "helpers/common/config";
const _ = require('lodash');

// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Slider from '@mui/material/Slider';

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import { canvasPreview } from "helpers/common/canvasPreview"
import { useDebounceEffect } from "helpers/common/useDebounceEffect"
import "react-image-crop/dist/ReactCrop.css"

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}


function BlogPost() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    emailId: "",
    body: "",
    postId: null,
    userId: null,
    parentId: null,
    active: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    blogPost: [],
    users: [],
  });

  useEffect(() => {
    console.log("id",id)
    loadBlogPostsOptions();
    loadUsersOptions();
    
    if (id){
      loadData(id);
      setIsDisabled(false);
    }
    console.log("IsDisabled",isDisabled)     
  }, []);

  const [blogPostQuery, setBlogPostQuery] = useState("");
  const [selectedBlogPostValue, setSelectedBlogPostValue] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const [selectedUser,setSelectedUser] = useState();

  const loadUsersOptions = async (inputValue) => {
    console.log("loadUsersOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}users/search/advance?q={"userName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    console.log("loadUsersOptions.res.data.items",res.data.items)
    return res.data.items;
  }

  const onUserChange = (value) => {
    console.log("onUsersChange-value", value);   
    if (value) {
      formData.userId = value.id;
      setSelectedUser(value)
      console.log(" formData.postId ", formData.userId)
    }
    console.log("onUsersChange-selectedUser",selectedUser);    
  }

  const onBlogPostChange = (value) => {
    console.log("onBlogPostChange -value", value);
    if (value) {
      formData.postId = value.id;
      setSelectedBlogPostValue(value)
      console.log(" formData.postId ", formData.postId)      
    }
  }

  const loadBlogPostsOptions = async (inputValue) => {
    console.log("loadBlogPostsOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}blogPosts/search/advance?q={"title":{"$like": "*${inputValue}*"}}&totalResults=true&offset=0&limit=50`);
    console.log("res:",res.data.items)
    return res.data.items;  
  }

  const loadParentOptions = async (inputValue) => {
    console.log("loadParentOptions.inputValue",inputValue)
    const res = await axios.get(`${config.api.url}blogComments/search/advance?q={"name":{"$like": "*${inputValue}*"}}&totalResults=true&offset=0&limit=50`);
    console.log("res:",res.data.items) //TODO : remove current blog comment from result
    return res.data.items;  
  }

  const onParentChange = (value) => {
    console.log("onParentChange -value", value);
    if (value) {
      formData.parentId = value.id;
      setSelectedBlogPostValue(value)
      console.log(" formData.postId ", formData.parentId)      
    }
  }
  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e,sourceLabel) => {
    console.log("handleSubmit-selectedBlogComments:",selectedUser)

    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    let bodyData ={
      name: formData.name,
      emailId: formData.emailId,
      body: formData.body,
      postId: formData.postId,
      userId: formData.userId,
      parentId: formData.parentId,      
      active: formData.active,
    };
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "blogComments",
        query: "/" + formData.id,
        body: bodyData,
        method: "PATCH",
      };
    else
      data1 = {
        url: "blogComments",
        method: "POST",
        body: bodyData,
      };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;      
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0){          
          console.log("currentData.id",currentData.id)
          loadData(currentData.id);
        }
      },
      (error) => {
        if (error && error.status && error.status===500)
          common.notify("E","Api Error " +  error.data.msg);
        else
          common.notify("E", "Api Error " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
   
    setIsLoading(false);
    if(sourceLabel === "Save")
      navigate('/blogcommentslist'); 
  
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "blogComments",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
       console.log("loaddata-users",currentData.users);        
        setFormData(currentData);
        setSelectedUser(currentData.users);
        setSelectedBlogPostValue(currentData.blogPost);

        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Blog Post
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="text"
                            label="Email"
                            name="emailId"
                            value={formData.emailId}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4} xl={4}>
                          <MDInput
                            type="number"
                            label="Parent"
                            name="parentId"
                            value={formData.parentId}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12} xl={12}>
                          <MDInput
                            type="text"
                            label="Body"
                            multiline
                            rows={4}
                            name="body"
                            value={formData.body}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2} mb={5}>
                        <Grid item xs={6}>
                          <div className="selectWrapper">
                            <label>Users</label>
                            <AsyncSelect
                              label="Users"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search Users"
                              cacheOptions
                              fullWidth
                              value={selectedUser}
                              getOptionLabel={(e) => e.userName}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadUsersOptions}
                              onInputChange={(value) => setUserQuery(value)}
                              onChange={onUserChange}
                              sx={{ width: "18%", mr: 1, mt: 2 }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="selectWrapper">
                            <label>Blog Posts</label>
                            <AsyncSelect
                              label="Blog Posts"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search BlogPosts"
                              cacheOptions
                              fullWidth
                              value={selectedBlogPostValue}
                              getOptionLabel={(e) => e.slug}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadBlogPostsOptions}
                              onInputChange={(value) => setBlogPostQuery(value)}
                              onChange={onBlogPostChange}
                              sx={{ width: "18%", mr: 1, mt: 2 }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2} mb={5}>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/blogcommentslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlogPost;
