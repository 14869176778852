import { Link, useParams,useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
import Select from "react-select";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'


import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AsyncSelect from 'react-select/async'
import axios from 'axios' 
import { config } from "helpers/common/config";


const gridStyle = { minHeight: 250,minWidth: 50, marginRight: 20, marginLeft: 20 }

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  })
}

function Inquiry() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");
  const [sortInfo, setSortInfo] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));

  const [enquiryItemSelected, setEnquiryItemSelected] = useState({});
  const [enquiryItemRegionSelected, setEnquiryItemRegionSelected] = useState({});



  const onEnquiryItemSelectionChange = useCallback(({ selected }) => {
    setEnquiryItemSelected(selected)
    console.log("enquiryItemSelected",selected)

  }, [])

  const onEnquiryItemRegionSelectionChange = useCallback(({ selected }) => {
    setEnquiryItemRegionSelected(selected)
    console.log("EnquiryItemRegionSelected",selected)

  }, [])

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    enquiryNo: "",
    enquiryDate: moment().format("YYYY-MM-DD"),
    enquiryType: "",
    status: "",
    valueType: "",
    firstPartyId: "",
    thirdPartyId: "",
    effStartDate: moment().format("YYYY-MM-DD"),
    effEndDate: "",
    enquiryValue: "",
    funcCurrency: "",
    transCurrency: "",
    description: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    enquiryItems: [],
    firstParties: {},
    thirdParties: {},
    funcCurrencyObj: {},
    transCurrencyObj: {},
  });

  const [formEnquiryItemData, setFormEnquiryItemData] = useState({
    "id": 0,
    "enquiryHdrId": "",
    "displayOrder": "",
    "contentId": "",
    "contentRightId": "",
    "exclusiveRight": "",
    "durationType": "",
    "durationUom": "",
    "durationValue": "",
    "remarks": "",
    "status": "",
    "effStartDate": "",
    "effEndDate": "",
    "active": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null,
    enquiryItemRegions: [],
  });

  const [formEnquiryItemRegionData, setFormEnquiryItemRegionData] = useState({
    "id": 0,
    "enquiryItemId": "",
    "regionId": "",
    "incExlType": "",
    "effStartDate": "",
    "effEndDate": "",
    "active": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null,
    enquiryItemRegions: [],
  });

  //Status & ValueType
    const [statusListData, setStatusListData] = useState([]);
    const [ValueTypeListData, setValueTypeListData] = useState([]);

    const loadStatusList = () => {
      setIsLoading(true);
      const data1 = {
        url: "lookupValuesAdvanceSearch",
        method: "GET",
        query: `?q={"lookupType":{"$eq": "ZR_ENQUIRY_STATUS"}}&orderBy=[["id", "ASC"]]`,
      };
      api.call(
        data1,
        (res) => {
          console.log("loadStatusList", res.data.items);
          setStatusListData(res.data.items);
          setIsLoading(false);
        },
        (error) => {
          common.notify("E", "Api Failed " + error.statusText);
          setIsLoading(false);
        },
        (final) => {}
      );
    };

    const handleStatusChange = (val) => {
      const name = "status";

      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: val.lookupCode,
        };
      });
    };

    const loadValueTypeList = () => {
      setIsLoading(true);
      const data1 = {
        url: "lookupValuesAdvanceSearch",
        method: "GET",
        query: `?q={"lookupType":{"$eq": "ZR_ENQUIRY_VALUE_TYPE"}}&orderBy=[["id", "ASC"]]`,
      };
      api.call(
        data1,
        (res) => {
          console.log("loadStatusList", res.data.items);
          setValueTypeListData(res.data.items);
          setIsLoading(false);
        },
        (error) => {
          common.notify("E", "Api Failed " + error.statusText);
          setIsLoading(false);
        },
        (final) => {}
      );
    };

    const handleValueTypeChange = (val) => {
      const name = "valueType";

      setFormData((prevState) => {
        return {
          ...prevState,
          [name]: val.lookupCode,
        };
      });
    };
  //For First & Third Parties
  const [partyQuery, setPartyQuery] = useState("");

  const loadPartyOptions = async (inputValue) => {
    console.log("loadPartyOptions", inputValue);
    const res = await axios.get(`${config.api.url}parties/search/advance?q={"partyName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    return res.data.items;
  }
  const onPartyChange = (e,fieldName,objName) => {
    console.log("onPartyChange", e, "   ", e.id," fieldName ",fieldName);
    if (e) {
      let localData = formData;
      localData[fieldName] = e.id;
      localData[objName]["partyName"]=e.partyName;
      setFormData(localData);
      console.log("onPartyChange-setFormData", formData)
    }
  }

  //For Currencies
  const [currencyQuery, setCurrencyQuery] = useState("");

  const loadCurrencyOptions = async (inputValue) => {
    console.log("loadCurrencyOptions", inputValue);
    const res = await axios.get(`${config.api.url}currencies/search/advance?q={"code":{"$like": "*${inputValue}*"}}&offset=0&limit=50`);
    return res.data.items;
  }
  const onCurrencyChange = (e,fieldName,objName) => {
    console.log("onCurrencyChange", e, "   ", e.id," fieldName ",fieldName);
    if (e) {
      let localData = formData;
      localData[fieldName] = e.code;
      localData[objName]["code"]=e.code;
      setFormData(localData);
      console.log("onCurrencyChange-setFormData", formData)
    }
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "enquiryHeader",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);        
        let currentData = res.data.item;
        currentData.enquiryDate = moment(currentData.enquiryDate).format('YYYY-MM-DD');
        currentData.effStartDate = moment(currentData.effStartDate).format('YYYY-MM-DD');
        currentData.effEndDate = currentData.effEndDate?moment(currentData.effEndDate).format('YYYY-MM-DD'):null;
        setFormData(currentData);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  useEffect(() => {
    loadStatusList();
    loadValueTypeList();
    if (id)
      loadData(id);
  }, []); 

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleGridSwitchChange = (event,id,url) => {    
    const { name, checked } = event.target;
    console.log("name: ", name," checked: ",checked);
    if (id> 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        [name]: checked ? "Y" : null
      }
      
      wsData = {
        url: url,
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadData(formData.id);        
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => { }
      );
    }

  };

 
  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleAddEnquiryItem = (e) => {
    console.log("handleAddEnquiryItem")
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "enquiryItems",
      method: "POST",
      body: {
        enquiryHdrId: formContentData.enquiryHdrId,
        displayOrder: formContentData.displayOrder,
        contentId: formContentData.contentId,
        contentRightId: formContentData.contentRightId,
        exclusiveRight: formContentData.exclusiveRight,
        durationType: formContentData.durationType,
        durationUom: formContentData.durationUom,
        durationValue: formContentData.durationValue,
        remarks: formContentData.remarks,
        status: formContentData.status,
        effStartDate: formContentData.effStartDate,
        effEndDate: formContentData.effEndDate,
        active: formContentData.active,
      },
    };

    console.log("Add EnquiryItem with", data)
    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setContentPopupOpen(false);
        setIsLoading(false);
        loadData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { }
    );

  }

  const onEnquiryItemEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...formData.enquiryItems];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]', data[index]);
      console.log('data[index]["id"]', data[index]["enquiryItems"]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setFormData((prevState) => {
        return {
          ...prevState,
          enquiryItems: data,
        };
      });
  
      if (data[index]["enquiryItems"]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: data[index]["active"],
        }
        wsData = {
          url: "enquiryItems",
          query: "/" + data[index]["enquiryItems"]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadData(id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      }
    },
    [formData.enquiryItems]
  );

  const onEnquiryItemRegionEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...formData.enquiryItems[0].enquiryItemRegions]; //TODO
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]', data[index]);
      console.log('data[index]["id"]', data[index]["enquiryItemRegions"]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setFormData((prevState) => {
        return {
          ...prevState,
          enquiryItemRegions: data,
        };
      });
  
      if (data[index]["enquiryItemRegions"]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: data[index]["active"],
        }
        wsData = {
          url: "enquiryItemRegions",
          query: "/" + data[index]["enquiryItemRegions"]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadData(id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      }
    },
    [formData.enquiryItems]
  );

  const handleEnquiryItemFormChange = (val) => {
    const { name, value } = val.target;
    console.log(name, value)
    setFormContentData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }
  const onDeleteEnquiryItem = () => {
    if (enquiryItemSelected > 0) {
      const data1 = {
        url: "enquiryItems",
        query: "/" + enquiryItemSelected, 
        method: "DELETE",
      };
  
      api.call(
        data1,
        (res) => {
          console.log("deleted enquiryItem", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => { }
      );
  
    }
  }

  const onDeleteEnquiryItemRegion = () => {
    if (enquiryItemSelected > 0) {
      const data1 = {
        url: "enquiryItemRegions",
        query: "/" + enquiryItemRegionSelected, 
        method: "DELETE",
      };
  
      api.call(
        data1,
        (res) => {
          console.log("deleted enquiryItem", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => { }
      );
  
    }
  }

  const [enquiryItemPopupOpen, setEnquiryItemPopupOpen] = useState(false);
  const handleEnquiryItemPopupClickOpen = () => {
    setFormEnquiryItemData({
      "id": 0,
      "enquiryHdrId": "",
      "displayOrder": "",
      "contentId": "",
      "contentRightId": "",
      "exclusiveRight": "",
      "durationType": "",
      "durationUom": "",
      "durationValue": "",
      "remarks": "",
      "status": "",
      "effStartDate": "",
      "effEndDate": "",
      "active": "",
      "createdBy": null,
      "creationDate": null,
      "lastUpdatedBy": null,
      "lastUpdateDate": null,
      "lastUpdateLogin": null,
      "versionNumber": null,
      enquiryItemRegions: [],
    }
    )
    setEnquiryItemPopupOpen(true);
  };

  const handleEnquiryItemPopupClose = () => {
    setEnquiryItemPopupOpen(false);
  };

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    let body = {
      enquiryNo: formData.enquiryNo,
      enquiryDate: formData.enquiryDate,
      enquiryType: formData.enquiryType,
      status: formData.status,
      valueType: formData.valueType,
      firstPartyId: formData.firstPartyId,
      thirdPartyId: formData.thirdPartyId,
      effStartDate: formData.effStartDate,
      effEndDate: formData.effEndDate,
      enquiryValue: formData.enquiryValue,
      funcCurrency: formData.funcCurrency,
      transCurrency: formData.transCurrency,
      description: formData.description,
      active: formData.active,
    };
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "enquiryHeader",
        query: "/" + formData.id,
        body: body,
        method: "PATCH",
      };
    else
      data1 = {
        url: "enquiryHeader",
        method: "POST",
        body: body,
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;      
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0){
          console.log("EnquiryHeader", currentData.id)
          loadData(currentData.id)
        }
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
         navigate('/inquirylist');
       }
    );
  }
 
  const createContract = (e, sourceLabel) => {
    console.log("Action -createContract",e)
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    const enquiryItemsData = [...formData.enquiryItems];
    console.log("enquiryItemsData",enquiryItemsData)
    let contractItems = enquiryItemsData.map((enquiryItem) => {
      return {
        displayOrder: enquiryItem.displayOrder,
        contentId: enquiryItem.contentId,
        contentRightId: enquiryItem.contentRightId,
        remarks: enquiryItem.remarks,
        // status:enquiryItem.status,
        effStartDate: enquiryItem.effStartDate,
        effEndDate: enquiryItem.effEndDate,
        active: enquiryItem.active,
        contractItemRegions: enquiryItem.enquiryItemRegions.map((enquiryItemRegion) => {
          return {
            regionId: enquiryItemRegion.regionId,
            incExlType: enquiryItemRegion.incExlType,
            effStartDate: enquiryItemRegion.effStartDate,
            effEndDate: enquiryItemRegion.effEndDate,
            active: enquiryItemRegion.active,
          };
        }),
      };
    });

    console.log("contractItems :",contractItems);
    let body = {
      enquiryId: formData.id,
      contractDate: moment().format("YYYY-MM-DD"),
      name: `newContract-${formData.id}`, // TODO need to check
      contractType: formData.enquiryType,
      status: formData.status,
      valueType: formData.valueType,
      firstPartyId: formData.firstPartyId,
      thirdPartyId: formData.thirdPartyId,
      effStartDate: formData.effStartDate,
      effEndDate: formData.effEndDate,
      contractValue: formData.enquiryValue,
      funcCurrencyCode: formData.funcCurrency,
      transCurrencyCode: formData.transCurrency,
      description: formData.description,
      active: formData.active,
      // contractDocPath: formData.contractDocPath TODO: do we need to upload doc in enquiry page

      contractItems: contractItems,
      contractDetails: [], //TODO , not available in enquiry page
    };
    console.log("Create Contract Request Body",body)
      data1 = {
        url: "contractHeader",
        method: "POST",
        body: body,
      };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) {
          console.log("contractHeader", currentData.id);
          common.notify("S", "Contract Created Successfully with id",currentData.id);
        }
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if (sourceLabel === "Save") navigate("/inquirylist");
      }
    );
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Inquiry
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="EnquiryNo "
                            name="enquiryNo"
                            value={formData.enquiryNo}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="EnquiryType "
                            name="enquiryType"
                            value={formData.enquiryType}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="EnquiryValue"
                            name="enquiryValue"
                            value={formData.enquiryValue}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="enquiryDate"
                              label="EnquiryDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.enquiryDate}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="effStartDate"
                              label="Effective StartDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.effStartDate}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="effEndDate"
                              label="Effective EndDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.effEndDate}
                              onChange={handleChange}
                            />
                          </Grid>
                        </LocalizationProvider>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          {/* <MDInput
                            type="text"
                            label="Status "
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          /> */}
                          <div className="selectWrapper">
                            <label>Status</label>
                            <Select
                              id="status"
                              label="Status"
                              name="status"
                              classNamePrefix="cus-select"
                              onChange={handleStatusChange}
                              value={statusListData.find(
                                (i) => i.lookupCode == formData.status //compare string with number value
                              )}
                              options={statusListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select status"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="selectWrapper">
                            <label>ValueType</label>
                            <Select
                              id="valueType"
                              label="ValueType"
                              name="valueType"
                              classNamePrefix="cus-select"
                              onChange={handleValueTypeChange}
                              value={ValueTypeListData.find(
                                (i) => i.lookupCode == formData.valueType //compare string with number value
                              )}
                              options={ValueTypeListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select ValueType"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="selectWrapper">
                            <label>FuncCurrency *</label>
                            <AsyncSelect
                              label="Func Currency"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search Func Currency"
                              cacheOptions
                              fullWidth
                              value={{
                                code: formData.funcCurrencyObj.code,
                                id: formData.funcCurrency,
                              }}
                              getOptionLabel={(e) => e.code}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadCurrencyOptions}
                              onInputChange={(value) => setCurrencyQuery(value)}
                              onChange={(e) =>
                                onCurrencyChange(e, "funcCurrency", "funcCurrencyObj")
                              }
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="selectWrapper">
                            <label>TransCurrency *</label>
                            <AsyncSelect
                              label="TransCurrency"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search TransCurrency"
                              cacheOptions
                              fullWidth
                              value={{
                                code: formData.transCurrencyObj.code,
                                id: formData.transCurrency,
                              }}
                              getOptionLabel={(e) => e.code}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadCurrencyOptions}
                              onInputChange={(value) => setCurrencyQuery(value)}
                              onChange={(e) =>
                                onCurrencyChange(e, "transCurrency", "transCurrencyObj")
                              }
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <div className="selectWrapper">
                            <label>FirstParty *</label>
                            <AsyncSelect
                              label="FirstParty"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search first party"
                              cacheOptions
                              fullWidth
                              value={{
                                partyName: formData.firstParties.partyName,
                                id: formData.firstPartyId,
                              }}
                              getOptionLabel={(e) => e.partyName}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadPartyOptions}
                              onInputChange={(value) => setPartyQuery(value)}
                              onChange={(e) => onPartyChange(e, "firstPartyId", "firstParties")}
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className="selectWrapper">
                            <label>ThirdParty *</label>
                            <AsyncSelect
                              label="ThirdParty"
                              styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search third party"
                              cacheOptions
                              fullWidth
                              value={{
                                partyName: formData.thirdParties?.partyName,
                                id: formData.thirdPartyId,
                              }}
                              getOptionLabel={(e) => e.partyName}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadPartyOptions}
                              onInputChange={(value) => setPartyQuery(value)}
                              onChange={(e) => onPartyChange(e, "thirdPartyId", "thirdParties")}
                              // sx={{ width: "18%", mr: 1, mt: 2 }}
                            />{" "}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={4}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        sx={{ mr: 1 }}
                        component={Link}
                        to="/inquirylist"
                      >
                        Cancel
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        name="action"
                        disabled={formError}
                        onClick={(e) => createContract(e, "Action")}
                      >
                        {!isLoading ? "Action" : "Processing..."}
                      </MDButton>
                    </MDBox>

                    <MDBox pt={1}>
                      <>
                        <MDBox pt={1}>
                          {/* {isAdmin && (
                            <MDButton
                              color="info"
                              sx={{ mr: 1 }}
                              onClick={handleEnquiryItemPopupClickOpen}
                            >
                              Add EnquiryItem
                            </MDButton>
                          )} */}
                          {isAdmin && enquiryItemSelected > 0 && (
                            <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteEnquiryItem}>
                              Delete
                            </MDButton>
                          )}
                        </MDBox>
                        <MDBox pt={1}>
                          {/* <Dialog open={enquiryItemPopupOpen} onClose={handleEnquiryItemPopupClose}>
                            <DialogTitle>Add Enquiry Item</DialogTitle>
                            <DialogContent>
                              <DialogContentText>EnquiryItem</DialogContentText>
                              <Grid container spacing={1} mt={2} mb={5}>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="DisplayOrder"
                                    name="displayOrder"
                                    value={formEnquiryItemData.displayOrder}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="ContentId"
                                    name="contentId"
                                    value={formEnquiryItemData.contentId}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="contentRightId"
                                    name="contentRightId"
                                    value={formEnquiryItemData.contentRightId}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={2} mb={5}>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="DurationType"
                                    name="durationType"
                                    value={formEnquiryItemData.durationType}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="DurationUom"
                                    name="durationUom"
                                    value={formEnquiryItemData.durationUom}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="DurationValue"
                                    name="durationValue"
                                    value={formEnquiryItemData.durationValue}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={2} mb={5}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <Grid item xs={6} mt={3}>
                                    <MDInput
                                      type="date"
                                      name="effStartDate"
                                      label="Effective StartDate"
                                      InputLabelProps={{ shrink: true }}
                                      value={formData.effStartDate}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                  <Grid item xs={6} mt={3}>
                                    <MDInput
                                      type="date"
                                      name="agreementeffEndDateEndDate"
                                      label="Effective EndDate"
                                      InputLabelProps={{ shrink: true }}
                                      value={formData.effEndDate}
                                      onChange={handleChange}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                              </Grid>

                              <Grid container spacing={1} mt={2}>
                                <Grid item xs={4}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="exclusiveRight"
                                        checked={formData.exclusiveRight ? "Y" : null}
                                        onChange={handleSwitchChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                    }
                                    label="ExclusiveRight"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="active"
                                        checked={formData.active ? "Y" : null}
                                        onChange={handleSwitchChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                      />
                                    }
                                    label="Active"
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} mt={2} mb={5}>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="Status"
                                    name="status"
                                    value={formEnquiryItemData.status}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                  <MDInput
                                    type="text"
                                    label="Remarks"
                                    name="remarks"
                                    value={formEnquiryItemData.remarks}
                                    onChange={handleEnquiryItemFormChange}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleEnquiryItemPopupClose}>Cancel</MDButton>
                              <MDButton onClick={handleAddEnquiryItem}>Ok</MDButton>
                            </DialogActions>
                          </Dialog> */}
                          <ReactDataGrid
                            idProperty="id"
                            // style={gridStyle}
                            selected={enquiryItemSelected}
                            checkboxColumn
                            multiSelect={false}
                            showZebraRows
                            showHoverRows
                            editable
                            onEditComplete={onEnquiryItemEditComplete}
                            onSelectionChange={onEnquiryItemSelectionChange}
                            showCellBorders="vertical"
                            columns={[
                              {
                                // defaultLocked: true,
                                header: "Order",
                                name: "displayOrder",
                                // defaultFlex: 0.5,
                                defaultWidth: 70,
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.displayOrder}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "ContentName",
                                name: "contentId",
                                // defaultFlex: 1.5,
                                defaultWidth: 150,
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.content?.titleEn}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "ContentRight",
                                name: "contentRightId",
                                // defaultFlex:  1.5,
                                defaultWidth: 150,
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.contentRights?.rightType?.name}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "ExclusiveRight",
                                name: "exclusiveRight",
                                // defaultFlex: 1,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="exclusiveRight"
                                        checked={data.exclusiveRight === "Y" ? true : false}
                                        onChange={(e) =>
                                          handleGridSwitchChange(e, data.id, "enquiryItems")
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                          align: "center",
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                ),
                              },
                              {
                                header: "Active",
                                name: "active",
                                // defaultFlex: 0.5,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        name="active"
                                        checked={data.active === "Y" ? true : false}
                                        onChange={(e) =>
                                          handleGridSwitchChange(e, data.id, "enquiryItems")
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                          align: "center",
                                        }}
                                      />
                                    }
                                    label=""
                                  />
                                ),
                              },
                              {
                                header: "DurationType",
                                name: "durationType",
                                // defaultFlex:1.5,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.durationType}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "DurationUom",
                                name: "durationUom",
                                // defaultFlex: 1.5,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.durationUom}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "DurationValue",
                                name: "durationValue",
                                // defaultFlex: 0.7,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.durationValue}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "Eff StartDate",
                                name: "effStartDate",
                                // defaultFlex: 0.7,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {/* {data.effStartDate} */}
                                    {data.effStartDate
                                      ? moment(data.effStartDate).format("DD-MM-YYYY")
                                      : ""}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "Eff EndDate",
                                name: "effEndDate",
                                // defaultFlex: 0.7,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {/* {data.effEndDate} */}
                                    {data.effEndDate
                                      ? moment(data.effEndDate).format("DD-MM-YYYY")
                                      : ""}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "Status",
                                name: "status",
                                // defaultFlex: 0.7,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.status}
                                  </MDTypography>
                                ),
                              },
                              {
                                header: "Remarks",
                                name: "remarks",
                                // defaultFlex: 0.7,
                                defaultWidth: 100,
                                headerAlign: "center",
                                textAlign: "center",
                                render: ({ data, value }) => (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    {data.remarks}
                                  </MDTypography>
                                ),
                              },
                            ]}
                            pagination
                            dataSource={formData.enquiryItems}
                            defaultLimit={5}
                            onSortInfoChange={setSortInfo}
                            enableColumnAutosize
                          />
                        </MDBox>

                        <MDBox pt={1}>
                          {enquiryItemSelected > 0 && formData.enquiryItems[0].enquiryItemRegions && (
                            <MDBox>
                              {isAdmin && enquiryItemRegionSelected > 0 && (
                                <MDButton
                                  color="error"
                                  sx={{ mr: 1 }}
                                  onClick={onDeleteEnquiryItemRegion}
                                >
                                  Delete
                                </MDButton>
                              )}

                              <MDBox pt={1}>
                                <ReactDataGrid
                                  idProperty="id"
                                  style={gridStyle}
                                  scrollProps={false}
                                  selected={enquiryItemRegionSelected}
                                  checkboxColumn
                                  multiSelect={false}
                                  showZebraRows
                                  showHoverRows
                                  editable
                                  onEditComplete={onEnquiryItemRegionEditComplete}
                                  onSelectionChange={onEnquiryItemRegionSelectionChange}
                                  showCellBorders="vertical"
                                  columns={[
                                    {
                                      header: "Region",
                                      name: "regionId",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.region?.name}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "IncExlType",
                                      name: "incExlType",
                                      defaultFlex: 0.5,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.incExlType}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "EffStartDate",
                                      name: "effStartDate",
                                      defaultFlex: 0.5,
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {/* {data.effStartDate} */}
                                          {data.effStartDate
                                            ? moment(data.effStartDate).format("DD-MM-YYYY")
                                            : ""}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "EffEndDate",
                                      name: "effEndDate",
                                      defaultFlex: 0.7,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {/* {data.effEndDate} */}
                                          {data.effEndDate
                                            ? moment(data.effEndDate).format("DD-MM-YYYY")
                                            : ""}
                                        </MDTypography>
                                      ),
                                    },
                                    {
                                      header: "Active",
                                      name: "active",
                                      defaultFlex: 0.5,
                                      headerAlign: "center",
                                      textAlign: "center",
                                      render: ({ data, value }) => (
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              name="active"
                                              checked={data.active === "Y" ? true : false}
                                              onChange={(e) =>
                                                handleGridSwitchChange(
                                                  e,
                                                  data.id,
                                                  "enquiryItemRegions"
                                                )
                                              }
                                              inputProps={{
                                                "aria-label": "controlled",
                                                align: "center",
                                              }}
                                            />
                                          }
                                          label=""
                                        />
                                      ),
                                    },
                                  ]}
                                  pagination
                                  dataSource={formData.enquiryItems[0].enquiryItemRegions}
                                  defaultLimit={10}
                                  onSortInfoChange={setSortInfo}
                                  enableColumnAutosize
                                />
                              </MDBox>
                            </MDBox>
                          )}
                        </MDBox>
                      </>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Inquiry;
