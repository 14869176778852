/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function FormatList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);
  const defaultSortInfo = [{ name: 'ordering', dir: 1 }];
  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole('Admin');

    console.log("filters",filters)
    let url =
      `${config.api.url}formats/search/advance?totalResults=true` +
      '&offset=' +
      skip +
      '&limit=' +
      limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;

    console.log("url",url)
    return axios.get(url).then(res => {
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }


  const dataSource = useCallback(loadData, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Formats
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin &&
                  <MDButton variant="gradient" color="secondary" component={Link} to="/format">
                    New
                  </MDButton>
                }
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    {
                      header: "Action", name: "id", defaultWidth: 100, headerAlign: "center", textAlign: 'center', render: ({ value }) => <MDTypography component={Link}
                        to={'/format/' + value} variant="caption" color="text" fontWeight="medium">
                        Edit
                      </MDTypography>
                    },
                    { header: "Code", name: "code", defaultFlex: 1.5 },
                    { header: "Name", name: "name", defaultFlex: 1 },
                    { header: "Description", name: "description", defaultFlex: 1.5 },
                    { header: "Ordering", name: "ordering", defaultFlex: 0.7, textAlign: "center" },                                 
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  defaultSortInfo={defaultSortInfo}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    // { name: 'id', operator: 'eq', type: 'number', value: '' },
                    { name: 'code', operator: 'startsWith', type: 'string', value: '' },     
                    { name: 'name', operator: 'startsWith', type: 'string', value: '' },                   
                    { name: 'description', operator: 'startsWith', type: 'string', value: '' },
                    { name: 'ordering', operator: 'eq', type: 'string', value: '' },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FormatList;
