import { useRef, useState, useEffect } from "react";
import styles from "./dragAndDropFiles.module.css";
// import Image from "next/image";
// import uploadIcon from "../../assets/images/Upload_Icon.png";
// import closeIcon from "../../assets/images/Close_Icon_Yellow.png";
// import { useSelector, useDispatch } from "react-redux";
// import { getPromoTypes } from "../../../features/actionCreator/mastersCreator";
// import { SelectPromoType } from "../../forms/selectInput/index";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";

const DragAndDropFiles = ({ uploadedFile }) => {
  const dropZoneRef = useRef(null);
  const uploadFiles = useRef(null);
  // const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  // const promoTypesData = useSelector(
  //   (state) => state.rootReducer.masterPromoTypesState.promoTypesData
  // );

  const allowedFileTypes = ["video/mp4", "video/quicktime"]; // Allowed file types

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   control,
  //   watch,
  //   setValue,
  // } = useForm({ resolver: yupResolver() });

  // useEffect(() => {
  //   setSelectedFiles(uploadedPromos);
  // }, [uploadedPromos]);

  // useEffect(() => {
  //   dispatch(getPromoTypes());
  // }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    const videoFiles = files.filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    const newUploadedData = videoFiles.map((file) => ({
      promoType: {
        lookupCode: "TRAILER",
        meaning: "Trailer",
        enabledFlag: "Y",
        ordering: 2,
      },
      file: file,
    }));

    setUploadedData((prevUploadedData) => [
      ...prevUploadedData,
      ...newUploadedData,
    ]);
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    const videoFiles = files.filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    const newUploadedData = videoFiles.map((file) => ({
      // promoType: {
      //   lookupCode: "TRAILER",
      //   meaning: "Trailer",
      //   enabledFlag: "Y",
      //   ordering: 2,
      // },
      file: file,
    }));

    setUploadedData((prevUploadedData) => [
      ...prevUploadedData,
      ...newUploadedData,
    ]);
  };

  const removeFile = (file) => {
    uploadFiles.current.value = "";

    setUploadedData((prevUploadedData) =>
      prevUploadedData.filter((data) => data.file !== file)
    );
  };

  // const handleOnSelectChange = (selectedOption, index) => {
  //   const newUploadedData = uploadedData.map((data, dataIndex) => {
  //     if (dataIndex === index) {
  //       return {
  //         ...data,
  //         promoType: selectedOption,
  //       };
  //     }
  //     return data;
  //   });

  //   setUploadedData(newUploadedData);
  // };

  useEffect(() => {
    if (uploadedData.length >= 0) {
      uploadedFile(uploadedData);
    }
  }, [uploadedData]);

  return (
    <div >
      <div
        ref={dropZoneRef}
        className={`${styles.dropzone} ${isDragging ? styles.dragging : ""}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="uploadFiles"
          multiple
          accept=".mov, .mp4"
          onChange={handleFileInputChange}
          ref={uploadFiles}
        />

        {/* <div className={styles.uploadCloud}>
          <Image src={uploadIcon} alt="Upload Icon" />
        </div> */}
        {/* <p className={styles.dragText}>
          Drag &amp; Drop your teaser or trailer here
        </p> */}
        <label htmlFor="uploadFiles">
          <button type="button" className={styles.uploadBtn}>
            Upload Video
          </button>
        </label>
        {/* <p className={styles.subTxt}>or drop a file right here</p> */}

        <p className={styles.subTxt}>Allowed formats: .mov, .mp4</p>
      </div>
      {/* <div className={styles.selectedFilesArea}>
        {selectedFiles.length > 0 ? (
          <ul>
            {selectedFiles.map((selectedFile, index) => (
              <li key={index} className={styles.previewRow}>
                <div className={styles.previewRowImage}>
                  {selectedFile?.file.type.startsWith("video/") ? (
                    <video controls className={styles.previewVideo}>
                      <source
                        src={URL.createObjectURL(selectedFile?.file)}
                        type={selectedFile?.file.type}
                      />
                      Unable to Preview
                    </video>
                  ) : null}
                  <div className={styles.fileNameMain}>
                    <p className={styles.fileName}>{selectedFile?.file.name}</p>
                    <SelectPromoType
                      idName={`promoType${index}`}
                      name={`promoType${index}`}
                      control={control}
                      defaultValue={selectedFile.promoType}
                      options={promoTypesData}
                      getOptionLabel={(option) => option.meaning}
                      getOptionValue={(option) => option.lookupCode}
                      onSelectChange={(selectedOption) =>
                        handleOnSelectChange(selectedOption, index)
                      }
                    />
                  </div>
                </div>
                <div className={styles.removeBtnMain}>
                  <button
                    type="button"
                    onClick={() => removeFile(selectedFile?.file)}
                    className={styles.removeBtn}
                  >
                    <Image src={closeIcon} alt="close Icon" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.nofiles}>No files uploaded</p>
        )}
      </div> */}
    </div>
  );
};

export default DragAndDropFiles;
