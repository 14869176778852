import { Link, useParams, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
import Select from "react-select";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from "@mui/material/Avatar";
import Link1 from "@mui/material/Link";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import FileUpload from "@mui/icons-material/FileUpload";
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
//import PdfWebViewer from "components/PdfWebViewer/PdfWebViewer";
import PDFWebViewer from "components/UI/pdfWebViewer";
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    bottom: 3,
    border: "none",
  },
}));

const Thumbnail = styled(Avatar)(({ theme }) => ({
  width: 350,
  height: 164,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const Image = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `2px solid ${theme.palette.background.paper}`,
}));

import CropModal from "../../../../components/modal/cropModal";
import ImageCropper from "../../../../components/UI/imageCropper";
import axios from "axios";
import { config } from "helpers/common/config";
import { object } from "prop-types";

// import PdfViewerComponent from "components/PdfViewerComponent";

function Party() {
  const { id } = useParams();
  console.log(common.userInRole("Admin"));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole("Admin"));

  // const [selectedPartyType, setSelectedPartyType] = useState(null);
  // const [selectedPartyCategory, setSelectedPartyCategory] = useState(null);

  // const partyTypeData = [
  //   { label: "SELLER", value: "SELLER" },
  //   { label: "BUYER", value: "BUYER" },
  //   { label: "BOTH", value: "BOTH" },
  // ];

  const [partyTypeListData, setPartyTypeListData] = useState([]);
  const [partyCategoryListData, setPartyCategoryListData] = useState([]);

  const [fileInfo, setFileInfo] = useState({ file: "", type: "" });
  const [instance, setInstance] = useState();
  const [docLoaded, setDocLoaded] = useState(false);

  // const [numPages, setNumPages] = useState(0);
  // const [pageNumber, setPageNumber] = useState(1);

  // const onDocumentLoadSuccess = ( numPages ) =>  {
  //   setNumPages(numPages);
  // }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOnReady = (instance) => {
    setDocLoaded(false);
    const { Core, UI } = instance;
    // adding an event listener for when a document is loaded
    Core.documentViewer.addEventListener("documentLoaded", () => {
      console.log("document loaded");
      setDocLoaded(true);
    });
    /*
    if (instance)
    {
    setDocLoaded(true);
    instance.contentDocument.addEventListener("mouseup", handleMouseUp);
    }
    */
    console.log(instance);
    setInstance(instance);
  };

  const loadPartyTypeList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_PARTY_TYPE"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadPartyTypeList", res.data.items);
        setPartyTypeListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handlePartyTypeChange = (val) => {
    const name = "partyType";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  const loadPartyCategoryList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_PARTY_CATEGORY"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadPartyCategoryList", res.data.items);
        setPartyCategoryListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handlePartyCategoryChange = (val) => {
    const name = "partyCategory";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  // const partyCategoryData =[
  //   { label: "INDIVIDUAL", value: "INDIVIDUAL" },
  //   { label: "ORGANIZATION", value: "ORGANIZATION" },
  // ]

  //Image upload - start
  const [uploadFile, setUploadFile] = useState("");
  const [toggleCropModal, setToggleCropModal] = useState(false);
  const [imageRatio, setImageRatio] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  const profileImageInput = useRef(null);

  const [settingData, setSettingData] = useState({
    id: 0,
    settingsContext: "",
    settingsKey: "",
    value: "",
    settingsType: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const loadSettings = (settingsContext, settingsKey) => {
    // setIsLoading(true);
    const data1 = {
      url: "settings",
      method: "GET",
      query: `/search/advance?q={"settingsContext":{"$eq": "${settingsContext}"},"settingsKey":{"$eq": "${settingsKey}"}}&offset=0&limit=50`,
    };

    console.log("loadSettings:API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("loadSettings:res.data", res.data.items);
        let currentData = res.data.items[0];
        setSettingData(currentData);
        console.log("settingData", settingData);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const imageUploadHandle = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    if (file) {
      setUploadFile(URL.createObjectURL(file));
      setToggleCropModal(true);
      //Get image aspect Ration from setting api
      console.log("settingData.value", settingData.value);
      let splitter = new RegExp("x", "i");
      const [width, height] = settingData?.value?.split(splitter);
      console.log("width, height", width, height);
      setImageRatio(width / height);
    }
  };

  const getCroppedFile = (croppedImage) => {
    setCroppedImage(croppedImage);
    setToggleCropModal(false);
    formData.partyImage = URL.createObjectURL(croppedImage);
    setCroppedImagePreview(URL.createObjectURL(croppedImage));
    console.log("formData.partyImage", formData.partyImage);
  };

  const uploadImgDelete = () => {
    profileImageInput.current.value = null;
  };

  //Image Upload -end

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  // const handlePartyTypeChange = (val) => {
  //   const name = 'partyType'
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: val.value,
  //     };
  //   });
  //   setSelectedPartyType(val.value);
  //   console.log("selectedPartyType",selectedPartyType)
  // }

  // const handlePartyCategoryChange = (val) => {
  //   const name = 'partyCategory'

  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: val.value,
  //     };
  //   });
  //   setSelectedPartyCategory(val.value);

  // }

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    partyName: "",
    partyType: "",
    description: "",
    taxIdNo: "",
    gstNo: "",
    contactName: "",
    mobile: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    bankName: "",
    branchName: "",
    ifscCode: "",
    agreementStartDate: moment().format("YYYY-MM-DD"),
    agreementEndDate: "",
    agreementFileUrl: "",
    partyCategory: "",
    featured: "Y",
    partyImage: "",
    partyImageType: "",
    partyImageSize: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  useEffect(() => {
    loadPartyTypeList();
    loadPartyCategoryList();
    if (id) loadData(id);
    loadSettings("Party", "partyThumbnail");
  }, []);

  // const handleSwitchChange = (event) => {
  //   const { name, checked } = event.target;
  //   setFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: checked ? "Y" : null,
  //     };
  //   });
  // };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e, sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id);
    if (formData.id > 0)
      data1 = {
        url: "parties",
        query: "/" + formData.id,
        body: {
          partyName: formData.partyName,
          partyType: formData.partyType,
          description: formData.description,
          taxIdNo: formData.taxIdNo,
          gstNo: formData.gstNo,
          contactName: formData.contactName,
          mobile: formData.mobile,
          email: formData.email,
          address1: formData.address1,
          address2: formData.address2,
          country: formData.country,
          state: formData.state,
          city: formData.city,
          pincode: formData.pincode,
          bankName: formData.bankName,
          branchName: formData.branchName,
          ifscCode: formData.ifscCode,
          agreementStartDate: formData.agreementStartDate,
          agreementEndDate: formData.agreementEndDate,
          // agreementFileUrl: formData.agreementFileUrl,
          partyCategory: formData.partyCategory,
          featured: formData.featured,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "parties",
        method: "POST",
        body: {
          partyName: formData.partyName,
          partyType: formData.partyType,
          description: formData.description,
          taxIdNo: formData.taxIdNo,
          gstNo: formData.gstNo,
          contactName: formData.contactName,
          mobile: formData.mobile,
          email: formData.email,
          address1: formData.address1,
          address2: formData.address2,
          country: formData.country,
          state: formData.state,
          city: formData.city,
          pincode: formData.pincode,
          bankName: formData.bankName,
          branchName: formData.branchName,
          ifscCode: formData.ifscCode,
          agreementStartDate: formData.agreementStartDate,
          agreementEndDate: formData.agreementEndDate,
          // agreementFileUrl: formData.agreementFileUrl,
          partyCategory: formData.partyCategory,
          featured: formData.featured,
        },
      };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) {
          console.log("partiesId", currentData.id);
          uploadAgreementFile(currentData.id);
          uploadPartiesImage(currentData.id);
          loadData(currentData.id);
        }

        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if (sourceLabel === "Save") navigate("/partylist");
      }
    );
  };

  const uploadAgreementFile = (partiesId) => {
    let agreementFile = formData.agreementFileUrl;
    console.log("uploadAgreementFile-agreementFile", agreementFile);

    if (!(agreementFile instanceof Object)) {
      return;
    }
    // setIsLoading(true);
    console.log("payload.uploadAgreementFile", agreementFile);

    let payLoad = new FormData();
    payLoad.append("file", agreementFile, "agreementFile.pdf");
    console.log("payLoad", payLoad.get("file"));

    let data1 = {
      url: "parties",
      query: "/agreementFile/" + partiesId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  const uploadPartiesImage = (partiesId) => {
    console.log("uploadPartiesImage");
    if (!croppedImage) return;
    // setIsLoading(true);
    console.log("payload.croppedImage", croppedImage);

    let payLoad = new FormData();
    payLoad.append("image", croppedImage, "partyImage.png");
    console.log("payLoad", payLoad.get("file"));

    let data1 = {
      url: "parties",
      query: "/uploadImage/" + partiesId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) loadData(currentData.id);
        if (currentData?.partyImage) setCroppedImagePreview(currentData?.partyImage);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  const handleAgreementFile = (event) => {
    console.log("handleAgreementFile :" + event.target.files[0].name);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      let agreementFile = event.target.files[0];
      console.log("agreementFile", agreementFile);
      formData.agreementFileUrl = agreementFile;
      console.log("formData.agreementFileUrl", formData.agreementFileUrl);
    }
  };

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "parties",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        currentData.agreementStartDate = moment(res.data.item.agreementStartDate).format(
          "YYYY-MM-DD"
        );
        currentData.agreementEndDate = moment(res.data.item.agreementEndDate).format("YYYY-MM-DD");
        // setSelectedPartyType({value:res.data.item.partyType});
        // setSelectedPartyCategory({value:res.data.item.partyCategory});
        setFormData(currentData);
        if (currentData?.partyImage) setCroppedImagePreview(currentData?.partyImage);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Party
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Party Name"
                            name="partyName"
                            value={formData.partyName}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>Party Type *</label>
                            <Select
                              id="partyType"
                              label="PartyType"
                              name="partyType"
                              classNamePrefix="cus-select"
                              onChange={handlePartyTypeChange}
                              value={partyTypeListData.find(
                                (i) => i.lookupCode == formData.partyType //compare string with number value
                              )}
                              options={partyTypeListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select partyType"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                          {/* <div className="custom-select">
                            <Select
                              id="partyType"
                              name="partyType"
                              onChange={handlePartyTypeChange}
                              value={partyTypeData.find((i) => i.value === formData.partyType)}
                              options={partyTypeData}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              // className="custom-select-box basic-single"
                              placeholder="Select Party Type"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div> */}
                          {/* <MDInput
                            type="text"
                            label="Party Type"
                            name="partyType"
                            value={formData.partyType}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          /> */}
                        </Grid>
                        <Grid item xs={4}>
                          <div>
                            <div className="selectWrapper">
                              <label>Party Category *</label>
                              <Select
                                id="partyCategory"
                                label="partyCategory"
                                name="Party Category"
                                classNamePrefix="cus-select"
                                onChange={handlePartyCategoryChange}
                                value={partyCategoryListData.find(
                                  (i) => i.lookupCode == formData.partyCategory //compare string with number value
                                )}
                                options={partyCategoryListData}
                                getOptionLabel={(e) => e.lookupCode}
                                getOptionValue={(e) => e.lookupCode}
                                // className="custom-select-box basic-single"
                                placeholder="Select partyCategory"
                                fullWidth
                                menuPosition="fixed"
                              />
                            </div>
                            {/* <Select
                              id="partyCategory"
                              name="partyCategory"
                              onChange={handlePartyCategoryChange}
                              value={partyCategoryData.find(
                                (i) => i.value === formData.partyCategory
                              )}
                              options={partyCategoryData}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              // className="custom-select-box basic-single"
                              placeholder="Select Party Category"
                              fullWidth
                              menuPosition="fixed"
                            /> */}
                          </div>
                          {/* <MDInput
                            type="text"
                            label="Party Category"
                            name="partyCategory"
                            value={formData.partyCategory}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          /> */}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={4}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="TaxId No"
                            name="taxIdNo"
                            value={formData.taxIdNo}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="GST No"
                            name="gstNo"
                            value={formData.gstNo}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="featured"
                                checked={formData.featured === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Featured"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Contact Name"
                            name="contactName"
                            value={formData.contactName}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Address1"
                            name="address1"
                            value={formData.address1}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Address2"
                            name="address2"
                            value={formData.address2}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="State"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="Pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Bank Name"
                            name="bankName"
                            value={formData.bankName}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Branch Name"
                            name="branchName"
                            value={formData.branchName}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="IFSC Code"
                            name="ifscCode"
                            value={formData.ifscCode}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Grid item xs={4}>
                            <MDInput
                              type="date"
                              name="agreementStartDate"
                              required={true}
                              label="Agreement StartDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.agreementStartDate}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="date"
                              name="agreementEndDate"
                              required={true}
                              label="Agreement EndDate"
                              InputLabelProps={{ shrink: true }}
                              value={formData.agreementEndDate}
                              onChange={handleChange}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <p>
                            Upload agreement file:
                            {/* {formData.agreementFileUrl && (
                              <Link1 href={formData.agreementFileUrl} variant="body2">
                                Uploaded Agreement file
                              </Link1>
                            )} */}
                          </p>
                          <StyledBadge
                            overlap="rectangular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload agreement file"
                                component="label"
                                size="xl"
                                onChange={handleAgreementFile}
                              >
                                <input hidden accept="*/*" type="file" />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Image
                              src={formData.agreementFileUrl}
                              variant="square"
                              size="xxl"
                              shadow="sm"
                            ></Image>
                          </StyledBadge>
                          {formData.agreementFileUrl && (
                            <Button variant="text" onClick={handleOpen}>
                              Click to open agreement file
                            </Button>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <p>Upload Parties Profile Images:</p>
                          <StyledBadge
                            overlap="rectangular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                size="xl"
                                onChange={imageUploadHandle}
                              >
                                <input
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  id="profileImage"
                                  ref={profileImageInput}
                                />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Thumbnail
                              src={croppedImagePreview} //{formData.image}
                              variant="square"
                              size="xxl"
                              shadow="sm"
                            ></Thumbnail>
                          </StyledBadge>

                          {toggleCropModal && (
                            <CropModal>
                              <ImageCropper
                                uploadFile={uploadFile}
                                getCroppedFile={getCroppedFile}
                                imageRatio={imageRatio}
                                cropShape="rect"
                                uploadFileType="image"
                                closeCropPopup={() => {
                                  setToggleCropModal(false);
                                  if (croppedImage)
                                    formData.image = URL.createObjectURL(croppedImage);
                                }}
                                uploadImgDelete={uploadImgDelete}
                              />
                            </CropModal>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="warning" component={Link} to="/partylist">
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
                  <Box display="flex" alignItems="center">
                    <Box textAlign="center" flexGrow={1}>
                      AgreementFile
                    </Box>
                    <Box>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <DialogContent>
                    <PDFWebViewer document={formData.agreementFileUrl} />
                    {/* <Document
                      file={formData.agreementFileUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document> */}
                  </DialogContent>
                </Dialog>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Party;
