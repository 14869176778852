import { Link, useParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Select from "react-select";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const _ = require("lodash");
import moment from "moment";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "react-image-crop/dist/ReactCrop.css";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 };

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100,
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 }),
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 15,
    bottom: 15,
    border: "none",
  },
}));

function Servicerequest() {
  const { id } = useParams();
  console.log(common.userInRole("Admin"));
  const [sortInfo, setSortInfo] = useState([]);

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole("Admin"));

  const [selected, setSelected] = useState({});
  const [selectedDtlId, setSelectedDtlId] = useState(0);

  const [isDtlLoading, setIsDtlLoading] = useState(false);
  const [dtlTableData, setDtlTableData] = useState([]);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: 0,
    requestType: "",
    summary: "",
    severity: "",
    additionalDtls: "",
    email: "",
    name: "",
    requestStatus: "",
    ordering: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    loadRequestTypeList();
    loadSeverityList();
    loadRequestStatusList();
    if (id) {
      loadData(id);
      setIsDisabled(false);
    }
  }, []);

  const [requestTypeListData, setRequestTypeListData] = useState([]);
  const [severityListData, setSeverityListData] = useState([]);
  const [requestStatusListData, setRequestStatusListData] = useState([]);


  const loadRequestTypeList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_SERVICE_REQUEST_TYPE"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadRequestTypeList", res.data.items);
        setRequestTypeListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleRequestTypeChange = (val) => {
    const name = "requestType";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };


  const loadSeverityList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_SERVICE_REQUEST_SEVERITY"}}&orderBy=[["ordering", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadSeverityList", res.data.items);
        setSeverityListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleSeverityChange = (val) => {
    const name = "severity";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };


   const loadRequestStatusList = () => {
     setIsLoading(true);
     const data1 = {
       url: "lookupValuesAdvanceSearch",
       method: "GET",
       query: `?q={"lookupType":{"$eq": "ZR_SERVICE_REQUEST_STATUS"}}&orderBy=[["id", "DESC"]]`,
     };
     api.call(
       data1,
       (res) => {
         console.log("loadRequestStatusList", res.data.items);
         setRequestStatusListData(res.data.items);
         setIsLoading(false);
       },
       (error) => {
         common.notify("E", "Api Failed " + error.statusText);
         setIsLoading(false);
       },
       (final) => {}
     );
   };

   const handleRequestStatusChange = (val) => {
     const name = "requestStatus";

     setFormData((prevState) => {
       return {
         ...prevState,
         [name]: val.lookupCode,
       };
     });
   };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleSrCommentsSwitchChange = (event, id) => {
    console.log("handleSrCommentsSwitchChange-id", id);
    const { name, checked } = event.target;
    setFormSrCommentsData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
    if (id > 0) {
      let bodyData = {};
      bodyData = {
        // ordering: data[index]["ordering"],
        [name]: checked ? "Y" : null,
      };
      console.log("ServiceRequestComments edit bodyData", bodyData);
      let wsData = {
        url: "serviceRequestComments",
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          console.log("handleSrCommentsSwitchChange -response ",res.data.item);
          common.notify("S", "Saved Successfully");
          loadSrComments(res.data.item.srId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const [srCommentsPopupOpen, setSrCommentsPopupOpen] = useState(false);

  const [formSrCommentsData, setFormSrCommentsData] = useState({
    id: 0,
    srId: "",
    userId: common.getEmployeeNo(),
    rating: "",
    commentContent: "",
    parentId: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const handleSrCommentsPopupClickOpen = () => {
    console.log("handleSrCommentsPopupClickOpen");
    setFormSrCommentsData({
      id: 0,
      srId: "",
      userId: common.getEmployeeNo(),
      rating: "",
      commentContent: "",
      parentId: "",
      active: "Y",
      created_by: null,
      creation_date: null,
      last_updated_by: null,
      last_update_date: null,
      last_update_login: null,
      version_number: null,
    });
    setSrCommentsPopupOpen(true);
  };

    const handleSrCommentsReplyPopupClickOpen = () => {
      console.log("handleSrCommentsReplyPopupClickOpen");
      setFormSrCommentsData({
        id: 0,
        srId: "",
        userId: common.getEmployeeNo(),
        rating: "",
        commentContent: "",
        parentId: selected,
        active: "Y",
        created_by: null,
        creation_date: null,
        last_updated_by: null,
        last_update_date: null,
        last_update_login: null,
        version_number: null,
      });
      setSrCommentsPopupOpen(true);
    };

  const handleSrCommentsPopupClose = () => {
    setSrCommentsPopupOpen(false);
  };

  const handleAddSrComments = (e) => {
    console.log("handleAddSrComments");
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "serviceRequestComments",
      method: "POST",
      body: {
        srId: formData.id,
        userId: formSrCommentsData.userId,
        rating: formSrCommentsData.rating,
        commentContent: formSrCommentsData.commentContent,
        parentId: formSrCommentsData.parentId,
        active: formSrCommentsData.active,
      },
    };

    console.log("Add ServiceRequestComments with", data);
    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setSrCommentsPopupOpen(false);
        setIsLoading(false);
        loadData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onAddSrCommentsEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...dtlTableData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log("rowId", rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);

      console.table(data);
      data[index][columnId] = value;
      setDtlTableData(data);

      // if (columnId === "ordering" && data[index]["id"]) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        // ordering: data[index]["ordering"],
        [columnId]: data[index][columnId],
      };
      console.log("ServiceRequestComments edit bodyData", bodyData);
      wsData = {
        url: "serviceRequestComments",
        query: "/" + data[index]["id"],
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadSrComments(pId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
      // }
    },
    [dtlTableData]
  );

  const handleSrCommentsFormChange = (val) => {
    const { name, value } = val.target;
    console.log(name, value);
    setFormSrCommentsData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onDeleteSrComments = () => {
    console.log("onDeleteSrComments -selected", selected);
    if (selected > 0) {
      const data1 = {
        url: "serviceRequestComments",
        query: "/" + selected, //TODO..Need to remove contentId from collections
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted ServiceRequestComments ", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const handleSubmit = (e, sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let bodyObj = {
      requestType: formData.requestType,
      summary: formData.summary,
      severity: formData.severity,
      additionalDtls: formData.additionalDtls,
      email: formData.email,
      name: formData.name,
      requestStatus: formData.requestStatus,
      ordering: formData.ordering,
      active: formData.active,
    };
    let data1 = {};
    console.log("formData.id", formData.id);
    if (formData.id > 0)
      data1 = {
        url: "serviceRequests",
        query: "/" + formData.id,
        body: bodyObj,
        method: "PATCH",
      };
    else
      data1 = {
        url: "serviceRequests",
        method: "POST",
        body: bodyObj,
      };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) loadData(currentData.id);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if (sourceLabel === "Save") navigate("/serviceRequestslist");
      }
    );
  };

  const handleSrCommentChange = (val) => {
    const { name, value } = val.target;
    setFormSrCommentsData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const srCommentColumns = [
    { name: "id", header: "Id", defaultFlex: 0.6 },

    {
      name: "users",
      header: "User",
      defaultFlex: 1,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.users?.userProfiles?.firstName}
        </MDTypography>
      ),
    },
    { name: "rating", header: "Rating", defaultFlex: 0.5, textAlign: "center" },
    { name: "commentContent", header: "CommentContent", defaultFlex: 1.0 },
    { name: "parentId", header: "ParentId", defaultFlex: 0.7, textAlign: "center" },
    {
      header: "CreationDate",
      name: "creationDate",
      defaultFlex: 1.3,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.creationDate ? moment(data.creationDate).format("DD-MM-YYYY HH:mm") : ""}
        </MDTypography>
      ),
    },
    {
      name: "active",
      header: "Active",
      defaultFlex: 0.6,
      textAlign: "center",
      render: ({ data, value }) => (
        <FormControlLabel
          control={
            <Switch
              name="active"
              checked={data.active === "Y" ? true : false}
              onChange={(e) => handleSrCommentsSwitchChange(e, data.id)}
              inputProps={{
                "aria-label": "controlled",
                align: "center",
              }}
            />
          }
          label=""
        />
      ),
    },
  ];

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "serviceRequests",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        setIsLoading(false);
        loadSrComments(pId);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadSrComments = (pId) => {
    console.log("pId:", pId);
    setIsDtlLoading(true);
    const data1 = {
      url: "serviceRequestCommentsAdvanceSearch",
      method: "GET",
      query: `?q={"srId":{"$eq": ${pId}}}&orderBy=[["id", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadSrComments Response ", res.data.items);
        setDtlTableData(res.data.items);
        setFormSrCommentsData(res.data.items);
        setIsDtlLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error?.statusText);
        setIsDtlLoading(false);
      },
      (final) => {}
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  ServiceRequestComments
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>RequestType *</label>
                            <Select
                              id="requestType"
                              label="RequestType"
                              name="requestType"
                              onChange={handleRequestTypeChange}
                              value={requestTypeListData.find(
                                (i) => i.lookupCode == formData.requestType //compare string with number value
                              )}
                              options={requestTypeListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select requestType"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>Severity *</label>
                            <Select
                              id="severity"
                              label="Severity"
                              name="severity"
                              onChange={handleSeverityChange}
                              value={severityListData.find(
                                (i) => i.lookupCode == formData.severity //compare string with number value
                              )}
                              options={severityListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select severity"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="selectWrapper">
                            <label>RequestStatus *</label>
                            <Select
                              id="requestStatus"
                              label="RequestStatus"
                              name="requestStatus"
                              onChange={handleRequestStatusChange}
                              value={requestStatusListData.find(
                                (i) => i.lookupCode == formData.requestStatus //compare string with number value
                              )}
                              options={requestStatusListData}
                              getOptionLabel={(e) => e.lookupCode}
                              getOptionValue={(e) => e.lookupCode}
                              // className="custom-select-box basic-single"
                              placeholder="Select requestStatus"
                              fullWidth
                              menuPosition="fixed"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Summary"
                            // multiline
                            // rows={2}
                            name="summary"
                            value={formData.summary}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="AdditionalDtls"
                            multiline
                            rows={4}
                            name="additionalDtls"
                            value={formData.additionalDtls}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="number"
                            label="Ordering"
                            name="ordering"
                            value={formData.ordering}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active ? "Y" : null}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/servicerequestslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                    <Dialog
                      open={srCommentsPopupOpen}
                      onClose={handleSrCommentsPopupClose}
                      maxWidth="md"
                      fullWidth={true}
                    >
                      <DialogTitle>ServiceRequestComments</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Add or edit a new ServiceRequestComments
                        </DialogContentText>
                        <Grid container spacing={1} mt={1}>
                          {/* <Grid item xs={3}>
                            <MDInput
                              type="text"
                              // required
                              // display={false}
                              label="userId"
                              name="userId"
                              onChange={handleSrCommentChange}
                              value={formSrCommentsData.userId}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid> */}
                          <Grid item xs={3}>
                            <MDInput
                              type="text"
                              required
                              label="Rating"
                              name="rating"
                              onChange={handleSrCommentChange}
                              value={formSrCommentsData.rating}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={9}>
                            <MDInput
                              type="text"
                              label="Comments"
                              multiline
                              rows={2}
                              name="commentContent"
                              value={formSrCommentsData.commentContent}
                              onChange={handleSrCommentChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          {/* <Grid item xs={3}>
                            <MDInput
                              type="number"
                              display={false}
                              label="ParentId"
                              name="parentId"
                              onChange={handleSrCommentChange}
                              value={formSrCommentsData.parentId}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid> */}
                          <Grid item xs={4}>
                            <FormControlLabel
                              control={
                                <Switch
                                  name="active"
                                  checked={formSrCommentsData.active ? "Y" : null}
                                  onChange={(e) => handleSrCommentsSwitchChange(e, 0)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Active"
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <MDButton onClick={handleSrCommentsPopupClose}>Cancel</MDButton>
                        <MDButton onClick={handleAddSrComments}>Ok</MDButton>
                      </DialogActions>
                    </Dialog>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-start", p: 0, mb: 1 }}>
                      {formData.id > 0 && (
                        <MDButton
                          variant="gradient"
                          color="info"
                          sx={{ mr: 1 }}
                          onClick={handleSrCommentsPopupClickOpen}
                        >
                          Add
                        </MDButton>
                      )}
                      {selected && selected > 0 && (
                        <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteSrComments}>
                          Delete
                        </MDButton>
                      )}
                      {selected && selected > 0 && (
                        <MDButton
                          color="success"
                          sx={{ mr: 1 }}
                          onClick={handleSrCommentsReplyPopupClickOpen}
                        >
                          Reply
                        </MDButton>
                      )}
                    </MDBox>
                    <MDBox>
                      <ReactDataGrid
                        idProperty="id"
                        selected={selected}
                        style={gridStyle}
                        checkboxColumn
                        editable
                        onEditComplete={onAddSrCommentsEditComplete}
                        multiSelect={false}
                        onSelectionChange={onSelectionChange}
                        columns={srCommentColumns}
                        loading={isDtlLoading}
                        dataSource={dtlTableData}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Servicerequest;
