/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import ReportsBarChart from "coreui/Charts/BarCharts/ReportsBarChart";
import PieChart from "coreui/Charts/PieChart";
import MixedChart from "coreui/Charts/MixedChart";
import DefaultDoughnutChart from "coreui/Charts/DoughnutCharts/DefaultDoughnutChart";
import ReportsLineChart from "coreui/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "coreui/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Worklist from "layouts/dashboard/components/Worklist";
import LatestApproval from "layouts/dashboard/components/LatestApproval";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [isLoading, setIsLoading] = useState(false);
  const [quickDbRows, setQuickDbRows] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [totalContentCount, setTotalContentCount] = useState(0);
  const [soldContentCount, setSoldContentCount] = useState(0);
  const [submittedContentCount, setSubmittedContentCount] = useState(0);
  const [totalBuyersCount, setTotalBuyersCount] = useState(0);
  const [totalSellersCount, setTotalSellersCount] = useState(0);
  const [totalUserRegChart, setTotalUserRegChart] = useState({
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "User Registration", data: [320, 500, 350, 200, 230, 500] }
  });

  const [totalUserMixedChart, setTotalUserMixedChart] = useState({
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [{ color:"green", label: "Buyers", data: [320, 500, 350, 200, 230, 500] },{ color:"success", label: "Sellers", data: [160, 250, 150, 100, 110, 200] }]
  });

  const [totalContentTypesChart, setTotalContentTypesChart] = useState({
    labels: ["Movie", "Series", "TV Shows", "Stage Plays", "Serial", "Short Films"],
    datasets: { label: "Content Types", data: [600, 200, 350, 200, 230, 100] }
  });


  const loadUserRegChart = () => {
    setIsLoading(true);
    const data1 = {
      url: "userRegChart",
      method: "GET",
      query: `?limit=10`,
    };

    api.call(
      data1,
      (res) => {
        console.table("res.data.items",res.data.items);
        var cats = [];
        var series1Data = [];
        res.data.items.map((data, idx) => {
          console.log(" JSON " + JSON.stringify(data));
          cats.push(data.xName);
          series1Data.push(data.yValue);
        })

        let chartData = {
          labels: cats,
          datasets: { label: "User Registration", data: series1Data },
        }

        setTotalUserRegChart(chartData)

        // setQuickDbRows(res.data.items);
        // if (res.data.items && res.data.items.length > 0)
        // {
        //   const userdtls = res.data.items.filter(a => a.infoletType === "Total Users");
        //   if (userdtls && userdtls.length > 0)
        //   setTotalUsersCount(userdtls[0].totalCount);
          
        //   const contentdtls = res.data.items.filter(a => a.infoletType === "Total Content");
         
        //   if (contentdtls && contentdtls.length > 0)
        //   {
            
        //     setTotalContentCount(contentdtls[0].totalCount);
        //   }
           
        //   const buyerdtls = res.data.items.filter(a => a.infoletType === "Buyers");
        //   if (buyerdtls && buyerdtls.length > 0)
        //   setTotalBuyersCount(buyerdtls[0].totalCount);
        
        //   const sellerdtls = res.data.items.filter(a => a.infoletType === "Sellers");
        //   if (sellerdtls && sellerdtls.length > 0)
        //   setTotalSellersCount(sellerdtls[0].totalCount);
        // }
          
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const loadBuyerSellerChart = () => {
    setIsLoading(true);
    const data1 = {
      url: "buyerSellerChart",
      method: "GET",
      query: `?limit=10`,
    };

    api.call(
      data1,
      (res) => {
        console.table("res.data.items",res.data.items);
        var cats = [];
        var series1Data = [];
        var series2Data = [];
        res.data.items.map((data, idx) => {
          console.log(" JSON " + JSON.stringify(data));
          cats.push(data.xName);
          series1Data.push(data.yValue);
          series2Data.push(data.y1Value);
        })

        let chartData = {
          labels: cats,
          datasets: [{ color:"green", label: "Buyers", data: series1Data },{ color:"success", label: "Sellers", data: series2Data }]

        }

        // labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        // datasets: [{ color:"green", label: "Buyers", data: [320, 500, 350, 200, 230, 500] },{ color:"success", label: "Sellers", data: [160, 250, 150, 100, 110, 200] }]
     
        setTotalUserMixedChart(chartData)

        // setQuickDbRows(res.data.items);
        // if (res.data.items && res.data.items.length > 0)
        // {
        //   const userdtls = res.data.items.filter(a => a.infoletType === "Total Users");
        //   if (userdtls && userdtls.length > 0)
        //   setTotalUsersCount(userdtls[0].totalCount);
          
        //   const contentdtls = res.data.items.filter(a => a.infoletType === "Total Content");
         
        //   if (contentdtls && contentdtls.length > 0)
        //   {
            
        //     setTotalContentCount(contentdtls[0].totalCount);
        //   }
           
        //   const buyerdtls = res.data.items.filter(a => a.infoletType === "Buyers");
        //   if (buyerdtls && buyerdtls.length > 0)
        //   setTotalBuyersCount(buyerdtls[0].totalCount);
        
        //   const sellerdtls = res.data.items.filter(a => a.infoletType === "Sellers");
        //   if (sellerdtls && sellerdtls.length > 0)
        //   setTotalSellersCount(sellerdtls[0].totalCount);
        // }
          
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const loadContentTypeChart = () => {
    setIsLoading(true);
    const data1 = {
      url: "contentTypeChart",
      method: "GET",
      query: `?limit=10`,
    };

    api.call(
      data1,
      (res) => {
        console.table("res.data.items",res.data.items);
        var cats = [];
        var series1Data = [];
        res.data.items.map((data, idx) => {
          console.log(" JSON " + JSON.stringify(data));
          cats.push(data.name);
          series1Data.push(data.totalCount);
        })

        let chartData = {
          labels: cats,
          datasets: { label: "Content Types", data: series1Data },
        }

        setTotalContentTypesChart(chartData)

        // setQuickDbRows(res.data.items);
        // if (res.data.items && res.data.items.length > 0)
        // {
        //   const userdtls = res.data.items.filter(a => a.infoletType === "Total Users");
        //   if (userdtls && userdtls.length > 0)
        //   setTotalUsersCount(userdtls[0].totalCount);
          
        //   const contentdtls = res.data.items.filter(a => a.infoletType === "Total Content");
         
        //   if (contentdtls && contentdtls.length > 0)
        //   {
            
        //     setTotalContentCount(contentdtls[0].totalCount);
        //   }
           
        //   const buyerdtls = res.data.items.filter(a => a.infoletType === "Buyers");
        //   if (buyerdtls && buyerdtls.length > 0)
        //   setTotalBuyersCount(buyerdtls[0].totalCount);
        
        //   const sellerdtls = res.data.items.filter(a => a.infoletType === "Sellers");
        //   if (sellerdtls && sellerdtls.length > 0)
        //   setTotalSellersCount(sellerdtls[0].totalCount);
        // }
          
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const loadQDashboardRows = () => {
    setIsLoading(true);
    const data1 = {
      url: "qdashboard",
      method: "GET",
      query: `?limit=10`,
    };

    api.call(
      data1,
      (res) => {
        console.table("res.data.items",res.data.items);
        setQuickDbRows(res.data.items);
        if (res.data.items && res.data.items.length > 0)
        {
          const userdtls = res.data.items.filter(a => a.infoletType === "Total Users");
          if (userdtls && userdtls.length > 0)
          setTotalUsersCount(userdtls[0].totalCount);
          
          const contentdtls = res.data.items.filter(a => a.infoletType === "Total Content");
          if (contentdtls && contentdtls.length > 0)
          setTotalContentCount(contentdtls[0].totalCount);
          
          const soldContentDtls = res.data.items.filter(a => a.infoletType === "Sold Content");
          if (soldContentDtls && soldContentDtls.length > 0)          
          setSoldContentCount(soldContentDtls[0].totalCount);

          const submittedContentDtls = res.data.items.filter(a => a.infoletType === "Submitted Content");
          if (submittedContentDtls && submittedContentDtls.length > 0)          
          setSubmittedContentCount(submittedContentDtls[0].totalCount);
          console.log("submittedContentDtls[0].totalCount",submittedContentDtls[0].totalCount)
          
           
          const buyerdtls = res.data.items.filter(a => a.infoletType === "Buyers");
          if (buyerdtls && buyerdtls.length > 0)
          setTotalBuyersCount(buyerdtls[0].totalCount);
        
          const sellerdtls = res.data.items.filter(a => a.infoletType === "Sellers");
          if (sellerdtls && sellerdtls.length > 0)
          setTotalSellersCount(sellerdtls[0].totalCount);
        }
          
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  useEffect(() => {
    const auth = common.getAuth();
    if (!auth) {
      common.redirect("/auth/signin");
    } else
    {
      loadQDashboardRows();
      loadUserRegChart();
      loadBuyerSellerChart();
      loadContentTypeChart();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Users"
                count={totalUsersCount ? totalUsersCount : 0}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Content"
                count={totalContentCount}
                percentage={{
                  color: "success",
                  amount: "6",//{submittedContentCount},//"+1%",TODO
                  label: "needs approval",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total Buyers"
                count={totalBuyersCount ? totalBuyersCount : 0}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Sellers"
                count={totalSellersCount ? totalSellersCount : 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox> 
      <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="User Registration"
                  description="Registration for the Last Six Months"
                  date="Updated Now"
                  chart={totalUserRegChart}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <PieChart
                  color="success"
                  title="Content Types"
                  description="Distribution of Content Types"
                  date="just updated"
                  chart={totalContentTypesChart}
                />  
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <MixedChart
                  color="success"
                  title="Buyers/Sellers"
                  description="Distribution of Buyers/Sellers"
                  date="just updated"
                  chart={totalUserMixedChart}
                />  
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <DefaultDoughnutChart
                  color="success"
                  title="Content Types"
                  description="Distribution of Content Types"
                  date="just updated"
                  chart={totalContentTypesChart}
                />  
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
