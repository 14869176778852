import { useEffect, useState, useRef,useCallback } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async'
// import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";

import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/Adapter/Dayjs';
// import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";
// import axios from 'axios';
// import { config } from "helpers/common/config";
const _ = require('lodash');

// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Slider from '@mui/material/Slider';

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import { canvasPreview } from "helpers/common/canvasPreview"
import { useDebounceEffect } from "helpers/common/useDebounceEffect"
import "react-image-crop/dist/ReactCrop.css"

import CropModal from "../../../../components/modal/cropModal";
import ImageCropper from "../../../../components/UI/imageCropper";
import axios from 'axios';
import { config } from "helpers/common/config";

const editorConfiguration = {
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: `/uploadblogimage`,

    // Enable the XMLHttpRequest.withCredentials property if required.
    withCredentials: true,

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      Authorization: "Bearer " + common.getAuth()
    }
  }
};

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 15,
    bottom: 15,
    border: 'none',
  },
}));


const Image = styled(Avatar)(({ theme }) => ({
  width: 1080,
  height: 608,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function BlogPost() {
  const { id } = useParams();
  console.log(common.userInRole("Admin"));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole("Admin"));
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    title: "",
    slug: "",
    shortText: "",
    fullText: "",
    featured: "",
    categoryId: "",
    coverImagePath: "",
    notes: "",
    effStartDate: moment().format("YYYY-MM-DD HH:mm"),
    effEndDate: "",
    views: "",
    metaDesc: "",
    metaKeywords: "",
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    blogTags: [],
  });

  useEffect(() => {
    console.log("id", id);
    loadBlogTagsOptions();
    loadSettings("Blog", "coverImage");

    if (id) {
      loadData(id);
      setIsDisabled(false);
    }
    console.log("IsDisabled", isDisabled);
  }, []);

  // const [blogCategoryQuery, setBlogCategoryQuery] = useState("");
  // const [blogTagQuery, setBlogTagQuery] = useState("");
  const [selectedBlogCatValue, setSelectedBlogCatValue] = useState("");
  const [selectedBlogTags, setSelectedBlogTags] = useState([]);
  const [assignedBlogTags,setAssignedBlogTags] = useState([]);

  //Blog Categories
  const loadBlogCategoriesOptions = async (inputValue) => {
    console.log("loadBlogCategoriesOptions.inputValue", inputValue);
    const res = await axios.get(
      `${config.api.url}blogCategories/search/advance?q={"name":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    console.log("loadBlogCategoriesOptions.res.data.items", res.data.items);
    return res.data.items;
  };
  const onBlogCategoryChange = (value) => {
    console.log("onBlogCategoryChange", value);
    if (value) {
      formData.categoryId = value.id;
      setSelectedBlogCatValue(value);
      console.log(" formData.categoryId ", formData.categoryId);
    }
  };

  //Blog Tags

  const loadBlogTagsOptions = async (inputValue) => {
    console.log("loadBlogTagsOptions");
    const res = await axios.get(
      `${config.api.url}blogTags/search/advance?q={"name":{"$like": "*${inputValue}*"}}&totalResults=true&offset=0&limit=50`
    );
    console.log("res:", res.data.items);
    let blogTagsOptions = res.data.items.map((blogTag) => {
      return createOption(blogTag.name, blogTag.id);
    });
    console.log("blogTagsOptions :", blogTagsOptions);
    return blogTagsOptions;
  };

  const onBlogTagChange = (value) => {
    console.log("onBlogTagChange", value);
    setSelectedBlogTags(value);
    console.log("selectedBlogTags", selectedBlogTags);
  };

  useEffect(() => {
    console.log("useEffect-selectedBlogTags", selectedBlogTags);
  }, [selectedBlogTags]);

  const createOption = (label, value) => ({
    label: label,
    value: value,
  });

  /** Create Bog Tags */
  const createBlogTag = (name) => {
    let data1 = {
      url: "blogTags",
      method: "POST",
      body: {
        name: name,
        active: "Y",
      },
    };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) {
          console.log("blogTagId :", currentData.id);
          console.log("selectedBlogTags :", selectedBlogTags);
          selectedBlogTags.push(createOption(currentData.name, currentData.id));
          // setSelectedBlogTags((prevVal) => {
          //   console.log("createBlogTag-prevVal", prevVal);
          //   [
          //   ...prevVal,
          //   createOption(currentData.name, currentData.id),
          // ]});
          setSelectedBlogTags(selectedBlogTags);
          console.log("createBlogTag-selectedBlogTags :", selectedBlogTags);
        }
        common.notify("S", "Saved Successfully");
        // if (currentData.id > 0)
        //   loadData(currentData.id)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  /** Delete  Bog Tags */
  const onDeleteBlogPostTags = async (blogPostId) => {
    if (blogPostId > 0) {
      const data1 = {
        url: "blogPostsTags",
        query: "/blogPost/" + blogPostId,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          console.log("deleted BlogPostTags", res);
          // common.notify("S", "Deleted Successfully");
          // setIsLoading(false);
          // loadData(formData.id);
          return res;
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          // setIsLoading(false);
        },
        (final) => {}
      );
    }
  };
  const deleteBlogPostTag = (blogPostId, blogTagId) => {
    if (blogPostId > 0) {
      const data1 = {
        url: "blogPostsTags",
        query: "/blogPost/" + blogPostId + "/blogTag/" + blogTagId,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          console.log("Deleted BlogPostTags", res);
          //  return res;
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };


  /** Create BogPostTags ( linking table) */
  const createBlogPostTag = async (postId) => {
    console.log("createBlogPostTag-selectedBlogTags ", selectedBlogTags);
    console.log("createBlogPostTag-assignedBlogTags ", assignedBlogTags);
    

    const oldBlogTags = assignedBlogTags.filter((assignedBlogTag) => {
      return !selectedBlogTags.some((selectedBlogTag) => {
        return assignedBlogTag.value === selectedBlogTag.value;
      });
    });

    console.log("oldBlogTags", oldBlogTags);

    oldBlogTags.map(oldBlogTag =>{
      deleteBlogPostTag(formData.id,oldBlogTag.value);
    });

    const newBlogTags = selectedBlogTags.filter((selectedBlogTag) => {
      return !assignedBlogTags.some((assignedBlogTag) => {
        return selectedBlogTag.value === assignedBlogTag.value;
      });
    });

    console.log("newBlogTags", newBlogTags);

    // Delete BlogTag for Blog post
    // let deleteResponse = await onDeleteBlogPostTags(formData.id);
    // console.log("onDeleteBlogPostTags- Response ", deleteResponse);
    //TODO : get max ordering
      let ordering = 1;
      let blogPostTags = [];
      // selectedBlogTags.forEach((selectedBlogTag) => {
      //   let selectedBlogTagData = {
      //     postId: formData.id,
      //     blogTagId: selectedBlogTag.value,
      //     active: "Y",
      //     ordering: ordering,
      //   };
      //   blogPostTags.push(selectedBlogTagData);
      // });
      newBlogTags.forEach((newBlogTag) => {
        let newBlogTagData = {
          postId: postId,//formData.id,
          blogTagId: newBlogTag.value,
          active: "Y",
          ordering: ordering,
        };
        blogPostTags.push(newBlogTagData);
      });
      console.log("blogPostTags", blogPostTags);
      let data1 = {};

      data1 = {
        url: "blogPostsTags",
        query: "/bulk",
        method: "POST",
        body: blogPostTags,
      };

      console.log("API data1", data1);
      api.call(
        data1,
        (res) => {
          console.log("createBlogPostTag -Response:", res);
          
          console.log("createBlogPostTag -selectedBlogTags :", selectedBlogTags);
          loadData(postId);

          // common.notify("S", "Saved Successfully");
          // setIsLoading(false);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );    
  };

  // const [blogTags, setBlogTags] = useState([]);
  // const [blogTag, setBlogTag] = useState(null);

  /** Handle for create blogTag select */
  const handleCreateBlogTags = (inputValue) => {
    console.log("handleCreateBlogTags");
    const sanitizedValue = inputValue.replace(/[^\w\s]/gi, ""); // Regex to remove special characters
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(sanitizedValue); //createOption(inputValue);
      createBlogTag(newOption.label);
      setIsLoading(false);
      // setBlogTags((prev) => [...prev, newOption]);
      // setBlogTag(newOption);
      // console.log("handleCreateBlogTags-blogTags", blogTags);
    }, 1000);
  };



  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  // handler
  const handleRteOnChange = (event, editor) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        fullText: editor.getData(),
      };
    });
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleTileSlugChange = (val) => {
    const { name, value } = val.target;
    let slugValue = _.kebabCase(value);
    setFormData((prevState) => {
      return {
        ...prevState,
        ["title"]: value,
        ["slug"]: slugValue,
      };
    });
  };

  const handleShortTextChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ["metaDesc"]: value,
      };
    });
  };

  // New Image crop -START

  const [uploadFile, setUploadFile] = useState("");
  const [toggleCropModal, setToggleCropModal] = useState(false);
  const [imageRatio, setImageRatio] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  const profileImageInput = useRef(null);

  const [settingData, setSettingData] = useState({
    id: 0,
    settingsContext: "",
    settingsKey: "",
    value: "",
    settingsType: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const loadSettings = (settingsContext, settingsKey) => {
    // setIsLoading(true);
    const data1 = {
      url: "settings",
      method: "GET",
      query: `/search/advance?q={"settingsContext":{"$eq": "${settingsContext}"},"settingsKey":{"$eq": "${settingsKey}"}}&offset=0&limit=50`,
    };

    console.log("loadSettings:API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("loadSettings:res.data", res.data.items);
        let currentData = res.data.items[0];
        setSettingData(currentData);
        console.log("settingData", settingData);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const imageUploadHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadFile(URL.createObjectURL(file));
      setToggleCropModal(true);
      //Get image aspect Ration from setting api
      console.log("settingData.value", settingData.value);
      let splitter = new RegExp("x", "i");
      const [width, height] = settingData?.value?.split(splitter);
      console.log("width, height", width, height);
      setImageRatio(width / height);
    }
  };

  const getCroppedFile = (croppedImage) => {
    setCroppedImage(croppedImage);
    setToggleCropModal(false);
    formData.coverImagePath = URL.createObjectURL(croppedImage);
    setCroppedImagePreview(URL.createObjectURL(croppedImage));
    console.log("formData.coverImagePath", formData.coverImagePath);
  };

  const uploadImgDelete = () => {
    profileImageInput.current.value = null;
  };

  // New Image crop -END

  const handleSubmit = (e, sourceLabel) => {
    console.log("handleSubmit-selectedBlogTags:", selectedBlogTags);

    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id);
    if (formData.id > 0)
      data1 = {
        url: "blogPosts",
        query: "/" + formData.id,
        body: {
          title: formData.title,
          slug: formData.slug,
          shortText: formData.shortText,
          fullText: formData.fullText,
          featured: formData.featured,
          categoryId: formData.categoryId,
          // coverImagePath: formData.coverImagePath,
          notes: formData.notes,
          effStartDate: formData.effStartDate,
          effEndDate: formData.effEndDate,
          views: formData.views,
          metaDesc: formData.metaDesc,
          metaKeywords: formData.metaKeywords,
          active: formData.active,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "blogPosts",
        method: "POST",
        body: {
          title: formData.title,
          slug: formData.slug,
          shortText: formData.shortText,
          fullText: formData.fullText,
          featured: formData.featured,
          categoryId: formData.categoryId,
          // coverImagePath: formData.coverImagePath,
          notes: formData.notes,
          effStartDate: formData.effStartDate,
          effEndDate: formData.effEndDate,
          views: formData.views,
          metaDesc: formData.metaDesc,
          metaKeywords: formData.metaKeywords,
          active: formData.active,
        },
      };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) {
          console.log("currentData.id", currentData.id);
          console.log("selectedBlogTags:", selectedBlogTags);

          console.log("selectedBlogTags", selectedBlogTags);

          createBlogPostTag(currentData.id);

          loadData(currentData.id);
          // Only image uploaded
          if (croppedImage) uploadCoverImage(currentData.id);
        }
      },
      (error) => {
        if (error && error.status && error.status === 500)
          common.notify("E", "Api Error " + error.data.msg);
        else common.notify("E", "Api Error " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
    // if (croppedImage)
    //   // Only image uploaded
    //   uploadCoverImage();
    setIsLoading(false);
    if (sourceLabel === "Save") navigate("/blogpostslist");
  };

  const uploadCoverImage = (postId) => {
    console.log("uploadCoverImage", croppedImage);
    // const croppedImage = getCroppedImg();
    // console.log("payload.croppedImage", croppedImage)
    setIsLoading(true);
    let payLoad = new FormData();
    payLoad.append("image", croppedImage, "imageUpload.png");
    console.log("payLoad", payLoad.get("image"));

    let data1 = {
      url: "blogPosts",
      query: "/uploadImage/" +postId,// formData.id,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) loadData(currentData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    setIsLoading(false);
  };

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "blogPosts",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        
        currentData.effStartDate = moment(res.data.item.effStartDate).utc(false).format("YYYY-MM-DD HH:mm");
        currentData.effEndDate = res.data.item.effEndDate
          ? moment(res.data.item.effEndDate).utc(false).format("YYYY-MM-DD HH:mm")
          : null;

        setSelectedBlogCatValue({
          name: currentData.blogCategory.name,
          id: currentData.categoryId,
        });
        console.log("selectedBlogCatValue", selectedBlogCatValue);
        let blogTags = currentData.blogTags;

        let selectedBlogTags = blogTags.map((blogTag) => {
          return createOption(blogTag.name, blogTag.id);
        });

        // if(blogTags){
        //   blogTags.map(blogTag => {
        //     // {label:blogTag.name,value:blogTag.id}
        //   } )
        // }
        // setSelectedBlogTags(currentData.blogTags)
        console.log("loadData-setSelectedBlogTags", selectedBlogTags);
        setSelectedBlogTags(selectedBlogTags);
        setAssignedBlogTags(selectedBlogTags);
        console.log("loadData-setSelectedBlogTags- state", selectedBlogTags);

        setFormData(currentData);
        console.log("FormData-coverImagePath", formData.coverImagePath);
        setCroppedImagePreview(currentData.coverImagePath);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Blog Post
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12} xl={4}>
                          <MDInput
                            type="text"
                            label="Title"
                            name="title"
                            value={formData.title}
                            onChange={handleTileSlugChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} xl={4}>
                          <MDInput
                            disabled={isDisabled}
                            type="text"
                            label="Slug"
                            name="slug"
                            value={formData.slug}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12} xl={8}>
                          <MDInput
                            type="text"
                            label="ShortText"
                            multiline
                            rows={4}
                            name="shortText"
                            value={formData.shortText}
                            onChange={handleShortTextChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={12} xl={8}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={formData.fullText}
                            config={editorConfiguration}
                            onReady={(editor) => {
                              editor.ui.view.editable.element.style.height = "500px";
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              handleRteOnChange(event, editor);
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="featured"
                                checked={formData.featured === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Featured"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active === "Y" ? true : false}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        {/* <Grid item xs={4}>
                          <CreatableSelect
                          isClearable
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          onChange={(newValue) => setValue(newValue)}
                          onCreateOption={handleCreate}
                          options={options}
                          value={value}
                        />
                        </Grid> */}
                      </Grid>

                      <Grid container spacing={1} mt={2} mb={5}>
                        {/* <Grid item xs={3}>
                          <MDInput type="text" label="CategoryId" name="categoryId" value={formData.categoryId} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid> */}
                        <Grid item xs={5}>
                          <div className="selectWrapper">
                            <label>Blog Categories*</label>
                            <AsyncSelect
                              label="Blog Categories"
                              // styles={customSelectStyles}
                              classNamePrefix="cus-select"
                              placeholder="Type atleast 2 chars to search BlogCategories"
                              cacheOptions
                              fullWidth
                              value={selectedBlogCatValue}
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.id}
                              loadOptions={loadBlogCategoriesOptions}
                              // onInputChange={(value) => setBlogCategoryQuery(value)}
                              onChange={onBlogCategoryChange}
                              defaultOptions
                              sx={{ width: "18%", mr: 1, mt: 2 }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <div className="selectWrapper">
                            <label>Blog Tags*</label>
                            <AsyncCreatableSelect
                              // isClearable
                              defaultOptions
                              isClearable
                              isMulti
                              classNamePrefix="cus-select"
                              placeholder="Create or Type atleast 2 chars to search BlogTags"
                              // cacheOptions
                              // isDisabled={isLoading}
                              // isLoading={isLoading}
                              value={selectedBlogTags}
                              onChange={onBlogTagChange}
                              onCreateOption={handleCreateBlogTags}
                              // getOptionLabel={(e) => e.label}
                              // getOptionValue={(e) => e.value}
                              // onInputChange={(value) => setBlogTagQuery(value)}
                              // options={blogTags}
                              loadOptions={loadBlogTagsOptions}
                              // value={blogTag}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="notes"
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <MDInput
                            type="number"
                            label="views"
                            name="views"
                            value={formData.views}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                          <Grid item xs={3}>
                            <MDInput
                              type="datetime-local"
                              name="effStartDate"
                              label="Publish Start Date"
                              InputLabelProps={{ shrink: true }}
                              value={formData.effStartDate}
                              onChange={handleChange}
                              required={true}
                            />
                            {/* <DateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              label="Publish Start Date"
                              value={formData.effStartDate}
                              onChange={(newValue) => {
                                // setValue(newValue);
                                formData.effStartDate=newValue;
                              }}
                            /> */}
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="datetime-local"
                              name="effEndDate"
                              label="Publish End Date"
                              InputLabelProps={{ shrink: true }}
                              value={formData.effEndDate}
                              onChange={handleChange}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        {/* <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="CoverImagePath"
                            name="coverImagePath"
                            value={formData.coverImagePath}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid> */}
                        <Grid item xs={6}>
                          {/* <StyledBadge
                            overlap="rectangular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                size="xl"
                                // onClick={handleUploadImagePopup}
                                onChange={handleUploadImagePopup}
                              >
                                <input hidden accept="image/*" type="file" />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Image
                              src={formData.coverImagePath}
                              variant="square"
                              // size="xxl"
                              shadow="sm"

                              // onClick={handleUploadImagePopup}
                            ></Image>
                          </StyledBadge>

                          <Dialog open={uploadImagePopupOpen} onClose={handleUploadImagePopupClose}>
                            <DialogTitle>Upload BlogPost</DialogTitle>
                            <DialogContent>
                              <DialogContentText>Only PNG/JPG Allowed</DialogContentText>
                              <Grid container spacing={1} mt={2}>
                                <Grid item xs={12}>
                                
                                   {!!imgSrc && (
                                    <ReactCrop
                                      crop={crop}
                                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                                      onComplete={(c) => setCompletedCrop(c)}
                                      aspect={aspect}
                                      minWidth={1080}
                                      minHeight={608}
                                      maxWidth={1080}
                                      maxHeight={608}
                                      locked={true}
                                    >
                                      <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{
                                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                                        }}
                                        onLoad={onImageLoad}
                                      />
                                    </ReactCrop>
                                  )}
                                  {!!completedCrop && (
                                    
                                      <div>
                                        <canvas 
                                          hidden
                                          ref={previewCanvasRef}
                                          style={{
                                            border: "1px solid black",
                                            objectFit: "contain",
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                          }}
                                        />
                                      </div>
                                    
                                  )}
                                  <MDBox width={300}>
                                    <Slider
                                      size="small"
                                      defaultValue={100}
                                      onChange={(e) => setScale(Number(e.target.value) / 100)}
                                      aria-label="Small"
                                      valueLabelDisplay="auto"
                                      min={50}
                                      max={200}
                                    />
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleUploadImagePopupClose}>Cancel</MDButton>
                              <MDButton onClick={handleUploadImage}>Ok</MDButton>
                            </DialogActions>
                          </Dialog> */}
                          <StyledBadge
                            overlap="rectangular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                size="xl"
                                onChange={imageUploadHandle}
                              >
                                <input
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  id="profileImage"
                                  ref={profileImageInput}
                                />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Image
                              src={croppedImagePreview} //{formData.image}
                              variant="square"
                              size="xxl"
                              shadow="sm"
                            ></Image>
                          </StyledBadge>

                          {toggleCropModal && (
                            <CropModal>
                              <ImageCropper
                                uploadFile={uploadFile}
                                getCroppedFile={getCroppedFile}
                                imageRatio={imageRatio}
                                cropShape="rect"
                                uploadFileType="image"
                                closeCropPopup={() => {
                                  setToggleCropModal(false);
                                  if (croppedImage)
                                    formData.coverImagePath = URL.createObjectURL(croppedImage);
                                }}
                                uploadImgDelete={uploadImgDelete}
                              />
                            </CropModal>
                          )}
                        </Grid>
                      </Grid>

                      <MDTypography variant="h5" mt={2}>
                        Meta Details
                      </MDTypography>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3} lg={4}>
                          <MDInput
                            type="text"
                            label="Meta Keywords"
                            multiline
                            rows={2}
                            name="metaKeywords"
                            value={formData.metaKeywords}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={7} lg={4}>
                          <MDInput
                            type="text"
                            disabled={isDisabled}
                            label="Meta Description"
                            multiline
                            rows={2}
                            name="metaDesc"
                            value={formData.metaDesc}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/blogpostslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlogPost;
