/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "react-select";

// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function ContentReportList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const [contentReportStatusListData, setContentReportStatusListData] = useState([]);

  const [formData, setFormData] = useState({
    id: 0,
    contentId: "", 
    userId: "Y",     
    reason: "",   
    status: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,    
  });

  const loadContentReportStatusList = () => {
      setIsLoading(true);
      const data1 = {
        url: "lookupValuesAdvanceSearch",
        method: "GET",
        query: `?q={"lookupType":{"$eq": "ZR_ENQUIRY_REPORT_STATUS"}}&orderBy=[["id", "DESC"]]`,
      };
      api.call(
        data1,
        (res) => {
          console.log("loadContentReportStatusList", res.data.items);
          setContentReportStatusListData(res.data.items);
          setIsLoading(false);
        },
        (error) => {
          common.notify("E", "Api Failed " + error.statusText);
          setIsLoading(false);
        },
        (final) => {}
      );
    };

  useEffect(() => {
    loadContentReportStatusList();
  }, []);

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole('Admin');

    console.log("filters",filters," sorts:",sorts)
    let url =
      `${config.api.url}contentReports/search/advance?totalResults=true` +
      "&offset=" +
      skip +
      "&limit=" +
      limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;

    console.log("url",url)
    return axios.get(url).then(res => {
      console.log("Content Reports  api response",res.data.items)
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }

  const [changeCounter, setChangeCounter]= useState(0);

  const dataSource = useCallback(loadData, [changeCounter]);


  const handleContentReportStatusChange = (val, name, id) => {
    console.log("ContentReportStatus value", name, val.lookupCode, id);

    let bodyData = { [name]: val.lookupCode };
    console.log("bodyData", bodyData);
    let wsData = {
      url: "contentReports",
      query: "/" + id,
      body: bodyData,
      method: "PATCH",
    };
    api.call(
      wsData,
      (res) => {
        console.log(res);
        common.notify("S", "Saved Successfully");
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
      },
      (final) => {}
    );
  };
  
  // const dataSource = useCallback(loadData, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Content Report
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin && (
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/contentreport"
                  >
                    New
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    {
                      header: "Action",
                      name: "id",
                      defaultWidth: 100,
                      headerAlign: "center",
                      textAlign: "center",
                      render: ({ value }) => (
                        <MDTypography
                          component={Link}
                          to={"/contentreport/" + value}
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Edit
                        </MDTypography>
                      ),
                    },

                    {
                      header: "Content",
                      name: "contentId",
                      defaultFlex: 1,
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <MDTypography display="block" variant="button" fontWeight="medium">
                          {data.content?.titleEn}
                        </MDTypography>
                      ),
                    },
                    {
                      header: "User",
                      name: "userId",
                      defaultFlex: 1,
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <MDTypography display="block" variant="button" fontWeight="medium">
                          {data.user?.userProfiles?.firstName}
                        </MDTypography>
                      ),
                    },
                    {
                      header: "Reason",
                      name: "reason",
                      defaultFlex: 1,
                      textAlign: "start",
                    },
                    {
                      header: "Status",
                      name: "status",
                      defaultFlex: 1,
                      textAlign: "end",
                      render: ({ data, value }) => (
                        <div>
                          <Select
                            id="status"
                            label="Status"
                            name="status"
                            classNamePrefix="cus-select"
                            menuPortalTarget={document.querySelector("body")} // added for alignment of items
                            onChange={(e) => handleContentReportStatusChange(e, "status", data.id)}
                            value={contentReportStatusListData.find(
                              (i) => i.lookupCode == data.status //compare string with number value
                            )}
                            options={contentReportStatusListData}
                            getOptionLabel={(e) => e.lookupCode}
                            getOptionValue={(e) => e.lookupCode}
                            placeholder="Select Status"
                            fullWidth
                            // menuPosition="fixed"
                          />
                        </div>
                      ),
                    },
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    // { name: 'id', operator: 'eq', type: 'number', value: '' },
                    { name: "contentId", operator: "startsWith", type: "string", value: "" },
                    { name: "userId", operator: "startsWith", type: "string", value: "" },
                    { name: "reason", operator: "startsWith", type: "string", value: "" },
                    // { name: "status", operator: "startsWith", type: "string", value: "" },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ContentReportList;
