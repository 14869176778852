/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import qs from 'qs';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { api } from "helpers/common/api";
import { common } from "helpers/common/common";
import { countryData } from "helpers/common/country";
import Select from "react-select";
import MenuItem from '@mui/material/MenuItem';

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [verifyMode, setVerifyMode] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileNo, setMobileNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countryDetails, setCountryDetails] = useState(countryData);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onCountryCodeChange = (e) => 
  {
    console.log("et",e)
    setCountryCode(e.target.value);
  }

  const onMobileNoChange = (e) => setMobileNo(e.target.value);

  const onEmailIdChange = (e) => setEmailId(e.target.value);

  const onOtpChange = (e) => setOtp(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (verifyMode) {
      const data = {
        url: "loginOtp",
        method: "POST",
        auth: "none",
        body: {
          "countryCode": countryCode,
          "mobileNo": mobileNo,
          "email": emailId,
          "otp": otp
        },
      };

      api.call(
        data,
        (res) => {
          console.log(JSON.stringify(res));
          if (res.status !== 200) {
            common.notify("E", "Unauthorized");
            common.localRemove("zrAdminToken");
            common.localRemove("zrAdminRefreshToken");
            common.localRemove("zrAdminUserProfile");
            setIsLoading(false);
          } else {
            if (res.data.status === 1) {

              setVerifyMode(false)
              common.localSet("zrAdminToken", res.data.data.token);
              common.localSet("zrAdminRefreshToken", res.data.data.refreshToken);
              common.localSet("zrAdminUserProfile", common.parseJwt(res.data.data.token));
              const isAdmin = common.userInRole('Admin');
              if (isAdmin) {
                common.notify("S", "Login Successful");
                setIsLoading(false);
                common.redirect("/dashboard");
              }else{
                common.notify("E", "Need to be admin user to login");
                setIsLoading(false);
                // common.redirect("/auth/signin");
              }
              
            }
            else {
              setVerifyMode(false)
              setIsLoading(false);
              common.notify("E", "Login Failed. Please contact system administrator");
            }
          }
        },
        (error) => {
          common.localRemove("zrAdminToken");
          common.localRemove("zrAdminRefreshToken");
          common.localRemove("zrAdminUserProfile");
          common.notify("E", "Login failed " + error.statusText);
          setIsLoading(false);
        },
        (final) => { }
      );
    }
    else {
      const data = {
        url: "login",
        method: "POST",
        auth: "none",
        body: {
          "countryCode": countryCode,
          "mobileNo": mobileNo
        }
      };

      api.call(
        data,
        (res) => {
          if (res.status !== 200) {
            common.notify("E", "Sending OTP Failed");
            common.localRemove("zrAdminToken");
            common.localRemove("zrAdminRefreshToken");
            common.localRemove("zrAdminUserProfile");
            setVerifyMode(false);
            setIsLoading(false);
          } else {
            if (res.data.status === 1) {
              setVerifyMode(true);
              common.notify("S", "Sending OTP Successful");
              setIsLoading(false);
            }
            else {
              setVerifyMode(false)
              setIsLoading(false);
              common.notify("E", "Sending OTP Failed. Please contact system administrator");
            }
          }
        },
        (error) => {
          common.localRemove("zrAdminToken");
          common.localRemove("zrAdminRefreshToken");
          common.localRemove("zrAdminUserProfile");
          common.notify("E", "Login failed " + error.statusText);
          setIsLoading(false);
        },
        (final) => { }
      );
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={3} mb={3}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                id="countryCode"
                type="search"
                SelectProps={{
                  native: true,
                }}
                select
                fullWidth
                name="countryCode"
                label="Country Code"
                value={countryCode}
                onChange={(e) => onCountryCodeChange(e)}
                disabled={verifyMode}
                InputLabelProps={{ shrink: true }}
              >
                {countryDetails.map((item, index) => {
                  return (
                    <option key={index} value={item.dial_code}>
                    {item.name} - {item.dial_code}
                  </option>
                  );
                })}
              </MDInput>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Mobile No" fullWidth value={mobileNo} onChange={(e) => onMobileNoChange(e)} disabled={verifyMode} />
            </MDBox>
            {verifyMode===3 &&
            <MDBox mb={2}>
              <MDInput type="text" label="Email ID" fullWidth value={emailId} onChange={(e) => onEmailIdChange(e)} disabled={true} />
            </MDBox>
}
            {verifyMode &&
              <MDBox mb={2}>
                <MDInput type="password" label="OTP" fullWidth value={otp} onChange={(e) => onOtpChange(e)} />
              </MDBox>
            }
            
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                {!isLoading ? "Login" : "Processing..."}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
