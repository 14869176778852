import { Link, useParams ,useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from "react-select";

// @mui material components
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMaterialUIController } from "context";


function LookupType() {

  const { id } = useParams();
  const gridStyle = { minHeight: 500 };
  //State Management
  const [open, setOpen] = useState(false);

  const [formError, setFormError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDtlLoading, setIsDtlLoading] = useState(false);
  const [changeCounter, setChangeCounter]= useState(0);
  const [dtlTableData, setDtlTableData] = useState([]);
  const [languageListData, setLanguageListData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [sortInfo, setSortInfo] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    id: 0,
    // ApplicationId: 0,
    lookupType: null,
    customizationLevel: null,
    assignLeafOnly: null,
    formatType: null,
    imageAllowed: null,
    maxSize: null,
    numbersOnly: null,
    precision: null,
    uppercaseOnly: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    // SecurityGroupId: 0,
    // ViewApplicationId: 0,
    // AssignLeafOnly: null,
    // FormatType: null,
    // ImageAllowed: null,
    // MaxSize: 0,
    // NumbersOnly: null,
    // Precision: 0,
    // UppercaseOnly: null,

  });

  const [formDtlData, setFormDtlData] = useState({
    id: 0,
    lookupTypeId: null,
    lookupType: null,
    language: "US",
    lookupCode: null,
    meaning: null,
    description: null,
    enabledFlag: "Y",
    effStartDate: moment().format('YYYY-MM-DD'),
    effEndDate: null,
    sourceLang: null,
    tag: null,
    leafNode: null,
    dependentLookupType: null,
    dependentOn: null,
    cacheEnabled: null,
    ordering: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    // LookupTypeId: 0,
    // LookupType: null,
    // LookupCode: null,
    // Meaning: null,
    // Description: null,
    // EnabledFlag: null,
    // Language: "US",
    // StartDateActive: null,
    // EndDateActive: null,
    // CreatedBy: null,
    // CreationDate: null,
    // LastUpdatedBy: null,
    // LastUpdateDate: null,
    // LastUpdateLogin: null,
    // VersionNumber: null,
    // SourceLang: null,
    // SecurityGroupId: 0,
    // ViewApplicationId: 0,
    // TerritoryCode: null,
    // Tag: null,
    // LeafNode: null,
    // DependentLookup: null,
    // DependentOn: null,
    // CacheEnabled: null
  });

  const [selected, setSelected] = useState({});
  const [selectedDtlId, setSelectedDtlId] = useState(0);


  
  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "lookupTypes",
      method: "GET",
      query: "/" + pId ,//+ "?onlyData=true",
    };

    api.call(
      data1,
      (res) => {
        let currentData = res.data.item;
        console.log("Get lookupTypes currentData",currentData)
        setFormData(currentData);
        setIsLoading(false);
        loadDtls(pId);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  };

  const loadLanguagesList = () => {
    setIsLoading(true);
    const data1 = {
      url: "languages",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setLanguageListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }


  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const handleClickOpen = () => {
    console.log("handleClickOpen",formDtlData.effStartDate)
    let dtlInfo = {
      id: 0,
      lookupTypeId: 0,
      lookupType: null,
      language: "en-US", 
      lookupCode: null,
      meaning: null,
      description: null,
      enabledFlag: "Y",
      effStartDate: moment().format('YYYY-MM-DD'),
      effEndDate: null,      
      sourceLang: "en-US",     
      tag: null,
      leafNode: null,
      dependentLookupType: null,
      dependentOn: null,
      cacheEnabled: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    }
    console.log("handleClickOpen- dtlInfo",dtlInfo.effStartDate)

    setFormDtlData(dtlInfo);
    setOpen(true);
  };

  const handleGridSwitchChange = (event,id) => {
    console.log("checked ", event.target.checked);
    console.log("checked ", id);

    const { name, checked } = event.target;
    console.log("event.target ", event.target);
    if (id> 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        "enabledFlag": checked ? "Y" : null
      }
      wsData = {
        url: "lookupValues",
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          setChangeCounter(changeCounter+1);
          loadData(formData.id);        
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => { }
      );
    }

  };

   const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    resetDtlData();
    setSelectedDtlId(0);
  };

  const onLookupValuesEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...dtlTableData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log("rowId", rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);

      if(columnId === "lookupCode" || columnId === "effStartDate"){
        common.notify("E", `Can't update ${columnId}`);
        return;
      }
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setDtlTableData(data);

      // if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          // ordering: data[index]["ordering"],
          [columnId] : data[index][columnId]
        }
        console.log("LookupValues edit bodyData",bodyData)
        wsData = {
          url: "lookupValues",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadDtls(res.data.item.lookupTypeId)
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      // }
    },
    [dtlTableData]
  );

  const onDeleteDtl = () => {
    if (selected > 0) {
      const data1 = {
        url: "lookupValues",
        query: "/" + selected,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadDtls(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => { }
      );
    }
  };

  const dtlColumns = [
    { name: "lookupCode", header: "Code", defaultFlex: 1 },
    { name: "meaning", header: "Meaning", defaultFlex: 1 },
    { name: "description", header: "Description", defaultFlex: 1.2 },
    { name: "ordering", header: "Ordering", defaultFlex: 1 },
    { name: "enabledFlag", header: "Enabled",  defaultFlex:1, textAlign: "center",
    render: ({ data, value }) => (
      <FormControlLabel
        control={
          <Switch
            name="enabledFlag"
            checked={(data.enabledFlag === "Y") ? true : false}
            onChange={(e)=> handleGridSwitchChange(e,data.id)}
            inputProps={{
              "aria-label": "controlled",
              align: "center",
            }}
          />
        }
        label=""
      />
    ), } , 
    { name: "effStartDate", header: "StartDateActive", defaultFlex: 1.2},
    { name: "effEndDate", header: "EndDateActive", defaultFlex: 1.1},
    {
      name: "language", header: "Language", defaultFlex: 0.7
    },
   
  ];

  useEffect(() => {
    if (id) loadData(id);
    loadCustomizationLevelList();
    // loadLanguagesList();
  }, []);

  const [customizationLevelListData, setCustomizationLevelListDataData] = useState([]);

  const loadCustomizationLevelList = () => {
    const customizationLevels = [
      { label: "Seeded", value: "0" },
      { label: "Extended", value: "1" },
      { label: "User", value: "2" },
    ];
    setCustomizationLevelListDataData(customizationLevels);
  }

  const handleCustomizationLevelChange = (val) => {
    const name = 'customizationLevel'

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.value,
      };
    });
  }
  
  const handleChange = (val) => {
    const { name, value } = val.target;

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleDtlChange = (val) => {
    const { name, value } = val.target;
    console.log("handleDtlChange ",name,value)
    setFormDtlData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    
  };

  const handleLanguageChange = (val) => {
    const name = 'language'
    console.log("handleLanguageChange ",val)
    setFormDtlData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  }

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    console.log("handleDtlChange ",name,checked)
    setFormDtlData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

 
  const handleAddDtl = (e) => {
    console.log('formDtlData',formDtlData)
    e.preventDefault();
    setIsDtlLoading(true);
    let data = {};
    if (selectedDtlId > 0) {
      data = {
        url: "lookupValues",
        query: "/" + selectedDtlId,
        body: {
          // lookupTypeId: formData.id,
          lookupType: formData.lookupType,
          language: formDtlData.language,
          lookupCode: formDtlData.lookupCode,
          meaning: formDtlData.meaning,
          description: formDtlData.description,
          enabledFlag: formDtlData.enabledFlag,
          ordering: formDtlData.ordering,
          effStartDate: formDtlData.effStartDate,
          effEndDate: formDtlData.effEndDate,
        },
        method: "PATCH",
      };
    } else {
      data = {
        url: "lookupValues",
        method: "POST",
        body: {
          // id: 0,
          lookupTypeId: formData.id,
          lookupType: formData.lookupType,
          language: formDtlData.language,
          lookupCode: formDtlData.lookupCode,
          meaning: formDtlData.meaning,
          description: formDtlData.description,
          enabledFlag: formDtlData.enabledFlag,
          ordering: formDtlData.ordering,
          effStartDate: formDtlData.effStartDate,
          effEndDate: formDtlData.effEndDate,
          // createdBy: 0,
          // creationDate: moment().format("YYYY-MM-DD"),
          // lastUpdatedBy: null,
          // lastUpdateDate: null,
          // lastUpdateLogin: null,
          // versionNumber: null
        },
      };
    }
    console.log("data Body",data)
    api.call(
      data,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setOpen(false);
        setIsDtlLoading(false);
        loadDtls(formData.id);
        resetDtlData();
        setSelectedDtlId(0);
        setIsEdit(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsDtlLoading(false);
      },
      (final) => { }
    );
  };

  const resetDtlData = () => {
    let dtlInfo = {
      id: 0,
      lookupTypeId: 0,
      lookupType: "",
      language: "en-US",
      lookupCode: "",
      meaning: "",
      description: "",
      enabledFlag: "Y",
      StartDateActive: null,
      effEndDate: null,      
      sourceLang: null,
      // SecurityGroupId: 0,
      // ViewApplicationId: 0,
      // TerritoryCode: null,
      // AttributeCategory: null,
      tag: null,
      leafNode: null,
      dependentLookupType: null,
      dependentOn: null,
      cacheEnabled: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    }
    setFormDtlData(dtlInfo);
  }

  const handleSubmit = (e,sourceLabel) => {
    console.log("Event Source:",sourceLabel)
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id",formData.id)
    if (formData.id > 0) {
      data1 = {
        url: "lookupTypes",
        query: "/" + formData.id,
        body: {
          // ApplicationId: formData.ApplicationId,
          lookupType: formData.lookupType,
          customizationLevel: formData.customizationLevel,
        },
        method: "PATCH",
      };
    } else
      data1 = {
        url: "lookupTypes",
        method: "POST",
        body: {
          // Id: 0,
          // ApplicationId: formData.ApplicationId,
          lookupType: formData.lookupType,
          customizationLevel: formData.customizationLevel,
        }
      };

    api.call(
      data1,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setFormData(currentData);
        setIsLoading(false);
        loadDtls(currentData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
        navigate('/lookupTypes');
       }
    );
  };

  const loadDtls = (pId) => {
    let skip = 0;
    let limit = 50;

    console.log("pId:",pId)
    setIsDtlLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      // query: `?q=LookupTypeId=${pId}&offset=0&limit=50&orderBy=CreationDate&onlyData=true`,
      query: `?q={"lookupTypeId":{"$eq": ${pId}}}&orderBy=[["ordering", "ASC"]] &offset=${skip}&limit=${limit}`,
    };
    api.call(
      data1,
      (res) => {
        res.data.items.map((item) => {
          console.log("item.effStartDate -", item.effStartDate);

          item.effStartDate = common.getDateFormatted(item.effStartDate);
          item.effEndDate = common.getDateFormatted(item.effEndDate);
          console.log("item.effStartDate", item.effStartDate);
        });
        console.log("res.data.items", res.data.items);
        setDtlTableData(res.data.items);
        console.log("DtlTableData", dtlTableData);
        setFormDtlData(res.data.items);
        setIsDtlLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsDtlLoading(false);
      },
      (final) => {}
    );
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Lookup Type
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={6}>
                        <MDInput
                          type="text"
                          required
                          label="Lookup Type"
                          name="lookupType"
                          onChange={handleChange}
                          value={formData.lookupType}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {/* <MDInput
                          type="text"
                          label="Customization Level"
                          name="customizationLevel"
                          onChange={handleChange}
                          value={formData.customizationLevel}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        /> */}
                        <div className="selectWrapper">
                          <label>CustomizationLevel *</label>
                          <Select
                            id="customizationLevel"
                            name="customizationLevel"
                            onChange={handleCustomizationLevelChange}
                            value={customizationLevelListData.find(
                              (i) => i.value === formData.customizationLevel
                            )}
                            options={customizationLevelListData}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            // className="custom-select-box basic-single"
                            placeholder="Select CustomizationLevel"
                            fullWidth
                            // menuPosition="fixed"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>

                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/lookupTypes"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
                      <DialogTitle>Lookup Values</DialogTitle>
                      <DialogContent>
                        <DialogContentText>Add or edit a new Lookup Value</DialogContentText>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={3}>
                            <MDInput
                              type="text"
                              required
                              label="Code"
                              name="lookupCode"
                              onChange={handleDtlChange}
                              value={formDtlData.lookupCode}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="text"
                              required
                              label="Meaning"
                              name="meaning"
                              onChange={handleDtlChange}
                              value={formDtlData.meaning}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={
                                <Switch
                                  name="enabledFlag"
                                  checked={formDtlData.enabledFlag === "Y" ? true : false}
                                  onChange={handleSwitchChange}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Enabled"
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <MDInput
                              type="number"
                              label="Ordering"
                              name="ordering"
                              onChange={handleDtlChange}
                              value={formDtlData.ordering}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              label="Description"
                              multiline
                              rows={4}
                              name="description"
                              value={formDtlData.description}
                              onChange={handleDtlChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={1}>
                          <Grid item xs={4}>
                            {/* <div className="custom-select">
                              <Select
                                id="language"
                                name="language"
                                onChange={handleLanguageChange}
                                value={languageListData.find((i) => i.id === formDtlData.language)}
                                options={languageListData}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.id}
                                className="custom-select-box basic-single"
                                placeholder="Select Language"
                                fullWidth
                                menuPosition="fixed"
                              />
                            </div> */}
                            <MDInput
                              type="text"
                              label="Language"
                              name="language"
                              onChange={handleDtlChange}
                              value={formDtlData.language}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid item xs={4}>
                              <MDInput
                                type="Date"
                                label="Start Date"
                                name="effStartDate"
                                onChange={handleDtlChange}
                                value={formDtlData.effStartDate}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <MDInput
                                type="Date"
                                label="End Date"
                                name="effEndDate"
                                onChange={handleDtlChange}
                                value={formDtlData.effEndDate}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                              />
                            </Grid>
                          </LocalizationProvider>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <MDButton onClick={handleClose}>Cancel</MDButton>
                        <MDButton onClick={handleAddDtl}>Ok</MDButton>
                      </DialogActions>
                    </Dialog>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-start", p: 0, mb: 1 }}>
                      {formData.id > 0 && (
                        <MDButton
                          variant="gradient"
                          color="info"
                          sx={{ mr: 1 }}
                          onClick={handleClickOpen}
                        >
                          Add
                        </MDButton>
                      )}
                      {selected && selected > 0 && (
                        <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteDtl}>
                          Delete
                        </MDButton>
                      )}
                    </MDBox>
                    <MDBox>
                      <ReactDataGrid
                        idProperty="id"
                        selected={selected}
                        style={gridStyle}
                        checkboxColumn
                        editable
                        onEditComplete={onLookupValuesEditComplete}
                        multiSelect={false}
                        onSelectionChange={onSelectionChange}
                        columns={dtlColumns}
                        pagination
                        defaultLimit={100}
                        onSortInfoChange={setSortInfo}
                        // rowHeight={60}
                        enableColumnAutosize
                        enableFiltering
                        defaultFilterValue={[
                          { name: "lookupCode", operator: "eq", type: "string", value: "" },
                          { name: "meaning", operator: "eq", type: "string", value: "" },
                        ]}
                        theme={darkMode ? "default-dark" : "default-light"}
                        loading={isDtlLoading}
                        dataSource={dtlTableData}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LookupType;
