import { Link, useParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import NativeSelect from "@mui/material/NativeSelect";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// import PdfViewerComponent from "components/PdfViewerComponent";
// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Link1 from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import FileUpload from "@mui/icons-material/FileUpload";

import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// @mui material components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useRef, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Slider from "@mui/material/Slider";
import moment from "moment";
import axios from "axios";
import { config } from "helpers/common/config";

import CropModal from "../../../../components/modal/cropModal";
import ImageCropper from "../../../../components/UI/imageCropper";
import DragAndDropFiles from "../../../../components/dragAndDropFiles";

// import { pdfjs, Document, Page } from "react-pdf";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import PDFWebViewer from "components/UI/pdfWebViewer";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    bottom: 3,
    border: "none",
  },
}));

const PrimaryImage = styled(Avatar)(({ theme }) => ({
  width: 375,
  height: 392,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const Thumbnail = styled(Avatar)(({ theme }) => ({
  width: 350,
  height: 164,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const Image = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const PromoThumbnail = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100,
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 }),
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 1 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 };

function Content() {
  const { id } = useParams();
  console.log(common.userInRole("Admin"));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole("Admin"));
  const [contentTypeList, setContentTypeList] = useState([]);

  const [contentRatingListData, setContentRatingListData] = useState([]);

  const [tabValue, setTabValue] = useState(0);
  const [fileInfo, setFileInfo] = useState({ file: "", type: "" });
  const [instance, setInstance] = useState();
  const [docLoaded, setDocLoaded] = useState(false);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const loadContentRatingList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_CONTENT_RATINGS"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadContentRatingList", res.data.items);
        setContentRatingListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleContentRatingChange = (val) => {
    const name = "contentRating";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  const [ageRatingListData, setAgeRatingListData] = useState([]);

  const loadAgeRatingList = () => {
    //TODO : duplicate code
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_AGE_RATINGS"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadAgeRatingList", res.data.items);
        setAgeRatingListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleAgeRatingChange = (val) => {
    console.log("handleAgeRatingChange", val);
    const name = "ageRating";

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  const [formData, setFormData] = useState({
    id: 0,
    title: "",
    titleEn: "",
    description: "",
    contentType: "",
    releaseDate: "",
    startDate: "",
    endDate: "",
    runningTimeSecs: "",
    ageRating: "",
    avgRating: "",
    noOfRatings: "",
    contentRating: "",
    website: "",
    views: "",
    hits: "",
    channelId: "",
    parentId: "",
    featured: "",
    yearProduced: new Date().getFullYear(),
    yearReleased: new Date().getFullYear(),
    countryOfProduction: "",
    promoColor: "",
    promoText: "",
    primaryImage: "",
    thumbnail: "",
    metaTitle: "",
    metaKeywords: "",
    metaDesc: "",
    authorizationFilePath: "",
    place: "",
    externalContentLink: "",
    signedBy: "",
    signedDate: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    genres: [],
    languages: [],
    formats: [],
    contentTypes: {},
  });

  // const [numPages, setNumPages] = useState(0);
  // const [pageNumber, setPageNumber] = useState(1);

  // const onDocumentLoadSuccess = (numPages) => {
  //   setNumPages(numPages);
  // };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Content - ContentType
  // const [contentTypeQuery, setContentTypeQuery] = useState({ label: formData.contentType?formData.contentTypes.name:"", value:formData.contentType?formData.contentTypes.id:"" });
  const [contentTypeQuery, setContentTypeQuery] = useState(null);

  const loadContentTypeOptions = async (inputValue) => {
    const res = await axios.get(
      `${config.api.url}contentTypes/search/advance?q={"name":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    console.log("res.data.items", res.data.items);
    return res.data.items;
  };

  const loadContentTypeList = () => {
    setIsLoading(true);
    const data1 = {
      url: "contentTypesAdvanceSearch",
      method: "GET",
      query: `?limit=50&offset=0&[['ordering','ASC']]`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setContentTypeList(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onContentTypeChange = (value) => {
    console.log("onContentTypeChange", value);
    console.log("contentTypeQuery", contentTypeQuery);
    if (value) {
      formData.contentType = value.id;
      console.log(" formData.contentType ", formData.contentType);
      setContentTypeQuery({ label: value.name, value: value.id });
    }
  };

  const handleAuthorizationFilePath = (event) => {
    console.log("handleAuthorizationFilePath :" + event.target.files[0].name);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      let authorizationFile = event.target.files[0];
      console.log("agreementFile", authorizationFile);
      formData.authorizationFilePath = authorizationFile;
      console.log("formData.agreementFileUrl", formData.authorizationFilePath);
      //TODO: Upload to cloud and get relative Path
    }
  };
  //Content - ContentType -end

  //Content - Country of productions
  const [productionCountryQuery, setProductionCountryQuery] = useState("");

  const loadProductionCountryOptions = async (inputValue) => {
    const res = await axios.get(
      `${config.api.url}regions/search/advance?q={"name":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    return res.data.items;
  };

  const onProductionCountryChange = (value) => {
    console.log("onProductionCountryChange", value);
    if (value) {
      formData.countryOfProduction = value.code;
      console.log(" formData.countryOfProduction ", formData.countryOfProduction);
    }
  };
  //Content - Country of productions -end

  //Image Upload
  const [uploadPrimaryImagePopupOpen, setUploadPrimaryImagePopupOpen] = useState(false);
  const [uploadThumbnailPopupOpen, setUploadThumbnailPopupOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  // const [croppedImage, setCroppedImage] = useState(undefined);
  // const [src, setSrc] = useState(null);
  const [scale, setScale] = useState(1);
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 375,
    height: 392,
  });
  const [image, setImage] = useState(null);
  // const [output, setOutput] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  // const [imageSize, setImageSize] = useState(null)
  // const [imageTitle, setImageTitle] = useState(null)
  // const [imageType, setImageType] = useState(null)

  const onUploadFile = (event) => {
    console.log("Files :" + event.target.files[0].name);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      let imageFile = event.target.files[0];
      console.log("imageFile.size", imageFile.size, " ", imageFile.name, " ", imageFile.type);
      setImageSize(imageFile.size);
      setImageTitle(imageFile.name);
      setImageType(imageFile.type);

      reader.addEventListener("load", () => setImageToCrop(reader.result));

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = imageRef?.naturalWidth / imageRef?.width;
    const scaleY = imageRef?.naturalHeight / imageRef?.height;

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    let dataURL = canvas.toDataURL("image/jpeg", 0.5);
    let blob = dataURItoBlob(dataURL); // for api upload

    formData.croppedImage = dataURL; // Base64 for formdata.
    console.log("blob:", blob);
    console.log("formData.croppedImage:", formData.croppedImage);
    return blob;
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  // Image upload -- start
  const [uploadFile, setUploadFile] = useState("");
  const [toggleCropModal, setToggleCropModal] = useState(false);
  const [imageRatio, setImageRatio] = useState("");
  const [imageSize, setImageSize] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);
  const [imageType, setImageType] = useState(null);

  // PrimaryImage

  const [croppedPrimaryImage, setCroppedPrimaryImage] = useState(null);
  const [croppedPrimaryImagePreview, setCroppedPrimaryImagePreview] = useState(null);
  //thumbnail
  const [croppedThumbnail, setCroppedThumbnail] = useState(null);
  const [croppedThumbnailPreview, setCroppedThumbnailPreview] = useState(null);
  //Image
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  //PromoThumbnail
  const [croppedPromoThumbnail, setCroppedPromoThumbnail] = useState(null);
  const [croppedPromoThumbnailPreview, setCroppedPromoThumbnailPreview] = useState(null);
  //PreviewThumbnail
  const [croppedPreviewThumbnail, setCroppedPreviewThumbnail] = useState(null);
  const [croppedPreviewThumbnailPreview, setCroppedPreviewThumbnailPreview] = useState(null);

  const profileImageInput = useRef(null);

  const [settingData, setSettingData] = useState({
    id: 0,
    settingsContext: "",
    settingsKey: "",
    value: "",
    settingsType: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const loadSettings = (settingsContext, settingsKey) => {
    // setIsLoading(true);
    const data1 = {
      url: "settings",
      method: "GET",
      query: `/search/advance?q={"settingsContext":{"$eq": "${settingsContext}"},"settingsKey":{"$eq": "${settingsKey}"}}&offset=0&limit=50`,
    };

    console.log("loadSettings:API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log("loadSettings:res.data", res.data.items);
        let currentData = res.data.items[0];
        setSettingData(currentData);
        console.log("settingData", settingData);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const imageUploadHandle = (e, imageName) => {
    const file = e.target.files[0];
    if (file) {
      console.log("imageFile.size", file.size, " ", file.name, " ", file.type);
      setImageSize(file.size);
      setImageTitle(file.name);
      setImageType(file.type);

      setUploadFile(URL.createObjectURL(file));
      setToggleCropModal(true);
      //Get image aspect Ration from setting api
      if (imageName === "primaryImage") {
        loadSettings("Content", "primaryImage");
      } else if (imageName === "thumbnail") {
        loadSettings("Content", "thumbnail");
      } //TODO get content image setting details

      console.log("settingData.value", settingData.value);
      let splitter = new RegExp("x", "i");
      const [width, height] = settingData?.value?.split(splitter);
      console.log("width, height", width, height);
      setImageRatio(width / height);
    }
  };

  // const getCroppedFile = (croppedImage) => {
  //   console.log("getCroppedFile -croppedImage",croppedImage)
  //   setCroppedImage(croppedImage);
  //   setToggleCropModal(false);
  //   // formData.thumbnailUrl = URL.createObjectURL(croppedImage);
  //   setCroppedImagePreview(URL.createObjectURL(croppedImage));
  //   console.log("formData.image",formData.thumbnailUrl);
  // };

  // PrimaryImage
  const getCroppedPrimaryImageFile = (croppedImage) => {
    console.log("getCroppedPrimaryImageFile -croppedImage", croppedImage);
    setCroppedPrimaryImage(croppedImage);
    setToggleCropModal(false);
    formData.primaryImage = URL.createObjectURL(croppedImage);
    setCroppedPrimaryImagePreview(URL.createObjectURL(croppedImage));
    console.log("formData.primaryImage", formData.primaryImage);
  };

  // thumbnail
  const getCroppedThumbnailFile = (croppedImage) => {
    console.log("getCroppedThumbnailFile -croppedImage", croppedImage);
    setCroppedThumbnail(croppedImage);
    setToggleCropModal(false);
    formData.thumbnail = URL.createObjectURL(croppedImage);
    setCroppedThumbnailPreview(URL.createObjectURL(croppedImage));
    console.log("formData.thumbnail", formData.thumbnail);
  };

  //Image
  const getCroppedImageFile = (croppedImage) => {
    console.log("getCroppedImageFile -croppedImage", croppedImage);
    setCroppedImage(croppedImage);
    setToggleCropModal(false);
    formImageData.imageUrl = URL.createObjectURL(croppedImage);
    setCroppedImagePreview(URL.createObjectURL(croppedImage));
    console.log("formData.image", formData.imageUrl);
  };
  //PromoThumbnail
  const getCroppedPromoThumbnailFile = (croppedImage) => {
    console.log("getCroppedPromoThumbnailFile -croppedImage", croppedImage);
    setCroppedPromoThumbnail(croppedImage);
    setToggleCropModal(false);
    formPromoData.thumbnail = URL.createObjectURL(croppedImage);
    setCroppedPromoThumbnailPreview(URL.createObjectURL(croppedImage));
    console.log("formPromoData.thumbnail", formPromoData.thumbnail);
  };
  //PreviewThumbnail
  const getCroppedPreviewThumbnailFile = (croppedImage) => {
    console.log("getCroppedPreviewThumbnailFile -croppedImage", croppedImage);
    setCroppedPreviewThumbnail(croppedImage);
    setToggleCropModal(false);
    formPreviewData.thumbnailUrl = URL.createObjectURL(croppedImage);
    setCroppedPreviewThumbnailPreview(URL.createObjectURL(croppedImage));
    console.log("formPreviewData.thumbnailUrl", formPreviewData.thumbnailUrl);
  };

  const uploadImgDelete = () => {
    profileImageInput.current.value = null;
  };

  // Image upload -- end
  // Image Upload - end

  //Genres
  const [genrePopupOpen, setGenrePopupOpen] = useState(false);
  const [genreSelected, setGenreSelected] = useState({});
  const [genreListData, setGenreListData] = useState([]);
  const [contentGenresData, setContentGenresData] = useState([]);
  const onGenreSelectionChange = useCallback(({ selected }) => {
    setGenreSelected(selected);
  }, []);

  const [formGenreData, setFormGenreData] = useState({
    id: 0,
    contentId: null,
    genreId: null,
    ordering: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    genre: {
      id: 0,
      name: null,
      description: null,
      ordering: null,
      bgColor: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onGenreEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentGenresData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentGenresData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentGenres",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentGenresData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentGenresData]
  );

  const loadGenresList = () => {
    setIsLoading(true);
    const data1 = {
      url: "genres",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setGenreListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentGenresData = (pId) => {
    setIsLoading(true);

    const data1 = {
      method: "GET",
      url: `contentGenres`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log("Get contentGenres for particular content::", res.data.items);
        setContentGenresData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteGenres = () => {
    if (genreSelected > 0) {
      const data1 = {
        url: "contentGenres",
        query: "/" + genreSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted genre", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentGenresData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleGenreFormChange = (val) => {
    const { name, value } = val.target;
    setFormGenreData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleGenreChange = (val) => {
    const name = "genreId";

    setFormGenreData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleGenrePopupClickOpen = () => {
    setFormGenreData({
      id: 0,
      contentId: null,
      genreId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setGenrePopupOpen(true);
  };

  const handleGenrePopupClose = () => {
    setGenrePopupOpen(false);
  };

  const handleAddGenre = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentGenres",
      method: "POST",
      body: {
        contentId: formData.id,
        genreId: formGenreData.genreId,
        ordering: formGenreData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setGenrePopupOpen(false);
        setIsLoading(false);
        loadContentGenresData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  //end genres

  //Crews
  const [crewPopupOpen, setCrewPopupOpen] = useState(false);
  const [multiCrewPopupOpen, setMultiCrewPopupOpen] = useState(false);

  const [crewSelected, setCrewSelected] = useState({});
  const [crewListData, setCrewListData] = useState([]);
  const [contentCrewsData, setContentCrewsData] = useState([]);
  const onCrewSelectionChange = useCallback(({ selected }) => {
    setCrewSelected(selected);
  }, []);

  const [formCrewData, setFormCrewData] = useState({
    id: 0,
    contentId: null,
    peopleId: null,
    asRole: null,
    description: null,
    ordering: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    people: {
      id: 0,
      name: null,
      primaryStill: null,
      description: null,
      ordering: null,
      active: "Y",
      image: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onCrewEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentCrewsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentCrewsData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentCrew",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentCrewsData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentCrewsData]
  );

  const loadCrewsList = () => {
    setIsLoading(true);
    const data1 = {
      url: "contentCrew",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setCrewListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed -loadCrewsList" + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentCrewsData = (pId) => {
    setIsLoading(true);

    const data1 = {
      method: "GET",
      url: `contentCrew`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    console.log("loadContentCrewsData url", data1.url);
    api.call(
      data1,
      (res) => {
        console.log("Get contentCrews for particular content::", res.data.items);
        setContentCrewsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed loadContentCrewsData" + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteCrews = () => {
    if (crewSelected > 0) {
      const data1 = {
        url: "contentCrew",
        query: "/" + crewSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted crew", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentCrewsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleCrewFormChange = (val) => {
    const { name, value } = val.target;
    setFormCrewData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleCrewChange = (val) => {
    const name = "crewId";

    setFormCrewData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleMultiCrewPopupClickOpen = () => {
    setFormCrewData({
      id: 0,
      contentId: null,
      peopleId: null,
      asRole: null,
      description: null,
      ordering: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setMultiCrewPopupOpen(true);
  };

  const handleMultiCrewPopupClose = () => {
    setMultiCrewPopupOpen(false);
  };

  const handleCrewPopupClickOpen = () => {
    setFormCrewData({
      id: 0,
      contentId: null,
      crewId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setCrewPopupOpen(true);
  };

  const handleCrewPopupClose = () => {
    setCrewPopupOpen(false);
  };

  const onCrewChange = (value) => {
    console.log("onCrewChange", value);
    if (value) {
      let localData = formCrewData;
      localData.crewId = value.id;
      localData.contentId = formData.id;
      setFormCrewData(localData);
    }
  };

  const handleAddCrew = (e) => {
    console.log("handleAddCrew");
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentCrew",
      method: "POST",
      body: {
        contentId: formData.id,
        peopleId: formCrewData.crewId,
        asRole: formCrewData.asRole,
        description: formCrewData.description,
        ordering: formCrewData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setCrewPopupOpen(false);
        setIsLoading(false);
        loadContentCrewsData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const [multiCrewData, setMultiCrewData] = useState([]);

  const onMultiCrewChange = (selectedValues) => {
    console.log("onMultiCrewChange", selectedValues);
    setMultiCrewData(selectedValues);
    console.log("onMultiCrewChange-multiCrewData", multiCrewData);
  };
  const handleMultiAddCrew = (e) => {
    console.log("multiCrewData", multiCrewData);
    e.preventDefault();
    setIsLoading(true);

    multiCrewData.map((castData) => {
      const data = {
        url: "contentCrew",
        method: "POST",
        body: {
          contentId: formData.id,
          peopleId: castData.id,
          asRole: castData.primarySkill,
          description: castData.description,
          ordering: castData.ordering,
        },
      };

      api.call(
        data,
        (res) => {
          console.log(res);
          let currentData = res.data;
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }); // End itration
    common.notify("S", "Saved Successfully");
    setMultiCrewPopupOpen(false);
    setIsLoading(false);
    loadContentCrewsData(formData.id);
  };

  //end crews

  //Casts
  const [castPopupOpen, setCastPopupOpen] = useState(false);
  const [multiCastPopupOpen, setMultiCastPopupOpen] = useState(false);

  const [castSelected, setCastSelected] = useState({});
  const [castListData, setCastListData] = useState([]);
  const [contentCastsData, setContentCastsData] = useState([]);
  const onCastSelectionChange = useCallback(({ selected }) => {
    setCastSelected(selected);
  }, []);

  const [formCastData, setFormCastData] = useState({
    id: 0,
    contentId: null,
    peopleId: null,
    asRole: null,
    description: null,
    ordering: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    people: {
      id: 0,
      name: null,
      primaryStill: null,
      description: null,
      ordering: null,
      active: "Y",
      image: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onCastEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentCastsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentCastsData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentCast",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentCastsData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentCastsData]
  );

  const loadCastsList = () => {
    setIsLoading(true);
    const data1 = {
      url: "contentCast",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setCastListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed loadCastsList" + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentCastsData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentCast`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true&orderBy=[["ordering",ASC]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("Get contentCasts for particular content::", res.data.items);
        setContentCastsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed loadContentCastsData" + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteCasts = () => {
    if (castSelected > 0) {
      const data1 = {
        url: "contentCast",
        query: "/" + castSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted cast", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentCastsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleCastFormChange = (val) => {
    const { name, value } = val.target;
    setFormCastData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleCastChange = (val) => {
    const name = "castId";

    setFormCastData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleMultiCastPopupClickOpen = () => {
    setFormCastData({
      id: 0,
      contentId: null,
      peopleId: null,
      asRole: null,
      description: null,
      ordering: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setMultiCastPopupOpen(true);
  };

  const handleMultiCastPopupClose = () => {
    setMultiCastPopupOpen(false);
  };

  const handleCastPopupClickOpen = () => {
    setFormCastData({
      id: 0,
      contentId: null,
      peopleId: null,
      asRole: null,
      description: null,
      ordering: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setCastPopupOpen(true);
  };

  const handleCastPopupClose = () => {
    setCastPopupOpen(false);
  };

  const [userQuery, setPeopleQuery] = useState("");

  const loadPeopleOptions = async (inputValue) => {
    const res = await axios.get(
      `${config.api.url}people/search/advance?q={"name":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    return res.data.items;
  };

  const onCastChange = (value) => {
    console.log("onCastChange", value);
    if (value) {
      let localData = formCastData;
      localData.castId = value.id;
      localData.contentId = formData.id;
      setFormCastData(localData);
      console.log("onCastChange-formCastData", formCastData);
    }
  };

  const handleAddCast = (e) => {
    console.log("formCastData", formCastData);
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentCast",
      method: "POST",
      body: {
        contentId: formData.id,
        peopleId: formCastData.castId,
        asRole: formCastData.asRole,
        description: formCastData.description,
        ordering: formCastData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setCastPopupOpen(false);
        setIsLoading(false);
        loadContentCastsData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const [multiCastData, setMultiCastData] = useState([]);

  const onMultiCastChange = (selectedValues) => {
    console.log("onMultiCastChange", selectedValues);
    setMultiCastData(selectedValues);
    console.log("onMultiCastChange-multiCastData", multiCastData);
  };
  const handleMultiAddCast = (e) => {
    console.log("multiCastData", multiCastData);
    e.preventDefault();
    setIsLoading(true);

    multiCastData.map((castData) => {
      const data = {
        url: "contentCast",
        method: "POST",
        body: {
          contentId: formData.id,
          peopleId: castData.id,
          asRole: castData.primarySkill,
          description: castData.description,
          ordering: castData.ordering,
        },
      };

      api.call(
        data,
        (res) => {
          console.log(res);
          let currentData = res.data;
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }); // End itration
    common.notify("S", "Saved Successfully");
    setMultiCastPopupOpen(false);
    setIsLoading(false);
    console.log("formData.id", formData.id);
    loadContentCastsData(formData.id);
  };

  // Fzor Multi People upload
  const [changeCounter, setChangeCounter] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const uploadMultiPeople = (event, peopleType) => {
    // peopleType="cast"; //for testing
    console.log("event.target.files", event.target.files);
    console.log("peopleType", peopleType);

    setSelectedFiles(event.target.files);
    const files = Array.from(event.target.files);
    console.log("files", files);
    setIsLoading(true);
    if (files && files.length > 0) {
      files.map((file) => {
        console.log("file.name", file.name);
        let completedFileName = file.name;
        let [fileName] = completedFileName.split(".");
        let [name, role] = fileName.split("-");
        formData.name = name;
        formData.primarySkill = role;
        formData.description = role;
        formData.ordering = 1;
        formData.active = "Y";
        uploadPeople(event, file, peopleType);
        // uploadPeopleImage(file)
      });
    }
  };

  const uploadPeople = (e, file, peopleType) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id);
    if (formData.id > 0)
      data1 = {
        url: "people",
        query: "/" + formData.id,
        body: {
          name: formData.name,
          primarySkill: formData.primarySkill,
          description: formData.description,
          ordering: formData.ordering,
          active: formData.active,
          // "image": formData.image,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "people",
        method: "POST",
        body: {
          name: formData.name,
          primarySkill: formData.primarySkill,
          description: formData.description,
          ordering: formData.ordering,
          active: formData.active,
          // "image": formData.image,
        },
      };

    console.log("API data1", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) {
          uploadPeopleImage(file, currentData.id);
          setMultiCastData(currentData);
          setChangeCounter(changeCounter + 1);
          if (peopleType === "cast") handleMultiAddCast(e); // For linking people to cast
          if (peopleType === "crew") handleMultiAddCrew(e); // For linking people to crew
        }
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const uploadPeopleImage = (file, peopleId) => {
    console.log("uploadPeopleImage");
    setIsLoading(true);
    let payLoad = new FormData();
    payLoad.append("image", file, file.name);
    console.log("payLoad", payLoad.get("image"));

    let data1 = {
      url: "people",
      query: "/uploadImage/" + peopleId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setChangeCounter(changeCounter + 1);
        setIsLoading(false);
      },
      (final) => {}
    );
    setIsLoading(false);
  };
  //end casts

  //Languages
  const [languagePopupOpen, setLanguagePopupOpen] = useState(false);
  const [languageSelected, setLanguageSelected] = useState({});
  const [languageListData, setLanguageListData] = useState([]);
  const [contentLanguagesData, setContentLanguagesData] = useState([]);
  const onLanguageSelectionChange = useCallback(({ selected }) => {
    setLanguageSelected(selected);
  }, []);

  const [formLanguageData, setFormLanguageData] = useState({
    id: 0,
    contentId: null,
    languageId: null,
    ordering: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    language: {
      id: 0,
      name: null,
      description: null,
      ordering: null,
      bgColor: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onLanguageEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentLanguagesData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentLanguagesData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentLanguages",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentLanguagesData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentLanguagesData]
  );

  const loadLanguagesList = () => {
    setIsLoading(true);
    const data1 = {
      url: "languages",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setLanguageListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentLanguagesData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentLanguages`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log("contentLanguages::", res.data.items);
        setContentLanguagesData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteLanguages = () => {
    if (languageSelected > 0) {
      const data1 = {
        url: "contentLanguages",
        query: "/" + languageSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted language", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentLanguagesData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleLanguageFormChange = (val) => {
    const { name, value } = val.target;
    setFormLanguageData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleLanguageChange = (val) => {
    const name = "languageId";

    setFormLanguageData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleLanguagePopupClickOpen = () => {
    setFormLanguageData({
      id: 0,
      contentId: null,
      languageId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setLanguagePopupOpen(true);
  };

  const handleLanguagePopupClose = () => {
    setLanguagePopupOpen(false);
  };

  const handleAddLanguage = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentLanguages",
      method: "POST",
      body: {
        contentId: formData.id,
        languageId: formLanguageData.languageId,
        ordering: formLanguageData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setLanguagePopupOpen(false);
        setIsLoading(false);
        loadContentLanguagesData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  //end languages

  //Promos

  const [promoPopupOpen, setPromoPopupOpen] = useState(false);
  const [promoSelected, setPromoSelected] = useState();
  const [promoListData, setPromoListData] = useState([]);
  const [contentPromosData, setContentPromosData] = useState([]);
  const [promoTypeListData, setPromoTypeListData] = useState([]);

  const onPromoSelectionChange = useCallback(({ selected }) => {
    setPromoSelected(selected);
  }, []);

  const [formPromoData, setFormPromoData] = useState({
    id: 0,
    contentId: null,
    promoType: null,
    promoTitle: null,
    promoUrl: null,
    promoDurationSecs: null,
    promoViews: null,
    description: null,
    thumbnail: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const loadPromoTypeList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_CONTENT_RIGHT_PROMO_TYPE"}}&orderBy=[["ordering", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadPromoTypeList", res.data.items);
        setPromoTypeListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handlePromoTypeChange = (val) => {
    const name = "promoType";

    setFormPromoData((prevState) => {
      return {
        ...prevState,
        [name]: val.lookupCode,
      };
    });
  };

  const onPromoEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentPromosData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentPromosData(data);

      let wsData = {};
      let bodyData = {};
      //TODO: need to do with switch condition
      if (columnId === "ordering" && data[index]["id"]) {
        bodyData = {
          ordering: data[index]["ordering"],
        };
      }
      if (columnId === "promoUrl" && data[index]["id"]) {
        bodyData = {
          promoUrl: data[index]["promoUrl"],
        };
      }
      if (columnId === "description" && data[index]["id"]) {
        bodyData = {
          description: data[index]["description"],
        };
      }
      if (columnId === "promoDurationSecs" && data[index]["id"]) {
        bodyData = {
          promoDurationSecs: data[index]["promoDurationSecs"],
        };
      }
      if (columnId === "promoViews" && data[index]["id"]) {
        bodyData = {
          promoViews: data[index]["promoViews"],
        };
      }

      wsData = {
        url: "contentPromos",
        query: "/" + data[index]["id"],
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadContentPromosData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    },
    [contentPromosData]
  );

  //  const loadPromosList = () => {
  //    setIsLoading(true);
  //    const data1 = {
  //      url: "promos",
  //      method: "GET",
  //      query: `?limit=10&offset=0`,
  //    };
  //    api.call(
  //      data1,
  //      (res) => {
  //        console.log(res.data.items);
  //        setPromoListData(res.data.items);
  //        setIsLoading(false);
  //      },
  //      (error) => {
  //        common.notify("E", "Api Failed " + error.statusText);
  //        setIsLoading(false);
  //      },
  //      (final) => { }
  //    );
  //  }

  const loadContentPromosData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentPromos`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log("contentPromos::", res.data.items);
        setContentPromosData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeletePromos = () => {
    if (promoSelected > 0) {
      const data1 = {
        url: "contentPromos",
        query: "/" + promoSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted promo", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentPromosData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handlePromoFormChange = (val) => {
    const { name, value } = val.target;
    setFormPromoData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePromoTypeSelectChange = (val) => {
    const { name, value } = val.target;
    setFormPromoData((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ["promoTitle"]: value,
      };
    });
  };

  const handlePromoPopupClickOpen = () => {
    setFormPromoData({
      id: 0,
      contentId: null,
      promoType: "Trailer",
      promoTitle: "Trailer",
      promoUrl: "",
      promoDurationSecs: null,
      promoViews: null,
      description: null,
      thumbnail: "",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setCroppedPromoThumbnailPreview(null);
    setPromoPopupOpen(true);
  };

  const handlePromoPopupClose = () => {
    setPromoPopupOpen(false);
  };

  const [promoVideo, setPromoVideo] = useState(null);

  const handlePromoVideo = (uploadFile) => {
    console.log("uploadPromoVideo ", uploadFile);
    if (uploadFile?.length === 0) {
      return (formPromoData.promoUrl = "");
    }
    let videoFile = uploadFile[0].file;
    setPromoVideo(videoFile);
    // setToggleCropModal(false);
    formPromoData.promoUrl = URL.createObjectURL(videoFile);
    console.log("formPromoData.promoUrl", formPromoData.promoUrl);
  };

  const handleAddPromo = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentPromos",
      method: "POST",
      body: {
        contentId: formData.id,
        promoType: formPromoData.promoType,
        promoTitle: formPromoData.promoTitle,
        // "promoUrl": formPromoData.promoUrl, //TODO - Video upload
        promoDurationSecs: formPromoData.promoDurationSecs,
        promoViews: formPromoData.promoViews,
        description: formPromoData.description,
        // "thumbnail": formPromoData.thumbnail,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        console.log("PromocurrentData", currentData);
        if (currentData.id > 0) {
          setPromoSelected(currentData.id);
          console.log("PromoSelected", promoSelected);
          if (croppedPromoThumbnail) uploadPromoThumbnail(currentData.id);
          if (promoVideo) uploadPromoVideo(currentData.id);
        }
        // common.notify("S", "Saved Successfully");
        setPromoPopupOpen(false);
        // setIsLoading(false);
        // loadContentPromosData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const uploadPromoThumbnail = (promoId) => {
    console.log("uploadPromoThumbnail");
    // const croppedImage = getCroppedImg();
    setIsLoading(true);
    console.log("payload.croppedPromoThumbnail", croppedPromoThumbnail);

    let payLoad = new FormData();
    payLoad.append("image", croppedPromoThumbnail, "imageUpload.png");
    console.log("payLoad", payLoad.get("image"));
    console.log("formData.id:", formData.id, " promoSelected:", promoSelected);
    console.log("formData.id:", formData.id, " promoId:", promoId);

    let data1 = {
      url: "content",
      query: "/uploadPromoImage/content/" + formData.id + "/promo/" + promoId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);

        let currentData = res.data.item;
        console.log("PromocurrentData", currentData);
        if (currentData.id > 0) {
          loadContentPromosData(formData.id);
          setCroppedPromoThumbnailPreview(currentData.thumbnail);
        }
        common.notify("S", "Saved Successfully");
        setPromoPopupOpen(false);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  const uploadPromoVideo = (promoId) => {
    console.log("uploadPromoVideo");
    // const croppedImage = getCroppedImg();
    setIsLoading(true);
    console.log("payload.croppedPromoThumbnail", promoVideo);

    let payLoad = new FormData();
    payLoad.append("image", promoVideo, "promoUpload.mov");
    console.log("payLoad", payLoad.get("image"));
    console.log("formData.id:", formData.id, " promoSelected:", promoSelected);
    console.log("formData.id:", formData.id, " promoId:", promoId);

    let data1 = {
      url: "content",
      query: "/uploadPromoVideo/content/" + formData.id + "/promo/" + promoId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);

        let currentData = res.data.item;
        console.log("PromocurrentData", currentData);
        if (currentData.id > 0) {
          loadContentPromosData(formData.id);
        }
        common.notify("S", "Saved Successfully");
        setPromoPopupOpen(false);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  const [uploadPromoThumbnailPopupOpen, setUploadPromoThumbnailPopupOpen] = useState(false);

  const handleUploadPromoThumbnailPopup = (e) => {
    setCrop({
      unit: "px", // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    onUploadFile(e);
    setUploadPromoThumbnailPopupOpen(true);
  };

  const handleUploadPromoThumbnailPopupClose = () => {
    setUploadPromoThumbnailPopupOpen(false);
  };

  const handleUploadPromoThumbnail = (e) => {
    console.log("handleUploadPromoThumbnail");
    const croppedImage = getCroppedImg();
    console.log("croppedImage::", croppedImage);
    formData.image = formData.croppedImage;
    console.log("formData.image", formData.image);
    e.preventDefault();
    setUploadPromoThumbnailPopupOpen(false);
  };

  //end promos

  //Images
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [imageSelected, setImageSelected] = useState({});
  const [imageListData, setImageListData] = useState([]);
  const [contentImagesData, setContentImagesData] = useState([]);
  const onImageSelectionChange = useCallback(({ selected }) => {
    setImageSelected(selected);
  }, []);

  const [formImageData, setFormImageData] = useState({
    id: 0,
    contentId: null,
    imageType: null,
    imageTitle: null,
    imageUrl: null,
    imageSize: null,
    description: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const onImageEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentImagesData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log("rowId", rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentImagesData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentImages",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentImagesData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentImagesData]
  );

  const loadContentImagesData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentImages`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log("contentImages::", res.data.items);
        setContentImagesData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteImages = () => {
    if (imageSelected > 0) {
      const data1 = {
        url: "contentImages",
        query: "/" + imageSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted image", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentImagesData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleImageFormChange = (val) => {
    const { name, value } = val.target;
    setFormImageData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleImageChange = (val) => {
    const imageType = "imageType";
    const imageTitle = "imageTitle";

    setFormImageData((prevState) => {
      return {
        ...prevState,
        [imageType]: val.id,
        [imageTitle]: val.id,
      };
    });
  };

  const handleImagePopupClickOpen = () => {
    setFormImageData({
      id: 0,
      contentId: null,
      imageType: null,
      imageTitle: null,
      imageUrl: null,
      imageSize: null,
      description: null,
      created_by: null,
      creation_date: null,
      last_updated_by: null,
      last_update_date: null,
      last_update_login: null,
      version_number: null,
    });
    setImagePopupOpen(true);
  };

  const handleImagePopupClose = () => {
    setImagePopupOpen(false);
  };

  const handleAddImage = (e) => {
    console.log("handleAddImage");
    e.preventDefault();
    setIsLoading(true);
    console.log("imageSize", imageSize, " ", imageTitle, " ", imageType);
    formImageData.imageType = imageType;
    formImageData.imageTitle = imageTitle;
    formImageData.imageSize = imageSize;
    const data = {
      url: "contentImages",
      method: "POST",
      body: {
        contentId: formData.id,
        imageType: formImageData.imageType,
        imageTitle: formImageData.imageTitle,
        imageUrl: "/", //formImageData.imageUrl,
        imageSize: formImageData.imageSize,
        description: formImageData.description,
      },
    };

    console.log("Add contentImages with", data);
    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) {
          console.log("contentImageID", currentData.id);
          setImageSelected();
          if (croppedImage) uploadContentImage(currentData.id);
        }
        common.notify("S", "Saved Successfully");
        setImagePopupOpen(false);
        setIsLoading(false);
        loadContentImagesData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const [uploadImagePopupOpen, setUploadImagePopupOpen] = useState(false);

  const handleUploadImagePopup = (e) => {
    setCrop({
      unit: "px", // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    onUploadFile(e);
    setUploadImagePopupOpen(true);
  };

  const handleUploadImagePopupClose = () => {
    setUploadImagePopupOpen(false);
  };

  const handleUploadImage = (e) => {
    console.log("handleUploadImage");
    const croppedImage = getCroppedImg();
    console.log("croppedImage::", croppedImage);
    formData.image = formData.croppedImage;
    console.log("formData.image", formData.image);
    e.preventDefault();

    setUploadImagePopupOpen(false);
  };

  const uploadContentImage = (imageId) => {
    console.log("uploadContentImage");
    // const croppedImage = getCroppedImg();
    setIsLoading(true);
    console.log("payload.croppedImage", croppedImage);
    console.log("formData.id:", formData.id, "imageSelected", imageId);

    let payLoad = new FormData();
    payLoad.append("image", croppedImage, "imageUpload.png");
    console.log("payLoad", payLoad.get("image"));
    // payLoad.image=croppedFormData
    // console.log("formData1 for upload",payLoad)
    let data1 = {
      url: "content",
      query: "/uploadImage/content/" + formData.id + "/image/" + imageId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) {
          setCroppedImagePreview(currentData.imageUrl);
        }
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  //end Images

  //Formats
  const [formatPopupOpen, setFormatPopupOpen] = useState(false);
  const [formatSelected, setFormatSelected] = useState({});
  const [formatListData, setFormatListData] = useState([]);
  const [contentFormatsData, setContentFormatsData] = useState([]);
  const onFormatSelectionChange = useCallback(({ selected }) => {
    setFormatSelected(selected);
  }, []);

  const [formFormatData, setFormFormatData] = useState({
    id: 0,
    contentId: null,
    formatId: null,
    ordering: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    format: {
      id: 1,
      name: null,
      description: null,
      ordering: null,
      bgColor: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onFormatEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentFormatsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentFormatsData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentFormats",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");

            loadContentFormatsData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentFormatsData]
  );

  const loadFormatsList = () => {
    setIsLoading(true);
    const data1 = {
      url: "formats",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setFormatListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentFormatsData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentFormats`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setContentFormatsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteFormats = () => {
    if (formatSelected > 0) {
      const data1 = {
        url: "contentFormats",
        query: "/" + formatSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted format", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentFormatsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleFormatFormChange = (val) => {
    const { name, value } = val.target;
    setFormFormatData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleFormatChange = (val) => {
    const name = "formatId";

    setFormFormatData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleFormatPopupClickOpen = () => {
    setFormFormatData({
      id: 0,
      contentId: null,
      formatId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setFormatPopupOpen(true);
  };

  const handleFormatPopupClose = () => {
    setFormatPopupOpen(false);
  };

  const handleAddFormat = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentFormats",
      method: "POST",
      body: {
        contentId: formData.id,
        formatId: formFormatData.formatId,
        ordering: formFormatData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setFormatPopupOpen(false);
        setIsLoading(false);
        loadContentFormatsData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  //end formats

  //Rights
  const [rightPopupOpen, setRightPopupOpen] = useState(false);
  const [rightSelected, setRightSelected] = useState({});
  const [rightListData, setRightListData] = useState([]);
  const [contentRightsData, setContentRightsData] = useState([]);
  const onRightSelectionChange = useCallback(({ selected }) => {
    setRightSelected(selected);
  }, []);
  const [durationTypeListData, setDurationTypeListData] = useState([]);
  const [durationUomListData, setDurationUomListData] = useState([]);

  const [formRightData, setFormRightData] = useState({
    id: 0,
    contentId: null,
    rightId: null,
    durationType: null,
    durationUom: null,
    durationValue: null,
    exclusiveRight: null,
    isSold: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    rightType: {
      id: 1,
      name: null,
      description: null,
      ordering: null,
      bgColor: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const loadDurationTypeList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_CONTENT_RIGHT_DURATION_TYPE"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadDurationTypeList", res.data.items);
        setDurationTypeListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadDurationUomList = () => {
    setIsLoading(true);
    const data1 = {
      url: "lookupValuesAdvanceSearch",
      method: "GET",
      query: `?q={"lookupType":{"$eq": "ZR_CONTENT_RIGHT_DURATION_UOM"}}&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("loadDurationUomList", res.data.items);
        setDurationUomListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleRightsDuractioChange = (val, name, id) => {
    console.log("durationType value", name, val.lookupCode, id);

    let bodyData = { [name]: val.lookupCode };
    console.log("bodyData", bodyData);
    let wsData = {
      url: "contentRights",
      query: "/" + id,
      body: bodyData,
      method: "PATCH",
    };
    api.call(
      wsData,
      (res) => {
        console.log(res);
        common.notify("S", "Saved Successfully");
        loadContentRightsData(res.data.item.contentId);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
      },
      (final) => {}
    );
  };

  const onRightEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log("onRightEditComplete", value, columnId, rowId);
      const data = [...contentRightsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      console.log("onRightEditComplete-data", data);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentRightsData(data);
      let wsData = {};
      let bodyData = {};
      //TODO: need to do with switch condition
      if (columnId === "ordering" && data[index]["id"]) {
        bodyData = {
          ordering: data[index]["ordering"],
        };
      }
      // if (columnId === "durationType" && data[index]["id"]) {
      //   bodyData = {
      //     durationType: data[index]["durationType"],
      //   }
      // }
      // if (columnId === "durationUom" && data[index]["id"]) {
      //   bodyData = {
      //     durationUom: data[index]["durationUom"],
      //   }
      // }
      if (columnId === "durationValue" && data[index]["id"]) {
        bodyData = {
          durationValue: data[index]["durationValue"],
        };
      }

      console.log("bodyData", bodyData);
      wsData = {
        url: "contentRights",
        query: "/" + data[index]["id"],
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          console.log(res);
          common.notify("S", "Saved Successfully");
          loadContentRightsData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
      // }
    },
    [contentRightsData]
  );

  const loadRightsList = () => {
    setIsLoading(true);
    const data1 = {
      url: "rightTypes",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setRightListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentRightsData = (pId) => {
    setIsLoading(true);

    const data1 = {
      method: "GET",
      url: `contentRights`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setContentRightsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteRights = () => {
    if (rightSelected > 0) {
      const data1 = {
        url: "contentRights",
        query: "/" + rightSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted right", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentRightsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleRightFormChange = (val) => {
    const { name, value } = val.target;
    console.log("handleRightFormChange -name", name, " value ", value);
    setFormRightData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    console.log("handleRightFormChange-formRightData", formRightData);
  };

  const handleRightChange = (val) => {
    const name = "rightId";

    setFormRightData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleRightPopupClickOpen = () => {
    setFormRightData({
      id: 0,
      contentId: null,
      rightId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setRightPopupOpen(true);
  };

  const handleRightPopupClose = () => {
    setRightPopupOpen(false);
  };

  const getRightsDetail = (rightTypeId) => {
    setIsLoading(true);
    const data1 = {
      url: "rightTypes",
      method: "GET",
      query: "/" + rightTypeId,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.item);
        // setRightListData(res.data.items);
        return res.data.item;
        // setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const handleAddRight = (e) => {
    e.preventDefault();
    setIsLoading(true);

    //Get RightTYpe details for given right id
    const data1 = {
      url: "rightTypes",
      method: "GET",
      query: "/" + formRightData.rightId,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.item);
        let rightTypeDetails = res.data.item;
        setIsLoading(false);
        //Create ContentPRights
        const data = {
          url: "contentRights",
          method: "POST",
          body: {
            contentId: formData.id,
            rightId: formRightData.rightId,
            ordering: formRightData.ordering,
            exclusiveRight: rightTypeDetails?.exclusiveRight,
            durationType: rightTypeDetails?.durationType,
            durationUom: rightTypeDetails?.durationUom,
            durationValue: rightTypeDetails?.durationValue,
            isSold: rightTypeDetails?.durationValue,
            active: "Y",
          },
        };

        api.call(
          data,
          (res) => {
            console.log(res);
            let currentData = res.data;
            common.notify("S", "Saved Successfully");
            setRightPopupOpen(false);
            setIsLoading(false);
            loadContentRightsData(formData.id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
            setIsLoading(false);
          },
          (final) => {}
        );
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  //end rights

  //Parties
  const [partyPopupOpen, setPartyPopupOpen] = useState(false);
  const [partySelected, setPartySelected] = useState({});
  const [partyListData, setPartyListData] = useState([]);
  const [contentPartiesData, setContentPartiesData] = useState([]);
  const onPartySelectionChange = useCallback(({ selected }) => {
    setPartySelected(selected);
  }, []);

  const [partyQuery, setPartyQuery] = useState("");

  const loadPartyOptions = async (inputValue) => {
    const res = await axios.get(
      `${config.api.url}parties/search/advance?q={"partyName":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    return res.data.items;
  };

  const onPartyChange = (value) => {
    console.log("onPartyChange", value, "   ", value.id);
    if (value) {
      let localData = formPartyData;
      localData.partyId = value.id;
      localData.contentId = formData.id;
      setFormPartyData(localData);
      console.log("onPartyChange-setFormPartyData", formPartyData);
    }
  };

  const [formPartyData, setFormPartyData] = useState({
    id: 0,
    contentId: null,
    partyId: null,
    ordering: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    party: {
      id: 1,
      partyName: null,
      partyType: null,
      description: null,
      partyCategory: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onPartyEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentPartiesData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log("columnId", columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      console.log("onPartyEditComplete-data", data);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentPartiesData(data);
      let wsData = {};
      let bodyData = {};
      if (columnId === "ordering" && data[index]["id"]) {
        bodyData = {
          [columnId]: data[index]["ordering"],
        };
      }

      wsData = {
        url: "contentParties",
        query: "/" + data[index]["id"],
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          console.log(res);
          common.notify("S", "Saved Successfully");
          loadContentPartiesData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
      // }
    },
    [contentPartiesData]
  );

  const loadPartiesList = () => {
    setIsLoading(true);
    const data1 = {
      url: "parties",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setPartyListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentPartiesData = (pId) => {
    setIsLoading(true);

    const data1 = {
      method: "GET",
      url: `contentParties`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setContentPartiesData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteParties = () => {
    if (partySelected > 0) {
      const data1 = {
        url: "contentParties",
        query: "/" + partySelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted party", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentPartiesData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handlePartyFormChange = (val) => {
    const { name, value } = val.target;
    setFormPartyData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePartyChange = (val) => {
    const name = "partyId";

    setFormPartyData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handlePartySwitchChange = (event, pId) => {
    console.log("handlePartySwitchChange checked ", event.target);
    const { name, checked } = event.target;

    if (pId > 0) {
      let data1 = {
        url: "contentParties",
        query: "/" + pId,
        body: {
          [name]: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("switchdata", res);
          common.notify("S", "Saved Successfully");
          loadContentPartiesData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const handlePartyPopupClickOpen = () => {
    setFormPartyData({
      id: 0,
      contentId: null,
      partyId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setPartyPopupOpen(true);
  };

  const handlePartyPopupClose = () => {
    setPartyPopupOpen(false);
  };

  const handleAddParty = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentParties",
      method: "POST",
      body: {
        contentId: formData.id,
        partyId: formPartyData.partyId,
        ordering: formPartyData.ordering,
        active: formPartyData.active,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setPartyPopupOpen(false);
        setIsLoading(false);
        loadContentPartiesData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  //end parties

  //Regions
  const [RegionPopupOpen, setRegionPopupOpen] = useState(false);
  const [Regionselected, setRegionselected] = useState({});
  const [RegionListData, setRegionListData] = useState([]);
  const [contentRegionsData, setContentRegionsData] = useState([]);
  const onRegionselectionChange = useCallback(({ selected }) => {
    setRegionselected(selected);
  }, []);

  const [formRegionData, setFormRegionData] = useState({
    id: 0,
    contentId: null,
    regionId: null,
    ordering: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    region: {
      id: 1,
      name: null,
      description: null,
      ordering: null,
      bgColor: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    },
  });

  const onRegionEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentRegionsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentRegionsData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentRegions",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            console.log("contentregions", res.data.item);
            common.notify("S", "Saved Successfully");
            loadContentRegionsData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentRegionsData]
  );

  const loadRegionsList = () => {
    setIsLoading(true);
    const data1 = {
      url: "regions",
      method: "GET",
      query: `?limit=10&offset=0`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setRegionListData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const loadContentRegionsData = (pId) => {
    setIsLoading(true);

    const data1 = {
      method: "GET",
      url: `contentRegions`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log(res.data.items);
        setContentRegionsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteRegions = () => {
    if (Regionselected > 0) {
      const data1 = {
        url: "contentRegions",
        query: "/" + Regionselected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted Region", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentRegionsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleRegionFormChange = (val) => {
    const { name, value } = val.target;
    setFormRegionData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleRegionChange = (val) => {
    const name = "regionId";

    setFormRegionData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleRegionPopupClickOpen = () => {
    setFormRegionData({
      id: 0,
      contentId: null,
      RegionId: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setRegionPopupOpen(true);
  };

  const handleRegionPopupClose = () => {
    setRegionPopupOpen(false);
  };

  const handleAddRegion = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentRegions",
      method: "POST",
      body: {
        contentId: formData.id,
        regionId: formRegionData.regionId,
        ordering: formRegionData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setRegionPopupOpen(false);
        setIsLoading(false);
        loadContentRegionsData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  //end Regions

  //Reviews

  const [reviewPopupOpen, setReviewPopupOpen] = useState(false);
  const [reviewSelected, setReviewSelected] = useState({});
  const [reviewListData, setReviewListData] = useState([]);
  const [contentReviewsData, setContentReviewsData] = useState([]);
  const onReviewSelectionChange = useCallback(({ selected }) => {
    setReviewSelected(selected);
  }, []);

  const [formReviewData, setFormReviewData] = useState({
    id: 0,
    contentId: null,
    userId: null,
    rating: null,
    reviewComments: null,
    active: null,
    parentId: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  // const handleReviewSwitchChange = (event) => {
  //       console.log("handleReviewSwitchChange -checked ", event.target);
  //       const { name, checked } = event.target;
  //       console.log("name ", name, " checked", checked)
  //       setFormReviewData((prevState) => {
  //         return {
  //           ...prevState,
  //           [name]: checked ? "Y" : null,
  //         };
  //       });
  //     };

  const handleReviewSwitchChange = (event, pId) => {
    console.log("handleReviewSwitchChange checked ", event.target);
    const { name, checked } = event.target;

    if (pId > 0) {
      let data1 = {
        url: "contentReviews",
        query: "/" + pId,
        body: {
          [name]: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("switchdata", res);
          common.notify("S", "Saved Successfully");
          loadContentReviewsData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };
  const onReviewEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log("onReviewEditComplete", value, columnId, rowId);
      const data = [...contentReviewsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentReviewsData(data);

      if (columnId === "active" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          active: data[index]["active"],
        };
        wsData = {
          url: "contentReviews",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            data[index]["id"] = res.item.id;
            data[index]["userId"] = res.item.userId;
            data[index]["rating"] = res.item.rating;
            data[index]["reviewComments"] = res.item.reviewComments;
            data[index]["active"] = res.item.active;
            data[index]["parentId"] = res.item.parentId;
            data[index]["createdBy"] = res.item.createdBy;
            data[index]["lastUpdatedBy"] = res.item.lastUpdatedBy;
            data[index]["lastUpdateDate"] = res.item.lastUpdateDate;
            data[index]["lastUpdateLogin"] = res.item.lastUpdateLogin;
            data[index]["versionNumber"] = res.item.versionNumber;
            setContentReviewsData(data);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentReviewsData]
  );

  //  const loadReviewsList = () => {
  //    setIsLoading(true);
  //    const data1 = {
  //      url: "reviews",
  //      method: "GET",
  //      query: `?limit=10&offset=0`,
  //    };
  //    api.call(
  //      data1,
  //      (res) => {
  //        console.log(res.data.items);
  //        setReviewListData(res.data.items);
  //        setIsLoading(false);
  //      },
  //      (error) => {
  //        common.notify("E", "Api Failed " + error.statusText);
  //        setIsLoading(false);
  //      },
  //      (final) => { }
  //    );
  //  }

  const loadContentReviewsData = (pId) => {
    console.log("ContentReviews.loadContentReviewsData ");

    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentReviews`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log("contentReviews::", res.data.items);
        setContentReviewsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteReviews = () => {
    if (reviewSelected > 0) {
      const data1 = {
        url: "contentReviews",
        query: "/" + reviewSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted review", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentReviewsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleReviewFormChange = (val) => {
    const { name, value } = val.target;
    setFormReviewData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleReviewChange = (val) => {
    const name = "reviewId";

    setFormReviewData((prevState) => {
      return {
        ...prevState,
        [name]: val.id,
      };
    });
  };

  const handleReviewPopupClickOpen = () => {
    setFormReviewData({
      id: 0,
      contentId: null,
      userId: null,
      rating: null,
      reviewComments: null,
      active: null,
      parentId: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setReviewPopupOpen(true);
  };

  const handleReviewPopupClose = () => {
    setReviewPopupOpen(false);
  };

  const handleAddReview = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentReviews",
      method: "POST",
      body: {
        contentId: formData.id,
        userId: formData.userId,
        rating: formData.rating,
        reviewComments: formData.reviewComments,
        active: formData.active,
        parentId: formData.parentId,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data;
        common.notify("S", "Saved Successfully");
        setReviewPopupOpen(false);
        setIsLoading(false);
        loadContentReviewsData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  // End Reviews

  //SubTitles
  const [subTitlePopupOpen, setSubTitlePopupOpen] = useState(false);
  const [subTitleSelected, setSubTitleSelected] = useState({});
  const [subTitleListData, setSubTitleListData] = useState([]);

  const [contentSubTitlesData, setContentSubTitlesData] = useState([]);
  const onSubTitleSelectionChange = useCallback(({ selected }) => {
    setSubTitleSelected(selected);
  }, []);

  const [formSubTitleData, setFormSubTitleData] = useState({
    id: 0,
    contentId: null,
    name: null,
    description: null,
    languageCode: null,
    fileType: null,
    fileUrl: null,
    ordering: null,
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const onSubTitleEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentSubTitlesData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentSubTitlesData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentSubTitles",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentSubTitlesData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentSubTitlesData]
  );

  const loadSubTitlesList = () => {
    const subTitleFileTypes = [
      { label: "text/vtt", value: "text/vtt" },
      { label: "application/x-subrip", value: "application/x-subrip" },
    ];
    setSubTitleListData(subTitleFileTypes);
    // setIsLoading(true);
    // const data1 = {
    //   url: "contentSubTitles",
    //   method: "GET",
    //   query: `?limit=10&offset=0`,
    // };
    // api.call(
    //   data1,
    //   (res) => {
    //     console.log(res.data.items);
    //     setSubTitleListData(res.data.items);
    //     setIsLoading(false);
    //   },
    //   (error) => {
    //     common.notify("E", "Api Failed " + error.statusText);
    //     setIsLoading(false);
    //   },
    //   (final) => { }
    // );
  };

  const loadContentSubTitlesData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentSubTitles`,
      query: `/content/${pId}?offset=0&limit=10&totalResults=true`,
    };
    api.call(
      data1,
      (res) => {
        console.log("contentSubTitles::", res.data.items);
        setContentSubTitlesData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeleteSubTitles = () => {
    if (subTitleSelected > 0) {
      const data1 = {
        url: "contentSubTitles",
        query: "/" + subTitleSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted subTitle", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentSubTitlesData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handleSubTitleFormChange = (val) => {
    const { name, value } = val.target;
    setFormSubTitleData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubTitleChange = (val) => {
    const name = "fileType";

    setFormSubTitleData((prevState) => {
      return {
        ...prevState,
        [name]: val.value,
      };
    });
  };

  const handleSubTitlePopupClickOpen = () => {
    setFormSubTitleData({
      id: 0,
      contentId: null,
      name: null,
      description: null,
      languageCode: null,
      fileType: null,
      fileUrl: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setSubTitlePopupOpen(true);
  };

  const handleSubTitlePopupClose = () => {
    setSubTitlePopupOpen(false);
  };

  const handleAddSubTitle = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentSubTitles",
      method: "POST",
      body: {
        contentId: formData.id,
        name: formSubTitleData.name,
        description: formSubTitleData.description,
        languageCode: formSubTitleData.languageCode,
        fileType: formSubTitleData.fileType ? formSubTitleData.fileType : "text/vtt",
        fileUrl: formSubTitleData.fileUrl ? formSubTitleData.fileUrl : "./",
        ordering: formSubTitleData.ordering,
        active: formSubTitleData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        console.log("currentData", currentData);
        if (currentData.id > 0) {
          console.log("contentSubTitleID", currentData.id);
          uploadSubTitleFile(currentData.id);
        }
        common.notify("S", "Saved Successfully");
        setSubTitlePopupOpen(false);
        setIsLoading(false);
        loadContentSubTitlesData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const uploadSubTitleFile = (subTitleId) => {
    console.log("uploadSubTitleFile");
    let subTitleFile = formSubTitleData.subTitleFile;
    // setIsLoading(true);
    console.log("payload.uploadSubTitleFile", subTitleFile);

    let payLoad = new FormData();
    payLoad.append("file", subTitleFile, "subTitleFile.txt");
    console.log("payLoad", payLoad.get("file"));

    let data1 = {
      url: "content",
      query: "/uploadSubTitles/content/" + formData.id + "/subTitle/" + subTitleId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  const [langaugeQuery, setLangaugeQuery] = useState("");

  const loadLangaugeOptions = async (inputValue) => {
    const res = await axios.get(
      `${config.api.url}languages/search/advance?q={"name":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    );
    return res.data.items;
  };

  const onLangaugeChange = (value) => {
    console.log("onLangaugeChange", value, "   ", value.isoLanguage);
    if (value) {
      let localData = formSubTitleData;
      localData.languageCode = value.isoLanguage;
      localData.contentId = formData.id;
      setFormSubTitleData(localData);
      console.log("onLangaugeChange-formSubTitleData", formSubTitleData);
    }
  };

  const handleSubTitlesFile = (event) => {
    console.log("Files :" + event.target.files[0].name);
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      let subTilesFile = event.target.files[0];
      console.log("subTilesFile", subTilesFile);

      // reader.addEventListener('load', () =>
      //   setImageToCrop(reader.result)
      // );
      formSubTitleData.subTitleFile = subTilesFile;
      console.log("formSubTitleData.SubTitleFile", formSubTitleData.subTitleFile);
      // formSubTitleData.subTitleFile = reader.readAsBinaryString(subTilesFile);
      // console.log("formSubTitleData.SubTitleFile", formSubTitleData.subTitleFile)

      //TODO: Upload to cloud and get relative Path
    }
  };
  //SubTtiles - end

  //Previews - Start

  const [previewPopupOpen, setPreviewPopupOpen] = useState(false);
  const [previewSelected, setPreviewSelected] = useState({});
  const [isDtlLoading, setIsDtlLoading] = useState(false);

  const [contentPreviewsData, setContentPreviewsData] = useState([]);
  const onPreviewSelectionChange = useCallback(({ selected }) => {
    setPreviewSelected(selected);
    loadPreviewContentsData(selected);
    loadPreviewReviewsData(selected);
  }, []);
  const [previewSubTabValue, setPreviewSubTabValue] = useState(0);

  const handlePreviewSubTabChange = (e, newValue) => {
    setPreviewSubTabValue(newValue);
  };

  const [formPreviewData, setFormPreviewData] = useState({
    id: 0,
    contentId: "",
    previewKey: "",
    name: "",
    description: "",
    previewType: "",
    status: "",
    securityType: "",
    password: "",
    noOfViews: "",
    avgRating: "",
    noOfRatings: "",
    thumbnailUrl: "",
    ordering: "",
    active: "Y",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const handleContentPreviewSwitchChange = (event, pId) => {
    const { name, checked } = event.target;
    console.log("handleContentPreviewSwitchChange ", name, checked, pId);

    if (pId > 0) {
      let data1 = {
        url: "contentPreviews",
        query: "/" + pId,
        body: {
          [name]: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("switchdata", res);
          common.notify("S", "Saved Successfully");
          loadContentPreviewsData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const onPreviewEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...contentPreviewsData];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]["id"]', data[index]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setContentPreviewsData(data);

      if (columnId === "ordering" && data[index]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        };
        wsData = {
          url: "contentPreviews",
          query: "/" + data[index]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadContentPreviewsData(res.data.item.contentId);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [contentPreviewsData]
  );

  const loadContentPreviewsData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `contentPreviewsAdvanceSearch`,
      query: `?q={"contentId":{"$eq": ${pId}}}&offset=0&limit=10&totalResults=true&orderBy=[["id", "DESC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("contentPreviews::", res.data.items);
        setContentPreviewsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onDeletePreviews = () => {
    if (previewSelected > 0) {
      const data1 = {
        url: "contentPreviews",
        query: "/" + previewSelected,
        method: "DELETE",
      };

      api.call(
        data1,
        (res) => {
          console.log("deleted preview", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadContentPreviewsData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handlePreviewFormChange = (val) => {
    const { name, value } = val.target;
    setFormPreviewData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePreviewChange = (val) => {
    const name = "fileType";

    setFormPreviewData((prevState) => {
      return {
        ...prevState,
        [name]: val.value,
      };
    });
  };

  const handlePreviewPopupClickOpen = () => {
    setFormPreviewData({
      id: 0,
      contentId: null,
      previewKey: null,
      name: null,
      description: null,
      previewType: null,
      status: null,
      securityType: null,
      password: null,
      noOfViews: null,
      avgRating: null,
      noOfRatings: null,
      thumbnailUrl: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setPreviewPopupOpen(true);
  };

  const handlePreviewPopupClose = () => {
    setPreviewPopupOpen(false);
  };

  const handleAddPreview = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentPreviews",
      method: "POST",
      body: {
        contentId: formData.id,
        thumbnailUrl: formPreviewData.thumbnailUrl,
        //  previewKey:formPreviewData.previewKey,
        name: formPreviewData.name,
        description: formPreviewData.description,
        previewType: formPreviewData.previewType,
        status: formPreviewData.status,
        securityType: formPreviewData.securityType,
        password: formPreviewData.password,
        noOfViews: formPreviewData.noOfViews,
        avgRating: formPreviewData.avgRating,
        noOfRatings: formPreviewData.noOfRatings,
        ordering: formPreviewData.ordering,
        active: formPreviewData.ordering,
      },
    };

    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        console.log("currentData", currentData);
        if (currentData.id > 0) {
          console.log("contentPreviewID", currentData.id);
          if (croppedPreviewThumbnail) uploadPreviewThumbnail(currentData.id);
        }
        common.notify("S", "Saved Successfully");
        setPreviewPopupOpen(false);
        setIsLoading(false);
        loadContentPreviewsData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const uploadPreviewThumbnail = (previewId) => {
    console.log("uploadPreviewThumbnail");
    // const croppedImage = getCroppedImg();
    setIsLoading(true);
    console.log("payload.croppedImage", croppedImage);

    let payLoad = new FormData();
    payLoad.append("image", croppedImage, "imageUpload.png");
    console.log("payLoad", payLoad.get("image"));
    console.log("formData.id:", formData.id, " promoId:", previewId);

    let data1 = {
      url: "contentPreviews",
      query: "/content/" + formData.id + "/preview/" + previewId,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);

        let currentData = res.data.item;
        console.log("ContentPreviewsData", currentData);
        if (currentData.id > 0) {
          loadContentPreviewsData(formData.id);
          setCroppedPreviewThumbnailPreview(currentData.thumbnailUrl);
        }
        common.notify("S", "Saved Successfully");
        setPreviewPopupOpen(false);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  //Preview Content
  const [previewContentPopupOpen, setPreviewContentPopupOpen] = useState(false);

  const [selectedPreviewContent, setSelectedPreviewContent] = useState({});

  const [formPreviewContentData, setFormPreviewContentData] = useState({
    id: 0,
    userId: null,
    previewId: null,
    contentUrl: null,
    description: null,
    status: null,
    contentType: null,
    contentSource: null,
    active: "Y",
    ordering: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const loadPreviewContentsData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `previewContentAdvanceSearch`,
      query: `?q={"previewId":{"$eq": ${pId}}}&offset=0&limit=10&totalResults=true&orderBy=[["ordering", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("PreviewContent::", res.data.items);
        setFormPreviewContentData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onPreviewContentSelectionChange = useCallback(({ selected }) => {
    console.log("selectedPreviewContent ", selected);
    setSelectedPreviewContent(selected);
  }, []);

  const handleAddPreviewContent = (e) => {
    console.log(
      "handleAddPreviewContent-formPreviewContentData",
      formPreviewContentData,
      " selectedPreviewContent:",
      previewSelected
    );

    e.preventDefault();
    setIsDtlLoading(true);
    let data = {};
    let bodyData = {
      previewId: previewSelected,
      contentUrl: formPreviewContentData.contentUrl,
      description: formPreviewContentData.description,
      status: formPreviewContentData.status,
      contentType: formPreviewContentData.contentType,
      contentSource: formPreviewContentData.contentSource,
      active: formPreviewContentData.active,
      ordering: formPreviewContentData.ordering,
    };
    if (selectedPreviewContent > 0) {
      data = {
        url: "previewContent",
        query: "/" + selectedPreviewContent,
        body: bodyData,
        method: "PATCH",
      };
    } else {
      data = {
        url: "previewContent",
        method: "POST",
        body: bodyData,
      };
    }
    api.call(
      data,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setPreviewContentPopupOpen(false);
        setIsDtlLoading(false);
        loadPreviewContentsData(previewSelected);
        setSelectedPreviewContent(0);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsDtlLoading(false);
      },
      (final) => {}
    );
  };

  const handlePreviewContentPopupClose = () => {
    setPreviewContentPopupOpen(false);
  };

  const handleOnReady = (instance) => {
    setDocLoaded(false);
    const { Core, UI } = instance;
    // adding an event listener for when a document is loaded
    Core.documentViewer.addEventListener("documentLoaded", () => {
      console.log("document loaded");
      setDocLoaded(true);
    });
    /*
  if (instance)
  {
    setDocLoaded(true);
    instance.contentDocument.addEventListener("mouseup", handleMouseUp);
  }
  */
    console.log(instance);
    setInstance(instance);
  };

  const onDeletePreviewContent = async () => {
    if (selectedPreviewContent > 0) {
      const data1 = {
        url: "previewContent",
        query: "/" + selectedPreviewContent,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadPreviewContentsData(previewSelected);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handlePreviewContentFormChange = (val) => {
    const { name, value } = val.target;
    setFormPreviewContentData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onPreviewContentEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log("rowId", rowId);
      console.log(columnId);

      if (rowId > 0) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          [columnId]: value,
        };
        wsData = {
          url: "previewContent",
          query: "/" + rowId,
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            console.log("previewSelected", previewSelected);
            loadPreviewContentsData(previewSelected);
            console.log("formPreviewContentData", formPreviewContentData);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [formData]
  );

  const handlePreviewContentSwitchChange = (event, pId) => {
    const { name, checked } = event.target;
    console.log("handleContentPreviewSwitchChange ", name, checked, pId);

    if (pId > 0) {
      let data1 = {
        url: "previewContent",
        query: "/" + pId,
        body: {
          [name]: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("switchdata", res);
          common.notify("S", "Saved Successfully");
          loadPreviewContentsData(res.data.item.previewId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const previewContentsColumns = [
    {
      name: "status",
      header: "Status",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },
    {
      header: "ContentType",
      name: "contentType",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.contentType}
        </MDTypography>
      ),
    },
    {
      header: "ContentSource",
      name: "contentSource",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.contentSource}
        </MDTypography>
      ),
    },
    {
      header: "ContentUrl",
      name: "contentUrl",
      defaultFlex: 1.75,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.contentUrl}
        </MDTypography>
      ),
    },
    {
      header: "Active",
      name: "active",
      defaultFlex: 0.5,
      headerAlign: "center",
      textAlign: "center",
      render: ({ data, value }) => (
        <FormControlLabel
          control={
            <Switch
              name="active"
              checked={data.active === "Y" ? true : false}
              onChange={(e) => handlePreviewContentSwitchChange(e, data.id)}
              inputProps={{
                "aria-label": "controlled",
                align: "center",
              }}
            />
          }
          label=""
        />
      ),
    },
    {
      name: "ordering",
      header: "Ordering",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },
  ];

  const handlePreviewContentPopupClickOpen = () => {
    setFormPreviewData({
      id: 0,
      userId: null,
      previewId: null,
      contentUrl: null,
      description: null,
      status: null,
      contentType: null,
      contentSource: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setPreviewContentPopupOpen(true);
  };

  const handlePreviewContentFormSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked);
    setFormPreviewContentData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
    console.log(
      "handlePreviewContentFormSwitchChange-formPreviewContentData ",
      formPreviewContentData
    );
  };

  //Preview Reviews
  const [previewReviewsPopupOpen, setPreviewReviewsPopupOpen] = useState(false);

  const [selectedPreviewReviews, setSelectedPreviewReviews] = useState({});

  const [formPreviewReviewsData, setFormPreviewReviewsData] = useState({
    id: 0,
    userId: null,
    previewId: null,
    rating: null,
    comments: null,
    parentId: null,
    noOfLikes: null,
    active: null,
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
  });

  const loadPreviewReviewsData = (pId) => {
    setIsLoading(true);
    const data1 = {
      method: "GET",
      url: `previewReviewsAdvanceSearch`,
      query: `?q={"previewId":{"$eq": ${pId}}}&offset=0&limit=10&totalResults=true&orderBy=[["id", "ASC"]]`,
    };
    api.call(
      data1,
      (res) => {
        console.log("PreviewReviews::", res.data.items);
        setFormPreviewReviewsData(res.data.items);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };

  const onPreviewReviewsSelectionChange = useCallback(({ selected }) => {
    console.log("selectedPreviewReviews ", selected);
    setSelectedPreviewReviews(selected);
  }, []);

  const handleAddPreviewReviews = (e) => {
    console.log(
      "handleAddPreviewReviews-formPreviewReviewsData",
      formPreviewReviewsData,
      " selectedPreviewReviews:",
      previewSelected
    );

    e.preventDefault();
    setIsDtlLoading(true);
    let data = {};
    let bodyData = {
      previewId: previewSelected,
      userId: formPreviewReviewsData.userId,
      rating: formPreviewReviewsData.rating,
      comments: formPreviewReviewsData.comments,
      parentId: formPreviewReviewsData.parentId,
      noOfLikes: formPreviewReviewsData.noOfLikes,
      active: formPreviewReviewsData.active,
    };
    if (selectedPreviewReviews > 0) {
      data = {
        url: "previewReviews",
        query: "/" + selectedPreviewReviews,
        body: bodyData,
        method: "PATCH",
      };
    } else {
      data = {
        url: "previewReviews",
        method: "POST",
        body: bodyData,
      };
    }
    api.call(
      data,
      (res) => {
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setPreviewReviewsPopupOpen(false);
        setIsDtlLoading(false);
        loadPreviewReviewsData(currentData.previewId);
        setSelectedPreviewReviews(0);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsDtlLoading(false);
      },
      (final) => {}
    );
  };

  const handlePreviewReviewsPopupClose = () => {
    setPreviewReviewsPopupOpen(false);
  };

  const onDeletePreviewReviews = async () => {
    if (selectedPreviewReviews > 0) {
      const data1 = {
        url: "previewReviews",
        query: "/" + selectedPreviewReviews,
        method: "DELETE",
      };
      api.call(
        data1,
        (res) => {
          let currentData = res.data.item;
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadPreviewReviewsData(currentData.previewId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    }
  };

  const handlePreviewReviewsFormChange = (val) => {
    const { name, value } = val.target;
    setFormPreviewReviewsData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onPreviewReviewsEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log("rowId", rowId);
      console.log(columnId);

      if (rowId > 0) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          [columnId]: value,
        };
        wsData = {
          url: "previewReviews",
          query: "/" + rowId,
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            console.log("previewSelected", previewSelected);
            loadPreviewReviewsData(previewSelected);
            console.log("formPreviewReviewsData", formPreviewReviewsData);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => {}
        );
      }
    },
    [formData]
  );

  const handlePreviewReviewsSwitchChange = (event, pId) => {
    const { name, checked } = event.target;
    console.log("handleContentPreviewSwitchChange ", name, checked, pId);

    if (pId > 0) {
      let data1 = {
        url: "previewReviews",
        query: "/" + pId,
        body: {
          [name]: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("previewReviewsData", res);
          common.notify("S", "Saved Successfully");
          loadPreviewReviewsData(res.data.item.previewId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const previewReviewsColumns = [
    {
      name: "userId",
      header: "User",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },
    {
      header: "Rating",
      name: "rating",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.rating}
        </MDTypography>
      ),
    },
    {
      header: "Comments",
      name: "comments",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.comments}
        </MDTypography>
      ),
    },
    {
      header: "NoOfLikes",
      name: "noOfLikes",
      defaultFlex: 0.5,
      render: ({ data, value }) => (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {data.noOfLikes}
        </MDTypography>
      ),
    },
    {
      header: "Active",
      name: "active",
      defaultFlex: 0.5,
      headerAlign: "center",
      textAlign: "center",
      render: ({ data, value }) => (
        <FormControlLabel
          control={
            <Switch
              name="active"
              checked={data.active === "Y" ? true : false}
              onChange={(e) => handlePreviewReviewsSwitchChange(e, data.id)}
              inputProps={{
                "aria-label": "controlled",
                align: "center",
              }}
            />
          }
          label=""
        />
      ),
    },
    {
      name: "ParentId",
      header: "parentId",
      defaultFlex: 0.4,
      headerAlign: "center",
      textAlign: "center",
    },
  ];

  const handlePreviewReviewsPopupClickOpen = () => {
    setFormPreviewData({
      id: 0,
      userId: null,
      previewId: null,
      contentUrl: null,
      description: null,
      status: null,
      contentType: null,
      contentSource: null,
      ordering: null,
      active: "Y",
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      lastUpdateLogin: null,
      versionNumber: null,
    });
    setPreviewReviewsPopupOpen(true);
  };

  const handlePreviewReviewsFormSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked);
    setFormPreviewReviewsData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
    console.log(
      "handlePreviewReviewsFormSwitchChange-formPreviewReviewsData ",
      formPreviewReviewsData
    );
  };

  useEffect(() => {
    loadPromoTypeList();
    loadContentRatingList();
    loadAgeRatingList();
    loadContentTypeList();
    loadGenresList();
    loadCrewsList();
    loadCastsList();
    loadLanguagesList();
    loadFormatsList();
    loadDurationTypeList();
    loadDurationUomList();
    loadRightsList();
    loadPartiesList();
    loadRegionsList();
    loadSubTitlesList();
    loadSettings("Content", "primaryImage");

    if (id) {
      loadData(id);
    }
  }, []);

  const handleSelectChange = (val) => {
    const { name, value } = val.target;
    console.log("name :" + name + " value:" + value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    console.log("name :" + name + " value:" + value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleContentTitleMetaChange = (val) => {
    const { name, value } = val.target;
    console.log("name :" + name + " value:" + value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ["metaTitle"]: value,
      };
    });
  };
  const handleContentDescMetaChange = (val) => {
    const { name, value } = val.target;
    console.log("name :" + name + " value:" + value);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
        ["metaDesc"]: value,
      };
    });
  };

  const handleSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleSwitchIsSoldChange = (event, pId) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;

    if (pId > 0) {
      let data1 = {
        url: "contentRights",
        query: "/" + pId,
        body: {
          isSold: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("switchdata", res);
          common.notify("S", "Saved Successfully");
          loadContentRightsData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const handleSwitchExclusiveRightChange = (event, pId) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;

    if (pId > 0) {
      let data1 = {
        url: "contentRights",
        query: "/" + pId,
        body: {
          exclusiveRight: checked ? "Y" : null,
        },
        method: "PATCH",
      };

      api.call(
        data1,
        (res) => {
          console.log("switchdata", res);
          common.notify("S", "Saved Successfully");
          loadContentRightsData(res.data.item.contentId);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const handleSubmit = (e, sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    if (formData.id > 0)
      data1 = {
        url: "content",
        query: "/" + formData.id,
        body: {
          title: formData.title,
          titleEn: formData.titleEn,
          description: formData.description,
          contentType: formData.contentType,
          releaseDate: formData.releaseDate,
          startDate: formData.startDate,
          endDate: formData.endDate,
          runningTimeSecs: formData.runningTimeSecs,
          ageRating: formData.ageRating,
          avgRating: formData.avgRating,
          noOfRatings: formData.noOfRatings,
          contentRating: formData.contentRating,
          website: formData.website,
          views: formData.views,
          hits: formData.hits,
          channelId: formData.channelId,
          parentId: formData.parentId,
          featured: formData.featured,
          yearProduced: formData.yearProduced,
          yearReleased: formData.yearReleased,
          countryOfProduction: formData.countryOfProduction,
          promoColor: formData.promoColor,
          promoText: formData.promoText,
          primaryImage: formData.primaryImage,
          thumbnail: formData.thumbnail,
          metaTitle: formData.metaTitle,
          metaKeywords: formData.metaKeywords,
          metaDesc: formData.metaDesc,
          authorizationFilePath: formData.authorizationFilePath,
          active: formData.active,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "content",
        method: "POST",
        body: {
          title: formData.title,
          titleEn: formData.titleEn,
          description: formData.description,
          contentType: formData.contentType,
          releaseDate: formData.releaseDate,
          startDate: formData.startDate,
          endDate: formData.endDate,
          runningTimeSecs: formData.runningTimeSecs,
          ageRating: formData.ageRating,
          avgRating: formData.avgRating,
          noOfRatings: formData.noOfRatings,
          contentRating: formData.contentRating,
          website: formData.website,
          views: formData.views,
          hits: formData.hits,
          channelId: formData.channelId,
          parentId: formData.parentId,
          featured: formData.featured,
          yearProduced: formData.yearProduced,
          yearReleased: formData.yearReleased,
          countryOfProduction: formData.countryOfProduction,
          promoColor: formData.promoColor,
          promoText: formData.promoText,
          primaryImage: formData.primaryImage,
          thumbnail: formData.thumbnail,
          metaTitle: formData.metaTitle,
          metaKeywords: formData.metaKeywords,
          metaDesc: formData.metaDesc,
          authorizationFilePath: formData.authorizationFilePath,
          active: formData.active,
        },
      };

    console.log("Request Save data1:", data1);
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        console.log("Response currentData:", currentData);
        setFormData(currentData);
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0) {
          if (croppedPrimaryImage) uploadContentPrimaryImage();
          if (croppedThumbnail) uploadContentThumbnail();
          //  loadData(currentData.id)
        }
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if (sourceLabel === "Save") navigate("/contentlist");
      }
    );
  };

  //Content Primary Image & Thumbnail - Start
  const uploadContentPrimaryImage = () => {
    setIsLoading(true);
    console.log("formData.primaryImage", formData.primaryImage);
    console.log("croppedPrimaryImage..", croppedPrimaryImage);
    console.log("croppedPrimaryImagePreview..", croppedPrimaryImagePreview);

    let payLoad = new FormData();
    payLoad.append("image", croppedPrimaryImage, "imageUpload.png");
    console.log("payLoad", payLoad.get("image"));
    let data1 = {
      url: "content",
      query: "/uploadPrimaryImage/" + formData.id,
      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) {
          formData.primaryImage = currentData.primaryImage;
          setCroppedPrimaryImagePreview(currentData.primaryImage);
        }
        common.notify("S", "Saved Successfully");
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    // setIsLoading(false);
  };

  const uploadContentThumbnail = (croppedFormData) => {
    setIsLoading(true);
    console.log("formData.thumbnail", formData.thumbnail);
    console.log("croppedThumbnailPreview", croppedThumbnailPreview);
    console.log("croppedThumbnail", croppedThumbnail);

    let payLoad = new FormData();
    payLoad.append("image", croppedThumbnail, "imageUpload.png");
    // payLoad.image=croppedFormData
    let data1 = {
      url: "content",
      query: "/uploadThumbnail/" + formData.id,

      method: "POST",
      body: payLoad,
      cType: 6,
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData.id > 0) {
          formData.thumbnail = currentData.thumbnail;
          setCroppedThumbnailPreview(currentData.thumbnail);
        }
        formData.thumbnail = currentData.thumbnail;
        common.notify("S", "Saved Successfully");
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {}
    );
    // common.notify("S", "Saved Successfully");
    setIsLoading(false);
  };

  // //Handler for crop popup
  // const handleUploadPrimaryImage = (e) => {
  //   console.log("handleUploadPromoThumbnail")
  //   const croppedImage = getCroppedImg();
  //   console.log("croppedImage::", croppedImage)
  //   formData.primaryImage = formData.croppedImage;
  //   console.log("primaryImage", formData.primaryImage)
  //   e.preventDefault();
  //   setIsLoading(true);
  //   // uploadContentPrimaryImage(croppedImage);
  //   setUploadPrimaryImagePopupOpen(false);

  // }
  // //Handler for crop popup
  // const handleUploadThumbnail = (e) => {
  //   console.log("handleUploadThumbnail")
  //   // cropImageNow();
  //   const croppedImage = getCroppedImg();
  //   formData.thumbnail = formData.croppedImage;
  //   console.log("thumbnail", formData.thumbnail)
  //   e.preventDefault();
  //   setIsLoading(true);
  //   uploadContentThumbnail(croppedImage);
  //   setUploadThumbnailPopupOpen(false);
  // }

  // const handleUploadPrimaryImagePopup = (e) => {
  //   setCrop({
  //     unit: 'px', // Can be 'px' or '%'
  //     x: 0,
  //     y: 0,
  //     width: 375,
  //     height: 392
  //   });
  //   onUploadFile(e);
  //   setUploadPrimaryImagePopupOpen(true);
  // };

  // const handleUploadPrimaryImagePopupClose = () => {
  //   setUploadPrimaryImagePopupOpen(false);
  // };

  // const handleUploadThumbnailPopup = (e) => {
  //   setCrop({
  //     unit: 'px', // Can be 'px' or '%'
  //     x: 0,
  //     y: 0,
  //     width: 350,
  //     height: 164
  //   });
  //   onUploadFile(e);
  //   setUploadThumbnailPopupOpen(true);
  // };

  // const handleUploadThumbnailPopupClose = () => {
  //   setUploadThumbnailPopupOpen(false);
  // };

  //Content Primary Image & Thumbnail - end

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "content",
      method: "GET",
      query: "/" + pId,
    };

    api.call(
      data1,
      (res) => {
        console.log("Get Content Detail Call", res.data.item);
        let currentData = res.data.item;
        currentData.releaseDate = moment(currentData?.releaseDate).format("YYYY-MM-DD");
        currentData.startDate = moment(currentData?.startDate).format("YYYY-MM-DD");
        currentData.endDate = moment(currentData?.endDate).format("YYYY-MM-DD");
        setFormData(currentData);
        //Content PrimaryImage
        setCroppedPrimaryImagePreview(currentData?.primaryImage);
        setCroppedThumbnailPreview(currentData?.thumbnail);

        loadContentGenresData(currentData.id);
        loadContentCastsData(currentData.id);
        loadContentCrewsData(currentData.id);
        loadContentLanguagesData(currentData.id);
        loadContentFormatsData(currentData.id);
        loadContentRegionsData(currentData.id);
        loadContentPartiesData(currentData.id);
        loadContentRightsData(currentData.id);
        loadContentPromosData(currentData.id);
        loadContentImagesData(currentData.id);
        loadContentReviewsData(currentData.id);
        loadContentSubTitlesData(currentData.id);
        loadContentPreviewsData(currentData.id);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => {}
    );
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3} py={1} px={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Content
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={0} py={0}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="basic tabs example"
                    indicatorColor="secondary"
                    textColor="inherit"
                    // variant="fullWidth"
                  >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Images" {...a11yProps(1)} />
                    <Tab label="Promos" {...a11yProps(2)} />
                    <Tab label="Cast" {...a11yProps(3)} />
                    <Tab label="Crew" {...a11yProps(4)} />
                    <Tab label="Languages" {...a11yProps(5)} />
                    <Tab label="Genres" {...a11yProps(6)} />
                    <Tab label="Formats" {...a11yProps(7)} />
                    <Tab label="Reviews" {...a11yProps(8)} />
                    <Tab label="Right Types" {...a11yProps(9)} />
                    <Tab label="Parties" {...a11yProps(10)} />
                    <Tab label="Regions" {...a11yProps(11)} />
                    <Tab label="SubTitles" {...a11yProps(12)} />
                    <Tab label="Previews" {...a11yProps(13)} />
                  </Tabs>
                  <TabPanel value={tabValue} index={0}>
                    <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                      <MDBox mb={2} mt={2} px={0} py={0}>
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              {/* <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                size="sm"
                                badgeContent={
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    size="xxl"
                                    // onClick={handleUploadPrimaryImagePopup}
                                    onChange={handleUploadPrimaryImagePopup}
                                  >
                                    <input hidden accept="image/*" type="file" />
                                    <CloudUploadRounded />
                                  </IconButton>
                                }
                              >
                                <PrimaryImage
                                  src={formData.primaryImage}
                                  variant="square"
                                  shadow="sm"
                                  // onClick={handleUploadPrimaryImagePopup}
                                ></PrimaryImage>
                              </StyledBadge>

                              <Dialog
                                open={uploadPrimaryImagePopupOpen}
                                onClose={handleUploadPrimaryImagePopupClose}
                              >
                                <DialogTitle>Upload Primary Image</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>Only PNG/JPG</DialogContentText>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      {imageToCrop && (
                                        <div>
                                          <ReactCrop
                                            src={imageToCrop}
                                            onImageLoaded={setImage}
                                            crop={crop}
                                            onChange={setCrop}
                                            minWidth={120}
                                            minHeight={140}
                                            maxWidth={120}
                                            maxHeight={140}
                                            locked={true}
                                          >
                                            <img
                                              src={imageToCrop}
                                              onLoad={(e) => {
                                                setImageRef(e.target);
                                              }}
                                            />
                                          </ReactCrop>
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleUploadPrimaryImagePopupClose}>
                                    Cancel
                                  </MDButton>
                                  <MDButton onClick={handleUploadPrimaryImage}>Ok</MDButton>
                                </DialogActions>
                              </Dialog> */}
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                size="sm"
                                badgeContent={
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    size="xl"
                                    onChange={(e) => imageUploadHandle(e, "primaryImage")}
                                  >
                                    <input
                                      hidden
                                      accept="image/*"
                                      type="file"
                                      id="profileImage"
                                      ref={profileImageInput}
                                    />
                                    <CloudUploadRounded />
                                  </IconButton>
                                }
                              >
                                <PrimaryImage
                                  src={croppedPrimaryImagePreview} //{formData.image}
                                  variant="square"
                                  size="xxl"
                                  shadow="sm"
                                ></PrimaryImage>
                              </StyledBadge>

                              {toggleCropModal && (
                                <CropModal>
                                  <ImageCropper
                                    uploadFile={uploadFile}
                                    getCroppedFile={getCroppedPrimaryImageFile}
                                    imageRatio={imageRatio}
                                    cropShape="rect"
                                    uploadFileType="image"
                                    closeCropPopup={() => {
                                      setToggleCropModal(false);
                                      if (croppedPrimaryImage)
                                        formData.primaryImage =
                                          URL.createObjectURL(croppedPrimaryImage);
                                    }}
                                    uploadImgDelete={uploadImgDelete}
                                  />
                                </CropModal>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={5} lg={5} xl={7}>
                            <Grid container spacing={1} mt={1}>
                              <Grid item xs={3}>
                                <MDInput
                                  id="contentType"
                                  type="search"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  select
                                  fullWidth
                                  name="contentType"
                                  label="Content Type"
                                  value={formData.contentType}
                                  onChange={handleSelectChange}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  {contentTypeList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </MDInput>
                              </Grid>
                              <Grid item xs={3}>
                                <MDInput
                                  type="text"
                                  disabled
                                  label="Content Key"
                                  name="contentKey"
                                  value={formData.contentKey}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name="active"
                                      checked={formData.active ? "Y" : null}
                                      onChange={handleSwitchChange}
                                      inputProps={{ "aria-label": "controlled" }}
                                    />
                                  }
                                  label="Active"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name="featured"
                                      checked={formData.featured ? "Y" : null}
                                      onChange={handleSwitchChange}
                                      inputProps={{ "aria-label": "controlled" }}
                                    />
                                  }
                                  label="Featured"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                              <Grid item xs={6}>
                                <MDInput
                                  type="text"
                                  label="Original Title"
                                  name="title"
                                  value={formData.title}
                                  onChange={handleContentTitleMetaChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MDInput
                                  type="text"
                                  label="English Title"
                                  name="titleEn"
                                  value={formData.titleEn}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                              <Grid item xs={12}>
                                <MDInput
                                  type="text"
                                  label="Description"
                                  multiline
                                  rows={4}
                                  name="description"
                                  value={formData.description}
                                  onChange={handleContentDescMetaChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                              <Grid item xs={3}>
                                <MDInput
                                  type="number"
                                  label="Year Produced"
                                  name="yearProduced"
                                  value={formData.yearProduced}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <MDInput
                                  type="number"
                                  label="Year Released"
                                  name="yearReleased"
                                  value={formData.yearReleased}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <div className="selectWrapper">
                                  <label>Country Of Production</label>
                                  <AsyncSelect
                                    label="Country Of Production"
                                    styles={customSelectStyles}
                                    classNamePrefix="cus-select"
                                    placeholder="Type atleast 2 chars to search country"
                                    cacheOptions
                                    fullWidth
                                    value={{
                                      name: formData.countryOfProduction,
                                      code: formData.countryOfProduction,
                                    }}
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.code}
                                    loadOptions={loadProductionCountryOptions}
                                    onInputChange={(value) => setProductionCountryQuery(value)}
                                    onChange={onProductionCountryChange}
                                    sx={{ width: "18%", mr: 1, mt: 2 }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                              <Grid item xs={3}>
                                <MDInput
                                  type="text"
                                  label="Running Time Secs"
                                  name="runningTimeSecs"
                                  value={formData.runningTimeSecs}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <MDInput
                                  type="text"
                                  label="Views"
                                  name="views"
                                  value={formData.views}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <MDInput
                                  type="text"
                                  label="Hits"
                                  name="hits"
                                  value={formData.hits}
                                  onChange={handleChange}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              {/* <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                size="sm"
                                badgeContent={
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    // onClick={handleUploadPrimaryImagePopup}
                                    onChange={handleUploadThumbnailPopup}
                                  >
                                    <input hidden accept="image/*" type="file" />
                                    <CloudUploadRounded />
                                  </IconButton>
                                }
                              >
                                <Thumbnail
                                  src={formData.thumbnail}
                                  variant="square"
                                  size="xxl"
                                  shadow="sm"
                                  // onClick={handleUploadPrimaryImagePopup}
                                ></Thumbnail>
                              </StyledBadge>
                              <Dialog
                                open={uploadThumbnailPopupOpen}
                                onClose={handleUploadThumbnailPopupClose}
                              >
                                <DialogTitle>Upload Thumbnail</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>Only PNG/JPG</DialogContentText>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      {imageToCrop && (
                                        <div>
                                          <ReactCrop
                                            src={imageToCrop}
                                            onImageLoaded={setImage}
                                            crop={crop}
                                            onChange={setCrop}
                                            minWidth={162}
                                            minHeight={115}
                                            maxWidth={162}
                                            maxHeight={115}
                                            locked={true}
                                          >
                                            <img
                                              src={imageToCrop}
                                              onLoad={(e) => {
                                                setImageRef(e.target);
                                              }}
                                            />
                                          </ReactCrop>
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleUploadThumbnailPopupClose}>
                                    Cancel
                                  </MDButton>
                                  <MDButton onClick={handleUploadThumbnail}>Ok</MDButton>
                                </DialogActions>
                              </Dialog> */}
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                size="sm"
                                badgeContent={
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    size="xl"
                                    onChange={(e) => imageUploadHandle(e, "thumbnail")}
                                  >
                                    <input
                                      hidden
                                      accept="image/*"
                                      type="file"
                                      id="profileImage"
                                      ref={profileImageInput}
                                    />
                                    <CloudUploadRounded />
                                  </IconButton>
                                }
                              >
                                <Thumbnail
                                  src={croppedThumbnailPreview} //{formData.image}
                                  variant="square"
                                  size="xxl"
                                  shadow="sm"
                                ></Thumbnail>
                              </StyledBadge>

                              {toggleCropModal && (
                                <CropModal>
                                  <ImageCropper
                                    uploadFile={uploadFile}
                                    getCroppedFile={getCroppedThumbnailFile}
                                    imageRatio={imageRatio}
                                    cropShape="rect"
                                    uploadFileType="image"
                                    closeCropPopup={() => {
                                      setToggleCropModal(false);
                                      if (croppedThumbnail)
                                        formData.thumbnail = URL.createObjectURL(croppedThumbnail);
                                    }}
                                    uploadImgDelete={uploadImgDelete}
                                  />
                                </CropModal>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={5} lg={5} xl={7}>
                            <MDTypography variant="h5" mt={2}>
                              Dates
                            </MDTypography>
                            <Grid container spacing={1} mt={2}>
                              {/* <Grid item xs={3}>
                          <MDInput type="text" label="Content Type" name="contentType" value={formData.contentType} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid> */}
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid item xs={6} xl={4}>
                                  <MDInput
                                    type="date"
                                    name="releaseDate"
                                    label="Release Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.releaseDate}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={6} xl={4}>
                                  <MDInput
                                    type="date"
                                    name="startDate"
                                    label="Start Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.startDate}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={6} xl={4}>
                                  <MDInput
                                    type="date"
                                    name="endDate"
                                    label="End Date"
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.endDate}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                        <MDTypography variant="h5" mt={2}>
                          Ratings
                        </MDTypography>
                        <Grid container spacing={1} mt={2}>
                          {/* <Grid item xs={3}>
                            <MDInput
                              id="contentRating"
                              type="search"
                              SelectProps={{
                                native: true,
                              }}
                              select
                              fullWidth
                              name="contentRating"
                              label="Content Rating"
                              value={formData.contentRating}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                            >
                              <option value="U/A">U/A</option>
                              <option value="A">Adults Only</option>
                              <option value="PG-13">PG-13</option>
                            </MDInput>
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="text"
                              label="Age Rating"
                              name="ageRating"
                              value={formData.ageRating}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid> */}
                          <Grid item xs={3}>
                            <div className="selectWrapper">
                              <label>Content Rating</label>
                              <Select
                                id="contentRating"
                                label="contentRating"
                                name="contentRating"
                                classNamePrefix="cus-select"
                                onChange={handleContentRatingChange}
                                value={contentRatingListData.find(
                                  (i) => i.lookupCode == formData.contentRating //compare string with number value
                                )}
                                options={contentRatingListData}
                                getOptionLabel={(e) => e.lookupCode}
                                getOptionValue={(e) => e.lookupCode}
                                // className="custom-select-box basic-single"
                                placeholder="Select ContentRating"
                                fullWidth
                                menuPosition="fixed"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="selectWrapper">
                              <label>Age Rating</label>
                              <Select
                                id="ageRating"
                                label="ageRating"
                                name="ageRating"
                                classNamePrefix="cus-select"
                                onChange={handleAgeRatingChange}
                                value={ageRatingListData.find(
                                  (i) => i.lookupCode == formData.ageRating //compare string with number value
                                )}
                                options={ageRatingListData}
                                getOptionLabel={(e) => e.lookupCode}
                                getOptionValue={(e) => e.lookupCode}
                                // className="custom-select-box basic-single"
                                placeholder="Select ageRating"
                                fullWidth
                                menuPosition="fixed"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="text"
                              label="Avg Rating"
                              name="avgRating"
                              value={formData.avgRating}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <MDInput
                              type="text"
                              label="No Of Ratings"
                              name="noOfRatings"
                              value={formData.noOfRatings}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <MDTypography variant="h5" mt={2}>
                          Other Info
                        </MDTypography>
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={6}>
                            <MDInput
                              type="text"
                              label="Website"
                              name="website"
                              value={formData.website}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={1.5}>
                            <MDInput
                              type="text"
                              label="channelId"
                              name="channelId"
                              value={formData.channelId}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <MDInput
                              type="text"
                              label="Parent Id"
                              name="parentId"
                              value={formData.parentId}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={2}>
                            <MDInput
                              type="color"
                              label="Promo Color"
                              name="promoColor"
                              value={formData.promoColor}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <MDInput
                              type="text"
                              label="Promo Text"
                              name="promoText"
                              value={formData.promoText}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <MDTypography variant="h5" mt={2}>
                          Meta Details
                        </MDTypography>

                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={6}>
                            <MDInput
                              type="text"
                              label="Meta Title"
                              name="metaTitle"
                              value={formData.metaTitle}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <MDInput
                              type="text"
                              label="Meta Keywords"
                              name="metaKeywords"
                              value={formData.metaKeywords}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={12}>
                            <MDInput
                              type="text"
                              label="Meta Desc"
                              multiline
                              rows={4}
                              name="metaDesc"
                              value={formData.metaDesc}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={6}>
                            <p>
                              Upload AuthorizationFile:
                              {/* {formData.authorizationFilePath && (
                                <Link1 href={formData.authorizationFilePath} variant="body2">
                                  Uploaded authorization file
                                </Link1>
                              )} */}
                            </p>
                            <StyledBadge
                              overlap="rectangular"
                              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                              size="sm"
                              badgeContent={
                                <IconButton
                                  color="primary"
                                  aria-label="upload AuthorizationFile"
                                  component="label"
                                  size="xl"
                                  onChange={handleAuthorizationFilePath}
                                >
                                  <input hidden accept="*/*" type="file" />
                                  <CloudUploadRounded />
                                </IconButton>
                              }
                            >
                              <Image
                                src={formData.authorizationFilePath}
                                variant="square"
                                size="xxl"
                                shadow="sm"
                              ></Image>
                            </StyledBadge>

                            {formData.authorizationFilePath && (
                              <Button variant="text" onClick={handleOpen}>
                                Click to open authorization file
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                        <MDButton
                          variant="gradient"
                          color="success"
                          sx={{ mr: 1 }}
                          type="submit"
                          disabled={formError}
                          onClick={(e) => handleSubmit(e, "Apply")}
                        >
                          {!isLoading ? "Apply" : "Processing..."}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="info"
                          sx={{ mr: 1 }}
                          type="submit"
                          disabled={formError}
                          onClick={(e) => handleSubmit(e, "Save")}
                        >
                          {!isLoading ? "Save" : "Processing..."}
                        </MDButton>
                        <MDButton
                          variant="gradient"
                          color="warning"
                          component={Link}
                          to="/contentlist"
                        >
                          Cancel
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleImagePopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {imageSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteImages}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={imagePopupOpen} onClose={handleImagePopupClose}>
                                <DialogTitle>Add image to content</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>Image</DialogContentText>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        id="contentRating"
                                        type="search"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        select
                                        fullWidth
                                        name="imageType"
                                        label="Content ImageType"
                                        value={formData.imageType}
                                        onChange={handleImageFormChange}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        <option value="Poster">Poster</option>
                                      </MDInput>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    {/* <Grid item xs={12}>
                                      <MDInput
                                        type="file"
                                        label="Image Url"
                                        name="imageUrl"
                                        value={formImageData.imageUrl}
                                        onChange={handleImageFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                      {/* <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            // onClick={handleUploadImagePopup}
                                            onChange={handleUploadImagePopup}
                                          >
                                            <input hidden accept="image/*" type="file" />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <Image
                                          src={formData.image}
                                          variant="square"
                                          size="xxl"
                                          shadow="sm"
                                          // onClick={handleUploadImagePopup}
                                        ></Image>
                                      </StyledBadge>

                                      <Dialog
                                        open={uploadImagePopupOpen}
                                        onClose={handleUploadImagePopupClose}
                                      >
                                        <DialogTitle>Upload Avatar</DialogTitle>
                                        <DialogContent>
                                          <DialogContentText>
                                            Only PNG/JPG Allowed
                                          </DialogContentText>
                                          <Grid container spacing={1} mt={2}>
                                            <Grid item xs={12}>
                                              {imageToCrop && (
                                                <div>
                                                  <ReactCrop
                                                    src={imageToCrop}
                                                    onImageLoaded={setImage}
                                                    crop={crop}
                                                    onChange={setCrop}
                                                    circularCrop={false}
                                                    minWidth={100}
                                                    minHeight={100}
                                                    maxWidth={100}
                                                    maxHeight={100}
                                                    locked={true}
                                                  >
                                                    <img
                                                      src={imageToCrop}
                                                      style={{ transform: `scale(${scale})` }}
                                                      onLoad={(e) => {
                                                        setImageRef(e.target);
                                                      }}
                                                    />
                                                  </ReactCrop>
                                                </div>
                                              )}
                                              <MDBox width={300}>
                                                <Slider
                                                  size="small"
                                                  defaultValue={100}
                                                  onChange={(e) =>
                                                    setScale(Number(e.target.value) / 100)
                                                  }
                                                  aria-label="Small"
                                                  valueLabelDisplay="auto"
                                                  min={50}
                                                  max={200}
                                                />
                                              </MDBox>
                                            </Grid>
                                          </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                          <MDButton onClick={handleUploadImagePopupClose}>
                                            Cancel
                                          </MDButton>
                                          <MDButton onClick={handleUploadImage}>Ok</MDButton>
                                        </DialogActions>
                                      </Dialog> */}

                                      <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            onChange={(e) => imageUploadHandle(e, "primaryImage")}
                                          >
                                            <input
                                              hidden
                                              accept="image/*"
                                              type="file"
                                              id="profileImage"
                                              ref={profileImageInput}
                                            />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <Image
                                          src={croppedImagePreview} //{formData.image}
                                          variant="square"
                                          size="xxl"
                                          shadow="sm"
                                        ></Image>
                                      </StyledBadge>

                                      {toggleCropModal && (
                                        <CropModal>
                                          <ImageCropper
                                            uploadFile={uploadFile}
                                            getCroppedFile={getCroppedImageFile}
                                            imageRatio={imageRatio}
                                            cropShape="rect"
                                            uploadFileType="image"
                                            closeCropPopup={() => {
                                              setToggleCropModal(false);
                                              if (croppedImage)
                                                formData.imageUrl =
                                                  URL.createObjectURL(croppedImage);
                                            }}
                                            uploadImgDelete={uploadImgDelete}
                                          />
                                        </CropModal>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {/* <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="String"
                                        label="Image Size"
                                        name="imageSize"
                                        value={formImageData.imageSize}
                                        onChange={handleImageFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} mt={2}>
                                    <MDInput
                                      type="String"
                                      label="Image Title"
                                      name="imageTitle"
                                      value={formImageData.imageTitle}
                                      onChange={handleImageFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12} mt={2}>
                                    <MDInput
                                      type="String"
                                      label="Image Type"
                                      name="imageType"
                                      value={formImageData.imageType}
                                      onChange={handleImageFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="String"
                                        label="Description"
                                        name="description"
                                        value={formImageData.description}
                                        onChange={handleImageFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleImagePopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddImage}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={imageSelected}
                                checkboxColumn
                                style={gridStyle}
                                multiSelect={false}
                                onSelectionChange={onImageSelectionChange}
                                editable
                                rowHeight={80}
                                showZebraRows
                                showHoverRows
                                onEditComplete={onImageEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Image Title",
                                    name: "imageTitle",
                                    defaultFlex: 2.0,
                                    render: ({ data, value }) => (
                                      <MDBox ml={2} lineHeight={2}>
                                        <MDAvatar
                                          src={data.imageUrl}
                                          name={value}
                                          size="md"
                                          variant="square"
                                        />
                                        <MDTypography variant="caption">
                                          {" "}
                                          {data.imageTitle}{" "}
                                        </MDTypography>
                                      </MDBox>
                                    ),
                                  },

                                  {
                                    header: "Image Type",
                                    name: "imageType",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBox display="flex" alignItems="center" lineHeight={2}>
                                        {/* <MDAvatar src={data.imageUrl} name={value} size="md" variant="square" /> */}
                                        <MDBox ml={2} lineHeight={2}>
                                          <MDBadge badgeContent={data.imageType} />
                                        </MDBox>
                                      </MDBox>
                                    ),
                                  },

                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Image Url",
                                    name: "imageUrl",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.imageUrl}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Image Size",
                                    name: "imageSize",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.imageSize}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentImagesData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handlePromoPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {promoSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeletePromos}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={promoPopupOpen} onClose={handlePromoPopupClose}>
                                <DialogTitle>Add promo to content</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>Promo</DialogContentText>
                                  <Grid container spacing={1} mt={2}>
                                    {/* <Grid item xs={12}>
                                      <MDInput
                                        type="file"
                                        label="Promo Thumbnail"
                                        name="thumbnail"
                                        value={formPromoData.thumbnail}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                      {/* <StyledBadge
                                        overlap="square"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            // onClick={handleUploadPromoThumbnailPopup}
                                            onChange={handleUploadPromoThumbnailPopup}
                                          >
                                            <input hidden accept="image/*" type="file" />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <PromoThumbnail
                                          src={formData.image}
                                          variant="circular"
                                          size="xxl"
                                          shadow="sm"
                                          // onClick={handleUploadPromoThumbnailPopup}
                                        ></PromoThumbnail>
                                      </StyledBadge>

                                      <Dialog
                                        open={uploadPromoThumbnailPopupOpen}
                                        onClose={handleUploadPromoThumbnailPopupClose}
                                      >
                                        <DialogTitle>Upload Avatar</DialogTitle>
                                        <DialogContent>
                                          <DialogContentText>
                                            Only PNG/JPG Allowed
                                          </DialogContentText>
                                          <Grid container spacing={1} mt={2}>
                                            <Grid item xs={12}>
                                              {imageToCrop && (
                                                <div>
                                                  <ReactCrop
                                                    src={imageToCrop}
                                                    onImageLoaded={setImage}
                                                    crop={crop}
                                                    onChange={setCrop}
                                                    circularCrop={false}
                                                    minWidth={100}
                                                    minHeight={100}
                                                    maxWidth={100}
                                                    maxHeight={100}
                                                    locked={true}
                                                  >
                                                    <img
                                                      src={imageToCrop}
                                                      style={{ transform: `scale(${scale})` }}
                                                      onLoad={(e) => {
                                                        setImageRef(e.target);
                                                      }}
                                                    />
                                                  </ReactCrop>
                                                </div>
                                              )}
                                              <MDBox width={300}>
                                                <Slider
                                                  size="small"
                                                  defaultValue={100}
                                                  onChange={(e) =>
                                                    setScale(Number(e.target.value) / 100)
                                                  }
                                                  aria-label="Small"
                                                  valueLabelDisplay="auto"
                                                  min={50}
                                                  max={200}
                                                />
                                              </MDBox>
                                            </Grid>
                                          </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                          <MDButton onClick={handleUploadPromoThumbnailPopupClose}>
                                            Cancel
                                          </MDButton>
                                          <MDButton onClick={handleUploadPromoThumbnail}>
                                            Ok
                                          </MDButton>
                                        </DialogActions>
                                      </Dialog> */}
                                      <StyledBadge
                                        overlap="square"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            onChange={(e) => imageUploadHandle(e, "thumbnail")}
                                          >
                                            <input
                                              hidden
                                              accept="image/*"
                                              type="file"
                                              id="profileImage"
                                              ref={profileImageInput}
                                            />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <PromoThumbnail
                                          src={croppedPromoThumbnailPreview} //{formData.image}
                                          variant="circular"
                                          size="xxl"
                                          shadow="sm"
                                        ></PromoThumbnail>
                                      </StyledBadge>

                                      {toggleCropModal && (
                                        <CropModal>
                                          <ImageCropper
                                            uploadFile={uploadFile}
                                            getCroppedFile={getCroppedPromoThumbnailFile}
                                            imageRatio={imageRatio}
                                            cropShape="rect"
                                            uploadFileType="image"
                                            closeCropPopup={() => {
                                              setToggleCropModal(false);
                                              if (croppedPromoThumbnail)
                                                formPromoData.thumbnail =
                                                  URL.createObjectURL(croppedPromoThumbnail);
                                            }}
                                            uploadImgDelete={uploadImgDelete}
                                          />
                                        </CropModal>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      {/* <MDInput
                                        id="contentRating"
                                        type="search"
                                        SelectProps={{
                                          native: true,
                                        }}
                                        select
                                        fullWidth
                                        name="promoType"
                                        label="Content Promo Type"
                                        value={formPromoData.promoType}
                                        onChange={handlePromoTypeSelectChange}
                                        InputLabelProps={{ shrink: true }}
                                      >
                                        <option value="Trailer">Trailer</option>
                                        <option value="Teaser">Teaser</option>
                                        <option value="Promo 1">Promo 1</option>
                                        <option value="Promo 2">Promo 2</option>
                                      </MDInput> */}
                                      <div className="selectWrapper">
                                        <label>Promo Type</label>
                                        <Select
                                          id="promoType"
                                          label="Promo Type"
                                          name="promoType"
                                          onChange={handlePromoTypeChange}
                                          classNamePrefix="cus-select"
                                          value={promoTypeListData.find(
                                            (i) => i.lookupCode == formPromoData.promoType //compare string with number value
                                          )}
                                          options={promoTypeListData}
                                          getOptionLabel={(e) => e.lookupCode}
                                          getOptionValue={(e) => e.lookupCode}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select Promo Type"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12} mt={2}>
                                      <MDInput
                                        type="string"
                                        disabled
                                        label="Promo Url"
                                        name="promoUrl"
                                        value={formPromoData.promoUrl}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} mt={2}>
                                      <MDInput
                                        type="string"
                                        label="Promo Title"
                                        name="promoTitle"
                                        value={formPromoData.promoTitle}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Promo Duration Secs"
                                        name="promoDurationSecs"
                                        value={formPromoData.promoDurationSecs}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Promo Views"
                                        name="promoViews"
                                        value={formPromoData.promoViews}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formPromoData.ordering}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Promo Description"
                                        name="description"
                                        value={formPromoData.description}
                                        onChange={handlePromoFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                      <DragAndDropFiles uploadedFile={handlePromoVideo} />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handlePromoPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddPromo}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={promoSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onPromoSelectionChange}
                                editable
                                rowHeight={100}
                                showZebraRows
                                showHoverRows
                                onEditComplete={onPromoEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Promo Title",
                                    name: "title",
                                    defaultFlex: 1.0,
                                    render: ({ data, value }) => (
                                      <MDBox ml={2} lineHeight={2}>
                                        <MDAvatar
                                          src={data.thumbnail}
                                          name={value}
                                          size="md"
                                          variant="square"
                                        />
                                        <MDTypography variant="caption">
                                          {" "}
                                          {data.promoTitle}{" "}
                                        </MDTypography>
                                      </MDBox>
                                    ),
                                  },

                                  {
                                    header: "Type",
                                    name: "promoType",
                                    defaultFlex: 0.6,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.promoType} />
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.6,
                                    render: ({ data, value }) => (
                                      <MDBox ml={2} lineHeight={2}>
                                        <MDBadge badgeContent={data.ordering} />
                                      </MDBox>
                                    ),
                                  },

                                  {
                                    header: "Promo Url",
                                    name: "promoUrl",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.promoUrl}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "description",
                                    defaultFlex: 1.0,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Duration(secs)",
                                    name: "promoDurationSecs",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.promoDurationSecs}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Views",
                                    name: "promoViews",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.promoViews}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentPromosData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleCastPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleMultiCastPopupClickOpen}
                                >
                                  Add Multi Cast
                                </MDButton>
                              )}
                              {isAdmin && (
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  component="label"
                                  onChange={(e) => {
                                    uploadMultiPeople(e, "cast");
                                  }}
                                >
                                  Upload People
                                  <input hidden accept="image/*" multiple type="file" />
                                </MDButton>
                              )}
                              {castSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteCasts}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={castPopupOpen} onClose={handleCastPopupClose}>
                                <DialogTitle>Add cast to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Cast</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Cast</label>
                                        <AsyncSelect
                                          label="Cast"
                                          // styles={customSelectStyles}
                                          classNamePrefix="cus-select"
                                          placeholder="Type atleast 2 chars to search cast"
                                          cacheOptions
                                          fullWidth
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          loadOptions={loadPeopleOptions}
                                          onInputChange={(value) => setPeopleQuery(value)}
                                          onChange={onCastChange}
                                          sx={{ width: "18%", mr: 1, mt: 2 }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="As Role"
                                        name="asRole"
                                        value={formCastData.asRole}
                                        onChange={handleCastFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Description"
                                        name="description"
                                        value={formCastData.description}
                                        onChange={handleCastFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formCastData.ordering}
                                        onChange={handleCastFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleCastPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddCast}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <Dialog open={multiCastPopupOpen} onClose={handleMultiCastPopupClose}>
                                <DialogTitle>Add multi cast to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Cast</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Cast</label>
                                        <AsyncSelect
                                          label="Cast"
                                          styles={customSelectStyles}
                                          classNamePrefix="cus-select"
                                          menuPortalTarget={document.body}
                                          placeholder="Type atleast 2 chars to search cast"
                                          cacheOptions
                                          defaultOptions
                                          isMulti
                                          fullWidth
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          loadOptions={loadPeopleOptions}
                                          onInputChange={(value) => setPeopleQuery(value)}
                                          onChange={onMultiCastChange}
                                          sx={{ width: "18%", mr: 1, mt: 2 }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {/* <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="As Role"
                                        name="asRole"
                                        value={formCastData.asRole}
                                        onChange={handleCastFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Description"
                                        name="description"
                                        value={formCastData.description}
                                        onChange={handleCastFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formCastData.ordering}
                                        onChange={handleCastFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid> */}
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleMultiCastPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleMultiAddCast}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={castSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onCastSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onCastEditComplete}
                                showCellBorders="vertical"
                                // style={{ minHeight: 600, marginRight: 20, marginLeft: 20 }}
                                rowHeight={60}
                                columns={[
                                  {
                                    header: "Name",
                                    name: "name",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBox display="flex" alignItems="center" lineHeight={2}>
                                        <MDAvatar
                                          src={data.people.image}
                                          name={value}
                                          size="md"
                                          variant="square"
                                        />
                                        <MDBox ml={2} lineHeight={2}>
                                          <MDTypography
                                            display="block"
                                            variant="button"
                                            fontWeight="medium"
                                          >
                                            {value}
                                          </MDTypography>
                                          <MDBadge
                                            badgeContent={data.people.name}
                                            variant="contained"
                                            color="primary"
                                          />
                                          <MDBadge
                                            badgeContent={data.asRole}
                                            variant="contained"
                                            color="success"
                                          />
                                        </MDBox>
                                      </MDBox>
                                    ),
                                  },
                                  {
                                    header: "AsRole",
                                    name: "asRole",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption"> {data.asRole} </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentCastsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleCrewPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleMultiCrewPopupClickOpen}
                                >
                                  Add Multi
                                </MDButton>
                              )}
                              {isAdmin && (
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  component="label"
                                  onChange={(e) => {
                                    uploadMultiPeople(e, "crew");
                                  }}
                                >
                                  Upload People
                                  <input hidden accept="image/*" multiple type="file" />
                                </MDButton>
                              )}
                              {crewSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteCrews}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={crewPopupOpen} onClose={handleCrewPopupClose}>
                                <DialogTitle>Add crew to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Crew</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Crew</label>
                                        <AsyncSelect
                                          label="Crew"
                                          styles={customSelectStyles}
                                          classNamePrefix="cus-select"
                                          placeholder="Type atleast 2 chars to search cast"
                                          cacheOptions
                                          fullWidth
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          loadOptions={loadPeopleOptions}
                                          onInputChange={(value) => setPeopleQuery(value)}
                                          onChange={onCrewChange}
                                          sx={{ width: "18%", mr: 1, mt: 2 }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="As Role"
                                        name="asRole"
                                        value={formCrewData.asRole}
                                        onChange={handleCrewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Description"
                                        name="description"
                                        value={formCrewData.description}
                                        onChange={handleCrewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formCrewData.ordering}
                                        onChange={handleCrewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleCrewPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddCrew}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <Dialog open={multiCrewPopupOpen} onClose={handleMultiCrewPopupClose}>
                                <DialogTitle>Add multi crew to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Crew</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Crew</label>
                                        <AsyncSelect
                                          label="Crew"
                                          styles={customSelectStyles}
                                          classNamePrefix="cus-select"
                                          menuPortalTarget={document.body}
                                          placeholder="Type atleast 2 chars to search cast"
                                          cacheOptions
                                          defaultOptions
                                          isMulti
                                          fullWidth
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          loadOptions={loadPeopleOptions}
                                          onInputChange={(value) => setPeopleQuery(value)}
                                          onChange={onMultiCrewChange}
                                          sx={{ width: "18%", mr: 1, mt: 2 }}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleMultiCrewPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleMultiAddCrew}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={crewSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onCrewSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onCrewEditComplete}
                                showCellBorders="vertical"
                                rowHeight={60}
                                // style={{ minHeight: 600, marginRight: 20, marginLeft: 20 }}
                                columns={[
                                  {
                                    header: "Name",
                                    name: "id",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBox display="flex" alignItems="center" lineHeight={2}>
                                        <MDAvatar
                                          src={data.people.image}
                                          name={value}
                                          size="md"
                                          variant="square"
                                        />
                                        <MDBox ml={2} lineHeight={2}>
                                          <MDTypography
                                            display="block"
                                            variant="button"
                                            fontWeight="medium"
                                          >
                                            {value}
                                          </MDTypography>
                                          <MDBadge
                                            badgeContent={data.people.name}
                                            variant="contained"
                                            color="primary"
                                          />
                                          <MDBadge
                                            badgeContent={data.asRole}
                                            variant="contained"
                                            color="success"
                                          />
                                        </MDBox>
                                      </MDBox>
                                    ),
                                  },
                                  {
                                    header: "As Role",
                                    name: "asRole",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption"> {data.asRole} </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentCrewsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleLanguagePopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {languageSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteLanguages}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={languagePopupOpen} onClose={handleLanguagePopupClose}>
                                <DialogTitle>Add language to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Language</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Language</label>
                                        <Select
                                          id="languageId"
                                          name="languageId"
                                          classNamePrefix="cus-select"
                                          onChange={handleLanguageChange}
                                          value={languageListData.find(
                                            (i) => i.id === formLanguageData.languageId
                                          )}
                                          options={languageListData}
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select Language"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formLanguageData.ordering}
                                        onChange={handleLanguageFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleLanguagePopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddLanguage}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={languageSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onLanguageSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onLanguageEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "languageId",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.language.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "language.description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.language.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "BG Color",
                                    name: "language.bgColor",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      // <MDTypography variant="caption">
                                      //   {" "}
                                      //   {data.language.bgColor}{" "}
                                      // </MDTypography>
                                      <MDBox
                                        size="sx"
                                        color="white"
                                        style={{ backgroundColor: data.language.bgColor }}
                                        opacity={1}
                                        variant="gradient"
                                        p={0.3}
                                      ></MDBox>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentLanguagesData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={6}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleGenrePopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {genreSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteGenres}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={genrePopupOpen} onClose={handleGenrePopupClose}>
                                <DialogTitle>Add genre to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Genre</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Genre</label>
                                        <Select
                                          id="genreId"
                                          name="genreId"
                                          classNamePrefix="cus-select"
                                          onChange={handleGenreChange}
                                          value={genreListData.find(
                                            (i) => i.id === formGenreData.genreId
                                          )}
                                          options={genreListData}
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select Genre"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formGenreData.ordering}
                                        onChange={handleGenreFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleGenrePopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddGenre}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={genreSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onGenreSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onGenreEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "genreId",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.genre.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "genre.description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.genre.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "BG Color",
                                    name: "genre.bgColor",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      // <MDTypography variant="caption">
                                      //   {" "}
                                      //   {data.genre.bgColor}{" "}
                                      // </MDTypography>
                                      <MDBox
                                        size="sx"
                                        color="white"
                                        style={{ backgroundColor: data.genre.bgColor }}
                                        opacity={1}
                                        variant="gradient"
                                        p={0.3}
                                      ></MDBox>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentGenresData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={7}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleFormatPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {formatSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteFormats}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={formatPopupOpen} onClose={handleFormatPopupClose}>
                                <DialogTitle>Add format to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Format</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Format</label>
                                        <Select
                                          id="formatId"
                                          name="formatId"
                                          classNamePrefix="cus-select"
                                          onChange={handleFormatChange}
                                          value={formatListData.find(
                                            (i) => i.id === formFormatData.formatId
                                          )}
                                          options={formatListData}
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select Format"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formFormatData.ordering}
                                        onChange={handleFormatFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleFormatPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddFormat}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={formatSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onFormatSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onFormatEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "formatId",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.format.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "format.description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.format.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentFormatsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={8}>
                    {/* <MDTypography variant="h6">Reviews</MDTypography> */}
                    {/* <ContentReviews /> */}
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            {/* <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleReviewPopupClickOpen}
                                >
                                  Active Preview
                                </MDButton>
                              )}
                              {reviewSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteReviews}>
                                  Deactive Preview
                                </MDButton>
                              )}
                            </MDBox> */}
                            <MDBox pt={1}>
                              <Dialog open={reviewPopupOpen} onClose={handleReviewPopupClose}>
                                <DialogTitle>Add review to content</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>Review</DialogContentText>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="custom-select">
                                        <Select
                                          id="reviewId"
                                          name="reviewId"
                                          onChange={handleReviewChange}
                                          value={reviewListData.find(
                                            (i) => i.id === formReviewData.id
                                          )}
                                          options={reviewListData}
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select Review"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Review Url"
                                        name="reviewUrl"
                                        value={formReviewData.reviewUrl}
                                        onChange={handleReviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleReviewPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddReview}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={reviewSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onReviewSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onReviewEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "User Name",
                                    name: "reviewUser",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.user.userName} />
                                    ),
                                  },
                                  {
                                    header: "Review Rating",
                                    name: "reviewRating",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption"> {data.rating} </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Review Comments",
                                    name: "reviewComments",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.reviewComments}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Active",
                                    name: "active",
                                    defaultFlex: 0.6,
                                    textAlign: "center",
                                    render: ({ data, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={data.active === "Y" ? true : false}
                                            onChange={(e) => handleReviewSwitchChange(e, data.id)}
                                            inputProps={{
                                              "aria-label": "controlled",
                                              align: "center",
                                            }}
                                          />
                                        }
                                        label=""
                                      />
                                    ),
                                  },
                                  {
                                    header: "Parent Id",
                                    name: "parentId",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.parentId}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentReviewsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={9}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleRightPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {rightSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteRights}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={rightPopupOpen} onClose={handleRightPopupClose}>
                                <DialogTitle>Add right to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Right</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Right</label>
                                        <Select
                                          id="rightId"
                                          name="rightId"
                                          classNamePrefix="cus-select"
                                          onChange={handleRightChange}
                                          value={rightListData.find(
                                            (i) => i.id === formRightData.rightId
                                          )}
                                          options={rightListData}
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select Right"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formRightData.ordering}
                                        onChange={handleRightFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleRightPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddRight}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={rightSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onRightSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onRightEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "rightId",
                                    defaultFlex: 1.75,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.rightType.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "right.description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.rightType.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 1.2,
                                    textAlign: "center",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "isSold",
                                    name: "isSold",
                                    defaultFlex: 1,
                                    render: ({ data, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="isSold"
                                            checked={data.isSold === "Y" ? true : false}
                                            onChange={(e) => handleSwitchIsSoldChange(e, data.id)}
                                            inputProps={{ "aria-label": "controlled" }}
                                          />
                                        }
                                        label=""
                                      />
                                    ),
                                  },
                                  {
                                    header: "ExclusiveRight",
                                    name: "exclusiveRight",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="exclusiveRight"
                                            checked={data.exclusiveRight === "Y" ? true : false}
                                            onChange={(e) =>
                                              handleSwitchExclusiveRightChange(e, data.id)
                                            }
                                            inputProps={{ "aria-label": "controlled" }}
                                          />
                                        }
                                        label=""
                                      />
                                    ),
                                  },
                                  {
                                    header: "Duration Type",
                                    name: "durationType",
                                    defaultFlex: 2,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <div>
                                        <Select
                                          id="durationType"
                                          label="Duration Type"
                                          name="durationType"
                                          classNamePrefix="cus-select"
                                          menuPortalTarget={document.querySelector("body")} // added for alignment of items
                                          onChange={(e) =>
                                            handleRightsDuractioChange(e, "durationType", data.id)
                                          }
                                          value={durationTypeListData.find(
                                            (i) => i.lookupCode == data.durationType //compare string with number value
                                          )}
                                          options={durationTypeListData}
                                          getOptionLabel={(e) => e.lookupCode}
                                          getOptionValue={(e) => e.lookupCode}
                                          placeholder="Select DurationType"
                                          fullWidth
                                          // menuPosition="fixed"
                                        />
                                      </div>
                                    ),
                                  },
                                  {
                                    header: "Duration UOM",
                                    name: "durationUom",
                                    defaultFlex: 1.9,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <div>
                                        <Select
                                          id="durationUom"
                                          label="Duration UOM"
                                          name="durationUom"
                                          classNamePrefix="cus-select"
                                          menuPortalTarget={document.querySelector("body")} // added for alignment of items
                                          onChange={(e) =>
                                            handleRightsDuractioChange(e, "durationUom", data.id)
                                          }
                                          value={durationUomListData.find(
                                            (i) => i.lookupCode == data.durationUom //compare string with number value
                                          )}
                                          options={durationUomListData}
                                          getOptionLabel={(e) => e.lookupCode}
                                          getOptionValue={(e) => e.lookupCode}
                                          placeholder="Select DurationUom"
                                          fullWidth
                                          // menuPosition="fixed"
                                        />
                                      </div>
                                    ),
                                  },
                                  {
                                    header: "Duration Value",
                                    name: "durationValue",
                                    defaultFlex: 1.5,
                                    textAlign: "center",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.durationValue}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentRightsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={10}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handlePartyPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {partySelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteParties}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={partyPopupOpen} onClose={handlePartyPopupClose}>
                                <DialogTitle>Add Party to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Party</DialogContentText> */}

                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Party</label>
                                        <AsyncSelect
                                          label="Party"
                                          styles={customSelectStyles}
                                          classNamePrefix="cus-select"
                                          placeholder="Type atleast 2 chars to search party"
                                          cacheOptions
                                          fullWidth
                                          getOptionLabel={(e) => e.partyName}
                                          getOptionValue={(e) => e.id}
                                          loadOptions={loadPartyOptions}
                                          onInputChange={(value) => setPartyQuery(value)}
                                          onChange={onPartyChange}
                                          sx={{ width: "18%", mr: 1, mt: 2 }}
                                        />{" "}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={formPartyData.active ? "Y" : null}
                                            onChange={handleSwitchChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                          />
                                        }
                                        label="Active"
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formPartyData.ordering}
                                        onChange={handlePartyFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handlePartyPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddParty}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={partySelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onPartySelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onPartyEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "partyId",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.party.partyName} />
                                    ),
                                  },

                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.8,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "active",
                                    name: "active",
                                    defaultFlex: 0.8,
                                    render: ({ data, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={data.active === "Y" ? true : false}
                                            onChange={(e) => handlePartySwitchChange(e, data.id)}
                                            inputProps={{ "aria-label": "controlled" }}
                                          />
                                        }
                                        label=""
                                      />
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentPartiesData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>

                  <TabPanel value={tabValue} index={11}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleRegionPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {Regionselected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteRegions}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={3}>
                              <Dialog open={RegionPopupOpen} onClose={handleRegionPopupClose}>
                                <DialogTitle>Add region to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>Region</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Region</label>
                                        <Select
                                          id="regionId"
                                          name="regionId"
                                          classNamePrefix="cus-select"
                                          onChange={handleRegionChange}
                                          value={RegionListData.find(
                                            (i) => i.id === formRegionData.regionId
                                          )}
                                          options={RegionListData}
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select region"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formRegionData.ordering}
                                        onChange={handleRegionFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleRegionPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddRegion}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={Regionselected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onRegionselectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onRegionEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "regionId",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.region.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "region.description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.region.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "ISO CountryCode",
                                    name: "region.isoCountryCode",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.region.isoCountryCode}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentRegionsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={12}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handleSubTitlePopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {subTitleSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteSubTitles}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={subTitlePopupOpen} onClose={handleSubTitlePopupClose}>
                                <DialogTitle>Add subTitle to content</DialogTitle>
                                <DialogContent>
                                  {/* <DialogContentText>SubTitle</DialogContentText> */}
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Name"
                                        name="name"
                                        value={formSubTitleData.name}
                                        onChange={handleSubTitleFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Description"
                                        name="description"
                                        value={formSubTitleData.description}
                                        onChange={handleSubTitleFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>Langauge</label>
                                        <AsyncSelect
                                          label="Langauge"
                                          styles={customSelectStyles}
                                          classNamePrefix="cus-select"
                                          placeholder="Type atleast 2 chars to search langauge"
                                          cacheOptions
                                          fullWidth
                                          getOptionLabel={(e) => e.name}
                                          getOptionValue={(e) => e.id}
                                          loadOptions={loadLangaugeOptions}
                                          onInputChange={(value) => setLangaugeQuery(value)}
                                          onChange={onLangaugeChange}
                                          sx={{ width: "18%", mr: 1, mt: 2 }}
                                        />{" "}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <div className="selectWrapper">
                                        <label>File Type</label>
                                        <Select
                                          id="fileType"
                                          name="fileType"
                                          classNamePrefix="cus-select"
                                          onChange={handleSubTitleChange}
                                          value={subTitleListData.find(
                                            (i) => i.value === subTitleListData.value
                                          )}
                                          options={subTitleListData}
                                          getOptionLabel={(e) => e.label}
                                          getOptionValue={(e) => e.value}
                                          // className="custom-select-box basic-single"
                                          placeholder="Select SubTitle FileType"
                                          fullWidth
                                          menuPosition="fixed"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            // onClick={handleUploadPrimaryImagePopup}
                                            onChange={handleSubTitlesFile}
                                          >
                                            <input hidden accept="*/*" type="file" />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <Image
                                          src={formData.fileUrl}
                                          variant="square"
                                          size="xxl"
                                          shadow="sm"
                                        ></Image>
                                      </StyledBadge>
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formSubTitleData.ordering}
                                        onChange={handleSubTitleFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={formData.active ? "Y" : null}
                                            onChange={handleSwitchChange}
                                            inputProps={{
                                              "aria-label": "controlled",
                                              align: "center",
                                            }}
                                          />
                                        }
                                        label="Active"
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handleSubTitlePopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddSubTitle}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={subTitleSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onSubTitleSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onSubTitleEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "name",
                                    defaultFlex: 1,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "subTitle.description",
                                    defaultFlex: 1,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Language",
                                    name: "subTitle.languageCode",
                                    defaultFlex: 1,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.languageCode}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "FileType",
                                    name: "subTitle.fileType",
                                    defaultFlex: 1,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.fileType}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Active",
                                    name: "subTitle.active",
                                    defaultFlex: 0.7,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={formData.active ? "Y" : null}
                                            onChange={handleSwitchChange}
                                            inputProps={{
                                              "aria-label": "controlled",
                                              align: "center",
                                            }}
                                          />
                                        }
                                        label=""
                                      />
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentSubTitlesData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </TabPanel>
                  <TabPanel value={tabValue} index={13}>
                    <MDBox pt={2} pb={3}>
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <Card>
                            <MDBox mt={1} mr={3} textAlign="end">
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handlePreviewPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {previewSelected > 0 && (
                                <MDButton color="error" sx={{ mr: 1 }} onClick={onDeletePreviews}>
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>
                            <MDBox pt={1}>
                              <Dialog open={previewPopupOpen} onClose={handlePreviewPopupClose}>
                                <DialogTitle>Add preview to content</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>Preview</DialogContentText>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                      <MDInput
                                        type="string"
                                        label="Name"
                                        name="name"
                                        value={formPreviewData.name}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                      <MDInput
                                        type="string"
                                        disabled
                                        label="PreviewKey"
                                        name="previewKey"
                                        value={formPreviewData.previewKey}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid> */}
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={12}>
                                      <MDInput
                                        type="string"
                                        label="Description"
                                        name="description"
                                        value={formPreviewData.description}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="string"
                                        label="Type"
                                        name="previewType"
                                        value={formPreviewData.previewType}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="string"
                                        label="Status"
                                        name="status"
                                        value={formPreviewData.status}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="string"
                                        label="SecurityType"
                                        name="securityType"
                                        value={formPreviewData.securityType}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="string"
                                        label="Password"
                                        name="password"
                                        value={formPreviewData.password}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="number"
                                        label="NoOfViews"
                                        name="noOfViews"
                                        value={formPreviewData.noOfViews}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="number"
                                        label="AvgRating"
                                        name="avgRating"
                                        value={formPreviewData.avgRating}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="number"
                                        label="NoOfRatings"
                                        name="noOfRatings"
                                        value={formPreviewData.noOfRatings}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <MDInput
                                        type="number"
                                        label="Ordering"
                                        name="ordering"
                                        value={formPreviewData.ordering}
                                        onChange={handlePreviewFormChange}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1} mt={2}>
                                    <Grid item xs={6}>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={formData.active ? "Y" : null}
                                            onChange={handleSwitchChange}
                                            inputProps={{
                                              "aria-label": "controlled",
                                              align: "center",
                                            }}
                                          />
                                        }
                                        label="Active"
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <p>PreviewThumbnail</p>
                                      {/* <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            // onClick={handleUploadImagePopup}
                                            onChange={handleUploadImagePopup}
                                          >
                                            <input hidden accept="image/*" type="file" />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <Image
                                          src={formData.image}
                                          variant="square"
                                          size="xxl"
                                          shadow="sm"
                                          // onClick={handleUploadImagePopup}
                                        ></Image>
                                      </StyledBadge>

                                      <Dialog
                                        open={uploadImagePopupOpen}
                                        onClose={handleUploadImagePopupClose}
                                      >
                                        <DialogTitle>Upload Avatar</DialogTitle>
                                        <DialogContent>
                                          <DialogContentText>
                                            Only PNG/JPG Allowed
                                          </DialogContentText>
                                          <Grid container spacing={1} mt={2}>
                                            <Grid item xs={12}>
                                              {imageToCrop && (
                                                <div>
                                                  <ReactCrop
                                                    src={imageToCrop}
                                                    onImageLoaded={setImage}
                                                    crop={crop}
                                                    onChange={setCrop}
                                                    circularCrop={false}
                                                    minWidth={100}
                                                    minHeight={100}
                                                    maxWidth={100}
                                                    maxHeight={100}
                                                    locked={true}
                                                  >
                                                    <img
                                                      src={imageToCrop}
                                                      style={{ transform: `scale(${scale})` }}
                                                      onLoad={(e) => {
                                                        setImageRef(e.target);
                                                      }}
                                                    />
                                                  </ReactCrop>
                                                </div>
                                              )}
                                              <MDBox width={300}>
                                                <Slider
                                                  size="small"
                                                  defaultValue={100}
                                                  onChange={(e) =>
                                                    setScale(Number(e.target.value) / 100)
                                                  }
                                                  aria-label="Small"
                                                  valueLabelDisplay="auto"
                                                  min={50}
                                                  max={200}
                                                />
                                              </MDBox>
                                            </Grid>
                                          </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                          <MDButton onClick={handleUploadImagePopupClose}>
                                            Cancel
                                          </MDButton>
                                          <MDButton onClick={handleUploadImage}>Ok</MDButton>
                                        </DialogActions>
                                      </Dialog> */}
                                      <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        size="sm"
                                        badgeContent={
                                          <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            size="xl"
                                            onChange={(e) => imageUploadHandle(e, "thumbnail")}
                                          >
                                            <input
                                              hidden
                                              accept="image/*"
                                              type="file"
                                              id="profileImage"
                                              ref={profileImageInput}
                                            />
                                            <CloudUploadRounded />
                                          </IconButton>
                                        }
                                      >
                                        <Image
                                          src={croppedPreviewThumbnailPreview} //{formData.image}
                                          variant="square"
                                          size="xxl"
                                          shadow="sm"
                                        ></Image>
                                      </StyledBadge>

                                      {toggleCropModal && (
                                        <CropModal>
                                          <ImageCropper
                                            uploadFile={uploadFile}
                                            getCroppedFile={getCroppedPreviewThumbnailFile}
                                            imageRatio={imageRatio}
                                            cropShape="rect"
                                            uploadFileType="image"
                                            closeCropPopup={() => {
                                              setToggleCropModal(false);
                                              if (croppedPreviewThumbnail)
                                                formPreviewData.thumbnailUrl =
                                                  URL.createObjectURL(croppedPreviewThumbnail);
                                            }}
                                            uploadImgDelete={uploadImgDelete}
                                          />
                                        </CropModal>
                                      )}
                                    </Grid>
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <MDButton onClick={handlePreviewPopupClose}>Cancel</MDButton>
                                  <MDButton onClick={handleAddPreview}>Ok</MDButton>
                                </DialogActions>
                              </Dialog>
                              <ReactDataGrid
                                idProperty="id"
                                selected={previewSelected}
                                style={gridStyle}
                                checkboxColumn
                                multiSelect={false}
                                onSelectionChange={onPreviewSelectionChange}
                                editable
                                showZebraRows
                                showHoverRows
                                onEditComplete={onPreviewEditComplete}
                                showCellBorders="vertical"
                                columns={[
                                  {
                                    header: "Name",
                                    name: "name",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDBadge badgeContent={data.name} />
                                    ),
                                  },
                                  {
                                    header: "Description",
                                    name: "description",
                                    defaultFlex: 1.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.description}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Type",
                                    name: "previewType",
                                    defaultFlex: 1,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.previewType}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Status",
                                    name: "status",
                                    defaultFlex: 0.5,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption"> {data.status} </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "SecurityType",
                                    name: "securityType",
                                    defaultFlex: 1.2,
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.securityType}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Ordering",
                                    name: "ordering",
                                    defaultFlex: 1,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <MDTypography variant="caption">
                                        {" "}
                                        {data.ordering}{" "}
                                      </MDTypography>
                                    ),
                                  },
                                  {
                                    header: "Active",
                                    name: "active",
                                    defaultFlex: 1,
                                    textAlign: "end",
                                    render: ({ data, value }) => (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="active"
                                            checked={data.active === "Y" ? true : false}
                                            onChange={(e) =>
                                              handleContentPreviewSwitchChange(e, data.id)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                              align: "center",
                                            }}
                                          />
                                        }
                                        label=""
                                      />
                                    ),
                                  },
                                ]}
                                pagination
                                dataSource={contentPreviewsData}
                                defaultLimit={10}
                                enableColumnAutosize
                              />
                            </MDBox>
                          </Card>
                        </Grid>
                      </Grid>
                    </MDBox>

                    {previewSelected > 0 && (
                      <MDBox pt={2}>
                        <MDBox pt={2} pb={3} px={0} py={0}>
                          <Tabs
                            value={previewSubTabValue}
                            onChange={handlePreviewSubTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="basic tabs example"
                            indicatorColor="secondary"
                            textColor="inherit"
                            // variant="fullWidth"
                          >
                            <Tab label="PreviewContents" {...a11yProps(0)} />
                            <Tab label="PreviewReviews" {...a11yProps(1)} />
                          </Tabs>
                          <TabPanel value={previewSubTabValue} index={0}>
                            <MDBox
                              sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}
                            >
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handlePreviewContentPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {selectedPreviewContent > 0 && (
                                <MDButton
                                  color="error"
                                  sx={{ mr: 1 }}
                                  onClick={onDeletePreviewContent}
                                >
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>

                            <Dialog
                              open={previewContentPopupOpen}
                              onClose={handlePreviewContentPopupClose}
                              maxWidth="md"
                              fullWidth={true}
                            >
                              <DialogTitle>Preview Content</DialogTitle>
                              <DialogContent>
                                <DialogContentText>Preview</DialogContentText>
                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={6}>
                                    <MDInput
                                      type="string"
                                      label="ContentUrl"
                                      name="contentUrl"
                                      value={formPreviewContentData.contentUrl}
                                      onChange={handlePreviewContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={12}>
                                    <MDInput
                                      type="string"
                                      label="Description"
                                      name="description"
                                      value={formPreviewContentData.description}
                                      onChange={handlePreviewContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="string"
                                      label="Status"
                                      name="status"
                                      value={formPreviewContentData.status}
                                      onChange={handlePreviewContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="string"
                                      label="ContentType"
                                      name="contentType"
                                      value={formPreviewContentData.contentType}
                                      onChange={handlePreviewContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="string"
                                      label="ContentSource"
                                      name="contentSource"
                                      value={formPreviewContentData.contentSource}
                                      onChange={handlePreviewContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="number"
                                      label="Ordering"
                                      name="ordering"
                                      value={formPreviewContentData.ordering}
                                      onChange={handlePreviewContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={6}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name="active"
                                          checked={formPreviewContentData.active ? "Y" : null}
                                          onChange={handlePreviewContentFormSwitchChange}
                                          inputProps={{
                                            "aria-label": "controlled",
                                            align: "center",
                                          }}
                                        />
                                      }
                                      label="Active"
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <MDButton onClick={handlePreviewContentPopupClose}>Cancel</MDButton>
                                <MDButton onClick={handleAddPreviewContent}>Ok</MDButton>
                              </DialogActions>
                            </Dialog>
                            <MDBox>
                              <ReactDataGrid
                                idProperty="id"
                                selected={selectedPreviewContent}
                                style={gridStyle}
                                checkboxColumn
                                editable
                                onEditComplete={onPreviewContentEditComplete}
                                multiSelect={false}
                                onSelectionChange={onPreviewContentSelectionChange}
                                columns={previewContentsColumns}
                                loading={isDtlLoading}
                                dataSource={formPreviewContentData}
                              />
                            </MDBox>
                          </TabPanel>
                          <TabPanel value={previewSubTabValue} index={1}>
                            <MDBox
                              sx={{ display: "flex", justifyContent: "flex-end", p: 0, mb: 1 }}
                            >
                              {isAdmin && (
                                <MDButton
                                  color="info"
                                  sx={{ mr: 1 }}
                                  onClick={handlePreviewReviewsPopupClickOpen}
                                >
                                  Add
                                </MDButton>
                              )}
                              {selectedPreviewReviews > 0 && (
                                <MDButton
                                  color="error"
                                  sx={{ mr: 1 }}
                                  onClick={onDeletePreviewReviews}
                                >
                                  Delete
                                </MDButton>
                              )}
                            </MDBox>

                            <Dialog
                              open={previewReviewsPopupOpen}
                              onClose={handlePreviewReviewsPopupClose}
                              maxWidth="md"
                              fullWidth={true}
                            >
                              <DialogTitle>Preview Review</DialogTitle>
                              <DialogContent>
                                <DialogContentText>Preview</DialogContentText>
                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={6}>
                                    <MDInput
                                      type="string"
                                      label="User"
                                      name="userId"
                                      value={formPreviewReviewsData.userId}
                                      onChange={handlePreviewReviewsFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>

                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="number"
                                      label="Rating"
                                      name="rating"
                                      value={formPreviewReviewsData.rating}
                                      onChange={handlePreviewReviewsFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="string"
                                      label="Comments"
                                      name="comments"
                                      value={formPreviewReviewsData.comments}
                                      onChange={handlePreviewReviewsFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="string"
                                      label="ParentId"
                                      name="parentId"
                                      value={formPreviewReviewsData.parentId}
                                      onChange={handlePreviewReviewsFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <MDInput
                                      type="number"
                                      label="NoOfLikes"
                                      name="noOfLikes"
                                      value={formPreviewReviewsData.noOfLikes}
                                      onChange={handlePreviewReviewsFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                  <Grid item xs={6}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name="active"
                                          checked={formPreviewReviewsData.active ? "Y" : null}
                                          onChange={handlePreviewReviewsFormSwitchChange}
                                          inputProps={{
                                            "aria-label": "controlled",
                                            align: "center",
                                          }}
                                        />
                                      }
                                      label="Active"
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <MDButton onClick={handlePreviewReviewsPopupClose}>Cancel</MDButton>
                                <MDButton onClick={handleAddPreviewReviews}>Ok</MDButton>
                              </DialogActions>
                            </Dialog>
                            <MDBox>
                              <ReactDataGrid
                                idProperty="id"
                                selected={selectedPreviewReviews}
                                style={gridStyle}
                                checkboxColumn
                                editable
                                onEditComplete={onPreviewReviewsEditComplete}
                                multiSelect={false}
                                onSelectionChange={onPreviewReviewsSelectionChange}
                                columns={previewReviewsColumns}
                                loading={isDtlLoading}
                                dataSource={formPreviewReviewsData}
                              />
                            </MDBox>
                          </TabPanel>
                        </MDBox>
                      </MDBox>
                    )}
                  </TabPanel>
                </MDBox>
                <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
                  <Box display="flex" alignItems="center">
                    <Box textAlign="center" flexGrow={1}>
                      AuthorizationFile
                    </Box>
                    <Box>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <DialogContent>
                    {/* <PdfViewerComponent
                      document={formData.agreementFileUrl}
                      // onReady={handleOnReady}
                    /> */}
                    <PDFWebViewer document={formData.authorizationFilePath} />

                    {/* <Document
                      file={formData.authorizationFilePath}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document> */}
                  </DialogContent>
                </Dialog>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Content;
