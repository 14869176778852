/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// import UserManagementList from "layouts/admin/usermanagement"
// import User from "layouts/admin/usermanagement/crud/User"

import LogsList from "layouts/admin/logs";

import PeopleList from "layouts/admin/people";
import People from "layouts/admin/people/crud/CreateOrEdit"

import PartyList from "layouts/admin/parties";
import Party from "layouts/admin/parties/crud/CreateOrEdit"

import InquiryList from "layouts/admin/inquiries";
import Inquiry from "layouts/admin/inquiries/crud/CreateOrEdit"

import ContractList from "layouts/admin/contract";
import Contract from "layouts/admin/contract/crud/CreateOrEdit"

import BlogPostsList from "layouts/admin/blogPosts";
import BlogPost from "layouts/admin/blogPosts/crud/CreateOrEdit"

import BlogCommentsList from "layouts/admin/blogComments";
import BlogComments from "layouts/admin/blogComments/crud/CreateOrEdit"

import GenresList from "layouts/admin/genres";
import Genre from "layouts/admin/genres/crud/CreateOrEdit"

import LanguagesList from "layouts/admin/languages";
import Language from "layouts/admin/languages/crud/CreateOrEdit"

import ContentTypesList from "layouts/admin/contentTypes";
import ContentType from "layouts/admin/contentTypes/crud/CreateOrEdit"

import FormatsList from "layouts/admin/formats";
import Format from "layouts/admin/formats/crud/CreateOrEdit"

import RolesList  from "layouts/admin/roles";
import Role from "layouts/admin/roles/crud/CreateOrEdit"
import RightTypesList  from "layouts/admin/rightTypes";
import RightType from "layouts/admin/rightTypes/crud/CreateOrEdit"
import RegionsList  from "layouts/admin/regions";
import Region from "layouts/admin/regions/crud/CreateOrEdit"
import BlogCategoriesList  from "layouts/admin/blogCategories";
import BlogCategory from "layouts/admin/blogCategories/crud/CreateOrEdit"
import BlogTagsList  from "layouts/admin/blogTags";
import BlogTag from "layouts/admin/blogTags/crud/CreateOrEdit"
import CollectionsList  from "layouts/admin/collections";
import Collection from "layouts/admin/collections/crud/CreateOrEdit"

import SettingsList from "layouts/admin/settings";
import Setting from "layouts/admin/settings/crud/CreateOrEdit"

import PagesList from "layouts/admin/pages";
import Page from "layouts/admin/pages/crud/CreateOrEdit";


import LookupTypeList from "layouts/admin/lookuptypes";
import LookupType from "layouts/admin/lookuptypes/crud/CreateOrEdit"

import UserList from "layouts/admin/usermanager";
import User from "layouts/admin/usermanager/crud/CreateOrEdit"

import ContentList from "layouts/admin/content";
import Content from "layouts/admin/content/crud/CreateOrEdit"

import FaqGroupsList from "layouts/admin/faqGroups";
import FaqGroups from "layouts/admin/faqGroups/crud/CreateOrEdit"

import CurrenciesList  from "layouts/admin/currencies";
import Currency from "layouts/admin/currencies/crud/CreateOrEdit"

import ContentPreviewsList from "layouts/admin/contentPreviews";
import ContentPreviews from "layouts/admin/contentPreviews/crud/CreateOrEdit"

import ContentReviewsList from "layouts/admin/contentReviews";
import ContentReviews from "layouts/admin/contentReviews/crud/CreateOrEdit"

import ContentReportsList from "layouts/admin/contentReports";
import ContentReports from "layouts/admin/contentReports/crud/CreateOrEdit";

import ServiceRequestsList from "layouts/admin/serviceRequests";
import ServiceRequests from "layouts/admin/serviceRequests/crud/CreateOrEdit";


// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    roles: "Admin,SuperAdmin",
    component: <Dashboard />,
  },

  {
    type: "title",
    title: "Content",
    key: "content",
    roles: "Admin,SuperAdmin",
  },
  {
    type: "collapse",
    name: "Content List",
    key: "contentlist",
    icon: <Icon fontSize="small">speaker_notes</Icon>,
    route: "/contentlist",
    roles: "Admin,SuperAdmin",
    component: <ContentList />,
  },
  {
    type: "none",
    name: "Content",
    key: "content",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/content",
    roles: "Admin,SuperAdmin",
    component: <Content />,
  },
  {
    type: "none",
    name: "Content",
    key: "content",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/content/:id",
    roles: "Admin,SuperAdmin",
    component: <Content />,
  },

  {
    type: "collapse",
    name: "Content Previews",
    key: "contentPreviewslist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/contentpreviewslist",
    roles: "Admin,SuperAdmin",
    component: <ContentPreviewsList />,
  },
  {
    type: "none",
    name: "ContentPreview",
    key: "contentPreviewslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contentpreview",
    component: <ContentPreviews />,
  },
  {
    type: "none",
    name: "ContentPreview",
    key: "contentPreviewslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contentpreview/:id",
    component: <ContentPreviews />,
  },
  {
    type: "collapse",
    name: "Content Reviews",
    key: "contentReviewslist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/contentreviewslist",
    roles: "Admin,SuperAdmin",
    component: <ContentReviewsList />,
  },
  {
    type: "none",
    name: "ContentReview",
    key: "contentReviewslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contentreview",
    component: <ContentReviews />,
  },
  {
    type: "none",
    name: "ContentReview",
    key: "contentReviewslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contentreview/:id",
    component: <ContentReviews />,
  },
  {
    type: "collapse",
    name: "ContentReports",
    key: "contentReportslist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/contentreportslist",
    roles: "Admin,SuperAdmin",
    component: <ContentReportsList />,
  },
  {
    type: "none",
    name: "ContentReport",
    key: "contentReportslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contentreport",
    component: <ContentReports />,
  },
  {
    type: "none",
    name: "ContentReport",
    key: "contentReportslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contentreport/:id",
    component: <ContentReports />,
  },

  {
    type: "collapse",
    name: "Collections",
    key: "collectionslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/collectionslist",
    roles: "Admin,SuperAdmin",
    component: <CollectionsList />,
  },
  {
    type: "none",
    name: "Collection",
    key: "collectionslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/collection",
    component: <Collection />,
  },
  {
    type: "none",
    name: "Collection",
    key: "collectionslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/collection/:id",
    component: <Collection />,
  },
  {
    type: "collapse",
    name: "Blog Posts",
    key: "blogpostslist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/blogpostslist",
    roles: "Admin,SuperAdmin",
    component: <BlogPostsList />,
  },
  {
    type: "none",
    name: "BlogPost",
    key: "blogpostslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogpost",
    component: <BlogPost />,
  },
  {
    type: "none",
    name: "BlogPost",
    key: "blogpostslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogpost/:id",
    component: <BlogPost />,
  },

  {
    type: "collapse",
    name: "Blog Comments",
    key: "blogCommentslist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/blogCommentslist",
    roles: "Admin,SuperAdmin",
    component: <BlogCommentsList />,
  },
  {
    type: "none",
    name: "BlogComment",
    key: "blogCommentslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogComment",
    component: <BlogComments />,
  },
  {
    type: "none",
    name: "BlogComment",
    key: "blogCommentslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogComment/:id",
    component: <BlogComments />,
  },

  // {
  //   type: "collapse",
  //   name: "People",
  //   key: "people",
  //   icon: <Icon fontSize="small">deck</Icon>,
  //   route: "/dashboard",
  //   component: <Tables />,
  // },
  {
    type: "title",
    title: "Marketplace",
    key: "marketplace",
    roles: "Admin,SuperAdmin",
  },
  {
    type: "collapse",
    name: "People",
    key: "peoplelist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/peoplelist",
    roles: "Admin,SuperAdmin",
    component: <PeopleList />,
  },
  {
    type: "none",
    name: "people",
    key: "peoplelist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/people",
    component: <People />,
  },
  {
    type: "none",
    name: "people",
    key: "peoplelist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/people/:id",
    component: <People />,
  },

  {
    type: "collapse",
    name: "Parties",
    key: "partylist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/partylist",
    roles: "Admin,SuperAdmin",
    component: <PartyList />,
  },
  {
    type: "none",
    name: "Party",
    key: "partylist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/party",
    component: <Party />,
  },
  {
    type: "none",
    name: "Party",
    key: "partylist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/party/:id",
    component: <Party />,
  },

  {
    type: "collapse",
    name: "Inquiries",
    key: "inquirylist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/inquirylist",
    roles: "Admin,SuperAdmin",
    component: <InquiryList />,
  },
  {
    type: "none",
    name: "Inquiries",
    key: "inquirylist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/inquiry",
    component: <Inquiry />,
  },
  {
    type: "none",
    name: "Inquiries",
    key: "inquirylist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/inquiry/:id",
    component: <Inquiry />,
  },

  {
    type: "collapse",
    name: "Contracts",
    key: "contractlist",
    icon: <Icon fontSize="small">deck</Icon>,
    route: "/contractlist",
    roles: "Admin,SuperAdmin",
    component: <ContractList />,
  },
  {
    type: "none",
    name: "Contracts",
    key: "contractlist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contract",
    component: <Contract />,
  },
  {
    type: "none",
    name: "Contracts",
    key: "contractlist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contract/:id",
    component: <Contract />,
  },

  // {
  //   type: "collapse",
  //   name: "Genres",
  //   key: "genre",
  //   icon: <Icon fontSize="small">account_balance</Icon>,
  //   route: "/dashboard",
  //   component: <Tables />,
  // },

  {
    type: "title",
    title: "Helpdesk",
    key: "Helpdesk",
    roles: "Admin,SuperAdmin",
  },
  {
    type: "collapse",
    name: "Service Requests",
    key: "supportlist",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/servicerequestslist",
    roles: "Admin,SuperAdmin",
    component: <ServiceRequestsList />,
  },
  {
    type: "none",
    name: "Service Requests",
    key: "supportlist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/servicerequest",
    component: <ServiceRequests />,
  },
  {
    type: "none",
    name: "Service Requests",
    key: "supportlist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/servicerequest/:id",
    component: <ServiceRequests />,
  },
  {
    type: "title",
    title: "Masters",
    key: "masters",
    roles: "Admin,SuperAdmin",
  },
  {
    type: "collapse",
    name: "Genres",
    key: "genreslist",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/genreslist",
    roles: "Admin,SuperAdmin",
    component: <GenresList />,
  },
  {
    type: "none",
    name: "genre",
    key: "genreslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/genre",
    component: <Genre />,
  },
  {
    type: "none",
    name: "genre",
    key: "genreslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/genre/:id",
    component: <Genre />,
  },
  // {
  //   type: "collapse",
  //   name: "Languages",
  //   key: "languages",
  //   icon: <Icon fontSize="small">engineering</Icon>,
  //   route: "/dashboard",
  //   component: <Tables />,
  // },
  {
    type: "collapse",
    name: "Languages",
    key: "languageslist",
    icon: <Icon fontSize="small">engineering</Icon>,
    route: "/languageslist",
    roles: "Admin,SuperAdmin",
    component: <LanguagesList />,
  },
  {
    type: "none",
    name: "language",
    key: "languageslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/language",
    component: <Language />,
  },
  {
    type: "none",
    name: "language",
    key: "languageslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/language/:id",
    component: <Language />,
  },
  // {
  //   type: "collapse",
  //   name: "Content Types",
  //   key: "contenttypes",
  //   icon: <Icon fontSize="small">payments</Icon>,
  //   route: "/dashboard",
  //   component: <Tables />,
  // },
  {
    type: "collapse",
    name: "Content Types",
    key: "contenttypeslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/contenttypeslist",
    roles: "Admin,SuperAdmin",
    component: <ContentTypesList />,
  },
  {
    type: "none",
    name: "contentTypes",
    key: "contenttypeslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contenttype",
    component: <ContentType />,
  },
  {
    type: "none",
    name: "contentTypes",
    key: "contenttypeslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/contenttype/:id",
    component: <ContentType />,
  },
  {
    type: "collapse",
    name: "Formats",
    key: "formatslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/formatlist",
    roles: "Admin,SuperAdmin",
    component: <FormatsList />,
  },
  {
    type: "none",
    name: "Format",
    key: "formatslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/format",
    component: <Format />,
  },
  {
    type: "none",
    name: "Format",
    key: "formatslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/format/:id",
    component: <Format />,
  },

  {
    type: "collapse",
    name: "RightTypes",
    key: "righttypeslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/righttypeslist",
    roles: "Admin,SuperAdmin",
    component: <RightTypesList />,
  },
  {
    type: "none",
    name: "RightType",
    key: "righttypeslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/righttype",
    component: <RightType />,
  },
  {
    type: "none",
    name: "RightType",
    key: "righttypeslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/righttype/:id",
    component: <RightType />,
  },
  {
    type: "collapse",
    name: "Regions",
    key: "regionslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/regionslist",
    roles: "Admin,SuperAdmin",
    component: <RegionsList />,
  },
  {
    type: "none",
    name: "Region",
    key: "regionslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/region",
    component: <Region />,
  },
  {
    type: "none",
    name: "Region",
    key: "regionslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/region/:id",
    component: <Region />,
  },
  {
    type: "collapse",
    name: "Blog Categories",
    key: "blogcategorieslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/blogcategorieslist",
    roles: "Admin,SuperAdmin",
    component: <BlogCategoriesList />,
  },
  {
    type: "none",
    name: "BlogCategory",
    key: "blogcategorieslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogcategory",
    component: <BlogCategory />,
  },
  {
    type: "none",
    name: "BlogCategory",
    key: "blogcategorieslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogcategory/:id",
    component: <BlogCategory />,
  },
  {
    type: "collapse",
    name: "Blog Tags",
    key: "blogtagslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/blogtagslist",
    roles: "Admin,SuperAdmin",
    component: <BlogTagsList />,
  },
  {
    type: "none",
    name: "BlogTag",
    key: "blogtagslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogtag",
    component: <BlogTag />,
  },
  {
    type: "none",
    name: "BlogTag",
    key: "blogtagslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/blogtag/:id",
    component: <BlogTag />,
  },
  {
    type: "collapse",
    name: "Faq Groups",
    key: "faqgroupslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/faqgroupslist",
    roles: "Admin,SuperAdmin",
    component: <FaqGroupsList />,
  },
  {
    type: "none",
    name: "FaqGroups",
    key: "faqgroup",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/faqgroup",
    component: <FaqGroups />,
  },
  {
    type: "none",
    name: "FaqGroups",
    key: "faqgroups",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/faqgroup/:id",
    component: <FaqGroups />,
  },

  {
    type: "none",
    name: "Sign In",
    key: "signin",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/auth/signin",
    component: <SignIn />,
  },

  {
    type: "title",
    title: "Setup",
    key: "setuptitle",
    roles: "Admin,SuperAdmin",
  },
  // {
  //   type: "collapse",
  //   name: "User Management",
  //   key: "usermanagement",
  //   icon: <Icon fontSize="small">manage_accounts</Icon>,
  //   route: "/userslist",
  //   roles: "Admin,TimeOfficeAdmin",
  //   component: <UserManagementList />,
  // },
  // {
  //   type: "none",
  //   name: "NewUser",
  //   key: "newuser",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/user",
  //   component: <User />,
  // },
  // {
  //   type: "none",
  //   name: "User",
  //   key: "user",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/user/:id",
  //   component: <User />,
  // },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: "/userslist",
    roles: "Admin,SuperAdmin",
    component: <UserList />,
  },
  {
    type: "none",
    name: "User",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user",
    component: <User />,
  },
  {
    type: "none",
    name: "User",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user/:id",
    component: <User />,
  },
  {
    type: "collapse",
    name: "Roles",
    key: "roleslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/roleslist",
    roles: "Admin,SuperAdmin",
    component: <RolesList />,
  },
  {
    type: "none",
    name: "Role",
    key: "roleslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/role",
    component: <Role />,
  },
  {
    type: "none",
    name: "Role",
    key: "roleslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/role/:id",
    component: <Role />,
  },
  {
    type: "collapse",
    name: "Currencies",
    key: "currencieslist",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "/currencieslist",
    roles: "Admin,SuperAdmin",
    component: <CurrenciesList />,
  },
  {
    type: "none",
    name: "Currency",
    key: "currencieslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/currency",
    component: <Currency />,
  },
  {
    type: "none",
    name: "Currency",
    key: "currencieslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/currency/:id",
    component: <Currency />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/dashboard",
    roles: "Admin,SuperAdmin",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settingslist",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settingslist",
    roles: "Admin,SuperAdmin",
    component: <SettingsList />,
  },
  {
    type: "none",
    name: "Setting",
    key: "settingslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/setting",
    component: <Setting />,
  },
  {
    type: "none",
    name: "Setting",
    key: "settingslist",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/setting/:id",
    component: <Setting />,
  },

  //Pages
  {
    type: "collapse",
    name: "Pages",
    key: "pagesList",
    icon: <Icon fontSize="small">pages</Icon>,
    route: "/pagesList",
    roles: "Admin,SuperAdmin",
    component: <PagesList />,
  },
  {
    type: "none",
    name: "Pages",
    key: "pagesList",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/page",
    component: <Page />,
  },
  {
    type: "none",
    name: "Pages",
    key: "pagesList",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/page/:id",
    component: <Page />,
  },

  {
    type: "collapse",
    name: "Logs",
    key: "logslist",
    icon: <Icon fontSize="small">assessment</Icon>,
    route: "/logslist",
    roles: "Admin,SuperAdmin",
    component: <LogsList />,
  },
  {
    type: "collapse",
    name: "Lookup Types",
    key: "lookuptypes",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    route: "/lookuptypes",
    roles: "Admin,SuperAdmin",
    component: <LookupTypeList />,
  },
  {
    type: "none",
    name: "Lookup Type",
    key: "lookuptype",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    route: "/lookuptype",
    component: <LookupType />,
  },
  {
    type: "none",
    name: "Lookup Type",
    key: "lookuptype",
    icon: <Icon fontSize="small">published_with_changes</Icon>,
    route: "/lookuptype/:id",
    component: <LookupType />,
  },
];

export default routes;
