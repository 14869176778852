import { AES, enc } from "crypto-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from "helpers/common/config";
import moment from "moment";


const dateFormat = "MMMM DD YYYY hh:mm:ss A";
const convertFormat = "DD-MM-YYYY hh:mm:ss A";

export const common = {
  redirect(page) {
    window.location.href = page;
  },
  loadCloudImg(img) {
    return config.img.imageUrl + img;
  },
  // intermediate
  loadImg(img) {
    return config.img.imageUrl + img;
  },
  // toast
  notify(type, msg) {
    switch (type) {
      case "S":
        toast.success(msg);
        break;
      case "W":
        toast.warning(msg);
        break;
      case "E":
        toast.error(msg,{autoClose:false});
        break;
      case "I":
        toast.info(msg);
        break;
      case "M":
        toast(msg);
        break;
      default:
        toast(msg);
    }
  },
  // local storage
  localSet(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  localGet(key, json = false) {
    let value = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },
  localRemove(key) {
    localStorage.removeItem(key);
  },
  // auth
  checkAuth() {
    const token = this.localGet("zrAdminToken");
    if (token === "" || token == null) {
      return false;
    }
    return this.getAuth() ? true : false;
  },
  getEmployeeNo() {
    const data = this.localGet("zrAdminUserProfile");
    const val = JSON.parse(data);
    // return val.prn;
    return val.id;
  },
  userInRole(role) {
    const data = this.localGet("zrAdminUserProfile");
    // console.log(data);
    if (data) {
      const val = JSON.parse(data);
      const userRoles = val.userRoles;
      if (userRoles && userRoles !== "" && userRoles !== null) {
        // const arr = userRoles.split(',');
        const rolearr = role.split(",");
        // console.table(arr);
        const found = userRoles.some((r) => rolearr.includes(r));
        // console.log(found);
        return found;
        //return arr.includes(role);
      }
    }
    return false;
  },
  getUserId() {
    const data = this.localGet("zrAdminUserProfile");
    const val = JSON.parse(data);
    return val.userId;
  },
  getUserName() {
    const data = this.localGet("zrAdminUserProfile");
    const val = JSON.parse(data);
    return val.userName;
  },
  getAuth() {
    const data = this.localGet("zrAdminToken");
    return data;
  },
  // encode decode
  crypt(str, json = false) {
    let str1 = str;
    if (json) {
      str1 = JSON.stringify(str1);
    }
    return AES.encrypt(str1, config.cryptKey).toString();
  },
  deCrypt(str, json = false) {
    try {
      const bytes = AES.decrypt(str, config.cryptKey);
      const data = bytes.toString(enc.Utf8);
      if (data === "") {
        localStorage.clear();
        this.redirect("/");
      } else {
        if (json) {
          return JSON.parse(data);
        }
        return data;
      }
      return data;
    } catch (err) {
      localStorage.clear();
      this.redirect("/");
    }
    return "";
  },
  parseJwt(token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  },
  // validation
  isEmail(mail) {
    /* eslint-disable no-useless-escape */
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  },
  // api
  apiStatus(res) {
    let status = "success";
    if (res.status === 200) {
      if (res.data.items === "") {
        status = "empty";
      }
    } else {
      status = "error";
    }
    return status;
  },

  utcToLocal(date, format = dateFormat) {
    return moment.utc(date, convertFormat).local().format(format);
  },

  //Filter Query
  getFilter(filterValue) {
    let filterString = "";
    let locOperator = "=";
    for (let obj of filterValue) {
      // console.log("name", obj.name);
      // console.log("operator", obj.operator);
      // console.log("value", obj.value);

      if (obj.type === "string") {
        switch (obj.operator) {
          case "startsWith":
            locOperator = '"' + obj.name + '":{"$like":"' + obj.value + '*"}'; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          case "endsWith":
            locOperator = '"' + obj.name + '":{"$like":"*' + obj.value + '"}'; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          case "empty":
            locOperator = '"' + obj.name + '":{"$is":' + null + "}"; //{"settingsKey":{"$eq": "qsdfsssdwe"}};
            break;
          case "notEmpty":
            locOperator = '"' + obj.name + '":{"$not":' + null + "}"; //{"settingsKey":{"$eq": "qsdfsssdwe"}};
            break;
          case "contains":
            locOperator = '"' + obj.name + '":{"$like":"*' + obj.value + '*"}'; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          case "notContains":
            locOperator = '"' + obj.name + '":{"$notLike":"*' + obj.value + '*"}'; //{"settingsKey":{"$eq": "qsdfsssdwe"}};
            break;
          case "neq":
            locOperator = '"' + obj.name + '":{"$ne":"' + obj.value + '"}'; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          default:
            locOperator = '"' + obj.name + '":{"$eq":"' + obj.value + '"}'; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
        }
      } else if (obj.type === "number") {
        switch (obj.operator) {
          case "gte":
            locOperator = '"' + obj.name + '":{"$gte":' + obj.value + "}"; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          case "lte":
            locOperator = '"' + obj.name + '":{"$lte":' + obj.value + "}"; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          case "neq":
            locOperator = '"' + obj.name + '":{"$ne":' + obj.value + "}"; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
            break;
          default:
            locOperator = '"' + obj.name + '":{"$eq":' + obj.value + "}"; //{"settingsKey":{"$eq": "qsdfsssdwe"}}
        }
      }

      if (obj.value !== "") filterString += locOperator + ",";
    }

    return "{" + filterString.slice(0, -1) + "}";
  },

  //Sorting
  getSort(sortInfo) {
    console.log("sortInfo",sortInfo)
    let sortArr = [];
    let locOperator = "=";
    if (sortInfo && sortInfo.length>0)
    {
      sortInfo.forEach((item) => {
        console.log('item: ' , item);

        if (item) {
          switch (item.dir) {
            case 1:
              locOperator = "ASC";
              break;
            case -1:
              locOperator = "DESC";
              break;
            default:
              locOperator = "";
          }
          let criteriaArr = [];
          criteriaArr.push(item.name)
          criteriaArr.push(locOperator)
          sortArr.push(criteriaArr);
        }
      });
      console.log("sortArr",JSON.stringify(sortArr));
      return JSON.stringify(sortArr);
    }
    return null;
    
  },

  getDateFormatted(date,formatType="en-IN"){
    if(_.isNil(date)) 
      return null;
      
    if(formatType ==="en-US")
    return moment(new Date(date)).format("MM/DD/YYYY")
    else
    return moment(new Date(date)).format("DD/MM/YYYY")
  }
};
