import { config } from 'helpers/common/config'
import { common } from 'helpers/common/common'
import axios from 'axios';

// services
const services = {
  login: config.api.auth + "login",
  loginOtp: config.api.auth + "loginOtp",
  profile: config.api.auth + "profile",
  users: config.api.auth + "users",
  usersAdvanceSearch: config.api.url + "users/search/advance",

  usersRoles: config.api.auth + "usersRoles",
  usersParties: config.api.auth + "usersParties",

  content_list: config.api.url + "content_list/all",
  content_detail: config.api.url + "content_detail",
  content: config.api.url + "content",
  settings: config.api.url + "settings",
  settingsTypesAdvanceSearch: config.api.url + "settings/search/advance",
  pages: config.api.url + "pages",
  genres: config.api.url + "genres",
  languages: config.api.url + "languages",
  people: config.api.url + "people",
  parties: config.api.url + "parties",
  partiesTypesAdvanceSearch: config.api.url + "parties/search/advance",
  blogPosts: config.api.url + "blogPosts",
  blogPostsTags: config.api.url + "blogPostsTags",
  blogComments: config.api.url + "blogComments",
  contentTypes: config.api.url + "contentTypes",
  contentTypesAdvanceSearch: config.api.url + "contentTypes/search/advance",
  formats: config.api.url + "formats",
  rightTypes: config.api.url + "rightTypes",
  roles: config.api.url + "roles",
  regions: config.api.url + "regions",
  blogTags: config.api.url + "blogTags",
  blogCategories: config.api.url + "blogCategories",
  contentImages: config.api.url + "contentImages",
  contentImagesAdvanceSearch: config.api.url + "contentImages/search/advance",
  contentPromos: config.api.url + "contentPromos",
  contentPromosAdvanceSearch: config.api.url + "contentPromos/search/advance",
  contentGenres: config.api.url + "contentGenres",
  contentGenresAdvanceSearch: config.api.url + "contentGenres/search/advance",
  contentLanguages: config.api.url + "contentLanguages",
  contentLanguagesAdvanceSearch: config.api.url + "contentLanguages/search/advance",
  contentFormats: config.api.url + "contentFormats",
  contentFormatsAdvanceSearch: config.api.url + "contentFormats/search/advance",
  contentRights: config.api.url + "contentRights",
  contentRightsAdvanceSearch: config.api.url + "contentRights/search/advance",
  contentParties: config.api.url + "contentParties",
  contentPartiesAdvanceSearch: config.api.url + "contentParties/search/advance",
  contentRegions: config.api.url + "contentRegions",
  contentRegionsAdvanceSearch: config.api.url + "contentRegions/search/advance",
  collections: config.api.url + "collections",
  contentReviews: config.api.url + "contentReviews",
  contentReviewsAdvanceSearch: config.api.url + "contentReviews/search/advance",
  contentReports: config.api.url + "contentReports",
  contentReportsAdvanceSearch: config.api.url + "contentReports/search/advance",
  contentSubTitles: config.api.url + "contentSubTitles",
  contentSubTitlesAdvanceSearch: config.api.url + "contentSubTitles/search/advance",
  contentPreviews: config.api.url + "contentPreviews",
  contentPreviewsAdvanceSearch: config.api.url + "contentPreviews/search/advance",
  previewContent: config.api.url + "previewContent",
  previewContentAdvanceSearch: config.api.url + "previewContent/search/advance",
  previewReviews: config.api.url + "previewReviews",
  previewReviewsAdvanceSearch: config.api.url + "previewReviews/search/advance",
  contentCrew: config.api.url + "contentCrew",
  contentCrewAdvanceSearch: config.api.url + "contentCrew/search/advance",
  contentCast: config.api.url + "contentCast",
  contentCastAdvanceSearch: config.api.url + "contentCast/search/advance",
  contentCollections: config.api.url + "contentCollections",
  contentCollectionsAdvanceSearch: config.api.url + "contentCollections/search/advance",

  faqGroup: config.api.url + "faqGroups",
  faqs: config.api.url + "faqs",
  faqsAdvanceSearch: config.api.url + "faqs/search/advance",
  qdashboard: config.api.url + "qdashboard",
  userRegChart: config.api.url + "admin/chart/userRegChart",
  buyerSellerChart: config.api.url + "admin/chart/buyerSellerChart",
  contentTypeChart: config.api.url + "admin/chart/contentTypeChart",

  enquiryHeader: config.api.url + "enquiryHeader",
  enquiryItems: config.api.url + "enquiryItems",
  enquiryItemRegions: config.api.url + "enquiryItemRegions",

  contractHeader: config.api.url + "contractHeader",
  contractItems: config.api.url + "contractItems",
  contractItemRegions: config.api.url + "contractItemRegions",
  contractDetail: config.api.url + "contractDetail",

  lookupTypes: config.api.url + "lookupTypes",
  lookupValues: config.api.url + "lookupValues",
  lookupValuesAdvanceSearch: config.api.url + "lookupValues/search/advance",
  currencies: config.api.url + "currencies",

  serviceRequests: config.api.url + "serviceRequests",
  serviceRequestComments: config.api.url + "serviceRequestComments",
  serviceRequestCommentsAdvanceSearch: config.api.url + "serviceRequestComments/search/advance",
};

axios.interceptors.request.use(request => {
  //console.log("request interceptor "+JSON.stringify(request));
  const accessToken = common.localGet("zrAdminToken");

  if (!request.url.includes("/public/") && !request.url.includes("/login/") && !request.url.includes("/refreshToken") && !request.url.includes("/register")) {
    //console.log("accessToken "+accessToken);
    request.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  }
  return request;
},
error => {
  return Promise.reject(error)
}


);

axios.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      console.log('You are not authorized')
      return Promise.reject(res)
    }
    return res;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && originalRequest.url === config.api.url + "login") {
      return Promise.reject(error);
    } 

    if (error.response.status === 401 && originalRequest.url === config.api.auth + "refreshToken") {
      return Promise.reject(error);
    }

     if (error.response) {
       //if ((error.response.status === 401 ) && !originalRequest._retry) {
       if (error.response.status === 401 && !originalRequest._retry) {
         const refreshToken = common.localGet("zrAdminRefreshToken");
         console.log(refreshToken)
         originalRequest._retry = true;
         //axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken;
         const { data } = await axios.post(config.api.auth + "refreshToken", {
           "token": refreshToken
         }).catch((err)=>{
            common.localRemove("zrAdminUserProfile");
            common.localRemove("zrAdminToken");
            common.localRemove("zrAdminRefreshToken");
            common.redirect("/auth/signin")
            return Promise.reject(err);
           });
         console.log("new refreshed token " + JSON.stringify(data));
         common.localSet("zrAdminToken", data.data.token);
         common.localSet("zrAdminRefreshToken", data.data.refreshtoken);
         common.localSet("zrAdminUserProfile", common.parseJwt(data.data.token));

         // Handle when role missing in refresh tokens
         if(!common.userInRole('Admin')){
          console.log("Not admin User " + JSON.stringify(data));
          common.localRemove("zrAdminUserProfile");
          common.localRemove("zrAdminToken");
          common.localRemove("zrAdminRefreshToken");
          common.redirect("/auth/signin")
          return Promise.reject(err);
         }

         axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.token;
         
         //console.log(originalRequest);
         return axios(originalRequest);
       }
     }
    return Promise.reject(error);
  }
);

// support
function getData(data1) {
  let data = data1;
  let transformeddata = {};
  data.query = data.query || "";
  data.keyQuery = data.keyQuery || "";
  transformeddata.method = data.method || "POST";
  console.log("data.query"+data.query)
  const isNull = (value) => typeof value === "object" && !value;
  // set url
  // if (data.query !== "") {
   if(!isNull(data.query)){ 
    transformeddata.url = (services[data.url] + data.query);
    console.log("transformeddata.url in if"+transformeddata.url)
  } else {
    transformeddata.url = services[data.url] + data.query;

    if (data.keyQuery !== "") {
      for (const key in data.keyQuery) {
        transformeddata.url = data.url.replace(`:${key}`, data.keyQuery[key]);
      }
    }
    console.log("transformeddata.url in else"+transformeddata.url)
  }
  if (data.cType === 2) {
    transformeddata.headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }else if (data.cType === 6) {
    transformeddata.headers = {
      "Content-Type": "multipart/form-data",
    };
    console.log("data:::",data)
    transformeddata.data=data.body
    console.log("transformeddata.data:::",transformeddata.data)
  }  
  else {
    transformeddata.headers = {
      "Content-Type": "application/json",
    };
  }

  // set body
  if (data.body) {
    console.log("data.body",JSON.stringify(data.body) )
    if (data.cType === 6) 
    transformeddata.data = data.body;
    else
    transformeddata.data = data.body ? JSON.stringify(data.body) : "";
  }

  console.log("transformeddata " + JSON.stringify(transformeddata));
  return transformeddata;
}

// api call
export const api = {
  call(data = {}, response, error, final) {
    axios(getData(data)).then((res) => {
      response(res);
    }).catch((err) => {
      // alert(err);
      console.log(err);
      error(err.response);
    }).then(() => {
      final()
    });
  },
};


export const syncapi = {
  call(data = {}) {
    return axios.get(getData(data))
  }
};

function getHeaders(data) {
  // default
  let headers = {};
  // content types
  const contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data"
  }
  headers["Content-Type"] = contentTypes[data.cType];
  // extra content types
  const moreHeaderList = {
    'rfv': "REST-Framework-Version"
  }
  if (data.moreHead) {
    for (const item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item]
    }
  }
  // authentication
  if (data.auth === "zrAdminToken") {
    const token = common.getAuth();
    headers.Authorization = "Bearer " + token;
  } else if (data.auth === "temp") {
    headers.Authorization = "Bearer " + localStorage.getItem("tempToken");
  } else if (data.auth === "basic") {
    headers.Authorization = "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  }
  return headers;
}

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data)
  };
  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}


function validateData(result) {
  if (config.api.isExpired !== "" && result.status === config.api.isExpired && JSON.parse(result.data).error !== "Mobile No already exists") {
    localStorage.clear();
    common.redirect("/signup");
    return false;
  }
  return true;
}