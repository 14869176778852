import styles from "./modal.module.css";

function CropModal({ handleClose, children }) {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.cropModalCard}>{children}</div>
    </div>
  );
}
export default CropModal;
