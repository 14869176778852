import { Link, useParams,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "ckeditor5-classic-plus";

const editorConfiguration = {
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: `/uploadblogimage`,

    // Enable the XMLHttpRequest.withCredentials property if required.
    withCredentials: true,

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      Authorization: "Bearer " + common.getAuth()
    }
  }
};

function Pages() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    "id": 0,
    "name": "",
    "description": "",
    "pageContent": "",
    "seoUrl": "",
    "metaTitle": "",
    "metaDesc": "",
    "metaKeywords": "",
    "active": "",
    "ordering": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });


  useEffect(() => {
    if (id)
      loadData(id);
  }, []);


  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  // handler
  const handleRteOnChange = (event, editor) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        pageContent: editor.getData(),
      };
    });
  };

  const handleSubmit = (e,sourceLabel) => {
    console.log("handleSubmit sourceLabel:",sourceLabel)
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "pages",
        query: "/" + formData.id,
        body: {
          "name": formData.name,
          "description": formData.description,
          "pageContent": formData.pageContent,
          "seoUrl": formData.seoUrl,
          "metaTitle": formData.metaTitle,
          "metaDesc": formData.metaDesc,
          "metaKeywords": formData.metaKeywords,
          "active": formData.active,
          "ordering": formData.ordering,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "pages",
        method: "POST",
        body: {
          "name": formData.name,
          "description": formData.description,
          "pageContent": formData.pageContent,
          "seoUrl": formData.seoUrl,
          "metaTitle": formData.metaTitle,
          "metaDesc": formData.metaDesc,
          "metaKeywords": formData.metaKeywords,
          "active": formData.active,
          "ordering": formData.ordering,
        },
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0)
          loadData(currentData.id)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { 
        if(sourceLabel === "Save")
        navigate('/pagesList');       
      }
    );
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "pages",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Page
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: '100%' }} >
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput type="text" label="Name" name="name" value={formData.name} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput type="text" label="Seo Url" name="seoUrl" value={formData.seoUrl} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput type="text" label="Description" multiline
                            rows={3} name="description" value={formData.description} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={formData.pageContent}
                          config={editorConfiguration}
                          onReady={editor => {
                            editor.ui.view.editable.element.style.height = '500px';
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            handleRteOnChange(event, editor)
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                      </Grid>
                      <Grid container spacing={1} mt={2}>

                        <Grid item xs={3}>
                          <MDInput type="text" label="Meta Title" multiline
                            rows={2} name="metaTitle" value={formData.metaTitle} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Meta Description" multiline
                            rows={2} name="metaDesc" value={formData.metaDesc} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Meta Keywords" multiline
                            rows={2} name="metaKeywords" value={formData.metaKeywords} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Active" name="active" value={formData.active} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput type="text" label="Ordering" name="ordering" value={formData.ordering} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, m: 1 }}>
                    <MDButton variant="gradient" color="success" sx={{ mr: 1 }} type="submit" disabled={formError} onClick={(e) => handleSubmit(e, "Apply")}>
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="info" sx={{ mr: 1 }} type="submit" disabled={formError}  onClick={(e) => handleSubmit(e, "Save")}>
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="warning" component={Link} to="/pagesList">
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Pages;
