import { useEffect, useState, useRef,useCallback } from "react";
import { Link, useParams ,useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const _ = require('lodash'); 

// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Slider from '@mui/material/Slider';

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import { canvasPreview } from "helpers/common/canvasPreview"
import { useDebounceEffect } from "helpers/common/useDebounceEffect"
import "react-image-crop/dist/ReactCrop.css"

import CropModal from "../../../../components/modal/cropModal";
import ImageCropper from "../../../../components/UI/imageCropper";
import axios from 'axios';
import { config } from "helpers/common/config";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 15,
    bottom: 15,
    border: 'none',
  },
}));


const Image = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  }),
  menuPortal: (zzz) => ({ ...zzz, zIndex: 9999 })
}

function BlogCategory() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    "id": 0,    
    "name": "",
    "slug": "",    
    "description": "",
    "coverImagePath": "",
    "views": "",
    "parentId": "",
    "metaDesc": "",
    "metaKeywords": "",
    "ordering": "",
    "active": "",   
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    loadSettings("Blog","coverImage"); 
    if (id){
      loadData(id);
      setIsDisabled(false);
    }
  }, []);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleNameSlugChange = (val) =>{
    const { name, value } = val.target;
    let slugValue =_.kebabCase(value);
    setFormData((prevState) => {
      return {
        ...prevState,
        ["name"]: value,
        ["slug"]: slugValue
      };
    });

  }

  // New Image crop -START

 const  centerAspectCrop =(mediaWidth, mediaHeight, aspect) =>{
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  // const blobUrlRef = useRef("")
  const [croppedBlob, setCroppedBlob] = useState()
  const [imageCropped, setImageCropped] = useState()


  // const [crop, setCrop] = useState()
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 375,
    height: 392
  });
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(16 / 9)

  const onSelectFile =(e) =>{
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad=(e) =>{
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  const getCroppedImgNew=async ()=> {
    console.log("previewCanvasRef:",previewCanvasRef.current)
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist")
    }

    let croppedImage1 = previewCanvasRef.current.toDataURL("image/jpeg", 0.5);
    formData.coverImagePath=croppedImage1
    setImageCropped(croppedImage1);    

    const blob = await new Promise(resolve => previewCanvasRef.current.toBlob(resolve));
    console.log("CroppedBlob",blob)
    return blob;
  }

  const [uploadFile, setUploadFile] = useState("");
  const [toggleCropModal, setToggleCropModal] = useState(false);
  const [imageRatio, setImageRatio] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(null);
  const profileImageInput = useRef(null);

  const [settingData, setSettingData] = useState({
    "id": 0,
    "settingsContext": "",
    "settingsKey": "",
    "value": "",
    "settingsType": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });

  const loadSettings = (settingsContext, settingsKey) => {
    // setIsLoading(true);
    const data1 = {
      url: "settings",
      method: "GET",
      query: `/search/advance?q={"settingsContext":{"$eq": "${settingsContext}"},"settingsKey":{"$eq": "${settingsKey}"}}&offset=0&limit=50`,
    };

    console.log("loadSettings:API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("loadSettings:res.data", res.data.items);
        let currentData = res.data.items[0];
        setSettingData(currentData);
        console.log("settingData",settingData)
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const imageUploadHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadFile(URL.createObjectURL(file));
      setToggleCropModal(true);      
      //Get image aspect Ration from setting api
      console.log("settingData.value",settingData.value)
      let splitter = new RegExp('x','i');
      const [width, height] = settingData?.value?.split(splitter)
      console.log("width, height",width, height)
      setImageRatio(width  / height);
    }
  };
  
  const getCroppedFile = (croppedImage) => {
    setCroppedImage(croppedImage);
    setToggleCropModal(false);
    formData.coverImagePath = URL.createObjectURL(croppedImage);
    setCroppedImagePreview(URL.createObjectURL(croppedImage));
    console.log("formData.coverImagePath",formData.coverImagePath);
  };
  
  const uploadImgDelete = () => {
    profileImageInput.current.value = null;
  };

  // New Image crop -END


    //Image Upload -start

const [uploadImagePopupOpen, setUploadImagePopupOpen] = useState(false);
const [imageToCrop, setImageToCrop] = useState(undefined);
// const [scale, setScale] = useState(1)
// const [crop, setCrop] = useState({
//   unit: "px", // Can be 'px' or '%'
//   x: 0,
//   y: 0,
//   width: 100,
//   height: 100,
// });
const [image, setImage] = useState(null);
const [imageRef, setImageRef] = useState(null);

  const onUploadFile = (event) => {
  console.log("Files :" + event.target.files[0].name);
  if (event.target.files && event.target.files.length > 0) {
    const reader = new FileReader();

    reader.addEventListener("load", () => setImageToCrop(reader.result));

    reader.readAsDataURL(event.target.files[0]);
  }
};

  const getCroppedImg = () => {
  const canvas = document.createElement("canvas");
  const scaleX = imageRef?.naturalWidth / imageRef?.width;
  const scaleY = imageRef?.naturalHeight / imageRef?.height;

  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    imageRef,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  let dataURL = canvas.toDataURL("image/jpeg", 0.5);
  let blob = dataURItoBlob(dataURL); // for api upload

  formData.croppedImage = dataURL; // Base64 for formdata.
  console.log("blob:", blob);
  console.log("formData.croppedImage:", formData.croppedImage);
  return blob;
};

  const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

  const handleUploadImagePopup = (e) => {
    setCrop({
      unit: 'px', // Can be 'px' or '%'
      x: 0,
      y: 0,
      width: 375,
      height: 392
    });
    // onUploadFile(e);
    onSelectFile(e);
    setUploadImagePopupOpen(true);
  };

  const handleUploadImagePopupClose = () => {
    setUploadImagePopupOpen(false);
  };

  const handleUploadImage = async (e) => {
    console.log("handleUploadImage")
    const croppedBlob = await getCroppedImgNew();
    setCroppedBlob(croppedBlob);   
    e.preventDefault();    
    setUploadImagePopupOpen(false);
  }
  //Image Upload -end


  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "blogCategories",
        query: "/" + formData.id,
        body: {
          "name": formData.name,
          "slug": formData.slug,          
          "description": formData.description,
          // "coverImagePath": formData.coverImagePath,
          "views": formData.views,
          "parentId": formData.parentId,
          "metaDesc": formData.metaDesc,
          "metaKeywords": formData.metaKeywords,
          "ordering": formData.ordering,
          "active": formData.active,         
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "blogCategories",
        method: "POST",
        body: {
          "name": formData.name,
          "slug": formData.slug,          
          "description": formData.description,
          // "coverImagePath": formData.coverImagePath,
          "views": formData.views,
          "parentId": formData.parentId,
          "metaDesc": formData.metaDesc,
          "metaKeywords": formData.metaKeywords,
          "ordering": formData.ordering,
          "active": formData.active,         
        },
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0)        
          loadData(currentData.id)        
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { }
    );
    if(croppedImage) // Only image uploaded
      uploadCoverImage();
    if(sourceLabel === "Save")
      navigate('/blogcategorieslist'); 
  }

  const uploadCoverImage = () => {
    console.log("uploadCoverImage-croppedImage",croppedImage)
    // const croppedImage = getCroppedImg();
    // console.log("uploadCoverImage-croppedImage", croppedImage)
    setIsLoading(true);
    let payLoad = new FormData()
    payLoad.append("image", croppedImage, "imageUpload.png")
    console.log("payLoad", payLoad.get("image"))

    let data1 = {
      url: "blogCategories",
      query: "/uploadImage/" + formData.id,
      method: "POST",
      body: payLoad,
      cType: 6
    };
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        if (currentData?.id > 0){
          loadData(currentData?.id) 
          setCroppedImagePreview(currentData?.coverImagePath)
        }      
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { }
    );
    setIsLoading(false);
  };


  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "blogCategories",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        setCroppedImagePreview(currentData?.coverImagePath)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Blog Category
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={2}>
                          {/* <StyledBadge
                            overlap="square"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            size="sm"
                            badgeContent={
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                size="xl"
                                // onClick={handleUploadImagePopup}
                                onChange={handleUploadImagePopup}
                              >
                                <input hidden accept="image/*" type="file" />
                                <CloudUploadRounded />
                              </IconButton>
                            }
                          >
                            <Image
                              src={formData.coverImagePath}
                              variant="square"
                              size="xxl"
                              shadow="sm"
                              // onClick={handleUploadImagePopup}
                            ></Image>
                          </StyledBadge> */}

                          {/* <Dialog open={uploadImagePopupOpen} onClose={handleUploadImagePopupClose}>
                            <DialogTitle>Upload Avatar</DialogTitle>
                            <DialogContent>
                              <DialogContentText>Only PNG/JPG Allowed</DialogContentText>
                              <Grid container spacing={1} mt={2}>
                                <Grid item xs={12}>
                                
                                  {!!imgSrc && (
                                    <ReactCrop
                                      crop={crop}
                                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                                      onComplete={(c) => setCompletedCrop(c)}
                                      aspect={aspect}
                                      minWidth={100}
                                      minHeight={100}
                                      maxWidth={100}
                                      maxHeight={100}
                                      locked={true}
                                    >
                                      <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{
                                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                                        }}
                                        onLoad={onImageLoad}
                                      />
                                    </ReactCrop>
                                  )}
                                  {!!completedCrop && (
                                    
                                      <div>
                                        <canvas 
                                          hidden
                                          ref={previewCanvasRef}
                                          style={{
                                            border: "1px solid black",
                                            objectFit: "contain",
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                          }}
                                        />
                                      </div>
                                    
                                  )}
                                  <MDBox width={300}>
                                    <Slider
                                      size="small"
                                      defaultValue={100}
                                      onChange={(e) => {setScale(Number(e.target.value) / 100); }}
                                      aria-label="Small"
                                      valueLabelDisplay="auto"
                                      min={50}
                                      max={200}
                                    />
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={handleUploadImagePopupClose}>Cancel</MDButton>
                              <MDButton onClick={handleUploadImage}>Ok</MDButton>
                            </DialogActions>
                          </Dialog> */}
                          <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                size="sm"
                                badgeContent={
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    size="xl"
                                    onChange={imageUploadHandle}
                                  >
                                    <input
                                      hidden
                                      accept="image/*"
                                      type="file"
                                      id="profileImage"
                                      ref={profileImageInput}
                                    />
                                    <CloudUploadRounded />
                                  </IconButton>
                                }
                              >
                                <Image
                                  src={croppedImagePreview} //{formData.image}
                                  variant="square"
                                  size="xxl"
                                  shadow="sm"
                                ></Image>
                              </StyledBadge>

                              {toggleCropModal && (
                                <CropModal>
                                  <ImageCropper
                                    uploadFile={uploadFile}
                                    getCroppedFile={getCroppedFile}
                                    imageRatio={imageRatio}
                                    cropShape="rect"
                                    uploadFileType="image"
                                    closeCropPopup={() => {
                                      setToggleCropModal(false);
                                      if (croppedImage)
                                        formData.coverImagePath = URL.createObjectURL(croppedImage);
                                    }}
                                    uploadImgDelete={uploadImgDelete}
                                  />
                                </CropModal>
                              )} 
                        </Grid>
                        <Grid item xs={2}>
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleNameSlugChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <MDInput
                            type="text"
                            disabled={isDisabled}
                            label="Slug"
                            name="slug"
                            value={formData.slug}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={2}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        {/* <Grid item xs={4}>
                          <MDInput type="text" label="CoverImagePath" name="coverImagePath" value={formData.coverImagePath} onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth />
                        </Grid> */}
                        <Grid item xs={1}>
                          <MDInput
                            type="number"
                            label="Views"
                            name="views"
                            value={formData.views}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="text"
                            label="Meta Keywords"
                            name="metaKeywords"
                            value={formData.metaKeywords}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <MDInput
                            type="text"
                            label="ParentId"
                            name="parentId"
                            value={formData.parentId}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Meta Description"
                            multiline
                            rows={2}
                            name="metaDesc"
                            value={formData.metaDesc}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active ? "Y" : null}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            type="number"
                            label="Ordering"
                            name="ordering"
                            value={formData.ordering}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                    <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/blogcategorieslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlogCategory;
