import styles from "./imageCropper.module.css";
import Cropper from "react-easy-crop";
import { useState, useCallback } from "react";
import getCroppedImg from "./cropImage";

function ImageCropper({
  uploadFile,
  getCroppedFile,
  imageRatio,
  cropShape,
  closeCropPopup,
  uploadFileType,
  uploadImgDelete,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(uploadFile, croppedAreaPixels);
      setCroppedImage(croppedImage);
      getCroppedFile(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);
  const closeCropModal = () => {
    setCroppedImage(null);
    closeCropPopup();
    uploadImgDelete(uploadFileType);
  };
  return (
    <>
      <div className={styles.cropper}>
        <Cropper
          objectFit="auto-cover"
          image={uploadFile}
          aspect={imageRatio}
          crop={crop}
          zoom={zoom}
          cropShape={cropShape}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropAreaChange={(croppedArea) => {
            setCroppedArea(croppedArea);
          }}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className={styles.cropModalActions}>
        <button
          type="button"
          className="primaryBorderBtn"
          onClick={closeCropModal}
        >
          Cancel
        </button>
        <button type="button" className="primaryBtn" onClick={showCroppedImage}>
          Crop
        </button>
      </div>
    </>
  );
}
export default ImageCropper;
