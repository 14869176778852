/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function PeopleList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);
  const [changeCounter, setChangeCounter]= useState(0);

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole('Admin');

    console.log("filters",filters)
    let url =
      `${config.api.url}people/search/advance?totalResults=true` +
      '&offset=' +
      skip +
      '&limit=' +
      limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;

    console.log("url",url)
    return axios.get(url).then(res => {
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }

  const dataSource = useCallback(loadData, [changeCounter]);

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [formData, setFormData] = useState({
    "id": 0,
    "name": "",
    "primarySkill": "",
    "description": "",
    "ordering": "",
    "active": "",
    "image": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });

  const uploadFiles = (event) => {
      console.log("event.target.files", event.target.files);
  
      setSelectedFiles(event.target.files);
      const files = Array.from(event.target.files);
      console.log("files", files);
      setIsLoading(true);
      if (files && files.length > 0) {
        files.map((file) => {
          console.log("file.name", file.name);
          let completedFileName = file.name;
          let [fileName] = completedFileName.split(".");
          let [name, role] = fileName.split("-");
          formData.name = name;
          formData.primarySkill = role;
          formData.description = role;
          formData.ordering = 1;
          formData.active = "Y";
          uploadPeople(event, file);
          // uploadPeopleImage(file)
        });
      }
    };
  
    const uploadPeople = (e, file) => {
      e.preventDefault();
      setIsLoading(true);
      let data1 = {};
      console.log("formData.id", formData.id);
      if (formData.id > 0)
        data1 = {
          url: "people",
          query: "/" + formData.id,
          body: {
            name: formData.name,
            primarySkill: formData.primarySkill,
            description: formData.description,
            ordering: formData.ordering,
            active: formData.active,
            // "image": formData.image,
          },
          method: "PATCH",
        };
      else
        data1 = {
          url: "people",
          method: "POST",
          body: {
            name: formData.name,
            primarySkill: formData.primarySkill,
            description: formData.description,
            ordering: formData.ordering,
            active: formData.active,
            // "image": formData.image,
          },
        };
  
      console.log("API data1", data1);
      api.call(
        data1,
        (res) => {
          console.log(res);
          let currentData = res.data.item;
          common.notify("S", "Saved Successfully");
          if (currentData.id > 0) uploadPeopleImage(file, currentData.id);
          setChangeCounter(changeCounter+1);
          setIsLoading(false);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => {}
      );
    };
  
    const uploadPeopleImage = (file, peopleId) => {
      console.log("uploadPeopleImage");
      setIsLoading(true);
      let payLoad = new FormData();
      payLoad.append("image", file, file.name);
      console.log("payLoad", payLoad.get("image"));
  
      let data1 = {
        url: "people",
        query: "/uploadImage/" + peopleId,
        method: "POST",
        body: payLoad,
        cType: 6,
      };
      api.call(
        data1,
        (res) => {
          console.log(res);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setChangeCounter(changeCounter+1);
          setIsLoading(false);
        },
        (final) => {}
      );
      setIsLoading(false);
    };



    const handleSwitchChange = (event,id) => {
      console.log("checked ", event.target.checked);
      const { name, checked } = event.target;
      if (id> 0) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          "active": checked ? "Y" : null
        }
        wsData = {
          url: "people",
          query: "/" + id,
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            setChangeCounter(changeCounter+1);
            //loadData();        
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      }
  
    };
  

  // const dataSource = useCallback(loadData, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  People
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin &&
                  <MDButton variant="gradient"  sx={{ mr: 1 }} color="secondary" component={Link} to="/people">
                    New
                  </MDButton>
                } 
                
                {isAdmin &&
                  <MDButton variant="contained" color="secondary" component="label" onChange={uploadFiles} >
                    Add Multiple
                    <input hidden accept="image/*" multiple type="file" />
                  </MDButton>
                }
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    {
                      header: "Action", name: "id", defaultWidth: 100, headerAlign: "center", textAlign: 'center', render: ({ value }) => <MDTypography component={Link}
                        to={'/people/' + value} variant="caption" color="text" fontWeight="medium">
                        Edit
                      </MDTypography>
                    },
                    { header: "Name", name: "name", defaultFlex: 2, render: ({ data, value }) => 
                    <MDBox display="flex" alignItems="center" lineHeight={1}>
                    <MDAvatar src={data.image} name={value} size="sm" />
                    <MDBox ml={2} lineHeight={1}>
                      <MDTypography display="block" variant="button" fontWeight="medium">
                        {value}
                      </MDTypography>
                      <MDTypography variant="caption">{data.primarySkill}</MDTypography>
                    </MDBox>
                  </MDBox>
                  },
                    { header: "Primary Skill", name: "primarySkill", defaultFlex: 1 },
                    { header: "Description", name: "description", defaultFlex: 1.5 },
                    // { header: "Ordering", name: "ordering", defaultFlex: 0.7, textAlign: "right" },
                    { header: "Active", name: "active", defaultFlex:0.6, textAlign: "center",
                    render: ({ data, value }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            name="active"
                            checked={(data.active==="Y") ? true : false}
                            onChange={(e)=> handleSwitchChange(e,data.id)}
                            inputProps={{
                              "aria-label": "controlled",
                              align: "center",
                            }}
                          />
                        }
                        label=""
                      />
                    ), } ,                
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    // { name: 'id', operator: 'eq', type: 'number', value: '' },
                    { name: 'name', operator: 'startsWith', type: 'string', value: '' },
                    { name: 'primarySkill', operator: 'startsWith', type: 'string', value: '' },
                    { name: 'description', operator: 'startsWith', type: 'string', value: '' },
                    // { name: 'ordering', operator: 'eq', type: 'string', value: '' },                    
                    { name: 'active', operator: 'startsWith', type: 'string', value: '' },                    
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PeopleList;
