import { Link, useParams,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

function Role() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));

  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    "id": 0,
    "roleName": "",    
    "description": "",    
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null
  });


  useEffect(() => {
    if (id)
      loadData(id);
  }, []);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    let data1 = {};
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "roles",
        query: "/" + formData.id,
        body: {
          "roleName": formData.roleName,       
          "description": formData.description,
          
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "roles",
        method: "POST",
        body: {
          "roleName": formData.roleName,       
          "description": formData.description,
        },
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0)
          loadData(currentData.id)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
          navigate('/roleslist'); 
       }
    );
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "roles",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Role
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Role Name"
                            name="roleName"
                            value={formData.roleName}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={7}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={2}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton variant="gradient" color="warning" component={Link} to="/roleslist">
                        Cancel
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Role;
