/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { config } from "helpers/common/config";
import axios from "axios";
import moment from "moment";


const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 };

function ServiceRequestsList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole("Admin"));
  const [sortInfo, setSortInfo] = useState([]);
  const defaultSortInfo = [{ name: "id", dir: -1 }];
  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole("Admin");

    console.log("filters", filters);
    let url =
      `${config.api.url}serviceRequests/search/advance?totalResults=true` +
      "&offset=" +
      skip +
      "&limit=" +
      limit;

    if (filters && filters !== "") url += "&q=" + filters;

    if (sorts && sorts !== "") url += "&orderBy=" + sorts;

    console.log("url", url);
    return axios.get(url).then((res) => {
      return Promise.resolve({ data: res.data.items, count: res.data.items?.length });
    });
  };
  const [changeCounter, setChangeCounter] = useState(0);

  const dataSource = useCallback(loadData, [changeCounter]);

  const handleSwitchChange = (event, id) => {
    console.log("checked ", event.target.checked);
    const { name, checked } = event.target;
    if (id > 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        active: checked ? "Y" : null,
      };
      wsData = {
        url: "serviceRequests",
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          setChangeCounter(changeCounter + 1);
          //loadData();
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  // const dataSource = useCallback(loadData, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Service Requests
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin && (
                  <MDButton
                    variant="gradient"
                    color="secondary"
                    component={Link}
                    to="/serviceRequest"
                  >
                    New
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    {
                      header: "Action",
                      name: "id",
                      defaultWidth: 100,
                      headerAlign: "center",
                      textAlign: "center",
                      render: ({ value }) => (
                        <MDTypography
                          component={Link}
                          to={"/serviceRequest/" + value}
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Edit
                        </MDTypography>
                      ),
                    },
                    { header: "SR NO", name: "id", defaultFlex: 1 },
                    { header: "RequestType", name: "requestType", defaultFlex: 1.2 },
                    { header: "Summary", name: "summary", defaultFlex: 1.5 },
                    {
                      header: "CreationDate",
                      name: "creationDate",
                      defaultFlex: 1.3,
                      render: ({ data, value }) => (
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                          {data.creationDate
                            ? moment(data.creationDate).format("DD-MM-YYYY HH:mm")
                            : ""}
                        </MDTypography>
                      ),
                    },
                    { header: "Severity", name: "severity", defaultFlex: 1, textAlign: "center" },
                    {
                      header: "RequestStatus",
                      name: "requestStatus",
                      defaultFlex: 1.2,
                      textAlign: "center",
                    },
                    { header: "Ordering", name: "ordering", defaultFlex: 1, textAlign: "center" },
                    {
                      header: "Active",
                      name: "active",
                      defaultFlex: 0.6,
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              checked={data.active === "Y" ? true : false}
                              onChange={(e) => handleSwitchChange(e, data.id)}
                              inputProps={{
                                "aria-label": "controlled",
                                align: "center",
                              }}
                            />
                          }
                          label=""
                        />
                      ),
                    },
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  defaultSortInfo={defaultSortInfo}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    // { name: "id", operator: "startsWith", type: "string", value: "" },
                    { name: "requestType", operator: "startsWith", type: "string", value: "" },
                    { name: "summary", operator: "startsWith", type: "string", value: "" },
                    { name: "creationDate", operator: "startsWith", type: "string", value: "" },
                    { name: "severity", operator: "startsWith", type: "string", value: "" },
                    { name: "requestStatus", operator: "startsWith", type: "string", value: "" },
                    { name: "ordering", operator: "eq", type: "string", value: "" },
                    { name: "active", operator: "startsWith", type: "string", value: "" },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ServiceRequestsList;
