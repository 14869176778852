/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { config } from "helpers/common/config";

import { api, syncapi } from "helpers/common/api";

import axios from 'axios'

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function CollectionsList() {
  const [changeCounter, setChangeCounter]= useState(0);
  const [isLoading, setIsLoading]= useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = common.getFilter(filterValue);
    const sorts = common.getSort(sortInfo);
    const isAdmin = common.userInRole('Admin');

    console.log("filters",filters)
    let url =
      `${config.api.url}collections/search/advance?totalResults=true` +
      '&offset=' +
      skip +
      '&limit=100' //+
      //limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;

    console.log("url",url)
    return axios.get(url).then(res => {
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }

  const dataSource = useCallback(loadData, [changeCounter]);

  const handleSwitchChange = (event,id) => {
    console.log("checked ", event.target.checked);
    const { name, checked } = event.target;
    if (id> 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        "active": checked ? "Y" : null
      }
      wsData = {
        url: "collections",
        query: "/" + id,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          setChangeCounter(changeCounter+1);
          //loadData();        
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => { }
      );
    }

  };


  const onCollectionEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      console.log(rowId);
      console.log(columnId);
     
      if ((columnId === "ordering") && rowId > 0) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: value
        }
        wsData = {
          url: "collections",
          query: "/" + rowId,
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            setChangeCounter(changeCounter+1);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      }
    },
    [dataSource]
  );
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Collections
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                {isAdmin && (
                  <MDButton variant="gradient" color="secondary" component={Link} to="/collection">
                    New
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    {
                      header: "Action",
                      name: "id",
                      defaultWidth: 100,
                      headerAlign: "center",
                      textAlign: "center",
                      render: ({ value }) => (
                        <MDTypography
                          component={Link}
                          to={"/collection/" + value}
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Edit
                        </MDTypography>
                      ),
                    },
                    { header: "Name", name: "name", defaultFlex: 0.5 },
                    { header: "Type", name: "type", defaultFlex: 1 },
                    { header: "Description", name: "description", defaultFlex: 1.2 },
                    {
                      header: "Ordering",
                      name: "ordering",
                      defaultFlex: 0.7,
                      headerAlign: "center",
                      textAlign: "center",
                    },
                    {
                      header: "Active",
                      name: "active",
                      defaultFlex: 0.5,
                      headerAlign: "center",
                      textAlign: "center",
                      render: ({ data, value }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              name="active"
                              checked={(data.active==="Y") ? true : false}
                              onChange={(e)=> handleSwitchChange(e,data.id)}
                              inputProps={{
                                "aria-label": "controlled",
                                align: "center",
                              }}
                            />
                          }
                          label=""
                        />
                      ),
                    },
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  editable
                  onEditComplete={onCollectionEditComplete}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    // { name: "name", operator: "startsWith", type: "string", value: "" },
                    { name: "type", operator: "startsWith", type: "string", value: "" },
                    // { name: "description", operator: "startsWith", type: "string", value: "" },
                    // { name: "ordering", operator: "eq", type: "number", value: "" },
                    { name: "active", operator: "startsWith", type: "string", value: "" },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CollectionsList;
