/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
// Material Dashboard 2 React examples
import DataTable from "coreui/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Worklist/data";
import { Audio } from 'react-loader-spinner'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { api } from "helpers/common/api";
import { common } from "helpers/common/common";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 550, marginRight: 20, marginLeft: 20 }


function Worklist() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const getFilter = (filterValue) => {
    let filterString = "";
    let locOperator = "=";
    for (let obj of filterValue) {

      if (obj.type === "string") {
        switch (obj.operator) {
          case "startsWith":
            locOperator = " LIKE " + obj.value + "*";
            break;
          case "endsWith":
            locOperator = " LIKE *" + obj.value;
            break;
          case "empty":
            locOperator = " IS NULL";
            break;
          case "notEmpty":
            locOperator = " IS NOT NULL";
            break;
          case "contains":
            locOperator = " LIKE *" + obj.value + "*";
            break;
          case "notContains":
            locOperator = " NOT LIKE *" + obj.value + "*";
            break;
          case "neq":
            locOperator = "!=" + obj.value;
            break;
          default:
            locOperator = "=" + obj.value;
        }
      }
      else if (obj.type === "number") {
        switch (obj.operator) {
          case "gte":
            locOperator = ">" + obj.value;
            break;
          case "lte":
            locOperator = ">" + obj.value;
            break;
          case "neq":
            locOperator = "!=" + obj.value;
            break;
          default:
            locOperator = "=" + obj.value;
        }
      }
      else if (obj.type === "date") {
        const derivedDate = moment(obj.value, "DD/MM/YYYY").format("YYYY-MM-DD");
        switch (obj.operator) {
          case "eq":
            locOperator = "=" + derivedDate;
            break;
          case "after":
            locOperator = ">" + derivedDate;
            break;
          case "afterOrOn":
            locOperator = ">=" + derivedDate;
            break;
          case "before":
            locOperator = "<" + derivedDate;
            break;
          case "beforeOrOn":
            locOperator = "<=" + derivedDate;
            break;
          case "neq":
            locOperator = "!=" + derivedDate;
            break;
          default:
            locOperator = "=" + derivedDate;
        }
      }

      if (obj.value !== '' && obj.value !== null)
        filterString += obj.name + locOperator + ';'
    }
    //console.log(filterString);
    return filterString.slice(0, -1);
  }


  const getSort = (sortInfo) => {
    let sortString = "";
    let locOperator = "=";
    if (sortInfo) {
      switch (sortInfo.dir) {
        case 1:
          locOperator = ":asc";
          break;
        case -1:
          locOperator = ":desc";
          break;
        default:
          locOperator = "";
      }
      sortString += sortInfo.name + locOperator + ','
    }
    console.log(sortString);
    return sortString.slice(0, -1);
  }

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    setIsLoading(true);
    const filters = getFilter(filterValue);
    const sorts = getSort(sortInfo);
    let url = `${config.api.url}TnplWfNotifications?offset=${skip}&limit=${limit}&totalResults=true`;

    if (filters && filters !== "")
      url += `&q=RecipientRole=${common.getEmployeeNo()};` + filters;
    else
      url += `&q=RecipientRole=${common.getEmployeeNo()}`;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;
    else
      url += '&orderBy=Id:desc';

    return axios.get(url).then(res => {
      setIsLoading(false);
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    }).catch(err => setIsLoading(false));

  }

  const dataSource = useCallback(loadData, []);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
    </Menu>
  );


  return (

    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Universal Worklist
          </MDTypography>

        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>

        <ReactDataGrid
          idProperty="Id"
          style={gridStyle}
          loading={isLoading}
          showZebraRows
          showHoverRows
          showCellBorders="false"
          columns={[
            {
              header: "Module", name: "Context", defaultFlex: 0.4, filterEditor: SelectFilter,
              filterEditorProps: {
                placeholder: 'All',
                dataSource: [{ id: 'HR', label: 'HR' }, { id: 'PURCHASE', label: 'PURCHASE' },]
              }, render: ({ value }) =>
                <MDBadge badgeContent={value} color={value === 'HR' ? 'error' : 'success'} variant="gradient" size="sm" />
            },
            {
              header: "Notification Date", name: "BeginDate", defaultFlex: 0.4, minWidth: 200,
              filterEditor: DateFilter, filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                  dateFormat: 'DD/MM/YYYY',
                  cancelButton: false,
                  highlightWeekends: false,
                  placeholder: index === 1 ? 'Notification date before...' : 'Notification date Equals...'
                }
              }, render: ({ value }) => { return moment(value).format('DD/MM/YYYY') }
            },
            
            {
              header: "Status", name: "Status", defaultFlex: 0.4, filterEditor: SelectFilter,
              filterEditorProps: {
                placeholder: 'All',
                dataSource: [{ id: 'NEW', label: 'New' }, { id: 'COMPLETE', label: 'Complete' }, { id: 'REJECTED', label: 'Rejected' }]
              }, render: ({ value }) =>
                <MDBadge badgeContent={value} color={value === 'REJECTED' ? 'error' : 'success'} variant="gradient" size="sm" />
            },
            {
              header: "Subject", name: "Subject", defaultFlex: 1.5, render: ({ data,value }) =>
                <MDTypography component={Link} to={'/' + data.MessageName + '/' + data.ItemKey + '?fromWorklist=Y'} variant="caption" color="text" fontWeight="medium">
                  {value}
                </MDTypography>
            },
            {
              header: "Requester", name: "FullName", defaultFlex: 0.7, render: ({ data, value }) =>
                <MDTypography component={Link} to={'/' + data.MessageName + '/' + data.ItemKey + '?fromWorklist=Y'} variant="caption" color="text" fontWeight="medium">
                  ({data.FromUser}) {data.FullName}
                </MDTypography>
            },
            {
              header: "Due Date", name: "DueDate", defaultFlex: 0.4, minWidth: 200,
              filterEditor: DateFilter, filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                  dateFormat: 'DD/MM/YYYY',
                  cancelButton: false,
                  highlightWeekends: false,
                  placeholder: index === 1 ? 'Due date before...' : 'Due date Equals...'
                }
              }, render: ({ value }) => { return moment(value).format('DD/MM/YYYY') }
            },
          ]}
          dataSource={dataSource}
          pagination
          defaultLimit={10}
          onSortInfoChange={setSortInfo}
          enableColumnAutosize
          enableFiltering
          defaultFilterValue={[
            { name: 'Context', operator: 'eq', type: 'string', value: '' },
            { name: 'FullName', operator: 'contains', type: 'string', value: '' },
            { name: 'BeginDate', operator: 'eq', type: 'date', value: '' },
            { name: 'Status', operator: 'eq', type: 'string', value: 'NEW' },
            { name: 'Subject', operator: 'contains', type: 'string', value: '' },
            { name: 'DueDate', operator: 'eq', type: 'date', value: '' },
          ]
          }
        />
      </MDBox>
    </Card>
  );
}

export default Worklist;
