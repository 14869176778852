export const config = {
  // api
  api: {
    // setup
    isExpired: 401, // set empty for unvalidate
    //LOCALDEV
    //url: "http://3.110.165.28:3000/",
    //auth: "http://3.110.165.28:3000/",
    //fileUrl: "http://3.110.165.28:3000/",
    //cdnUrl: "https://d1sr2n8o72b8wc.cloudfront.net/",
    //REMOTEDEV
    url: "https://devapi.zettarights.com/ws/admin/",
    auth: "https://devapi.zettarights.com/ws/admin/",
    fileUrl: "https://devapi.zettarights.com/ws/admin/",
    cdnUrl: "https://d1sr2n8o72b8wc.cloudfront.net/",
    //PROD
    //url: "http://3.110.165.28:3000/",
    //auth: "http://3.110.165.28:3000/",
    //fileUrl: "http://3.110.165.28:3000/",
    //cdnUrl: "https://d1sr2n8o72b8wc.cloudfront.net/",
  },
};
