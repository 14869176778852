/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { useEffect, useState, useCallback } from "react";
import { common } from "helpers/common/common";
import { api } from "helpers/common/api";
import { ThreeDots } from 'react-loader-spinner'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 535, marginRight: 20, marginLeft: 20 }

function LogsList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);

  const getFilter = (filterValue) => {
    let filterString = "";
    let locOperator = "=";
    for (let obj of filterValue) {
      console.log("name", obj.name);
      console.log("operator", obj.operator);
      console.log("value", obj.value);

      if (obj.type === "string") {
        switch (obj.operator) {
          case "startsWith":
            locOperator = " LIKE " + obj.value + "*";
            break;
          case "endsWith":
            locOperator = " LIKE *" + obj.value;
            break;
          case "empty":
            locOperator = " IS NULL";
            break;
          case "notEmpty":
            locOperator = " IS NOT NULL";
            break;
          case "contains":
            locOperator = " LIKE *" + obj.value + "*";
            break;
          case "notContains":
            locOperator = " NOT LIKE *" + obj.value + "*";
            break;
          case "neq":
            locOperator = "!=" + obj.value;
            break;
          default:
            locOperator = "=" + obj.value;
        }
      }
      else if (obj.type === "number") {
        switch (obj.operator) {
          case "gte":
            locOperator = ">" + obj.value;
            break;
          case "lte":
            locOperator = ">" + obj.value;
            break;
          case "neq":
            locOperator = "!=" + obj.value;
            break;
          default:
            locOperator = "=" + obj.value;
        }
      }

      if (obj.value !== '')
        filterString += obj.name + locOperator + ';'
    }
    console.log(filterString);
    return filterString.slice(0, -1);
  }


  const getSort = (sortInfo) => {
    let sortString = "";
    let locOperator = "=";
    if (sortInfo) {
      switch (sortInfo.dir) {
        case 1:
          locOperator = ":asc";
          break;
        case -1:
          locOperator = ":desc";
          break;
        default:
          locOperator = "";
      }
      sortString += sortInfo.name + locOperator + ','
    }
    console.log(sortString);
    return sortString.slice(0, -1);
  }

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    const filters = getFilter(filterValue);
    const sorts = getSort(sortInfo);
    const empNo = common.getEmployeeNo();
    const isAdmin = common.userInRole('Admin');

    let url =
      `${config.api.url}logs?onlyData=true&totalResults=true` +
      '&page=' +
      skip +
      '&limit=' +
      limit;

    if (filters && filters !== "")
      url += '&q=' + filters;

      if (!isAdmin) {
        url =
          `${config.api.url}logs?onlyData=true&totalResults=true` +
          '&page=' +
          skip +
          '&limit=' +
          limit;
  
  
      }

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;

   

    return axios.get(url).then(res => {
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    });
  }


  const dataSource = useCallback(loadData, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  View Logs
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  showZebraRows
                  showHoverRows
                  showCellBorders="vertical"
                  columns={[
                    { header: "Log Id", name: "log_id", defaultFlex: 0.3 , sortable: true},
                    { header: "Log Date", name: "log_date", defaultFlex: 0.5, align: "left", sortable: true },
                    { header: "Message", name: "message", defaultFlex: 1.5, align: "left" },
                  ]}
                  pagination
                  dataSource={dataSource}
                  defaultLimit={10}
                  onSortInfoChange={setSortInfo}
                  defaultFilterValue={[
                    { name: 'log_id', operator: 'eq', type: 'number', value: '' },
                    { name: 'log_date', operator: 'eq', type: 'date', value: '' },
                    { name: 'message', operator: 'contains', type: 'string', value: '' },
                  ]}
                  enableColumnAutosize
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LogsList;
