import { Link, useParams,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";
import { common } from "helpers/common/common";
import { api, syncapi } from "helpers/common/api";
import { useEffect, useState, useCallback } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AsyncSelect from 'react-select/async'
import moment from "moment";
import axios from 'axios'
import { config } from "helpers/common/config";

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

const customSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: 100
  })
}

function Collections() {
  const { id } = useParams();
  console.log(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);
  const [formError, setFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [errorFormField, setErrorFormField] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [isStaticType, setIsStaticType] = useState(undefined);
  const [contentQuery, setContentQuery] = useState("");
  const [contentSelected, setContentSelected] = useState({});
  const onContentSelectionChange = useCallback(({ selected }) => {
    console.log("onContentSelectionChange-selected", selected);
    setContentSelected(selected)
  }, [])

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    type: "Static",
    description: "",
    dynamicCriteria: "",
    ordering: "",
    active: "",
    createdBy: null,
    creationDate: null,
    lastUpdatedBy: null,
    lastUpdateDate: null,
    lastUpdateLogin: null,
    versionNumber: null,
    contents: [],
  });

  const [formContentData, setFormContentData] = useState({
    "id": 0,
    "contentId": "",
    "collectionId": "",
    "ordering": "",
    "active": "",
    "createdBy": null,
    "creationDate": null,
    "lastUpdatedBy": null,
    "lastUpdateDate": null,
    "lastUpdateLogin": null,
    "versionNumber": null,
    content: {
      "id": null,
      "title": null,
      "primaryImage": null
    },
  });

  useEffect(() => {
    if (id)
      loadData(id);
  }, []);

  const onContentEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...formData.contents];
      const index = data.findIndex((p) => p.id === rowId);
      console.log(rowId);
      console.log(columnId);
      console.log('data[index]', data[index]);
      console.log('data[index]["id"]', data[index]["contentCollections"]["id"]);
      //console.log(data[rowId])
      console.table(data);
      data[index][columnId] = value;
      setFormData((prevState) => {
        return {
          ...prevState,
          contents: data,
        };
      });
  
      if (columnId === "ordering" && data[index]["contentCollections"]["id"]) {
        let wsData = {};
        let bodyData = {};
        bodyData = {
          ordering: data[index]["ordering"],
        }
        wsData = {
          url: "contentCollections",
          query: "/" + data[index]["contentCollections"]["id"],
          body: bodyData,
          method: "PATCH",
        };
        api.call(
          wsData,
          (res) => {
            common.notify("S", "Saved Successfully");
            loadData(id);
          },
          (error) => {
            common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          },
          (final) => { }
        );
      }
    },
    [formData.contents]
  );

  const loadContentOptions = (inputValue) => { return axios
    .get(
      `${config.api.url}content/search/advance?q={"titleEn":{"$like": "*${inputValue}*"}}&offset=0&limit=50`
    )
    .then((res) => res.data.items); }

  const onContentChange = (value) => {
    console.log(value);
    if (value) {
      let localData = formContentData;
      localData.contentId = value.id;
      localData.collectionId = formData.id;
      setFormContentData(localData);
    }
  }

  const handleSwitchChange = (event) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;
    console.log("name ", name, " checked", checked);
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: checked ? "Y" : null,
      };
    });
  };

  const handleGridSwitchChange = (event, contentColid) => {
    console.log("checked ", event.target);
    const { name, checked } = event.target;

    if (contentColid > 0) {
      let wsData = {};
      let bodyData = {};
      bodyData = {
        active: checked ? "Y" : null,
      };
      wsData = {
        url: "contentCollections",
        query: "/" + contentColid,
        body: bodyData,
        method: "PATCH",
      };
      api.call(
        wsData,
        (res) => {
          common.notify("S", "Saved Successfully");
          loadData(id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        },
        (final) => {}
      );
    }
  };

  const handleChange = (val) => {
    const { name, value } = val.target;
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const handleSubmit = (e,sourceLabel) => {
    e.preventDefault();
    setIsLoading(true);
    if(formData.type === "Static")
      formData.dynamicCriteria =""; // reset it if it provided
    let data1 = {};
    console.log("formData.id", formData.id)
    if (formData.id > 0)
      data1 = {
        url: "collections",
        query: "/" + formData.id,
        body: {
          "name": formData.name,
          "type": formData.type,
          "description": formData.description,
          "dynamicCriteria": formData.dynamicCriteria,
          "ordering": formData.ordering,
          "active": formData.active,
        },
        method: "PATCH",
      };
    else
      data1 = {
        url: "collections",
        method: "POST",
        body: {
          "name": formData.name,
          "type": formData.type,
          "description": formData.description,
          "dynamicCriteria": formData.dynamicCriteria,
          "ordering": formData.ordering,
          "active": formData.active,
        },
      };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        if (currentData.id > 0)
          loadData(currentData.id)
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => {
        if(sourceLabel === "Save")
        navigate('/collectionslist'); 
       }
    );
  }

  const loadData = (pId) => {
    setIsLoading(true);
    const data1 = {
      url: "collections",
      method: "GET",
      query: "/" + pId,
    };

    console.log("API data1", data1)
    api.call(
      data1,
      (res) => {
        console.log("res.data", res.data.item);
        let currentData = res.data.item;
        setFormData(currentData);
        if (currentData.type === "Static") {
          setIsStaticType(true)
        }
        setIsLoading(false);
      },
      (error) => {
        common.notify("E", "Api Failed " + error.statusText);
        setIsLoading(false);
      },
      (final) => { }
    );
  }

  const [contentPopupOpen, setContentPopupOpen] = useState(false);
  const handleContentPopupClickOpen = () => {
    setFormContentData({
      "id": 0,
      "contentId": null,
      "imageType": null,
      "imageTitle": null,
      "imageUrl": null,
      "imageSize": null,
      "description": null,
      "created_by": null,
      "creation_date": null,
      "last_updated_by": null,
      "last_update_date": null,
      "last_update_login": null,
      "version_number": null
    }
    )
    setContentPopupOpen(true);
  };

  const handleContentPopupClose = () => {
    setContentPopupOpen(false);
  };


  const handleAddContent = (e) => {
    console.log("handleAddContent")
    e.preventDefault();
    setIsLoading(true);
    const data = {
      url: "contentCollections",
      method: "POST",
      body: {
        "contentId": formContentData.contentId,
        "collectionId": formData.id,
        "ordering": formContentData.ordering,
        "active": "Y"
      },
    };

    console.log("Add content with", data)
    api.call(
      data,
      (res) => {
        console.log(res);
        let currentData = res.data.item;
        common.notify("S", "Saved Successfully");
        setContentPopupOpen(false);
        setIsLoading(false);
        loadData(formData.id);
      },
      (error) => {
        common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
        setIsLoading(false);
      },
      (final) => { }
    );

  }

  const handleContentFormChange = (val) => {
    const { name, value } = val.target;
    console.log(name, value)
    setFormContentData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }

  const onDeleteContent = () => {
    console.log(
      "Collection-onDeleteContent-contentSelected ",
      contentSelected,
      " & formData.id",
      formData.id
    );
    if (contentSelected > 0) {
      const data1 = {
        url: "contentCollections",
        query: "/content/" + contentSelected+"/collection/"+formData.id, 
        method: "DELETE",
      };

      console.log("onDeleteContent-data1", data1);
  
      api.call(
        data1,
        (res) => {
          console.log("deleted content collection", res);
          common.notify("S", "Deleted Successfully");
          setIsLoading(false);
          loadData(formData.id);
        },
        (error) => {
          common.notify("E", "Api Error " + error.statusText + " : " + error.data.msg);
          setIsLoading(false);
        },
        (final) => { }
      );
  
    }
  }

  // const dataSource = useCallback(loadData(id), []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="sm"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Collection
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <MDBox pt={2} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit} autoComplete="off">
                    <MDBox mb={2} mt={2} sx={{ width: "100%" }}>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            required
                            value={formData.name}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <MDInput
                            id="type"
                            type="search"
                            required
                            SelectProps={{
                              native: true,
                            }}
                            select
                            fullWidth
                            name="type"
                            label="Type"
                            value={formData.type}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                          >
                            <option value="Static">Static</option>
                            <option value="Dynamic">Dynamic</option>
                          </MDInput>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={9}>
                          <MDInput
                            type="text"
                            label="Description"
                            multiline
                            rows={2}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      {formData.type === "Dynamic" && (
                        <Grid container spacing={1} mt={2}>
                          <Grid item xs={9}>
                            <MDInput
                              type="text"
                              label="Dynamic Criteria"
                              name="dynamicCriteria"
                              value={formData.dynamicCriteria}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={1} mt={2}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="active"
                                checked={formData.active ? "Y" : null}
                                onChange={handleSwitchChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Ordering"
                            name="ordering"
                            required
                            value={formData.ordering}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end", p: 1, m: 1 }}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Apply")}
                      >
                        {!isLoading ? "Apply" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ mr: 1 }}
                        type="submit"
                        disabled={formError}
                        onClick={(e) => handleSubmit(e, "Save")}
                      >
                        {!isLoading ? "Save" : "Processing..."}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="warning"
                        component={Link}
                        to="/collectionslist"
                      >
                        Cancel
                      </MDButton>
                    </MDBox>

                    <MDBox pt={1}>
                      {isStaticType && (
                        <>
                          <MDBox pt={1}>
                            {isAdmin && (
                              <MDButton
                                color="info"
                                sx={{ mr: 1 }}
                                onClick={handleContentPopupClickOpen}
                              >
                                Add
                              </MDButton>
                            )}
                            {contentSelected > 0 && (
                              <MDButton color="error" sx={{ mr: 1 }} onClick={onDeleteContent}>
                                Delete
                              </MDButton>
                            )}
                          </MDBox>
                          <MDBox pt={1}>
                            <Dialog open={contentPopupOpen} onClose={handleContentPopupClose}>
                              <DialogTitle>Add Content</DialogTitle>
                              <DialogContent>
                                <DialogContentText>Content</DialogContentText>
                                <Grid container spacing={1} mt={2} mb={5}>
                                  <Grid item xs={12}>
                                    <div className="selectWrapper">
                                      <label>Content</label>
                                      <AsyncSelect
                                        label="Content"
                                        styles={customSelectStyles}
                                        classNamePrefix="cus-select"
                                        placeholder="Type atleast 2 chars to search content"
                                        cacheOptions
                                        fullWidth
                                        getOptionLabel={(e) => e.titleEn}
                                        getOptionValue={(e) => e.id}
                                        loadOptions={loadContentOptions}
                                        onInputChange={(value) => setContentQuery(value)}
                                        onChange={onContentChange}
                                        sx={{ width: "18%", mr: 1, mt: 2 }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} mt={3}>
                                    <MDInput
                                      type="text"
                                      label="Ordering"
                                      name="ordering"
                                      value={formContentData.ordering}
                                      onChange={handleContentFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1} mt={2}>
                                  {/* <Grid item xs={12}>
                                    <MDInput
                                      type="file"
                                      label="Image Url"
                                      name="imageUrl"
                                      value={formData.imageUrl}
                                      onChange={handleImageFormChange}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                    />
                                  </Grid> */}
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <MDButton onClick={handleContentPopupClose}>Cancel</MDButton>
                                <MDButton onClick={handleAddContent}>Ok</MDButton>
                              </DialogActions>
                            </Dialog>
                            <ReactDataGrid
                              idProperty="id"
                              style={gridStyle}
                              selected={contentSelected}
                              checkboxColumn
                              multiSelect={false}
                              showZebraRows
                              showHoverRows
                              editable
                              onEditComplete={onContentEditComplete}
                              onSelectionChange={onContentSelectionChange}
                              showCellBorders="vertical"
                              columns={[
                                {
                                  header: "Content Title",
                                  name: "title",
                                  defaultFlex: 1.5,
                                  render: ({ data, value }) => (
                                    <MDBox display="flex" alignItems="center" lineHeight={2}>
                                      <MDAvatar
                                        src={data.thumbnail}
                                        name={value}
                                        size="md"
                                        variant="square"
                                      />
                                      <MDBox ml={2} lineHeight={2}>
                                        <MDTypography
                                          variant="caption"
                                          color="text"
                                          fontWeight="medium"
                                        >
                                          {data.titleEn}
                                        </MDTypography>
                                      </MDBox>
                                    </MDBox>
                                  ),
                                },
                                {
                                  header: "Content Type",
                                  name: "contenttype",
                                  defaultFlex: 0.5,
                                  render: ({ data, value }) => (
                                    <MDTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      {data.contentTypes.name}
                                    </MDTypography>
                                  ),
                                },
                                {
                                  header: "Ordering",
                                  name: "ordering",
                                  defaultFlex: 0.7,
                                  headerAlign: "center",
                                  textAlign: "center",
                                  render: ({ data, value }) => (
                                    <MDTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      {data.contentCollections.ordering}
                                    </MDTypography>
                                  ),
                                },
                                {
                                  header: "Active",
                                  name: "active",
                                  defaultFlex: 0.5,
                                  headerAlign: "center",
                                  textAlign: "center",
                                  render: ({ data, value }) => (
                                    // <MDTypography variant="caption" color="text" fontWeight="medium">
                                    //   {data.contentCollections.active}
                                    // </MDTypography>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          name="active"
                                          checked={
                                            data.contentCollections.active === "Y" ? true : false
                                          }
                                          onChange={(e) =>
                                            handleGridSwitchChange(e, data.contentCollections.id)
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                            align: "center",
                                          }}
                                        />
                                      }
                                      label=""
                                    />
                                  ),
                                },
                              ]}
                              pagination
                              dataSource={formData.contents}
                              defaultLimit={10}
                              onSortInfoChange={setSortInfo}
                              // defaultFilterValue={[
                              //   { name: 'contents.title', operator: 'startsWith', type: 'string', value: '' },
                              //   { name: 'contents.contentType', operator: 'startsWith', type: 'string', value: '' },
                              //   { name: 'contentCollections.ordering', operator: 'eq', type: 'number', value: '' },
                              //   { name: 'contentCollections.active', operator: 'startsWith', type: 'string', value: '' },
                              // ]}
                              enableColumnAutosize
                            />
                          </MDBox>
                        </>
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Collections;
