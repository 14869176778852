/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useCallback } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "coreui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "coreui/Navbars/DashboardNavbar";
import Footer from "coreui/Footer";

// Data
import { ThreeDots } from 'react-loader-spinner'

import { api } from "helpers/common/api";
import { common } from "helpers/common/common";
import MDBadge from "components/MDBadge";
import moment from "moment";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import { config } from "helpers/common/config";
import axios from 'axios'

const gridStyle = { minHeight: 600, marginRight: 20, marginLeft: 20 }

function LookupTypesList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(common.userInRole('Admin'));
  const [sortInfo, setSortInfo] = useState([]);

  const getFilter = (filterValue) => {
    let filterString = "";
    let locOperator = "=";
    for (let obj of filterValue) {

      if (obj.type === "string") {
        switch (obj.operator) {
          case "startsWith":
            locOperator = " LIKE " + obj.value + "*";
            break;
          case "endsWith":
            locOperator = " LIKE *" + obj.value;
            break;
          case "empty":
            locOperator = " IS NULL";
            break;
          case "notEmpty":
            locOperator = " IS NOT NULL";
            break;
          case "contains":
            locOperator = " LIKE *" + obj.value + "*";
            break;
          case "notContains":
            locOperator = " NOT LIKE *" + obj.value + "*";
            break;
          case "neq":
            locOperator = "!=" + obj.value;
            break;
          default:
            locOperator = "=" + obj.value;
        }
      }
      else if (obj.type === "number") {
        switch (obj.operator) {
          case "gte":
            locOperator = ">" + obj.value;
            break;
          case "lte":
            locOperator = ">" + obj.value;
            break;
          case "neq":
            locOperator = "!=" + obj.value;
            break;
          default:
            locOperator = "=" + obj.value;
        }
      }
      else if (obj.type === "date") {
        const derivedDate = moment(obj.value, "DD/MM/YYYY").format("YYYY-MM-DD");
        switch (obj.operator) {
          case "eq":
            locOperator = "=" + derivedDate;
            break;
          case "after":
            locOperator = ">" + derivedDate;
            break;
          case "afterOrOn":
            locOperator = ">=" + derivedDate;
            break;
          case "before":
            locOperator = "<" + derivedDate;
            break;
          case "beforeOrOn":
            locOperator = "<=" + derivedDate;
            break;
          case "neq":
            locOperator = "!=" + derivedDate;
            break;
          default:
            locOperator = "=" + derivedDate;
        }
      }

      if (obj.value !== '' && obj.value !==null)
        filterString += obj.name + locOperator + ';'
    }
    console.log(filterString);
    return filterString.slice(0, -1);
  }


  const getSort = (sortInfo) => {
    let sortString = "";
    let locOperator = "=";
    if (sortInfo) {
      switch (sortInfo.dir) {
        case 1:
          locOperator = ":asc";
          break;
        case -1:
          locOperator = ":desc";
          break;
        default:
          locOperator = "";
      }
      sortString += sortInfo.name + locOperator + ','
    }
    console.log(sortString);
    return sortString.slice(0, -1);
  }

  const loadData = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
    setIsLoading(true);
    const filters = getFilter(filterValue);
    const sorts = getSort(sortInfo);
    let url = `${config.api.url}lookupTypes?offset=${skip}&limit=${limit}&totalResults=true`;;
   
    if (filters && filters !== "")
      url += '&q=' + filters;

    if (sorts && sorts !== "")
      url += '&orderBy=' + sorts;
    else
      url += '&orderBy=[["id", "DESC"]]';

    return axios.get(url).then(res => {
      setIsLoading(false);
      return Promise.resolve({ data: res.data.items, count: res.data.totalResults });
    }).catch(err => setIsLoading(false));

  }

  const dataSource = useCallback(loadData, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-2}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Lookup Types
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mr={3} textAlign="end">
                <MDButton variant="gradient" color="secondary" component={Link} to="/lookuptype">
                  New
                </MDButton>
              </MDBox>
              <MDBox pt={1}>
                <ReactDataGrid
                  idProperty="Id"
                  style={gridStyle}
                  loading={isLoading}
                  showZebraRows
                  showHoverRows
                  showCellBorders="none"
                  columns={[
                    {
                      header: "Action", name: "id", defaultFlex: 0.3, headerAlign: 'center', textAlign: 'center', render: ({ value }) => <MDTypography component={Link}
                        to={'/lookuptype/' + value} variant="caption" color="text" fontWeight="medium">
                        Edit
                      </MDTypography>
                    },
                    // {
                    //   header: "LookupType", name: "lookupType", defaultFlex: 0.9, render: ({ data, value }) => <MDTypography component={Link}
                    //     to={'/lookuptype/' + data.id} variant="caption" color="text" fontWeight="medium">
                    //     {value}</MDTypography>
                    // },
                    { header: "LookupType", name: "lookupType", defaultFlex: 0.9},
                    // {
                    //   header: "Ordering", name: "ordering", defaultFlex: 0.9, render: ({ data, value }) => <MDTypography component={Link}
                    //     to={'/lookuptype/' + data.Id} variant="caption" color="text" fontWeight="medium">
                    //     {value}</MDTypography>
                    // },
                    {
                      header: "CustomizationLevel", name: "customizationLevel", defaultFlex: 0.9, render: ({ value }) =>
                        <MDTypography variant="caption" color="text" fontWeight="medium">
                          {value}
                        </MDTypography>
                    },
                  ]}
                  dataSource={dataSource}
                  pagination
                  defaultLimit={10}
                  onSortInfoChange={setSortInfo}
                  enableColumnAutosize
                  enableFiltering
                  defaultFilterValue={[
                    // { name: 'Code', operator: 'startsWith', type: 'string', value: '' },
                    { name: 'lookupType', operator: 'eq', type: 'string', value: '' },
                    // { name: 'Description', operator: 'contains', type: 'string', value: '' },
                  ]
                  }
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default LookupTypesList;
